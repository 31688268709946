import { useState, useEffect } from 'react';
import ApiService from '../../../utilities/apiService/ApiService';
import { STOCKENDPOINT } from '../../../utilities/apiService/constant';
const useStock = (itemRef, filters = []) => {
    const [queries, setQueries] = useState({
        ref: null,
        customer: null,
    });
    const [stocks, setStocks] = useState(null);
    const [isStockLoaded, setIsStockLoaded] = useState(true);
    const loadStock = async () => {
        setIsStockLoaded(false);
        const stocksService = new ApiService(STOCKENDPOINT);
        if (queries.ref) {
            const stocks = await stocksService.search({
                filtered: [
                    { id: 'customerId', value: queries.customer },
                    { id: 'itemCode', value: queries.ref },
                ],
            });
            setStocks(stocks);
            setIsStockLoaded(true);
        }
    };
    useEffect(() => {
        loadStock();
    }, [queries]);
    useEffect(() => {
        let customer = null;
        const filter = filters.filter(el => el.id == 'cmd.costumer.id');
        if (filter.length > 0) {
            customer = filter[0].value;
        }
        setQueries({
            ref: itemRef,
            customer: customer,
        });
    }, [filters, itemRef]);
    return {
        stocks,
        isStockLoaded,
    };
};
export default useStock;
