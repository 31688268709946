import React, { useMemo } from 'react';
import FicheMessage from './FicheMessage';
import Loading from '../../../shared/Loading';
import moment from 'moment';
const Message = ({ isLoading, messages, user }) => {
    const sortResponses = (responses) => {
        return responses.sort((itemA, itemB) => {
            return itemA.createdAt === itemB.createdAt ? 0 : moment(itemA.createdAt).isBefore(itemB.createdAt) ? -1 : 1;
        });
    };
    const messagesToDisplay = useMemo(() => (messages && messages.responses ? sortResponses(messages.responses) : null), [messages]);
    return (React.createElement("div", { className: `card card-color-white card-panel-right message-card overflowY` },
        isLoading && React.createElement(Loading, { withoutLogo: false }),
        !isLoading && messages && React.createElement(FicheMessage, { user: user, message: messages, from: messages.from }),
        messagesToDisplay &&
            messagesToDisplay.length > 0 &&
            messagesToDisplay.map(r => React.createElement(FicheMessage, { user: user, from: messages.from, message: r }))));
};
export default Message;
