import React, { useState } from 'react';
import { RightPanelSelectedRelance } from './FicheRelance';
import AnswerForm from './AnswerForm';
import Icon from '../../../components/Icon/Icon';
import { FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import classnames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';
import Moment from 'react-moment';
import { CloseForm } from '../NewAnomalies/AnomalieCmd';
import ApiService from '../../../utilities/apiService/ApiService';
import { GCALENDARENDPOINT } from '../../../utilities/apiService/constant';
const AnswerComponent = ({ relance, answer, reload, user, answerIdx }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const editComment = () => { };
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const closeModal = () => {
        reload();
        setIsModalOpen(!isModalOpen);
    };
    return (React.createElement("div", { className: classnames('card-message', { author: user.emal === relance.creator.email }) },
        React.createElement("div", { className: 'card-message-row' },
            React.createElement("div", { className: 'card-message-header' },
                React.createElement("div", { className: 'user-container' },
                    React.createElement(Icon, { icon: 'icon-utilisateur', text: answer.creator.displayName, className: 'bold' }),
                    relance.creator.email === user.email && relance.status === 'OPEN' && (React.createElement(Icon, { icon: `icon-edit`, className: 'size-small withPointerEvents cursorPointer', onClick: () => toggleModal() }))),
                React.createElement("div", { className: "small-date" },
                    React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, answer.hasOwnProperty('date') ? answer.date : new Date()))),
            React.createElement("div", { className: 'card-message-body' },
                React.createElement(FicheContainer, { color: 'white', className: "card-message-body-message" },
                    React.createElement("div", null, answer.comment)))),
        React.createElement(Modal, { isOpen: isModalOpen, toggle: toggleModal },
            React.createElement(ModalBody, null,
                React.createElement(AnswerForm, { reload: closeModal, relance: relance, user: user, initialValue: { comment: answer.comment }, answerIdx: answerIdx, isUpdate: true })))));
};
const RightPanelRelances = ({ relance, reload, user }) => {
    const onCloseRelance = () => {
        const relanceService = new ApiService(GCALENDARENDPOINT);
        return relanceService
            .patch({ _id: relance._id, status: relance.status === 'OPEN' ? 'CLOSE' : 'OPEN' })
            .then(() => reload());
    };
    return (React.createElement("div", { className: 'card card-color-lightgray card-panel-right cardRelanceContainer' },
        React.createElement("div", null,
            React.createElement(RightPanelSelectedRelance, { relance: relance, user: user })),
        React.createElement("div", { className: 'answers' }, relance.subject.hasOwnProperty('answers') &&
            relance.subject.answers.map((a, idx) => {
                return (React.createElement(AnswerComponent, { key: `answer-${idx}`, answer: a, answerIdx: idx, relance: relance, reload: reload, user: user }));
            })),
        relance.status === 'OPEN' && React.createElement(AnswerForm, { reload: reload, relance: relance, user: user }),
        relance.status === 'OPEN' && React.createElement(CloseForm, { onCloseAno: onCloseRelance }),
        relance.status === 'CLOSE' && (React.createElement("div", { className: "share-header" },
            React.createElement("div", { className: "header share-form close-form", style: { backgroundColor: 'lightgray' } },
                React.createElement("div", { className: "infos", style: { backgroundColor: 'lightgray' } },
                    React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, relance.updatedAt),
                    React.createElement("div", { className: 'shared-dest-list' }, "Clotur\u00E9")))))));
};
export default RightPanelRelances;
