import { handleActions } from 'redux-actions';
import { SET_ANOMALIE_PANEL_RELOAD, SET_NOTEPANEL__RELOAD, SET_RDVPANEL__RELOAD, SET_RELANCEPANEL__RELOAD, SET_UPDATE_MESSAGE_PANEL_RELOAD, } from './constant';
const defaultState = { anomaliePanelReload: false, messagePanelReload: false, rdvPanelReload: false, notesListReload: false, notePanelReload: false, relancePanelReload: false };
const reloaderReducer = handleActions({
    [SET_ANOMALIE_PANEL_RELOAD]: (state, action) => ({ ...state, anomaliePanelReload: action.payload.reload }),
    [SET_UPDATE_MESSAGE_PANEL_RELOAD]: (state, action) => ({ ...state, messagePanelReload: action.payload.reload }),
    [SET_NOTEPANEL__RELOAD]: (state, action) => ({ ...state, notePanelReload: action.payload.reload }),
    [SET_RELANCEPANEL__RELOAD]: (state, action) => ({ ...state, relancePanelReload: action.payload.reload }),
    [SET_RDVPANEL__RELOAD]: (state, action) => ({ ...state, rdvPanelReload: action.payload.reload }),
}, defaultState);
export default reloaderReducer;
