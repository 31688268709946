import React, { Fragment, useEffect, useState } from 'react';
import { FicheBody, FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import ApiService from '../../../utilities/apiService/ApiService';
import { COSTUMERSENDPOINT } from '../../../utilities/apiService/constant';
import Icon from '../../../components/Icon/Icon';
import { setAddress, setNcIfNull } from '../../../helper/setNcIfNull';
const ClientPanel = ({ client }) => {
    const [clientContacts, setClientContacts] = useState([]);
    useEffect(() => {
        if (!client)
            return;
        (async function () {
            const clientService = new ApiService(COSTUMERSENDPOINT);
            const clients = await clientService.search({
                filtered: [
                    { id: 'costumer.id', value: client.id },
                    { id: 'human', value: true },
                ],
            });
            setClientContacts(clients['hydra:member']);
        })();
    }, [client]);
    return (React.createElement(Fragment, null,
        React.createElement(FicheContainer, { className: 'halfRounded-bottom', color: 'lightgray', onClick: null },
            React.createElement(FicheBody, { columns: 'threeColumns', className: 'mt-10' },
                React.createElement("div", null,
                    React.createElement("div", null, `${setNcIfNull(client.ref)}`),
                    React.createElement("div", null, `${setNcIfNull(client.social_reason)}`)),
                React.createElement("div", null,
                    React.createElement("div", null, `${setAddress(client.adressRow1, client.adressRow2, client.adressRow3, client.adressRow4)}`),
                    React.createElement("div", null, `${setNcIfNull(client.zipCode)} ${setNcIfNull(client.city)}`)),
                React.createElement("div", null,
                    React.createElement("div", null),
                    React.createElement("div", null, `${setNcIfNull(client.phone)}`)))),
        React.createElement("div", { className: 'mt-3 mb-3' }, "Contacts / Charg\u00E9 d affaire"),
        clientContacts && clientContacts.length === 0 && React.createElement("div", null, "Aucun Contacts / Charg\u00E9 d affaire"),
        clientContacts.map(c => {
            return (React.createElement("div", { className: `card card-color-lightgray mb-3` },
                React.createElement(FicheBody, { columns: 'threeColumns' },
                    React.createElement("div", { className: 'flex flex-' },
                        React.createElement(Icon, { icon: 'icon-contact' }),
                        React.createElement("div", null, `${setNcIfNull(c.firstname)} ${setNcIfNull(c.lastname)}`)),
                    React.createElement("div", null, `${setNcIfNull(c.email)}`),
                    React.createElement("div", null, `${setNcIfNull(c.phone)}`))));
        })));
};
export default ClientPanel;
