export const CMDENDPOINT = `${process.env.REACT_APP_API}/api/cmds`;
export const ANOMALYENDPOINT = `${process.env.REACT_APP_API}/api/anomalyCmdLines`;
export const ANOMALYLINEENDPOINT = `${process.env.REACT_APP_API}/api/anomaly_cmd_lines`;
export const PARAMSENDPOINT = `${process.env.REACT_APP_API}/api/parameters`;
export const COSTUMERSENDPOINT = `${process.env.REACT_APP_API}/api/costumers`;
export const SALERSENDPOINT = `${process.env.REACT_APP_API}/api/internal_users`;
export const KPIENDPOINT = `${process.env.REACT_APP_API}/api/kpis/total-ca`;
export const KPIOUTSTANDINGENDPOINT = `${process.env.REACT_APP_API}/api/kpis/outstanding`;
export const OUTSTANDINGENDPOINT = `${process.env.REACT_APP_API}/api/bills`;
export const ANOMALIESENDPOINT = `${process.env.REACT_APP_API}/api/cmds`;
export const CMDLINESENDPOINT = `${process.env.REACT_APP_API}/api/cmd_lines`;
export const STOCKENDPOINT = `${process.env.REACT_APP_PIM_ENDPOINT}/api/stock`;
export const ANOMALIECMDLINESENDPOINT = `${process.env.REACT_APP_API}/api/anomaly_cmd_lines`;
export const HUMANFUNCTIONENDPOINT = `${process.env.REACT_APP_API}/api/human_functions`;
export const PRODUCTSENDPOINT = `${process.env.REACT_APP_PIM_ENDPOINT}/api/items`;
export const PRODUCTELASTICENDPOINT = `${process.env.REACT_API_ELASTIC}/csc-items/search.json`;
export const COSTUMERELASTICENDPOINT = `${process.env.REACT_API_ELASTIC}/csc-customers/search.json`;
export const CONTACTLASTICENDPOINT = `${process.env.REACT_API_ELASTIC}/csc-contact/search.json`;
export const MESSAGEENDPOINT = `${process.env.REACT_APP_NOTIFICATION}/messages`;
export const MESSAGEENDPOINTPROD = `${process.env.REACT_APP_NOTIFICATION}/messages`;
export const NOTIFICATIONPOINT = `${process.env.REACT_APP_NOTIFICATION}/user-message`;
export const MESSAGEREADENPOINT = `${process.env.REACT_APP_NOTIFICATION}/messages-mark`;
export const SOCKETENDPOINT = `${process.env.REACT_APP_SOCKET}`;
export const GCALENDARENDPOINT = `${process.env.REACT_APP_API_CALENDAR}/events`;
