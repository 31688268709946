import moment from 'moment';
import ApiService from '../../utilities/apiService/ApiService';
import { ANOMALIECMDLINESENDPOINT, GCALENDARENDPOINT, MESSAGEENDPOINT } from '../../utilities/apiService/constant';
export const formatDate = data => {
    const { date, startDate, endDate } = data;
    const newStartDate = moment(date);
    const newEndDate = moment(date);
    const newStartTime = moment(startDate, 'HH:mm');
    const newEndTime = moment(endDate, 'HH:mm');
    newStartDate.set({
        hour: newStartTime.get('hour'),
        minute: newStartTime.get('minute'),
    });
    newEndDate.set({
        hour: newEndTime.get('hour'),
        minute: newEndTime.get('minute'),
    });
    return {
        start: newStartDate.format('YYYY-MM-DD HH:mm:ss'),
        end: newEndDate.format('YYYY-MM-DD HH:mm:ss'),
    };
};
export const updateRdv = (data) => {
    const { company, attendees, creator } = data;
    const { start, end } = formatDate(data);
    const postData = {
        _id: data._id,
        start,
        end,
        creator,
        subject: { costumer: company.all, type: 'costumer' },
        organizer: creator,
        type: 'appointment',
        attendees: attendees.map(a => a.all),
        location: company.all.address,
    };
    const rdvService = new ApiService(GCALENDARENDPOINT);
    return rdvService.patch(postData);
};
export const submitRdv = (data) => {
    const { company, attendees, creator } = data;
    const { start, end } = formatDate(data);
    const postData = {
        start,
        end,
        creator,
        subject: { costumer: { ...company.all }, type: 'costumer' },
        organizer: creator,
        status: 'dontcare',
        type: 'appointment',
        attendees: attendees.map(a => a.all),
        location: company.all.address,
    };
    const rdvService = new ApiService(GCALENDARENDPOINT);
    return rdvService.create(postData);
};
export const updateNoteStatus = (data, status) => {
    const noteService = new ApiService(GCALENDARENDPOINT);
    const postData = {
        _id: data._id,
        status: status,
    };
    return noteService.patch(postData);
};
export const updateNote = data => {
    const { start, end } = formatDate(data);
    const { _id, status, comments } = data;
    const noteService = new ApiService(GCALENDARENDPOINT);
    const postData = {
        _id,
        start,
        end,
        status,
        note: comments,
    };
    return noteService.patch(postData);
};
export const updateRelance = data => {
    const relanceService = new ApiService(GCALENDARENDPOINT);
    return relanceService.patch(data);
};
export const submitRelance = (data, selectedAffair) => {
    const { creator, organizer, receiverMe, receiver, comments } = data;
    const { start, end } = formatDate(data);
    const postData = {
        start,
        end,
        creator,
        organizer: organizer,
        status: 'OPEN',
        type: 'relance',
        note: comments,
        subject: {
            cmd: selectedAffair,
            saler: receiverMe ? receiverMe.all : receiver.all,
        },
    };
    const relanceService = new ApiService(GCALENDARENDPOINT);
    return relanceService.create(postData);
};
export const submitNote = (data) => {
    const { cmd, client, creator, organizer, comments, status } = data;
    const { start, end } = formatDate(data);
    const postData = {
        start,
        end,
        creator,
        status,
        note: comments,
        subject: {
            cmd: cmd ? { ...cmd.all, costumer: client ? client.all : null } : null,
            type: 'affair',
        },
        organizer: organizer,
        type: 'note',
    };
    const noteService = new ApiService(GCALENDARENDPOINT);
    return noteService.create(postData);
};
export const updateMessage = data => {
    const messageService = new ApiService(MESSAGEENDPOINT);
    return messageService.patch(data);
};
export const submitAnomalies = (data) => {
    const anomaliesService = new ApiService(ANOMALIECMDLINESENDPOINT);
    const res = anomaliesService.create(data);
    return res;
};
export const updateAnomalie = data => {
    const anomaliesService = new ApiService(ANOMALIECMDLINESENDPOINT);
    return anomaliesService.update(data);
};
export const submitMessage = data => {
    console.log('::SubmitMessage::', data);
    const messageService = new ApiService(MESSAGEENDPOINT);
    return messageService.create(data);
};
