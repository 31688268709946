import React, { useState, useEffect, useRef } from 'react';
import echarts from 'echarts';
const TdChart = ({ canToDisplay, can, can1 }) => {
    const tauxReg = can.map((d, idx) => {
        const size = can1.length - 1;
        if (idx <= size) {
            if (can1[idx].value !== 0)
                return `${((d.value / can1[idx].value - 1) * 100).toFixed(2)}%`;
            return '0%';
        }
        return '0%';
    });
    return (React.createElement("tr", null, canToDisplay.map((d, idx) => {
        return (React.createElement("td", null,
            `${d.sellsQty}/${d.offerQty}`,
            React.createElement("br", null),
            `${d.value}€`,
            React.createElement("br", null),
            tauxReg[idx]));
    })));
};
const EchartCaBrut = ({ data }) => {
    const [chart, setChart] = useState(null);
    useEffect(() => {
        setChart(echarts.init(document.getElementById(`graphCaBrutCostumer`)));
    }, []);
    const chartsContainer = useRef(null);
    useEffect(() => {
        if (!chart)
            return;
        const dataX = [];
        const dataYearB = [];
        const dataYearC = [];
        data[0].map(el => {
            dataX.push(el.date);
            dataYearB.push(el);
            return null;
        }, []);
        data[1].map(el => {
            dataYearC.push(el);
            return null;
        }, []);
        const option = {
            grid: {
                right: '10px',
                left: '10px',
                top: '10px',
                bottom: '40px',
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    const dataCan1 = params[0].data;
                    const dataCan = params[1].data;
                    return `<div class="toolTip"><div>${dataCan1.sellsQty}/${dataCan1.offerQty}<br />${dataCan1.value}€</div><div>${dataCan.sellsQty}/${dataCan.offerQty}}<br />${dataCan.value}€</div></</div>`;
                },
                padding: 0,
                position: function (pt) {
                    return [pt[0], '0%'];
                },
            },
            xAxis: {
                type: 'category',
                data: dataX,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#ccc',
                    },
                },
                axisLabel: {
                    fontSize: 10,
                    color: '#7c8187',
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#c8c7cc',
                    },
                },
            },
            yAxis: {
                type: 'value',
                show: false,
            },
            series: [
                {
                    data: dataYearB,
                    type: 'line',
                    clip: true,
                    showSymbol: false,
                    lineStyle: { color: '#cfd3d8' },
                },
                {
                    data: dataYearC,
                    type: 'line',
                    clip: true,
                    showSymbol: false,
                    lineStyle: { color: '#4690ef' },
                },
            ],
        };
        chart.setOption(option, true);
    }, [chart]);
    const getWidth = () => {
        return chartsContainer.clientWidth;
    };
    return (React.createElement("div", null,
        React.createElement("div", { id: `graphCaBrutCostumer`, style: { width: getWidth(), height: '300px' } }),
        React.createElement("table", { id: "tableCaBrutCostumer" },
            React.createElement("tbody", null,
                React.createElement(TdChart, { canToDisplay: data[1], can: data[1], can1: data[0] }),
                React.createElement(TdChart, { canToDisplay: data[0], can: data[1], can1: data[0] })))));
};
export default EchartCaBrut;
