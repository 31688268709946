import React from 'react';
export const FicheHeader = ({ children, columns }) => {
    return React.createElement("div", { className: `card_header ${columns}` }, children);
};
export const FicheBody = ({ children, columns, className }) => {
    return React.createElement("div", { className: `card_body ${columns} ${className}` }, children);
};
export const FicheContainer = ({ color, children, onClick, className }) => {
    return (React.createElement("div", { className: `card card-color-${color} ${className}`, onClick: onClick }, children));
};
export const FicheMainContainer = ({ headerTitle, icon, columns, children }) => {
    return (React.createElement("div", { className: `cards-container  ${headerTitle ? '' : 'p-0'}` },
        React.createElement("div", { className: `cards-container_header ${headerTitle ? '' : 'p-0'}` },
            icon && React.createElement("i", { className: `${icon}` }),
            headerTitle && (React.createElement("p", { className: `cards-container_headerTitle ${icon != 'undefined' ? '' : 'text-left color-primary'}` }, headerTitle))),
        React.createElement("div", { className: `cards-container_body ${columns}` }, children)));
};
