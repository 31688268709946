import React, { useState, Fragment } from 'react';
import LoginForm from './LoginForm';
import { withRouter } from 'react-router';
import { FORM_ERROR } from 'final-form';
import * as Session from '../../../shared/session';
import Loading from '../../../shared/Loading';
import { useDispatch } from 'react-redux';
import { setCostumer, setUser } from '../../../../redux/user/action';
import ApiService from '../../../utilities/apiService/ApiService';
import { COSTUMERSENDPOINT, HUMANFUNCTIONENDPOINT, PARAMSENDPOINT } from '../../../utilities/apiService/constant';
import { setHumanFunctions } from '../../../../redux/humanFunctions/action';
import { setParams } from '../../../../redux/params/action';
const Login = ({ history }) => {
    const [loaded, setLoaded] = useState(true);
    const dispatch = useDispatch();
    const onSubmit = async (data) => {
        setLoaded(false);
        try {
            await Session.login(data.username, data.password);
            const newUser = await Session.getUser();
            if (newUser && newUser.take && Array.isArray(newUser.take) && newUser.take.length === 0) {
                alert("Vous n'avez pas d'organisations internes");
                await Session.logout();
                setLoaded(true);
                return { [FORM_ERROR]: "Vous n'avez pas d'organisations internes" };
            }
            if (!newUser.roles.includes('ROLE_ADMIN')) {
                await Session.logout();
                throw { message: 'Vous n’avez pas l’autorisation d’accéder à cette interface. ' };
            }
            else {
                const paramsService = new ApiService(PARAMSENDPOINT);
                const params = await paramsService.getAll();
                const humanFunctionService = new ApiService(HUMANFUNCTIONENDPOINT);
                const humanFunctions = await humanFunctionService.getAll();
                const clientService = new ApiService(COSTUMERSENDPOINT);
                const clientRes = await clientService.search({
                    filtered: [{ id: 'saler.id', value: newUser.take.id }],
                });
                const client = clientRes['hydra:member'];
                if (client && client.length > 0) {
                    dispatch(setCostumer(client[0]));
                }
                if (humanFunctions) {
                    dispatch(setHumanFunctions(humanFunctions['hydra:member'].filter(hf => hf.label !== '')));
                }
                if (params)
                    dispatch(setParams(params.CMD));
                await dispatch(setUser(newUser));
                history.push('/main/dashboard');
            }
            return;
        }
        catch (e) {
            return { [FORM_ERROR]: e.message };
        }
        finally {
            setLoaded(true);
        }
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'loginLayout' },
            !loaded && React.createElement(Loading, { withoutLogo: false }),
            React.createElement("img", { src: "/img/logo_keyops_crm_blanc.png", className: 'mb-30' }),
            React.createElement(LoginForm, { onSubmit: onSubmit }))));
};
export default withRouter(Login);
