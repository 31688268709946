import { useEffect, useState } from 'react';
import ApiService from '../../../utilities/apiService/ApiService';
import { CMDENDPOINT } from '../../../utilities/apiService/constant';
export const useAffaires = (pDefaultFilters = []) => {
    const [defaultFilters] = useState(pDefaultFilters);
    const [filters, setFilters] = useState(null);
    const [affaires, setAffaires] = useState([]);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [selectedAffaire, setSelectedAffaire] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const getSorted = () => {
        const currentFilters = formatFilters();
        const badgeFilters = currentFilters.find(f => f.id === 'status');
        if (!badgeFilters || badgeFilters.value === 'of') {
            return [{ id: 'writeAt', desc: true }];
        }
        else {
            return [{ id: 'orderAt', desc: true }];
        }
    };
    const formatFilters = () => {
        const eq = {
            'createdAt[after]': 'writeAt[after]',
            'createdAt[before]': 'writeAt[before]',
            costumer: 'costumer.id',
            saler: 'saler.id',
            dateAfter: 'writeAt[after]',
            dateBefore: 'writeAt[before]',
        };
        return filters
            ? filters.map(f => ({ id: eq[f.id] ? eq[f.id] : f.id, value: f.value })).concat(...defaultFilters)
            : defaultFilters;
    };
    const getOneAffair = async (id) => {
        const affairService = new ApiService(CMDENDPOINT);
        const affaire = await affairService.get({ _id: id });
        setSelectedAffaire(affaire);
    };
    const fetchAffairs = async () => {
        const affaireService = new ApiService(CMDENDPOINT);
        setIsLoading(true);
        const results = await affaireService.search({ filtered: formatFilters(), sorted: getSorted(), page: page });
        const totalItems = results['hydra:totalItems'];
        const data = results['hydra:member'];
        const newData = page > 1 ? [...affaires, ...data] : data;
        setHasNextPage(newData.length < totalItems);
        setIsLoading(false);
        setAffaires(newData);
    };
    const reloadData = () => {
        if (selectedAffaire) {
            getOneAffair(selectedAffaire.id);
        }
        setPage(1);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setPage(page + 1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        if (filters !== null) {
            setPage(1);
            setFireUseEffect(Math.random());
        }
    }, [filters]);
    useEffect(() => {
        fetchAffairs();
    }, [fireUseEffect]);
    return {
        affaires,
        isLoading,
        hasNextPage,
        getOneAffair,
        loadNextPage,
        reloadData,
        setFilters,
        setSelectedAffaire,
        selectedAffaire,
    };
};
