import { handleActions } from 'redux-actions';
import { SET_SELECTED_PRODUCT_FILTER_OPTIONS, SET_SELECTED_PRODUCT_FILTER, SET_SELECTED_PRODUCT_RELOAD_FILTER, RESET, } from './constant';
const defaultState = {
    salers: [],
    costumer: [],
    filters: [],
    reloadFilter: true,
};
const selectedProductFilterReducer = handleActions({
    [RESET]: (state, action) => {
        return {
            ...defaultState,
        };
    },
    [SET_SELECTED_PRODUCT_FILTER_OPTIONS]: (state, action) => {
        return {
            salers: action.payload.salers,
            costumers: action.payload.costumers,
            filters: [],
            reloadFilter: false,
        };
    },
    [SET_SELECTED_PRODUCT_FILTER]: (state, action) => {
        return { ...state, filters: action.payload.filters };
    },
    [SET_SELECTED_PRODUCT_RELOAD_FILTER]: (state, action) => {
        return { ...state, reloadFilter: true };
    },
}, defaultState);
export default selectedProductFilterReducer;
