import React, { Fragment } from 'react';
import { FicheBody, FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import { setAddress, setNcIfNull } from '../../../helper/setNcIfNull';
const ContactEnterprisePanel = ({ costumer }) => {
    return (React.createElement(Fragment, null,
        React.createElement("div", null, "Entreprise / Client"),
        React.createElement(FicheContainer, { className: 'halfRounded-bottom', color: 'lightgray', onClick: null }, costumer ? (React.createElement(FicheBody, { columns: 'threeColumns', className: 'mt-10' },
            React.createElement("div", null, `${setNcIfNull(costumer.socialReason)}`),
            React.createElement("div", null, `${setAddress(costumer.adressRow1, costumer.adressRow2, costumer.adressRow3, costumer.adressRow4, costumer.zipCode, costumer.city)}`),
            React.createElement("div", null, `${setNcIfNull(costumer.phone)}`))) : (React.createElement(FicheBody, { columns: 'singleColumn', className: 'mt-10' },
            React.createElement("div", null, "Ce contact n'est associé a aucun client"))))));
};
const ContactPanel = ({ contact }) => {
    return (React.createElement(Fragment, null,
        React.createElement(FicheContainer, { className: 'halfRounded-bottom', color: 'lightgray', onClick: null },
            React.createElement(FicheBody, { columns: 'threeColumns', className: 'mt-10' },
                React.createElement("div", null, `${setNcIfNull(contact.first_name)} ${setNcIfNull(contact.last_name)}`),
                React.createElement("div", null, `${setNcIfNull(contact.email)}`),
                React.createElement("div", null,
                    React.createElement("div", null, `${setNcIfNull(contact.phone)}`),
                    React.createElement("div", null, `${setNcIfNull(contact.mobile)}`)))),
        React.createElement(ContactEnterprisePanel, { costumer: contact.costumer })));
};
export default ContactPanel;
