import React, { Fragment } from 'react';
const TextareaField = ({ onChange, name, className, value, label = '', placeholder = '', disabled = false, meta, maxLength, }) => {
    return (React.createElement("div", { className: "textareaContainer w-100" },
        label && (React.createElement("label", null,
            React.createElement("span", null, label))),
        React.createElement("textarea", { "data-testid": "form_textarea_field", className: `textarea_field ${className}`, id: name, name: name, onChange: onChange, disabled: disabled, value: value, placeholder: placeholder, maxLength: maxLength }),
        meta.touched && meta.error && React.createElement("span", { className: "form__form-group-error formError" }, meta.error)));
};
const Fieldtextarea = ({ input, label = '', className = '', placeholder = '', disabled = false, meta, maxLength = 200, }) => {
    return (React.createElement(Fragment, null,
        React.createElement(TextareaField, Object.assign({}, input, { label: label, meta: meta, className: className, placeholder: placeholder, disabled: disabled, maxLength: maxLength }))));
};
export default Fieldtextarea;
