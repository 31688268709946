import React from 'react';
import classnames from 'classnames';
const Icon = ({ icon, text, onClick, className, classNameTxt, bold = false, classNameIcon = '', style = null, }) => {
    return (React.createElement("div", { className: `iconContainer ${className}`, onClick: () => {
            onClick();
        }, style: style },
        React.createElement("i", { className: `${icon} ${classNameIcon}` }),
        text && React.createElement("p", { className: classnames({ 'iconText-font-weights-regular': bold }, classNameTxt) }, text)));
};
export default Icon;
