import React, { useState } from 'react';
import { FicheBody, FicheMainContainer, FicheContainer } from './FicheDefaultLayout';
import Icon from '../Icon/Icon';
import Moment from 'react-moment';
import NameFormat from '../Nameformat';
import moment from 'moment';
import { getDiffFromToday } from '../../utilities/helper/momentHelper';
import { Redirect } from 'react-router';
export const FicheNotePanel = ({ isBlue = false, openModal, mainNote, validateNote, classNameOverload, }) => {
    const { start, subject, note, organizer: { displayName: oName }, createdAt, status, } = mainNote;
    const subjectName = subject.hasOwnProperty('cmd') ? subject.cmd.costumer.socialReason : 'null';
    const diff = getDiffFromToday(moment(new Date()), start);
    const symbol = diff === 0 ? '' : diff > 0 ? '+' : '';
    const formatedDate = `${symbol}${diff} J`;
    const renderNoteWithLineBreaks = () => {
        return note.replace(/\n/g, '<br />');
    };
    const noteHtml = renderNoteWithLineBreaks();
    return (React.createElement("div", { className: classNameOverload ? classNameOverload : `card card-color-lightgray card-panel-right` },
        React.createElement(FicheContainer, { color: isBlue ? 'lightblue' : 'white', className: 'card-note' },
            React.createElement("div", { className: 'card-note-header' },
                React.createElement(Icon, { icon: 'icon-entreprise', text: subjectName, bold: true }),
                React.createElement(Icon, { icon: 'icon-affaire', text: oName, bold: true }),
                React.createElement(Icon, { icon: 'icon-utilisateur', text: NameFormat({ value: oName }), bold: true }),
                React.createElement("div", { className: "actions" },
                    React.createElement(Icon, { icon: `icon-reminder ${status === 'confirmed' ? (diff > 0 ? 'color-green' : 'color-red') : ''}`, text: status === 'fixed' ? '-' : formatedDate }),
                    React.createElement(Icon, { icon: `icon-check_2 ${status === 'confirmed' ? 'color-green' : ''}`, className: 'withPointerEvents cursorPointer', onClick: () => validateNote(mainNote._id) }),
                    React.createElement(Icon, { icon: 'icon-edit', className: 'withPointerEvents cursorPointer', onClick: openModal }))),
            React.createElement("div", { className: 'card-note-header' },
                React.createElement(Icon, { icon: 'icon-contact', text: subject.cmd.costumer.lastname, bold: true }),
                React.createElement(Icon, { icon: 'icon-chantier', text: subject.cmd.ref, bold: true }),
                React.createElement("div", { className: "small-date" },
                    React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, createdAt)),
                React.createElement("div", null)),
            React.createElement("div", { className: 'card-note-body', dangerouslySetInnerHTML: { __html: noteHtml } }))));
};
export const FicheNote = ({ mainNote, idx, selected, onClick }) => {
    const { start, subject, creator: { displayName: cName }, organizer: { displayName: oName }, createdAt, status, } = mainNote;
    const onClickFiche = () => {
        onClick(idx);
    };
    const subjectName = subject.hasOwnProperty('cmd') ? subject.cmd.costumer.socialReason : 'null';
    const diff = getDiffFromToday(moment(new Date()), start.date);
    const symbol = diff === 0 ? '' : diff > 0 ? '+' : '';
    const formatedDate = `${symbol}${diff} J`;
    return (React.createElement(FicheContainer, { color: 'lightblue', className: selected ? 'card--selected card--hover' : 'card--hover', onClick: () => onClickFiche() },
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-entreprise', text: subjectName, bold: true, className: 'nowrap' }),
            React.createElement(Icon, { icon: `icon-reminder ${status === 'confirmed' ? (diff > 0 ? 'color-green' : 'color-red') : ''}`, text: status === 'fixed' ? '-' : formatedDate, bold: true, className: 'nowrap align-right' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-utilisateur', text: NameFormat({ value: oName }), bold: true, className: 'nowrap' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-affaire', text: oName, bold: true, className: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-utilisateur', text: NameFormat({ value: cName }), bold: true, className: 'nowrap align-right' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-chantier', text: oName, bold: true, className: 'nowrap' }),
            React.createElement("div", { className: 'nowrap small-date align-right' },
                React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, createdAt)))));
};
export const NotesDashboard = ({ notes, onClick, headerTitle = 'Dernièrs Messages', selected = null, }) => {
    const [redirect, setRedirect] = useState(null);
    const redirectToNote = note => {
        setRedirect(note);
    };
    return (React.createElement(FicheMainContainer, { columns: 'dualColumns', headerTitle: headerTitle, icon: 'icon-note' },
        notes
            .filter(n => n.subject.hasOwnProperty('cmd'))
            .map((note, idx) => {
            return (React.createElement("div", { key: `note-${idx}`, onClick: () => redirectToNote(note) },
                React.createElement(FicheNote, { mainNote: note, selected: selected === idx, idx: idx, onClick: onClick })));
        }),
        redirect && React.createElement(Redirect, { to: `/main/notes/rightpanel/notes/${redirect._id}/selectedNote` })));
};
