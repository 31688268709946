import React, { Fragment, useState } from 'react';
import { Form } from 'react-final-form';
import FFTextArea from '../../../components/FinalForm/FFTextArea';
import { required } from '../../../components/WhForm/WhFields/Validator';
import { FFAsyncSelect } from '../../../components/FinalForm/FFSelect';
import { CMDENDPOINT, COSTUMERSENDPOINT } from '../../../utilities/apiService/constant';
import FFText from '../../../components/FinalForm/FFText';
import { formatDate, submitNote, updateNote } from '../../../components/RightPanelModal/ModalService';
import Loading from '../../../shared/Loading';
import { replaceEntityFields } from '../../../utilities/storage';
const ModalNote = ({ onClose, user, cmd, client, initialValues = null, isDisabled = false, isUpdate = false, reload, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const formatCmdSaler = cmd => {
        return { ...cmd, saler: { ...cmd.saler, id: `${cmd.saler.id}` } };
    };
    const formatClient = obj => ({
        label: obj.socialReason,
        id: obj.id,
        value: obj['@id'],
        all: { ...obj, id: `${obj.id}` },
    });
    const formatCmd = obj => {
        return { label: obj.ref, value: obj['@id'], all: formatCmdSaler(obj) };
    };
    const onUpdate = (data) => {
        return updateNote({ ...data })
            .then((result) => {
            console.log({ result, reload });
            setIsLoading(false);
            console.log('flag 1');
            const { start, end } = formatDate(data);
            console.log('flag 1 bis');
            replaceEntityFields('notes', result._id, {
                note: data.comments,
                start,
                end,
            });
            if (reload)
                reload();
            console.log('flag 2');
            onClose();
        })
            .catch(() => setIsLoading(false));
    };
    const onSubmit = (data) => {
        console.log('FLAG DATA', { data });
        setIsLoading(true);
        return submitNote({ ...data })
            .then(() => {
            setIsLoading(false);
            if (reload)
                reload();
            onClose();
        })
            .catch(() => setIsLoading(false));
    };
    const mutators = {
        setRef: (_args, state, utils) => {
            utils.changeValue(state, 'cmd', () => _args[0]);
        },
        setDate: (_args, state, utils) => {
            utils.changeValue(state, 'date', () => _args[0]);
            utils.changeValue(state, 'startDate', () => '00');
            utils.changeValue(state, 'endDate', () => '00');
        },
        setClient: (_args, state, utils) => {
            utils.changeValue(state, 'client', () => _args[0]);
        },
    };
    console.log({ user });
    return (React.createElement(Form, { onSubmit: isUpdate ? onUpdate : onSubmit, initialValues: initialValues
            ? initialValues
            : {
                creator: { id: user.id, email: user.email, displayName: user.name },
                organizer: { id: user.id, email: user.email, displayName: user.name },
                comments: '',
                client: cmd
                    ? {
                        label: cmd.costumer.socialReason,
                        value: cmd.costumer['@id'],
                        all: { ...cmd.costumer, id: `${cmd.costumer.id}` },
                    }
                    : null,
                status: 'confirmed',
                cmd: cmd ? { label: cmd.ref, value: cmd['@id'], all: cmd } : null,
                date: null,
                startDate: null,
                endDate: null,
            }, mutators: { ...mutators }, render: ({ handleSubmit, values, form: { mutators } }) => {
            return (React.createElement("form", { onSubmit: handleSubmit },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                React.createElement("div", { className: "whModal noteModal darkkModal", style: { width: '50%' } },
                    React.createElement("div", { className: "whModal-header singleColumn mb-15" },
                        React.createElement("i", { className: "text-title text-color-green icon-note text-center" })),
                    React.createElement(Fragment, null,
                        React.createElement("div", { className: "whModal-body" },
                            React.createElement(FFTextArea, { name: 'comments', label: '', className: 'w-100', validate: required, placeholder: 'Commentaire' }),
                            React.createElement("div", { className: "whModal-btnContainer grid singleColumn mt-25" },
                                React.createElement(FFAsyncSelect, { format: formatClient, disabled: isDisabled, validate: required, onChange: value => mutators.setClient(value), className: 'whModal-select whModal-btn', classNamePrefix: 'modal_select', defaultFilter: [{ id: 'human', value: false }], searchFilter: { id: 'socialReason', value: null }, name: 'client', endpoint: COSTUMERSENDPOINT, placeholder: 'Client', label: '' })),
                            React.createElement("div", { className: "whModal-btnContainer grid singleColumn mt-25" },
                                React.createElement(FFAsyncSelect, { key: JSON.stringify(values.client), format: formatCmd, validate: required, onChange: value => mutators.setRef(value), className: 'whModal-select whModal-btn', classNamePrefix: 'modal_select', disabled: !isDisabled && values.client ? false : true, defaultFilter: values.client
                                        ? [
                                            { id: 'costumer.socialReason', value: values.client.label },
                                        ]
                                        : [], searchFilter: { id: 'ref', value: null }, name: 'cmd', endpoint: CMDENDPOINT, placeholder: 'Reference', label: '' })),
                            React.createElement("div", { className: "whModal-btnContainer grid singleColumn mt-25" },
                                React.createElement(FFText, { name: 'date', type: 'date', label: '', onChange: mutators.setDate, validate: required }))),
                        React.createElement("div", { className: "whModal-footer mt-25 flex-directionRowReverse flex-justifyStart flex-alignCenter" },
                            React.createElement("div", { className: "whModal-btnContainer reverse" },
                                React.createElement("button", { type: 'submit', className: "whModal-btn whModal-btn-primary text-regular" }, "Enregistrer"),
                                React.createElement("div", { onClick: onClose, className: "whModal-btn whModal-btn-secondary mr-15 text-regular" }, "Annuler")))))));
        } }));
};
export default ModalNote;
