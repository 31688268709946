import { createAction } from 'redux-actions';
import { SET_USER } from './constant.js';
import { SET_COSTUMER, SET_HIDDEN } from './constant';
export const defaultUser = {
    id: null,
    name: null,
    email: null,
    username: null,
    mobile: null,
    picture: null,
    roles: null
};
export const setUser = createAction(SET_USER, (user) => { return { user }; });
export const setHidden = createAction(SET_HIDDEN, (hidden) => ({ hidden }));
export const setCostumer = createAction(SET_COSTUMER, (costumer) => ({ costumer }));
