import React, { Fragment, useState } from 'react';
import { Form } from 'react-final-form';
import FFTextArea from '../../../components/FinalForm/FFTextArea';
import { submitAnomalies, submitMessage } from '../../../components/RightPanelModal/ModalService';
import Loading from '../../../shared/Loading';
const ModalAnomalie = ({ onClose, affair = null, user, humanFunctions, reload = null }) => {
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = (data) => {
        if (!affair) {
            return;
        }
        let { tos, comment, ...updatedData } = data;
        setIsLoading(true);
        updatedData = {
            ...updatedData,
            shared: data.tos ? data.tos.length > 0 : false,
        };
        let formatedTos = [];
        formatedTos = humanFunctions
            ? humanFunctions
                .map(hf => {
                const el = data.tos.find(el => el === hf.label);
                if (el)
                    return `FUNC_${hf.id}`;
                else
                    return null;
            })
                .filter(el => el !== null)
            : [];
        return submitAnomalies(updatedData)
            .then(res => {
            return submitMessage({
                tos: formatedTos,
                from: {
                    id: user.id,
                    sub: user.id,
                    email: user.email,
                    name: `${user.name}`,
                    status: 'readed',
                },
                subject: data.title,
                body: data.comment === '' ? 'Merci de checker cette anomalie' : data.comment,
                url: `main/anomalies/rightpanel/anomalies/${res.id}/selectedAnomalie`,
                type: 'chat',
                entity: 'anomalie',
                entityId: res.id,
                responses: [],
            })
                .then(() => {
                setIsLoading(false);
                onClose();
                if (reload)
                    reload();
                return;
            })
                .catch(() => {
                setIsLoading(false);
                onClose();
            });
        })
            .catch(() => setIsLoading(false));
    };
    const btnMutator = {
        setBtn: (_args, state, utils) => {
            utils.changeValue(state, 'tos', value => {
                const updatedValue = [...value];
                const idx = updatedValue.findIndex(v => v === _args[0]);
                idx === -1 ? updatedValue.push(_args[0]) : updatedValue.splice(idx, 1);
                return updatedValue;
            });
        },
    };
    return (React.createElement(Form, { onSubmit: onSubmit, initialValues: {
            title: `Anomalie - ${affair.ref} - ${affair.costumer.name}`,
            comment: '',
            tos: [],
            type: 4,
            status: 1,
            shared: false,
            cmd: affair['@id'],
        }, mutators: { ...btnMutator }, render: ({ handleSubmit, values, form: { mutators } }) => {
            return (React.createElement("form", { onSubmit: handleSubmit },
                isLoading && React.createElement(Loading, { withoutLogo: true }),
                React.createElement("div", { className: "whModal darkkModal darkModalAnomalie anomalieModal" },
                    React.createElement("div", { className: "whModal-header singleColumn mb-15" },
                        React.createElement("i", { className: "text-title text-color-green icon-anomalies-full text-center" })),
                    React.createElement(Fragment, null,
                        React.createElement("div", { className: "whModal-body" },
                            React.createElement("div", { className: "mb-5" },
                                React.createElement(FFTextArea, { name: 'title', placeholder: 'Titre', required: true, className: 'text-color-gray textarea-title text-left w-100', label: '' })),
                            React.createElement("div", { className: 'comment-area' },
                                React.createElement(FFTextArea, { name: 'comment', placeholder: 'Merci de checker cette anomalie', className: 'text-color-gray text-left w-100', label: '' })),
                            React.createElement("p", { className: "mt-20 mb-20 text-color-white text-center" }, "Destinataire(s)"),
                            React.createElement("div", { className: "whModal-btnContainer grid fourColumns w-100 flex-justifyCenter flex-alignCenter flex-selfCenter" }, humanFunctions &&
                                humanFunctions.map(hf => {
                                    return (React.createElement("div", { onClick: () => mutators.setBtn(hf.label), className: `whModal-btn anomalie-types ${values.tos.includes(hf.label)
                                            ? 'whModal-btn-primary'
                                            : 'whModal-btn-secondary'} text-regular` }, hf.label));
                                }))),
                        React.createElement("div", { className: "whModal-footer mt-25 flex-directionRowReverse flex-justifyStart flex-alignCenter" },
                            React.createElement("div", { className: "whModal-btnContainer reverse" },
                                React.createElement("button", { type: 'submit', className: "whModal-btn whModal-btn-primary text-regular" }, "Enregistrer"),
                                React.createElement("div", { onClick: onClose, className: "whModal-btn whModal-btn-secondary mr-15 text-regular" }, "Annuler"))))),
                isLoading && React.createElement("div", { className: "whModal-body" }, "LOADING")));
        } }));
};
export default ModalAnomalie;
