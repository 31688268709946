import React, { Fragment, useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useProduits } from './useProduits';
import FicheProduit from './FicheProduit';
import { FicheMainContainer } from '../../../components/Fiche/FicheDefaultLayout';
import Filters from '../../Filters/Filters';
import RightPanelRoute from '../RightPanelRoute';
import { resetProductFilters, setSelectedProductReloadFilter } from '../../../../redux/productFilters/action';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import Loading from '../../../shared/Loading';
import Footer from "../Footer/Footer";
const Produits = ({ match, history }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const [shouldWaitFetched, setShouldWaitFetched] = useState(false);
    const [elementToFetch, setElementToFetch] = useState(null);
    const dispatch = useDispatch();
    const { produits, setFilters, setSelectedProduct, selectedProduct, loadSingleProduct, hasNextPage, loadNextPage, isLoading, } = useProduits();
    const params = useSelector((state) => state.parameters);
    const onSelect = (type, id, defaultIcon, data) => {
        setSelectedProduct(data);
        dispatch(resetProductFilters());
        dispatch(setSelectedProductReloadFilter());
        history.push(`${match.path}/rightpanel/${type}/${id}/${defaultIcon}`);
    };
    const sendFilters = filter => {
        setFilters(filter);
    };
    useEffect(() => {
        if (!shouldWaitFetched)
            return;
        if (!elementToFetch)
            return;
        loadSingleProduct(elementToFetch);
        setShouldWaitFetched(false);
    }, [shouldWaitFetched]);
    const setElement = id => {
        setShouldWaitFetched(true);
        setElementToFetch(id);
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `whflex-col centerPanel ${selectedProduct !== null ? 'block-shadow' : ''}` },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Filters, { filtersConfig: ['libelle'], sendFilters: sendFilters }),
                isLoading && React.createElement(Loading, { withoutLogo: true }),
                React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: 'Produits', icon: 'icon-article' },
                    React.createElement("div", null,
                        React.createElement(InfiniteScroll, { data: produits, hasNextPage: hasNextPage, loadNextPage: loadNextPage, width: 450, rowHeight: 80, isNextPageLoading: isLoading, component: ({ index }) => {
                                return (React.createElement("div", { key: `product-${index}`, style: { marginBottom: '10px' }, onClick: (_e) => {
                                        if (_e.target.localName !== 'svg' &&
                                            _e.target.localName !== 'input' &&
                                            _e.target.localName !== 'polyline')
                                            onSelect('products', produits[index].id, 'selectedProduct', produits[index]);
                                    } },
                                    React.createElement(FicheProduit, { product: produits[index], color: selectedProduct &&
                                            selectedProduct.id === produits[index].id
                                            ? 'darkblue'
                                            : 'lightgray' })));
                            } }))))),
        React.createElement("div", { className: 'whflex-col rightPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Route, { path: "/main/products/rightpanel/:type/:id/:tab", name: "rightpanel" },
                    React.createElement(RightPanelRoute, { data: selectedProduct, type: 'products', setElement: setElement }))),
            React.createElement(Footer, { user: connectedUser, reload: null, type: 'products', humanFunctions: humanFunctions, selected: selectedProduct, disableElements: ['rdvs', 'notes', 'relances', 'anomalies', 'suivis', 'contact'] }))));
};
export default withRouter(Produits);
