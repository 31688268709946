import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitMessage, updateAnomalie, updateMessage } from '../../../components/RightPanelModal/ModalService';
import ApiService from '../../../utilities/apiService/ApiService';
import { MESSAGEENDPOINT } from '../../../utilities/apiService/constant';
import { setAnomaliePanelReload, setUpdateMessagePanelReload } from '../../../../redux/reloader/action';
export const useMessages = id => {
    const [messages, setMessages] = useState(null);
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const user = useSelector((state) => state.user.data);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [skip, setSkip] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const messagePanelReload = useSelector((state) => state.reloader.messagePanelReload);
    const [isLoading, setIsLoading] = useState(false);
    const [dest, setDest] = useState([]);
    const dispatch = useDispatch();
    const getHumanFunctionFromRole = () => {
        if (!messages)
            return;
        const roles = messages.roles;
        return roles
            .filter((role) => role)
            .map(r => {
            const hfId = r.split('_')[1];
            return humanFunctions ? humanFunctions.find(hf => hf.id === parseInt(hfId)) : null;
        });
    };
    console.log({ messages });
    const setSortedMessages = (messageObj) => {
        setMessages(messageObj);
    };
    const updateMessageBody = data => {
        const from = {
            sub: user.take.id,
            email: user.email,
            name: user.name,
            status: 'readed',
        };
        const response = {
            createdAt: new Date(),
            from,
            message: data.comment,
        };
        const currentRes = messages.responses;
        const currentTos = data.tos;
        let formatedTos = [];
        formatedTos = humanFunctions
            ? humanFunctions
                .map(hf => {
                const el = currentTos.find(el => el === hf.label);
                if (el)
                    return `FUNC_${hf.id}`;
                else
                    return null;
            })
                .filter(el => el !== null)
            : [];
        currentRes.push(response);
        return { _id: messages._id, tos: formatedTos, responses: currentRes };
    };
    const createMessageBody = data => {
        let formatedTos = [];
        formatedTos = humanFunctions
            ? humanFunctions
                .map(hf => {
                const el = data.tos.find(el => el === hf.label);
                if (el)
                    return `FUNC_${hf.id}`;
                else
                    return null;
            })
                .filter(el => el !== null)
            : [];
        return {
            tos: formatedTos,
            from: {
                id: user.id,
                sub: user.id,
                email: user.email,
                name: `${user.name}`,
                status: 'readed',
            },
            subject: data.title,
            body: data.comment,
            url: `main/anomalies/rightpanel/anomalies/${id}/selectedAnomalie`,
            type: 'chat',
            entity: 'anomalie',
            entityId: id,
            responses: [],
        };
    };
    const onShareMessage = data => {
        const updatedData = {
            _id: id,
            shared: data.tos ? data.tos.length > 0 : false,
        };
        if (!messages) {
            setIsLoading(true);
            return updateAnomalie(updatedData)
                .then(() => {
                setIsLoading(true);
                return submitMessage(createMessageBody(data))
                    .then(message => {
                    setSortedMessages(message);
                    dispatch(setAnomaliePanelReload(true));
                    dispatch(setUpdateMessagePanelReload(true));
                    setIsLoading(false);
                })
                    .catch(e => {
                    setIsLoading(false);
                    console.error('Erreur lors de la creation du message::useCmdAnomalies.ts::130', e);
                });
            })
                .catch(e => {
                setIsLoading(false);
                console.error("Erreur lors de l'update de l anomalie", e);
            });
        }
        setIsLoading(true);
        return updateAnomalie(updatedData)
            .then(() => {
            setIsLoading(true);
            return updateMessage(updateMessageBody(data))
                .then(message => {
                setSortedMessages(message);
                dispatch(setAnomaliePanelReload(true));
                dispatch(setUpdateMessagePanelReload(true));
                setIsLoading(false);
            })
                .catch(e => {
                setIsLoading(false);
                console.error('Erreur lors de la creation du message::useCmdAnomalies.ts::130', e);
            });
        })
            .catch(e => {
            setIsLoading(false);
            console.error("Erreur lors de l'update de l'anomalie", e);
        });
    };
    const onSendMessage = data => {
        const req = messages ? updateMessage : submitMessage;
        console.log('REQ::', req);
    };
    const loadMessage = async () => {
        setIsLoading(true);
        const messageService = new ApiService(MESSAGEENDPOINT);
        const results = await messageService.search({
            filtered: [
                { id: 'type', value: 'chat' },
                { id: 'entity', value: 'anomalie' },
                { id: 'entityId', value: id },
            ],
        });
        setIsLoading(false);
        const totalItems = results.total;
        const data = results.data;
        setSkip(data.length);
        setHasNextPage(data.length < totalItems);
        if (data.length > 0) {
            setSortedMessages({ ...data[0], responses: data[0].responses });
        }
        else {
            setDest([]);
            setMessages(null);
        }
    };
    const loadNextPage = () => {
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        if (!id)
            return;
        setSkip(0);
        setFireUseEffect(Math.random());
    }, [id]);
    useEffect(() => {
        if (!messagePanelReload)
            return;
        setSkip(0);
        setFireUseEffect(Math.random());
        dispatch(setUpdateMessagePanelReload(false));
    }, [messagePanelReload]);
    useEffect(() => {
        loadMessage();
    }, [fireUseEffect]);
    useEffect(() => {
        if (!messages)
            return;
        const dest = getHumanFunctionFromRole();
        setDest(dest);
    }, [messages]);
    return {
        messages,
        isLoading,
        loadNextPage,
        hasNextPage,
        skip,
        dest,
        onShareMessage,
        loadMessage,
    };
};
