import { useState, useEffect } from 'react';
import { CMDLINESENDPOINT, PRODUCTELASTICENDPOINT } from '../../../utilities/apiService/constant';
import ElasticService from '../../../utilities/apiService/ElasticService';
import ApiService from '../../../utilities/apiService/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { formatCmdLineForProductFilter } from '../../../../redux/productFilters/action';
export const useCmdLine = ref => {
    const filters = useSelector((state) => state.selectedProductFilter.filters);
    const reload = useSelector((state) => state.selectedProductFilter.reloadFilter);
    const [cmdLine, setCmdLine] = useState(null);
    const [initialCmdLine, setInitialCmdLine] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const loadCmdLine = async () => {
        setIsLoading(true);
        const cmdLinesService = new ApiService(CMDLINESENDPOINT);
        const res = await cmdLinesService.search({ filtered: [{ id: 'itemCode', value: ref }].concat(filters) });
        const result = res['hydra:member'];
        if (reload)
            dispatch(formatCmdLineForProductFilter(res['hydra:member']));
        setCmdLine(result.length > 0 ? result[0] : null);
        if (result.length > 0) {
            if (!initialCmdLine) {
                setInitialCmdLine(result[0]);
            }
        }
        setIsLoading(false);
    };
    useEffect(() => {
        loadCmdLine();
    }, [ref]);
    useEffect(() => {
        loadCmdLine();
    }, [filters]);
    return {
        cmdLine,
        initialCmdLine,
        isLoading,
    };
};
export const useProduits = () => {
    const [produits, setProduits] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [fireUseEffect, setFireUseEffect] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState([]);
    const EsFormat = obj => {
        const { _meta, ...objWithoutMeta } = obj;
        let updatedObj = { ...objWithoutMeta };
        for (const prop in objWithoutMeta) {
            updatedObj = {
                ...updatedObj,
                [prop]: objWithoutMeta[prop].raw,
            };
        }
        return updatedObj;
    };
    const loadSingleProduct = async (_id) => {
        const productService = new ElasticService(PRODUCTELASTICENDPOINT);
        const result = await productService.search({
            query: "",
            filters: { "all": [{ "id": _id }] },
            page: { current: 1, size: 20 },
        });
        const results = result.results;
        if (results && results.length > 0) {
            setSelectedProduct(EsFormat(results[0]));
        }
        return;
    };
    const loadProduct = async () => {
        setIsLoading(true);
        const productService = new ElasticService(PRODUCTELASTICENDPOINT);
        const filter = filters.find(f => f.id === 'libelle');
        const result = await productService.search({
            query: filter ? (filter.value ? filter.value : 1) : 1,
            page: { current: page, size: 20 },
        });
        const { current, total_pages } = result.meta.page;
        setHasNextPage(current < total_pages);
        const newResult = result.results.map(r => EsFormat(r));
        setIsLoading(false);
        setProduits(produits && current > 1 ? [...produits, ...newResult] : newResult);
    };
    const reloadData = () => {
        setPage(1);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setPage(page + 1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [filters]);
    useEffect(() => {
        if (!fireUseEffect)
            return;
        loadProduct();
    }, [fireUseEffect]);
    return {
        produits,
        isLoading,
        loadNextPage,
        loadSingleProduct,
        selectedProduct,
        setSelectedProduct,
        hasNextPage,
        reloadData,
        setFilters,
    };
};
