import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ApiService from '../../utilities/apiService/ApiService';
import { NOTIFICATIONPOINT } from '../../utilities/apiService/constant';
import { setReload } from '../../../redux/sockets/action';
export const useNotification = () => {
    const user = useSelector((state) => state.user.data);
    const reload = useSelector((state) => state.sockets);
    const dispatch = useDispatch();
    const [unreadedNotifications, setUnreadedNotifications] = useState([]);
    const [unreadedCount, setUnreadedCount] = useState(0);
    const [readedNotifications, setReadedNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadMessage = async () => {
        setIsLoading(true);
        const messageService = new ApiService(NOTIFICATIONPOINT);
        const messages = await messageService.search({
            filtered: [
                { id: 'email', value: user.email },
            ],
        });
        setIsLoading(false);
        setUnreadedNotifications(messages.unreadedMessage);
        setUnreadedCount(messages.unreadedMessage.length);
        setReadedNotifications(messages.readedMessage);
    };
    useEffect(() => {
        (async function () {
            await loadMessage();
        })();
    }, [user]);
    useEffect(() => {
        if (!reload)
            return;
        if (!user)
            return;
        (async function () {
            await loadMessage();
            dispatch(setReload(false));
        })();
    }, [reload]);
    return {
        unreadedNotifications,
        readedNotifications,
        unreadedCount,
        isLoading,
        loadMessage,
    };
};
