import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ApiService from '../../../utilities/apiService/ApiService';
const asyncLoad = async (value, format, endpoint, defaultFilter, searchFilter, formatEndpoint) => {
    const apiService = new ApiService(endpoint);
    let filtered = [];
    if (searchFilter.value !== null && Object.keys(value).length === 0 && value.constructor === Object)
        filtered.push({ ...searchFilter, value: value });
    else if (value !== null) {
        filtered.push({ id: searchFilter.id, value: value });
    }
    filtered = filtered.concat(defaultFilter);
    const res = await apiService.search({ filtered: filtered, pageSize: 50 });
    const result = res['hydra:member'].map(c => format(c));
    return formatEndpoint ? formatEndpoint(result) : result;
};
export const FieldAsyncSelect = ({ input, onChange, label, formatEndpointResult, disabled = false, classNamePrefix, format, endpoint, meta: { touched, error }, placeholder, className, defaultFilter, searchFilter, }) => {
    const [updatedDefaultFilter, setUpdatedDefaultFilter] = useState(defaultFilter);
    useEffect(() => {
        setUpdatedDefaultFilter(defaultFilter);
    }, [defaultFilter]);
    const loadOptions = selectValue => {
        return asyncLoad(selectValue, format, endpoint, updatedDefaultFilter, searchFilter, formatEndpointResult);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(AsyncSelect, Object.assign({ key: JSON.stringify(defaultFilter) }, input, { name: label, isDisabled: disabled, onChange: onChange, isSearchable: true, defaultOptions: true, loadOptions: loadOptions, placeholder: placeholder, className: className, classNamePrefix: classNamePrefix })),
        touched && error && React.createElement("span", { className: "form__form-group-error formError" }, error)));
};
export const FieldSelect = ({ name, onChange, value, disabled, options, placeholder }) => {
    const handleChange = value => {
        onChange(value);
    };
    return (React.createElement(Select, { name: name, value: value, isDisabled: disabled, options: options, onChange: handleChange, placeholder: placeholder, classNamePrefix: "react-select", className: "react-select" }));
};
export const renderFieldSelect = ({ input, label, meta, options, placeholder = 'Select', disabled = false, className, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldSelect, Object.assign({}, input, { name: label, className: className, disabled: disabled, options: options, placeholder: placeholder })),
        meta.touched && meta.error && React.createElement("span", { className: 'formError' }, meta.error)));
};
export default renderFieldSelect;
