import React, { useEffect, useState, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Euroformat from '../../components/Euroformat';
import ApiService from '../../utilities/apiService/ApiService';
import { KPIENDPOINT, MESSAGEREADENPOINT } from '../../utilities/apiService/constant';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Icon from '../../components/Icon/Icon';
import Notifications from './Notifications/Notification';
import * as Session from '../../shared/session';
import { useNotification } from './useNotification';
const MenuSideBar = ({ history, location, match }) => {
    const [kpi, setKpi] = useState({ total: 0, totalYearBefore: 0, progressRate: 0, sellsQty: 0, offerQty: 0 });
    const [openNotifications, setOpenNotifications] = useState(false);
    const [selected, setSelected] = useState(0);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const { unreadedNotifications, readedNotifications, unreadedCount, loadMessage } = useNotification();
    const onClickNotif = async (notif) => {
        const messageReadService = new ApiService(MESSAGEREADENPOINT);
        await messageReadService.patch({ _id: notif._id, to: { email: user.email } });
        await loadMessage();
        window.location.replace(`${window.location.origin}/#/${notif.url}`);
    };
    useEffect(() => {
        const menus = [
            'dashboard',
            'agenda',
            'anomalies',
            'notes',
            'affaires',
            'relances',
            'business',
            'selections',
            'products',
            'contacts',
            'clients',
        ];
        menus.map((m, idx) => {
            if (location.pathname.includes(m)) {
                setSelected(idx);
            }
        });
    }, []);
    useEffect(() => {
        (async function () {
            const kpiService = new ApiService(KPIENDPOINT, process.env.REACT_APP_API);
            const kpis = await kpiService.search({
                filtered: [{ id: 'saler.id', value: user.take.id }],
            });
            setKpi(kpis);
        })();
    }, []);
    const logout = async () => {
        await Session.logout();
        window.location.reload();
    };
    const unselectedStyle = {
        backgroundColor: '#93A9BA',
    };
    const onOpenNotification = () => {
        setOpenNotifications(!openNotifications);
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "wh-sidebar", style: { overflowX: 'hidden' } },
            React.createElement("div", { className: "wh-sidebar_header" },
                React.createElement("div", { className: 'notification-icon-container' },
                    React.createElement("div", { className: 'notificationsIconContainer', onClick: onOpenNotification },
                        React.createElement(Icon, { icon: `text-title icon-notifications ${unreadedCount > 0 ? 'color-darkred' : 'color-gray'}`, text: `${unreadedCount}` })),
                    React.createElement("div", { className: "wh-sidebar_date" },
                        React.createElement(Moment, { format: "DD/MM/YYYY" }, new Date())),
                    React.createElement("div", { className: 'wh-sidebar_header_logout', onClick: logout },
                        React.createElement("div", null, `${user.name}`),
                        React.createElement("div", null,
                            React.createElement("i", { className: "icon-logout" })))),
                React.createElement("span", { className: "wh-sidebar_cta" }),
                React.createElement("div", { className: "wh-sidebar_valueContainer" },
                    React.createElement("span", { className: "wh-sidebar_value" },
                        React.createElement(Euroformat, { value: kpi.total })),
                    React.createElement("div", { className: "wh-sidebar_indexContainer" },
                        React.createElement("span", { className: "wh-sidebar_index wh-sidebar_index--top text-color-green" }, `${kpi.sellsQty}/${kpi.offerQty + kpi.sellsQty}`),
                        kpi.progressRate > 0 ? (React.createElement("span", { className: "wh-sidebar_index wh-sidebar_index--bottom text-color-green" }, `${kpi.progressRate}%`)) : (React.createElement("span", { className: "wh-sidebar_index wh-sidebar_index--bottom text-color-red" }, `${kpi.progressRate}%`))))),
            openNotifications && (React.createElement(Notifications, { setOpenNotifications: setOpenNotifications, unreadedNotifications: unreadedNotifications, readedNotifications: readedNotifications, onClickNotif: onClickNotif })),
            !openNotifications && (React.createElement("ul", { className: "wh-sidebar_navContainer" },
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 0 ? unselectedStyle : {}, onClick: () => setSelected(0) },
                    React.createElement(Link, { to: { pathname: '/main/dashboard' }, className: 'wh-sidebar_navLink' },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-accueil" })),
                        React.createElement("span", null, "TABLEAU DE BORD"))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 1 ? unselectedStyle : {}, onClick: () => setSelected(1) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/agenda' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-Agenda" })),
                        React.createElement("span", null, "MON AGENDA"))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 2 ? unselectedStyle : {}, onClick: () => setSelected(2) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/anomalies' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-anomalies" })),
                        React.createElement("span", null, "ANOMALIES"))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 3 ? unselectedStyle : {}, onClick: () => setSelected(3) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/notes' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-note" })),
                        React.createElement("span", null, "MES NOTES."))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 4 ? unselectedStyle : {}, onClick: () => setSelected(4) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/affaires' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-affaire" })),
                        React.createElement("span", null, "MES AFFAIRES"))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 5 ? unselectedStyle : {}, onClick: () => setSelected(5) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/relances' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            false && React.createElement("span", { className: "wh-sidebar_navNotif" }),
                            React.createElement("i", { className: "icon-relances" })),
                        React.createElement("span", null, "MES RELANCES"))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 7 ? unselectedStyle : {}, onClick: () => setSelected(7) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/selections' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-business" })),
                        React.createElement("span", null, "MA SELECTION"))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 8 ? unselectedStyle : {}, onClick: () => setSelected(8) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/products' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-article" })),
                        React.createElement("span", null, "PRODUITS"))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 9 ? unselectedStyle : {}, onClick: () => setSelected(9) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/contacts' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-contact" })),
                        React.createElement("span", null, "CONTACTS"))),
                React.createElement("li", { className: "wh-sidebar_navItem", style: selected !== 10 ? unselectedStyle : {}, onClick: () => setSelected(10) },
                    React.createElement(Link, { className: "wh-sidebar_navLink", to: { pathname: '/main/clients' } },
                        React.createElement("span", { className: "wh-sidebar_navIcon" },
                            React.createElement("i", { className: "icon-entreprise" })),
                        React.createElement("span", null, "CLIENTS"))))))));
};
export default withRouter(MenuSideBar);
