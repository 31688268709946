import React, { Fragment, useState } from 'react';
import MenuSideBar from './MenuSideBar';
import { Route, Switch } from 'react-router';
import Dashboard from './Dashboard/Dashboard';
import Business from './Business/Business';
import Search from '../Search/Search';
import { createSearchMachine } from '../Machine/SearchMachine/searchMachine';
import { useMachine } from '@xstate/react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Selection from './Selection/Selection';
import Produits from './Produits/Produits';
import Contacts from './Contacts/Contacts';
import Clients from './Clients/Clients';
import NewAnomalies from './NewAnomalies/Anomalies';
import Affaires from './Affaires/Affaires';
import { DeleteAllMessage } from '../Debug/DeleteAllMessage';
import NotifDebug from '../Debug/NotifDebug';
import NewNotes from './Notes/NewNotes';
import { DeleteAllNote } from '../Debug/DeleteAllNotes';
import NewAgenda from './Agenda/NewAgenda';
import NewRelances from './Relances/NewRelances';
const EntityModal = ({ isOpen, toggle, entity }) => {
    return (React.createElement(Modal, { isOpen: isOpen, toggle: () => toggle(false) },
        React.createElement(ModalHeader, { toggle: () => toggle(false) },
            React.createElement(ModalBody, null,
                React.createElement("pre", null, JSON.stringify(entity, null, 4))))));
};
const Layout = () => {
    const [current, send] = useMachine(createSearchMachine());
    const [openModal, setOpenModal] = useState(false);
    const [entity, setEntity] = useState(null);
    return (React.createElement("div", { className: 'mainColumnsContainer' },
        React.createElement(MenuSideBar, null),
        React.createElement(Switch, null, !current.matches('view') && (React.createElement(Fragment, null,
            React.createElement(Route, { exact: true, path: "/main/dashboard", name: "dashboard", component: Dashboard }),
            React.createElement(Route, { path: "/main/affaires", name: "affaires", component: Affaires }),
            React.createElement(Route, { path: "/main/agenda", name: "agenda", component: NewAgenda }),
            React.createElement(Route, { path: "/main/anomalies", name: "anomalies", component: NewAnomalies }),
            React.createElement(Route, { exact: true, path: "/main/business", name: "business", component: Business }),
            React.createElement(Route, { path: "/main/notes", name: "notes", component: NewNotes }),
            React.createElement(Route, { path: "/main/relances", name: "relances", component: NewRelances }),
            React.createElement(Route, { path: '/main/products', name: 'products', component: Produits }),
            React.createElement(Route, { path: '/main/selections', name: 'selection', component: Selection }),
            React.createElement(Route, { path: '/main/contacts', name: 'contacts', component: Contacts }),
            React.createElement(Route, { path: '/main/clients', name: 'clients', component: Clients }),
            React.createElement(Route, { path: '/main/deleteAll', name: 'deleteAll', component: DeleteAllMessage }),
            React.createElement(Route, { path: '/main/notifDebug', name: 'deleteAll', component: NotifDebug }),
            React.createElement(Route, { path: '/main/deleteNote', name: 'deleteAllNote', component: DeleteAllNote })))),
        current.matches('open') && React.createElement(Search, { current: current, send: send }),
        current.matches('view') && (React.createElement("div", { className: 'whflex-col centerPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement("ul", { className: "searchModal-selection" }, current.context.selected.map(s => {
                    return (React.createElement("li", { className: "searchModal-selectionItem", onClick: () => {
                            setEntity(s);
                            setOpenModal(true);
                        } },
                        React.createElement("i", { className: "icon-article text-title" }),
                        React.createElement("p", null, s.libelle)));
                })),
                React.createElement("div", { className: "searchModal-buttonsConainer" },
                    React.createElement("div", { className: "searchModal-button button red", onClick: () => send('CLOSE') }, "X"),
                    React.createElement("div", { className: "searchModal-button button green", onClick: () => send('SEARCH_AGAIN') }, "+"))),
            React.createElement(EntityModal, { isOpen: openModal, toggle: setOpenModal, entity: entity })))));
};
export default Layout;
