import React from 'react';
import classnames from 'classnames';
import Icon from '../../../components/Icon/Icon';
import Moment from 'react-moment';
import { FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
const FicheMessage = ({ user, message, from }) => {
    return (React.createElement("div", { className: classnames('card-message', {
            author: user.id === message.from.sub,
        }) },
        React.createElement("div", { className: from.email === message.from.email ? 'card-message-row card-message-row-auteur' : 'card-message-row' },
            React.createElement("div", { className: 'card-message-header' },
                React.createElement("div", { className: "small-date" },
                    React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, message.createdAt)),
                React.createElement(Icon, { text: message.from.name, icon: 'icon-utilisateur', bold: true })),
            React.createElement("div", { className: 'card-message-body' },
                React.createElement(FicheContainer, { color: 'white', className: "card-message-body-message" },
                    React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: message.hasOwnProperty('message') ? message.message : message.body,
                        } }))))));
};
export default FicheMessage;
