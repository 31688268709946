import React, { useState, useEffect } from 'react';
const RightPanelFilters = ({ affair, setFilter, filters, className }) => {
    const [active, setActive] = useState(0);
    const onSelect = (idx, value) => {
        setActive(idx);
        if (setFilter)
            setFilter(value);
    };
    useEffect(() => {
        setActive(0);
    }, [affair]);
    return (React.createElement("div", { className: `${className} filtersContainer-row` },
        React.createElement("div", { className: "filterIcon icon-filter mr-10" }),
        React.createElement("div", { className: "filtersContainer-row mt-5" }, filters.map((f, idx) => {
            return React.createElement("span", { key: `filter_${idx}`, onClick: () => onSelect(idx, f), className: `badge badge-filterBadge ${active === idx ? "active" : ""}` }, f);
        }))));
};
export default RightPanelFilters;
