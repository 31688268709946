import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from './modules/Layout/Layout';
import Page404 from './components/404';
import MainWrapper from './modules/MainWrapper/MainWrapper';
import Messagerie from './modules/Messagerie';
import moment from 'moment';
moment.locale('fr');
const App = () => {
    return (React.createElement(Fragment, null,
        React.createElement(MainWrapper, null,
            React.createElement("div", { className: "main_container" },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/404", component: Page404 }),
                    React.createElement(Route, { exact: true, path: '/', component: () => React.createElement(Redirect, { to: '/main/dashboard' }) }),
                    React.createElement(Route, { path: "/message/:id/:author/:hash", component: Messagerie }),
                    React.createElement(Route, { path: "/main", component: Layout }))))));
};
export default App;
