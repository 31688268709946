import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';
import { fetch } from '../../utilities/apiService/fetch';
import Loading from '../../shared/Loading';
import { Button } from 'reactstrap';
const Messagerie = ({ match }) => {
    const [isPending, setIsPending] = useState(true);
    const [isMessagePending, setIsMessagePending] = useState(false);
    const [message, setMessage] = useState({});
    const [messageToSend, setMessageToSend] = useState('');
    const email = match.params.author;
    const sendMessage = async () => {
        setIsMessagePending(true);
        const response = {
            hash: match.params.hash,
            createdAt: new Date(),
            from: {
                email,
            },
            message: messageToSend,
        };
        const res = await fetch(`${process.env.REACT_APP_NOTIFICATION}/messages-front/${match.params.id}`, 'PATCH', response);
        setMessage({
            _id: res._id,
            subject: res.subject,
            body: res.body,
            responses: res.responses,
            createdAt: res.createdAt,
            from: res.from,
        });
        setMessageToSend('');
        setIsMessagePending(false);
    };
    const loadMessage = async () => {
        setIsPending(true);
        const res = await fetch(`${process.env.REACT_APP_NOTIFICATION}/messages/${match.params.id}`, 'GET');
        setMessage({
            _id: res._id,
            subject: res.subject,
            body: res.body,
            responses: res.responses,
            createdAt: res.createdAt,
            from: res.from,
        });
        setMessageToSend('');
        setIsPending(false);
    };
    useEffect(() => {
        loadMessage();
    }, []);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'mess-layout' },
            isPending && React.createElement(Loading, { withoutLogo: false }),
            !isPending && (React.createElement("div", { className: "mess" },
                isMessagePending && React.createElement(Loading, { withoutLogo: false }),
                React.createElement("div", { className: "mess-head" },
                    React.createElement("div", { className: "mess-subject" }, message.subject),
                    React.createElement("div", { className: "mess-author" },
                        "Par ",
                        message.from && message.from.name,
                        ", le",
                        ' ',
                        moment(message.createdAt).format('DD/MM/YYYY, à HH:mm'))),
                React.createElement("div", { className: "mess-conv" },
                    React.createElement("div", { className: "mess-body" }, message.body),
                    message.responses &&
                        message.responses.map((response, index) => (React.createElement("div", { className: `mess-rep ${response.from.email == email ? 'mess-author' : ''}`, key: index },
                            React.createElement("div", { className: "mess-rep-mess" },
                                React.createElement("div", null, response.message)),
                            React.createElement("div", { className: "mess-rep-auth" },
                                "Par ",
                                response.from.name,
                                ", le",
                                ' ',
                                moment(response.createdAt).format('DD/MM/YYYY, à HH:mm'))))),
                    React.createElement("div", { className: "mess-form" },
                        React.createElement(TextareaAutosize, { placeholder: "Saisir votre message ici", value: messageToSend, onChange: event => setMessageToSend(event.target.value) })),
                    React.createElement("div", { className: "mess-submit" },
                        React.createElement(Button, { size: "lg", color: "primary", onClick: () => sendMessage() }, "Envoyer"))))))));
};
export default Messagerie;
