import React from 'react';
const FieldTxt = (props) => {
    const returnType = () => {
        if (!props.type)
            return 'text';
        return props.type;
    };
    const { input, label, iconbefore, iconafter, btnbefore, btnafter, type = '', placeholder, help, meta: { touched, error }, } = props;
    return (React.createElement("div", { className: "form__form-group" },
        label && React.createElement("span", { className: "form__form-group-label" }, label),
        React.createElement("div", { className: "form__form-group-field" },
            iconbefore && React.createElement("div", { className: "form__form-group-icon" }, iconbefore),
            btnbefore && btnbefore(),
            React.createElement("div", { className: "form__form-group-input-wrap form__form-group-input-wrap--error-above" },
                (!type || ['password', 'text'].includes(type)) && (React.createElement("input", Object.assign({}, input, { type: returnType(), placeholder: placeholder ? placeholder : null }))),
                ['textarea'].includes(type) && (React.createElement("textarea", Object.assign({}, input, { type: returnType(), placeholder: placeholder ? placeholder : null }))),
                touched && error && React.createElement("span", { className: "form__form-group-error" }, error)),
            (type === 'date' || type === 'datetime') && (React.createElement("div", { className: "form__form-group-icon" },
                React.createElement("span", { className: "lnr lnr-calendar-full" }))),
            iconafter && React.createElement("div", { className: "form__form-group-icon" }, iconafter),
            btnafter && btnafter()),
        help && React.createElement("span", { className: "form__form-group-description" }, help)));
};
export default FieldTxt;
