import { useState, useEffect } from 'react';
import ApiService from '../../../../utilities/apiService/ApiService';
import { GCALENDARENDPOINT } from '../../../../utilities/apiService/constant';
import { setNotePanelReload } from '../../../../../redux/reloader/action';
import { useDispatch, useSelector } from 'react-redux';
export const useCustomerNotes = (costumerId, isCustomerNote) => {
    const [customerNotes, setCustomerNotes] = useState([]);
    const reload = useSelector((state) => state.reloader.notePanelReload);
    const [skip, setSkip] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const dispatch = useDispatch();
    const load = async () => {
        if (!costumerId)
            return;
        setIsLoading(true);
        const calandarService = new ApiService(GCALENDARENDPOINT);
        const results = await calandarService.search({
            skip: skip,
            filtered: [
                { id: isCustomerNote ? 'subject.cmd.costumer.id' : 'subject.cmd.id', value: costumerId },
                { id: 'type', value: 'note' },
            ].concat(filters),
        });
        const totalItems = results.total;
        const data = results.data;
        const newData = skip > 0 ? [...customerNotes, ...data] : data;
        setSkip(newData.length);
        setHasNextPage(newData.length < totalItems);
        setCustomerNotes(newData);
        setIsLoading(false);
    };
    const reloadData = () => {
        setSkip(0);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setFireUseEffect(Math.random());
    };
    const validateNote = id => {
        const Service = new ApiService(GCALENDARENDPOINT);
        return Service.patch({ _id: id, status: 'fixed' }).then(() => {
            reloadData();
        });
    };
    useEffect(() => {
        setFireUseEffect(Math.random());
    }, [filters, costumerId]);
    useEffect(() => {
        if (!reload)
            return;
        setSkip(0);
        setFireUseEffect(Math.random());
        dispatch(setNotePanelReload(false));
    }, [reload]);
    useEffect(() => {
        console.log('note loading !!!');
        load();
    }, [fireUseEffect]);
    return {
        customerNotes,
        loadNextPage,
        hasNextPage,
        reloadData,
        isLoading,
        load,
        validateNote,
        setFilters,
    };
};
