import React, { Fragment, useEffect, useState } from 'react';
import { fetchRelances } from '../../../utilities/apiService/fetchLists';
import Loading from '../../../shared/Loading';
import RightPanelRelances from "./RightPanelRelances";
const FetchRelanceFromCmd = ({ data, render, reload, setReload }) => {
    const [relances, setRelances] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const fetchRelance = async () => {
        setIsLoading(true);
        const cmdId = data.hasOwnProperty('@type') && data['@type'] ? data.id : data.subject.cmd.id;
        const res = await fetchRelances([
            { id: 'type', value: 'relance' },
            { id: 'subject.cmd.id', value: cmdId },
        ]);
        setRelances(res);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchRelance();
    }, [data]);
    useEffect(() => {
        if (!reload)
            return;
        fetchRelance();
        setReload(false);
    }, [reload]);
    return React.createElement(Fragment, null, render({ relances, isLoading }));
};
export const RelanceCmd = ({ cmd, user }) => {
    const [reload, setReload] = useState(false);
    const updateRelance = () => {
        setReload(true);
    };
    return (React.createElement(FetchRelanceFromCmd, { data: cmd, setReload: setReload, reload: reload, render: ({ relances, isLoading }) => {
            return (React.createElement("div", { className: "card card-color-lightgray card-panel-right" },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                relances.length === 0 && React.createElement("div", null, "Pas de r\u00E9sultats"),
                relances.map((r, idx) => {
                    return React.createElement(RightPanelRelances, { key: `cmd-relance-${idx}`, relance: r, reload: updateRelance, user: user });
                })));
        } }));
};
