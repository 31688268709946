import React from 'react';
const SearchElement = ({ element, onRemove }) => {
    return (React.createElement("li", { className: "searchModal-selectionItem", onClick: onRemove },
        React.createElement("i", { className: "icon-article text-title" }),
        React.createElement("p", null, element.libelle)));
};
const SearchSelection = ({ selections, onRemoveAll, onRemove, onSeeingAll }) => {
    return (React.createElement("div", { className: "searchModal-selectionContainer" },
        React.createElement("span", { className: "text-subtitle text-w-bold text-color-blue text-center" }, "Selection"),
        React.createElement("ul", { className: "searchModal-selection" }, selections.map((res, idx) => (React.createElement(SearchElement, { key: `searchSelection_${idx}`, element: res, onRemove: () => onRemove(idx) })))),
        React.createElement("div", { className: "searchModal-buttonsConainer" },
            React.createElement("div", { onClick: onRemoveAll, className: "searchModal-button button red" }, "Tout effacer"),
            React.createElement("div", { className: "searchModal-button button green", onClick: onSeeingAll }, "Voir la selection"))));
};
export default SearchSelection;
