import React, { Fragment, useState } from 'react';
import { useCmdAnomalies } from './useCmdAnomalies';
import { useMessages } from './useMessage';
import moment from 'moment';
import { ShareComponent } from './Share';
import Message from './Message';
import { Form } from 'react-final-form';
import FFTextArea from '../../../components/FinalForm/FFTextArea';
import Loading from '../../../shared/Loading';
import { updateAnomalie, updateMessage } from '../../../components/RightPanelModal/ModalService';
import Filters from '../../Filters/Filters';
import Moment from 'react-moment';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { setAnomaliePanelReload, setUpdateMessagePanelReload } from '../../../../redux/reloader/action';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import { replaceEntityFields } from '../../../utilities/storage';
export const FicheAnomalie = ({ status, createdAt, title, type, typeLabel, shared, cmdLine, isSelected = false, }) => {
    const types = {
        '1': 'Commande livrée après la date prévue',
        '2': 'Date de livraison prévue dépassée',
        '3': 'Commande préparée non livrée',
        '4': 'Autre (manuelle)',
    };
    const anoType = types[`${type}`];
    const getLabel = () => {
        if (cmdLine && cmdLine.libelle)
            return cmdLine.libelle;
        return 'Libelle non définis';
    };
    const formatMoment = date => {
        if (!date)
            return moment(new Date()).format('DD/MM/YYYY hh:mm');
        return moment(date).format('DD/MM/YYYY hh:mm');
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `card card-color-lightgray  card--hover ${isSelected ? 'card-selected_anomalies' : ''}` },
            React.createElement("div", { className: "card_body " },
                React.createElement("span", { className: `color ${status < 100 ? 'red' : ''}` }),
                React.createElement("div", null,
                    React.createElement("p", { className: "date -small" }, formatMoment(createdAt)),
                    React.createElement("p", { className: "name" }, type === 4 ? title : getLabel()),
                    React.createElement("p", { className: "type" }, type === 4 ? 'Manuel' : anoType)),
                React.createElement("span", { className: `icon icon-share2 ${shared ? 'color-gray' : ''}` })))));
};
export const CloseForm = ({ onCloseAno }) => {
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = async () => {
        setIsLoading(true);
        await onCloseAno();
        setIsLoading(false);
    };
    return (React.createElement(Form, { onSubmit: onSubmit, render: ({ handleSubmit }) => {
            return (React.createElement("form", { className: 'closeForm w-100', onSubmit: handleSubmit },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                React.createElement("button", { type: "submit", className: 'button w-100' }, "Cl\u00F4turer")));
        } }));
};
const SendMessageForm = ({ onSendMessage, isSendingMessage }) => {
    return (React.createElement(Form, { onSubmit: onSendMessage, initialValues: { message: '' }, render: ({ handleSubmit, form }) => {
            return (React.createElement("form", { className: 'send-message-form', onSubmit: event => handleSubmit(event).then(() => {
                    form.reset();
                }) },
                React.createElement(FFTextArea, { name: 'message', required: true, className: 'text-color-gray text-left message-area', label: '' }),
                React.createElement("button", { type: "submit", className: 'button message-btn' }, isSendingMessage ? React.createElement(Loading, { withoutLogo: false }) : React.createElement("span", null, "Envoyer"))));
        } }));
};
export const SingleAnomalie = ({ anomalie, user, reload }) => {
    const { messages, isLoading, dest, onShareMessage, loadMessage } = useMessages(anomalie.id);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const dispatch = useDispatch();
    const onShare = data => {
        return onShareMessage(data);
    };
    const sendMessageForm = data => {
        setIsSendingMessage(true);
        const from = {
            sub: user.take.id,
            email: user.email,
            name: user.name,
            status: 'readed',
        };
        const response = {
            createdAt: new Date(),
            from,
            message: data.message,
        };
        const currentRes = messages.responses;
        const body = { _id: messages._id, tos: messages.roles, responses: currentRes, newResponse: response };
        return updateMessage(body)
            .then(() => {
            setIsSendingMessage(false);
            dispatch(setUpdateMessagePanelReload(true));
            loadMessage();
        })
            .catch(e => {
            console.log('Error in AnomalieCmd, sendMessageForm', e);
            setIsSendingMessage(false);
        });
    };
    const onCloseAno = () => {
        const closeData = {
            _id: anomalie.id,
            status: 100,
        };
        replaceEntityFields('anomalies', anomalie.id.toString(), { status: closeData.status });
        return updateAnomalie(closeData).then(() => {
            if (reload) {
                reload();
            }
            dispatch(setAnomaliePanelReload(true));
        });
    };
    return (React.createElement("div", { className: "wrapper_anomalies d-flex card-color-lightgray" },
        React.createElement("div", { className: 'list_anomalies' }, anomalie && React.createElement(FicheAnomalie, Object.assign({}, anomalie))),
        React.createElement("div", { className: "left_panel" },
            React.createElement(Fragment, null,
                React.createElement(ShareComponent, { onShare: onShare, shared: anomalie.shared, message: messages, dest: dest, canShare: anomalie.status < 100 }),
                React.createElement(Message, { messages: messages, user: user, isLoading: isLoading }),
                dest && dest.length > 0 && anomalie.status <= 100 && (React.createElement(SendMessageForm, { onSendMessage: sendMessageForm, isSendingMessage: isSendingMessage })),
                anomalie.type === 4 && anomalie.status < 100 && React.createElement(CloseForm, { onCloseAno: onCloseAno }),
                anomalie.status === 100 && (React.createElement("div", { className: "share-header" },
                    React.createElement("div", { className: "header share-form" },
                        React.createElement("div", { className: "infos" },
                            React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, anomalie.updatedAt),
                            React.createElement("div", { className: 'shared-dest-list' }, "Clotur\u00E9")))))))));
};
const AnomalieCmd = ({ affair, user, history }) => {
    const { cmdAnomalies, setSelectedAnomalie, selectedAnomalie, isLoading, hasNextPage, loadNextPage, setFilters, } = useCmdAnomalies(affair);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const dispatch = useDispatch();
    const { messages, isLoading: isMessageLoading, onShareMessage, dest, loadMessage } = useMessages(selectedAnomalie ? selectedAnomalie.id : null);
    const sendMessageForm = data => {
        setIsSendingMessage(true);
        const from = {
            sub: user.take.id,
            email: user.email,
            name: user.name,
            status: 'readed',
        };
        const response = {
            createdAt: new Date(),
            from,
            message: data.message,
        };
        const currentRes = messages.responses;
        return updateMessage({ _id: messages._id, tos: messages.tos, responses: currentRes, newResponse: response })
            .then(() => {
            loadMessage();
            setIsSendingMessage(false);
        })
            .catch(e => {
            console.error('Error in sendMessageForm', e);
            setIsSendingMessage(false);
        });
    };
    const onShare = data => {
        return onShareMessage(data);
    };
    const sendFilters = filters => {
        setFilters(filters);
    };
    const onCloseAno = () => {
        if (!selectedAnomalie)
            return;
        const closeData = {
            _id: selectedAnomalie.id,
            status: 100,
        };
        return updateAnomalie(closeData).then(() => {
            dispatch(setAnomaliePanelReload(true));
        });
    };
    const goToSelectedAnomalie = anomaly => {
        setSelectedAnomalie(anomaly);
    };
    return (React.createElement("div", { className: "wrapper_anomalies d-flex card-color-lightgray" },
        React.createElement("div", { className: 'list_anomalies' },
            isLoading && React.createElement(Loading, { withoutLogo: false }),
            React.createElement(Filters, { filtersConfig: ['anomaly_status'], sendFilters: sendFilters }),
            React.createElement(InfiniteScroll, { data: cmdAnomalies, hasNextPage: hasNextPage, loadNextPage: loadNextPage, rowHeight: 120, width: 360, isNextPageLoading: isLoading, component: ({ index }) => {
                    return (React.createElement("div", { onClick: () => goToSelectedAnomalie(cmdAnomalies[index]) },
                        React.createElement(FicheAnomalie, Object.assign({}, cmdAnomalies[index], { isSelected: selectedAnomalie && selectedAnomalie.id === cmdAnomalies[index].id }))));
                } })),
        React.createElement("div", { className: "left_panel" }, selectedAnomalie && (React.createElement(Fragment, null,
            React.createElement(ShareComponent, { onShare: onShare, shared: selectedAnomalie.shared, dest: dest, message: messages, canShare: selectedAnomalie.status < 100 }),
            React.createElement(Message, { messages: messages, isLoading: isMessageLoading, user: user }),
            dest && dest.length > 0 && selectedAnomalie.status < 100 && (React.createElement(Fragment, null,
                React.createElement(SendMessageForm, { onSendMessage: sendMessageForm, isSendingMessage: isSendingMessage }))),
            selectedAnomalie.type === 4 && selectedAnomalie.status < 100 && (React.createElement(CloseForm, { onCloseAno: onCloseAno })),
            selectedAnomalie.status === 100 && (React.createElement("div", { className: "share-header" },
                React.createElement("div", { className: "header share-form" },
                    React.createElement("div", { className: "infos" },
                        React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, selectedAnomalie.updatedAt),
                        React.createElement("div", { className: 'shared-dest-list' }, "Clotur\u00E9"))))))))));
};
export default withRouter(AnomalieCmd);
