import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FicheBody, FicheMainContainer } from '../../../components/Fiche/FicheDefaultLayout';
import ApiService from '../../../utilities/apiService/ApiService';
import { CMDENDPOINT } from '../../../utilities/apiService/constant';
import moment from 'moment';
import { Badge } from '../../../components/Badge/Badge';
import Icon from '../../../components/Icon/Icon';
import NameFormat from '../../../components/Nameformat';
import { isInLocalStorage, removeEntity, saveEntity } from '../../../utilities/storage';
import SelectionCheckBox from '../../../components/SelectionCheckBox/SelectionCheckBox';
import classnames from 'classnames';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import SwipeableViews from 'react-swipeable-views';
import { SwipeWrapper } from '../Relances/FicheRelance';
export const WithSwipe = ({ params, affairId, reload }) => {
    const [error, setError] = useState({ idx: -1, value: null });
    const [btnText, setBtnText] = useState('Perdu');
    const onSubmit = async () => {
        if (error.idx === -1)
            return;
        setBtnText('Sauvegarde...');
        const cmdService = new ApiService(CMDENDPOINT);
        try {
            await cmdService.update({ _id: affairId, prospectingStatus: error.value });
            reload();
            setBtnText('Success');
        }
        catch (e) {
            setBtnText('Error');
        }
    };
    return (React.createElement("div", { className: "card backCard card-color-dark" },
        React.createElement("div", { className: "card_body fourColumns" }, params &&
            params.PROSPECTING_STATUS.map((ps, idx) => {
                return (React.createElement("div", { key: `prospecting_status_${idx}`, onClick: () => setError({ idx: idx + 1, value: ps.value }), className: `button ${error.idx === idx + 1 ? 'green' : 'transparent'} withBorder text-small` }, ps.label));
            })),
        React.createElement("div", { className: "card_body singleColumn mt-10" },
            React.createElement("div", { onClick: onSubmit, className: "button red" }, btnText))));
};
export const FicheAffairLeftPanel = ({ affair, params, hidden, color = 'lightpink', callback }) => {
    const [isChecked, setIsChecked] = useState(isInLocalStorage('affaires', affair.id));
    const isLocked = useSelector((state) => {
        return state.footer.isLocked;
    });
    const formatMoment = date => {
        return moment(date).format('DD/MM/YYYY');
    };
    const { statusLabel, manager, saler, costumer, refBusiness, contact, ref, orderAt, writeAt, willDeliveryAt, amountHt, marginHt, } = affair;
    const onChange = e => {
        if (e.target.checked)
            saveEntity('affaires', affair.id, affair);
        if (!e.target.checked)
            removeEntity('affaires', affair.id);
        setIsChecked(e.target.checked);
        if (callback)
            callback();
    };
    const badge = params && params.STATUS ? params.STATUS.find(st => st.label === statusLabel) : null;
    console.log({ hidden });
    return (React.createElement("div", { className: `card card-color-${color}` },
        React.createElement(SelectionCheckBox, { id: affair.id, isChecked: isChecked, onChange: onChange }),
        React.createElement(FicheBody, { columns: 'threeColumns' },
            React.createElement(Badge, { color: 'blue', text: badge && badge.value ? badge.value.toUpperCase() : '' }),
            React.createElement(Icon, { icon: 'icon-technico-commercial', text: manager ? NameFormat({ value: manager.name }) : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-utilisateur', text: saler ? saler.name : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-entreprise', text: costumer ? costumer.socialReason : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-contact', text: contact ? contact.name : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-ref-commande', text: refBusiness, bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
            React.createElement("div", null, ref),
            React.createElement(Icon, { icon: 'icon-check_2 icon-check_2-style', text: formatMoment(orderAt) }),
            !isLocked && React.createElement(Icon, { icon: 'icon-montant', text: amountHt }),
            React.createElement("div", null, formatMoment(writeAt)),
            React.createElement(Icon, { icon: 'icon-logout', text: formatMoment(willDeliveryAt) }),
            !isLocked && React.createElement(Icon, { icon: 'icon-marge-valeur color-blue', text: marginHt ? marginHt : '-' }))));
};
export const AffairListLeftPanel = ({ affairs, hidden, reload, onClick, selected = null, loadNextPage, hasNextPage, isNextPageLoading, }) => {
    const params = useSelector((state) => state.parameters);
    return (React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: '', icon: '' },
        React.createElement("div", null,
            React.createElement(InfiniteScroll, { data: affairs, hasNextPage: hasNextPage, loadNextPage: loadNextPage, rowHeight: 140, isNextPageLoading: isNextPageLoading, component: ({ index }) => {
                    return (React.createElement(SwipeWrapper, { key: `${JSON.stringify(affairs[index])}`, reload: reload, render: ({ onReload }) => {
                            return (React.createElement("div", { onClick: (_e) => {
                                    if (_e.target.localName !== 'svg' &&
                                        _e.target.localName !== 'input' &&
                                        _e.target.localName !== 'polyline') {
                                        onClick(affairs[index]);
                                    }
                                }, key: `affairs-header-${index}`, className: classnames('card-affaire-container', {
                                    'card-affaire-container-active': selected && selected.id === affairs[index].id,
                                }) },
                                React.createElement(SwipeableViews, { key: `affairs-swipe-${index}`, enableMouseEvents: true, swipeOptions: { continuous: false }, className: "react-swipe-container swipe-container" },
                                    React.createElement(FicheAffairLeftPanel, { affair: affairs[index], params: params, hidden: hidden, color: selected && selected.id === affairs[index].id
                                            ? 'darkblue'
                                            : 'lightpink' }),
                                    affairs[index].status === 'of' ? (React.createElement(WithSwipe, { params: params, affairId: affairs[index].id, reload: onReload })) : (React.createElement("div", null)))));
                        } }));
                } }))));
};
