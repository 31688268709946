import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAnomalies } from './useAnomalies';
import Loading from '../../../shared/Loading';
import Filters from '../../Filters/Filters';
import { FicheMainContainer } from '../../../components/Fiche/FicheDefaultLayout';
import { FicheAnomalie } from './FicheAnomalie';
import { Route } from 'react-router';
import RightPanelRoute from '../RightPanelRoute';
import classnames from 'classnames';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import Footer from '../Footer/Footer';
const NewAnomalies = ({ match, history }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const { anomalies, selectedAnomalie, setSelectedAnomalie, isLoading, setFilters, getOneAnomaly, hasNextPage, loadNextPage, reloadData, } = useAnomalies(match.isExact);
    const [shouldWaitFetched, setShouldWaitFetched] = useState(false);
    const [elementToFetch, setElementToFetch] = useState(null);
    const onSelect = (type, id, defaultIcon, data) => {
        setSelectedAnomalie(data);
        history.push(`${match.path}/rightpanel/${type}/${id}/${defaultIcon}`);
    };
    const sendFilters = filter => {
        setFilters(filter);
    };
    useEffect(() => {
        if (!shouldWaitFetched)
            return;
        if (!elementToFetch)
            return;
        getOneAnomaly(elementToFetch);
        setShouldWaitFetched(false);
    }, [shouldWaitFetched]);
    const setElement = id => {
        setShouldWaitFetched(true);
        setElementToFetch(id);
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `whflex-col centerPanel` },
            React.createElement("div", { className: "toBeScrolled" },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                React.createElement(Filters, { filtersConfig: ['company', 'badges_relance', 'date', 'saler'], sendFilters: sendFilters, type: 'anomaly' }),
                React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: 'Anomalies', icon: 'icon-anomalies' },
                    React.createElement("div", null,
                        React.createElement(InfiniteScroll, { data: anomalies, hasNextPage: hasNextPage, rowHeight: 160, width: 450, loadNextPage: loadNextPage, isNextPageLoading: isLoading, component: ({ index }) => {
                                return (React.createElement("div", { onClick: (_e) => {
                                        if (_e.target.localName !== 'svg' &&
                                            _e.target.localName !== 'input' &&
                                            _e.target.localName !== 'polyline') {
                                            onSelect('anomalies', anomalies[index].id, 'selectedAnomalie', anomalies[index]);
                                        }
                                    }, key: `anomalie-${index}`, className: classnames('card-affaire-container', {
                                        'card-affaire-container-active': selectedAnomalie && selectedAnomalie.id === anomalies[index].id,
                                    }) },
                                    React.createElement(FicheAnomalie, { anomalie: anomalies[index], color: selectedAnomalie &&
                                            selectedAnomalie.id === anomalies[index].id
                                            ? 'darkblue'
                                            : 'lightpurple' })));
                            } }))))),
        React.createElement("div", { className: 'whflex-col rightPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Route, { path: "/main/anomalies/rightpanel/:type/:id/:tab", name: "rightpanel" },
                    React.createElement(RightPanelRoute, { data: selectedAnomalie, type: 'anomalies', setElement: setElement, user: connectedUser }))),
            React.createElement(Footer, { user: connectedUser, type: 'anomalies', reload: reloadData, humanFunctions: humanFunctions, selected: selectedAnomalie, disableElements: selectedAnomalie ? ['anomalies'] : ['notes', 'relances', 'anomalies', 'suivis'] }))));
};
export default NewAnomalies;
