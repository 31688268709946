export const setNcIfNull = text => {
    if (text && text === 'null null' || text === '' || text === ' ')
        return 'NC';
    return text ? text : 'NC';
};
export const setAddress = (...args) => {
    let addr = '';
    args.forEach(ar => {
        if (ar && ar !== 'null null') {
            addr += ar;
        }
    });
    if (addr === '')
        return 'NC';
    return addr;
};
