import { FicheBody, FicheContainer, FicheMainContainer } from '../../../components/Fiche/FicheDefaultLayout';
import React, { useState } from 'react';
import { getDiffFromToday } from '../../../utilities/helper/momentHelper';
import moment from 'moment';
import Icon from '../../../components/Icon/Icon';
import NameFormat from '../../../components/Nameformat';
import Moment from 'react-moment';
import SelectionCheckBox from '../../../components/SelectionCheckBox/SelectionCheckBox';
import { isInLocalStorage, removeEntity, saveEntity } from '../../../utilities/storage';
import classnames from 'classnames';
import InfiniteScroll from "../../../components/InfiniteScroll/InfiniteScroll";
export const FicheNote = ({ mainNote, color = 'lightblue', onClick, callback }) => {
    const [isChecked, setIsChecked] = useState(isInLocalStorage('notes', mainNote._id));
    const { start, subject, creator: { displayName: cName }, organizer: { displayName: oName }, createdAt, status, } = mainNote;
    const onClickFiche = _e => {
        if (_e.target.localName !== 'svg' && _e.target.localName !== 'input' && _e.target.localName !== 'polyline') {
            onClick('notes', mainNote._id, 'selectedNote', mainNote);
        }
    };
    const onChange = e => {
        if (e.target.checked)
            saveEntity('notes', mainNote._id, mainNote);
        if (!e.target.checked)
            removeEntity('notes', mainNote._id);
        setIsChecked(e.target.checked);
        if (callback)
            callback();
    };
    const subjectName = subject.hasOwnProperty('cmd') ? subject.cmd.costumer.socialReason : 'null';
    const diff = getDiffFromToday(moment(new Date()), start);
    const symbol = diff === 0 ? '' : diff > 0 ? '+' : '';
    const formatedDate = `${symbol}${diff} J`;
    return (React.createElement(FicheContainer, { color: color, className: '', onClick: e => onClickFiche(e) },
        React.createElement(SelectionCheckBox, { id: mainNote._id, isChecked: isChecked, onChange: onChange }),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-entreprise', text: subjectName, bold: true, className: 'nowrap' }),
            React.createElement(Icon, { icon: `icon-reminder ${status === 'confirmed' ? (diff > 0 ? 'color-green' : 'color-red') : ''}`, text: status === 'fixed' ? '-' : formatedDate, bold: true, className: 'nowrap align-right' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-utilisateur', text: NameFormat({ value: oName }), bold: true, className: 'nowrap' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-affaire', text: oName, bold: true, className: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-utilisateur', text: NameFormat({ value: cName }), bold: true, className: 'nowrap align-right' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-chantier', text: oName, bold: true, className: 'nowrap' }),
            React.createElement("div", { className: 'nowrap small-date align-right' },
                React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, createdAt)))));
};
export const NotesLeftPanelList = ({ notes, onClick, selected = null, hasNextPage, loadNextPage, isNextPageLoading }) => {
    return (React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: 'Notes', icon: 'icon-Agenda' },
        React.createElement("div", null,
            notes.length === 0 && React.createElement("div", null, "Aucun r\u00E9sultat"),
            React.createElement(InfiniteScroll, { data: notes, hasNextPage: hasNextPage, loadNextPage: loadNextPage, rowHeight: 160, width: 450, isNextPageLoading: isNextPageLoading, component: ({ index }) => {
                    return (React.createElement("div", { className: classnames('card-affaire-container', {
                            'card-affaire-container-active': selected && selected._id === notes[index]._id,
                        }) },
                        React.createElement(FicheNote, { mainNote: notes[index], key: `note-${index}`, color: selected && selected._id === notes[index]._id ? 'darkblue' : 'lightblue', onClick: onClick })));
                } }))));
};
