import React, { useState } from 'react';
import Icon from '../../../components/Icon/Icon';
import { isInLocalStorage, removeEntity, saveEntity } from '../../../utilities/storage';
import { setNcIfNull } from '../../../helper/setNcIfNull';
const FicheContact = ({ contact, color = 'lightgray' }) => {
    const [isChecked, setIsChecked] = useState(isInLocalStorage('contacts', contact.id));
    const onChange = e => {
        if (e.target.checked)
            saveEntity('contacts', contact.id, contact);
        if (!e.target.checked)
            removeEntity('contacts', contact.id);
        setIsChecked(e.target.checked);
    };
    return (React.createElement("div", { className: `card card-color-${color}` },
        React.createElement("div", { style: { display: 'flex', width: '100%', justifyContent: 'flex-start' } },
            React.createElement(Icon, { icon: 'icon-contact', style: { width: '30px' } }),
            React.createElement("div", { style: { flex: 1 } },
                React.createElement("div", null, `${setNcIfNull(contact.first_name && contact.first_name.toUpperCase())} ${setNcIfNull(contact.last_name && contact.last_name.toUpperCase())}`)),
            React.createElement("div", { style: { width: '30px' } },
                React.createElement("input", { className: "inp-customCbx d-none", type: 'checkbox', id: `selected-${contact.id}`, name: 'affairs', onClick: onChange, defaultChecked: isChecked }),
                React.createElement("label", { className: "customCbx", htmlFor: `selected-${contact.id}` },
                    React.createElement("span", null,
                        React.createElement("svg", { width: "12px", height: "10px", viewBox: "0 0 12 10" },
                            React.createElement("polyline", { points: "1.5 6 4.5 9 10.5 1" }))),
                    React.createElement("span", null))))));
};
export default FicheContact;
