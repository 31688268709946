import { handleActions } from 'redux-actions';
import { SET_FOOTER_ANOMALIE, SET_FOOTER_CONTACT, SET_FOOTER_NOTE, SET_FOOTER_RDV, SET_FOOTER_RELANCE, SET_LOCKED, } from './constant';
const defaultState = {
    isNoteModalOpen: false,
    isRelanceModalOpen: false,
    isRdvModalOpen: false,
    isContactModalOpen: false,
    isAnomalieModalOpen: false,
    isLocked: false,
    initialValues: null,
};
const allFalse = {
    isNoteModalOpen: false,
    isRelanceModalOpen: false,
    isRdvModalOpen: false,
    isContactModalOpen: false,
    isAnomalieModalOpen: false
};
const footerReducer = handleActions({
    [SET_FOOTER_RELANCE]: (state, action) => {
        if (action.payload.relanceModalOpen === false) {
            return ({ ...state, ...allFalse, isRelanceModalOpen: action.payload.relanceModalOpen, initialValues: null });
        }
        return ({ ...state, ...allFalse, isRelanceModalOpen: action.payload.relanceModalOpen, initialValues: action.payload.initialValues ? action.payload.initialValues : null });
    },
    [SET_FOOTER_NOTE]: (state, action) => {
        if (action.payload.noteModalOpen === false) {
            return ({ ...state, ...allFalse, isNoteModalOpen: action.payload.noteModalOpen, initialValues: null });
        }
        return ({ ...state, ...allFalse, isNoteModalOpen: action.payload.noteModalOpen, initialValues: action.payload.initialValues ? action.payload.initialValues : null });
    },
    [SET_FOOTER_RDV]: (state, action) => {
        if (action.payload.rdvModalOpen === false) {
            return ({ ...state, ...allFalse, isRdvModalOpen: action.payload.rdvModalOpen, initialValues: null });
        }
        return ({ ...state, ...allFalse, isRdvModalOpen: action.payload.rdvModalOpen, initialValues: action.payload.initialValues ? action.payload.initialValues : null });
    },
    [SET_FOOTER_CONTACT]: (state, action) => {
        if (action.payload.contactModalOpen === false) {
            return ({ ...state, ...allFalse, isContactModalOpen: action.payload.contactModalOpen, initialValues: null });
        }
        return ({ ...state, ...allFalse, isContactModalOpen: action.payload.contactModalOpen, initialValues: action.payload.initialValues ? action.payload.initialValues : null });
    },
    [SET_FOOTER_ANOMALIE]: (state, action) => {
        if (action.payload.noteModalOpen === false) {
            return ({ ...state, ...allFalse, isAnomalieModalOpen: action.payload.anomalieModalOpen, initialValues: null });
        }
        return ({ ...state, ...allFalse, isAnomalieModalOpen: action.payload.anomalieModalOpen, initialValues: action.payload.initialValues ? action.payload.initialValues : null });
    },
    [SET_LOCKED]: (state) => {
        return ({ ...state, isLocked: !state.isLocked });
    }
}, defaultState);
export default footerReducer;
