import { useEffect, useState } from 'react';
import ApiService from '../../../utilities/apiService/ApiService';
import { ANOMALIECMDLINESENDPOINT } from '../../../utilities/apiService/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setAnomaliePanelReload } from '../../../../redux/reloader/action';
export const useAnomalies = getAll => {
    const [anomalies, setAnomalies] = useState([]);
    const reload = useSelector((state) => state.reloader.anomaliePanelReload);
    const [selectedAnomalie, setSelectedAnomalie] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState([]);
    const formatFilters = () => {
        return filters.map(f => {
            if (f.id === 'cmd.costumer')
                return { id: 'cmd.costumer.id', value: f.value };
            if (f.id === 'saler')
                return { id: 'cmd.saler.id', value: f.value };
            if (f.id === 'status')
                return { id: 'status', value: f.value === 'OPEN' ? 1 : 100 };
            return f;
        });
    };
    const formatId = datas => {
        return datas
            .map(d => {
            if (!d.cmd) {
                console.error('CMD IS NULL', d);
                return null;
            }
            const parsedId = d.cmd['@id'].split('/');
            return { ...d, cmd: { ...d.cmd, id: parsedId[3] } };
        })
            .filter(d => d !== null);
    };
    const getOneAnomaly = async (id) => {
        const anomalyService = new ApiService(ANOMALIECMDLINESENDPOINT);
        setIsLoading(true);
        const anomaly = await anomalyService.get({ _id: id });
        console.log({ anomaly });
        setSelectedAnomalie(anomaly);
        setIsLoading(false);
    };
    const getSorted = () => {
        const sorted = [{ id: 'createdAt', desc: true }];
        if (filters.find(f => f.id === 'status'))
            sorted.push({ id: 'updatedAt', desc: true });
        return sorted;
    };
    const getAnomalies = async () => {
        const anomalyService = new ApiService(ANOMALIECMDLINESENDPOINT);
        setIsLoading(true);
        const results = await anomalyService.search({
            sorted: getSorted(),
            filtered: formatFilters(),
            page,
        });
        const totalItems = results['hydra:totalItems'];
        const data = results['hydra:member'];
        const newData = page > 1 ? [...anomalies, ...data] : data;
        setHasNextPage(newData.length < totalItems);
        setIsLoading(false);
        setAnomalies(newData);
    };
    const loadNextPage = () => {
        setPage(page + 1);
        setFireUseEffect(Math.random());
    };
    const reloadData = () => {
        if (selectedAnomalie) {
            getOneAnomaly(selectedAnomalie.id);
        }
        setPage(1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [filters, getAll]);
    useEffect(() => {
        getAnomalies();
    }, [fireUseEffect]);
    useEffect(() => {
        if (!reload)
            return;
        if (selectedAnomalie) {
            getOneAnomaly(selectedAnomalie.id);
        }
        dispatch(setAnomaliePanelReload(false));
    }, [reload]);
    return {
        anomalies,
        getOneAnomaly,
        hasNextPage,
        loadNextPage,
        reloadData,
        isLoading,
        setSelectedAnomalie,
        selectedAnomalie,
        setFilters,
    };
};
