export const save = (name, value) => {
    value = JSON.stringify(value);
    localStorage.setItem(name, value);
};
export const get = name => {
    const value = localStorage.getItem(name);
    if (!value)
        return false;
    const parsing = JSON.parse(value);
    if (parsing) {
        return parsing;
    }
    else {
        return value;
    }
};
export const destroy = name => {
    localStorage.removeItem(name);
};
const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
};
export const getEntity = (entityName, id) => {
    let ids = localStorage.getItem('data');
    if (!ids)
        ids = { data: {} };
    else
        ids = JSON.parse(ids);
    return getNestedObject(ids.data, [entityName, id]);
};
export const saveEntity = (entityName, id, element) => {
    let ids = localStorage.getItem('data');
    if (!ids)
        ids = { data: {} };
    else
        ids = JSON.parse(ids);
    const entity = getEntity(entityName, id);
    console.log({ entityName, entity, id, element });
    if (!entity) {
        ids = {
            data: {
                ...ids.data,
                [entityName]: {
                    ...ids.data[entityName],
                    [id]: element,
                },
            },
        };
        console.log({ newIds: ids });
        localStorage.setItem('data', JSON.stringify(ids));
    }
};
export const replaceEntityFields = (entityName, id, newFields) => {
    let ids = localStorage.getItem('data');
    if (!ids)
        ids = { data: {} };
    else
        ids = JSON.parse(ids);
    const entity = getEntity(entityName, id);
    console.log({ entity, id, entityName, newFields });
    if (entity) {
        ids = {
            data: {
                ...ids.data,
                [entityName]: {
                    ...ids.data[entityName],
                    [id]: {
                        ...ids.data[entityName][id],
                        ...newFields,
                    },
                },
            },
        };
    }
    console.log({ ids });
    localStorage.setItem('data', JSON.stringify(ids));
};
export const removeEntity = (entityName, id) => {
    let ids = localStorage.getItem('data');
    if (!ids)
        ids = { data: {} };
    else
        ids = JSON.parse(ids);
    const entity = getEntity(entityName, id);
    if (entity) {
        const { [id]: toRemove, ...rest } = ids.data[entityName];
        ids = {
            data: {
                ...ids.data,
                [entityName]: {
                    ...rest,
                },
            },
        };
        localStorage.setItem('data', JSON.stringify(ids));
    }
};
export const isInLocalStorage = (entityName, id) => {
    const entity = getEntity(entityName, id);
    if (!entity)
        return false;
    return true;
};
