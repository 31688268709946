import { FicheBody } from '../../../components/Fiche/FicheDefaultLayout';
import Icon from '../../../components/Icon/Icon';
import NameFormat from '../../../components/Nameformat';
import Moment from 'react-moment';
import React, { useState } from 'react';
import { isInLocalStorage, removeEntity, saveEntity } from '../../../utilities/storage';
import SelectionCheckBox from '../../../components/SelectionCheckBox/SelectionCheckBox';
export const FicheAnomalie = ({ anomalie, color = 'lightpurple', callback }) => {
    const { cmd, contact, createdAt } = anomalie;
    const { saler } = cmd;
    const [isChecked, setIsChecked] = useState(isInLocalStorage('anomalies', anomalie.id));
    const onChange = e => {
        if (e.target.checked)
            saveEntity('anomalies', anomalie.id, anomalie);
        if (!e.target.checked)
            removeEntity('anomalies', anomalie.id);
        setIsChecked(e.target.checked);
        if (callback)
            callback();
    };
    return (React.createElement("div", { className: `card card-color-${color}` },
        React.createElement(SelectionCheckBox, { id: anomalie.id, isChecked: isChecked, onChange: onChange }),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-entreprise', bold: true, text: cmd && cmd.costumer ? cmd.costumer.socialReason : 'Social Raison non définis', className: 'nowrap' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-contact', bold: true, text: contact ? contact.name : 'Contact non définis', className: 'nowrap' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-affaire', text: cmd.ref, className: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-utilisateur', bold: true, text: saler ? NameFormat({ value: saler.name }) : 'saler', className: 'nowrap align-right' })),
        React.createElement(FicheBody, { columns: 'dualColumns' },
            React.createElement(Icon, { icon: 'icon-ref-commande', bold: true, text: cmd.refBusiness, className: 'nowrap' }),
            React.createElement("div", { className: 'align-right small-date' },
                React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, createdAt)))));
};
