import { useState, useEffect } from 'react';
import { CONTACTLASTICENDPOINT } from '../../../utilities/apiService/constant';
import ElasticService from '../../../utilities/apiService/ElasticService';
export const useClients = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [fireUseEffect, setFireUseEffect] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState([]);
    const EsFormat = obj => {
        const { _meta, ...objWithoutMeta } = obj;
        let updatedObj = { ...objWithoutMeta };
        for (const prop in objWithoutMeta) {
            updatedObj = {
                ...updatedObj,
                [prop]: objWithoutMeta[prop].raw,
            };
        }
        return updatedObj;
    };
    const loadSingleClient = async (_id) => {
        const productService = new ElasticService(CONTACTLASTICENDPOINT);
        const result = await productService.search({
            query: "",
            filters: { "all": [{ "id": _id }] },
            page: { current: 1, size: 20 },
        });
        const results = result.results;
        if (results && results.length > 0) {
            setSelectedClient(EsFormat(results[0]));
        }
        return;
    };
    const loadClient = async () => {
        setIsLoading(true);
        const productService = new ElasticService(CONTACTLASTICENDPOINT);
        const filter = filters.find(f => f.id === 'socialReason');
        const result = await productService.search({
            query: filter ? (filter.value ? filter.value : 1) : 1,
            page: { current: page, size: 20 },
        });
        const { current, total_pages } = result.meta.page;
        setHasNextPage(current < total_pages);
        const newResult = result.results.map(r => EsFormat(r));
        setIsLoading(false);
        setClients(clients && current > 1 ? [...clients, ...newResult] : newResult);
    };
    const reloadData = () => {
        setPage(1);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setPage(page + 1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [filters]);
    useEffect(() => {
        if (!fireUseEffect)
            return;
        loadClient();
    }, [fireUseEffect]);
    return {
        clients,
        isLoading,
        loadNextPage,
        loadSingleClient,
        selectedClient,
        setSelectedClient,
        hasNextPage,
        reloadData,
        setFilters,
    };
};
