import React, { useState, useEffect, Fragment } from 'react';
import * as Session from '../../shared/session';
import { useDispatch, useSelector } from 'react-redux';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import socketIOClient from 'socket.io-client';
import { defaultUser, setCostumer, setUser } from '../../../redux/user/action';
import { FORM_ERROR } from 'final-form';
import Loading from '../../shared/Loading';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import LoginFormContainer from '../Form/Login/Login';
import ApiService from '../../utilities/apiService/ApiService';
import { COSTUMERSENDPOINT, HUMANFUNCTIONENDPOINT, PARAMSENDPOINT, SOCKETENDPOINT, } from '../../utilities/apiService/constant';
import { setParams } from '../../../redux/params/action';
import { setHumanFunctions } from '../../../redux/humanFunctions/action';
import { setReload } from '../../../redux/sockets/action';
import { setUpdateMessagePanelReload } from '../../../redux/reloader/action';
import Messagerie from '../../modules/Messagerie';
const MainWrapper = ({ children, location }) => {
    const [loaded, setLoaded] = useState(false);
    const user = useSelector((state) => state.user);
    const params = useSelector((state) => state.parameters);
    const dispatch = useDispatch();
    const checkAuthentification = async () => {
        const token = Session.getJwtToken();
        if (!token) {
            dispatch(setUser(null));
            const paramsService = new ApiService(PARAMSENDPOINT);
            const params = await paramsService.getAll();
            if (params)
                dispatch(setParams(params.CMD));
            setLoaded(true);
            return;
        }
        try {
            const newUser = await Session.getUser();
            if (!newUser.roles.includes('ROLE_ADMIN')) {
                await Session.logout();
                setLoaded(true);
                return { [FORM_ERROR]: 'Vous n’avez pas l’autorisation d’accéder à cette interface.' };
            }
            if (newUser && newUser.take && Array.isArray(newUser.take) && newUser.take.length === 0) {
                alert("Vous n'avez pas d'organisations internes");
                await Session.logout();
                setLoaded(true);
                return { [FORM_ERROR]: "Vous n'avez pas d'organisations internes" };
            }
            const paramsService = new ApiService(PARAMSENDPOINT);
            const params = await paramsService.getAll();
            const humanFunctionService = new ApiService(HUMANFUNCTIONENDPOINT);
            const humanFunctions = await humanFunctionService.getAll();
            const clientService = new ApiService(COSTUMERSENDPOINT);
            const clientRes = await clientService.search({ filtered: [{ id: 'saler.id', value: newUser.take.id }] });
            const client = clientRes['hydra:member'];
            if (client && client.length > 0) {
                dispatch(setCostumer(client[0]));
            }
            if (humanFunctions) {
                dispatch(setHumanFunctions(humanFunctions['hydra:member'].filter(hf => hf.label !== '')));
            }
            if (params)
                dispatch(setParams(params.CMD));
            dispatch(setUser(newUser));
        }
        catch (e) {
            dispatch(setUser(defaultUser));
        }
        finally {
            setLoaded(true);
            return;
        }
    };
    useEffect(() => {
        (async function () {
            await checkAuthentification();
        })();
    }, []);
    useEffect(() => {
        if (!user || !user.data)
            return;
        const socket = socketIOClient(SOCKETENDPOINT, { query: { email: user.data.email } });
        const app = feathers();
        app.configure(socketio(socket, {
            timeout: 2000,
        }));
        app.io.on('new_notification', () => {
            console.log('VOUS AVEZ UNE NOUVELLE NOTIFICATION');
            dispatch(setUpdateMessagePanelReload(true));
            dispatch(setReload(true));
        });
        return () => app.io.disconnect();
    }, [user]);
    const redirect = () => {
        return React.createElement(Redirect, { to: '/main/dashboard' });
    };
    return (React.createElement(Fragment, null,
        !loaded && params === null && React.createElement(Loading, { withoutLogo: false }),
        loaded && (!user.data || !user.data.id) && (React.createElement("div", { className: "wrapper" },
            React.createElement("main", null,
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/login", component: LoginFormContainer }),
                    React.createElement(Route, { path: "/message/:id/:author/:hash", component: Messagerie }),
                    React.createElement(Redirect, { from: "*", to: "/login" }))))),
        loaded && user.data && user.data.id && (React.createElement("div", { className: "wrapper" },
            location.pathname === '/login' && React.createElement(Redirect, { from: "*", to: "/main/dashboard" }),
            children))));
};
export default withRouter(MainWrapper);
