import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ApiService from '../../../utilities/apiService/ApiService';
import { COSTUMERSENDPOINT } from '../../../utilities/apiService/constant';
import { setSelectedProductFilter } from '../../../../redux/productFilters/action';
export const SelectedProductFilters = () => {
    const filtersParams = useSelector((state) => state.selectedProductFilter);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(null);
    const updateFilters = filter => {
        const updatedFilters = filters ? [...filters] : [];
        const idx = updatedFilters.findIndex(f => f.id === filter.id);
        if (idx === -1) {
            updatedFilters.push(filter);
            dispatch(setSelectedProductFilter(updatedFilters));
            setFilters(updatedFilters);
            return;
        }
        else {
            updatedFilters[idx] = filter;
        }
        dispatch(setSelectedProductFilter(updatedFilters));
        setFilters(updatedFilters);
        return;
    };
    const clearValue = id => {
        if (!filters)
            return;
        const updatedFilters = filters.filter(f => f.id !== id);
        setFilters(updatedFilters);
        dispatch(setSelectedProductFilter(updatedFilters));
    };
    const onChangeCostumer = value => {
        updateFilters({ id: 'cmd.costumer.id', value: value.value, label: value.label });
    };
    const onChangeSaler = value => {
        updateFilters({ id: 'cmd.saler.id', value: value.value, label: value.label });
    };
    const getValue = id => {
        if (!filters)
            return null;
        const filter = filters.find(f => f.id === id);
        return filter && filter.value ? { label: filter.label, value: filter.value } : null;
    };
    const asyncLoad = async (value) => {
        let filtered = [{ id: 'human', value: false }];
        if (value)
            filtered = [
                { id: 'human', value: false },
                { id: 'socialReason', value: value },
            ];
        const companyService = new ApiService(COSTUMERSENDPOINT);
        const companies = await companyService.search({ filtered: filtered, pageSize: 50 });
        return [{ label: 'Tout', value: null }].concat(companies['hydra:member'].map(c => ({ label: c.socialReason, value: c.ref })));
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "filtersContainer productFilterContainer mb-15" },
            React.createElement("div", { className: 'productFilters' },
                React.createElement("div", { className: "filtersContainer-row withPointerEvents" },
                    React.createElement("div", { className: "filterIcon icon-entreprise mr-10" }),
                    React.createElement("div", { className: 'filterSelect withPointerEvents' },
                        React.createElement(AsyncSelect, { name: 'company', isDisabled: false, isSearchable: true, closeOnSelect: false, value: getValue('cmd.costumer.id'), defaultOptions: true, onChange: onChangeCostumer, loadOptions: selectValue => asyncLoad(selectValue), placeholder: 'Tout', classNamePrefix: "FMySel", className: "FMySelect-control" })),
                    React.createElement("div", { className: 'async-remove', onClick: () => clearValue('cmd.costumer.id') },
                        React.createElement("span", null, "X"))),
                React.createElement("div", { className: "filtersContainer-row withPointerEvents" },
                    React.createElement("div", { className: "filterIcon icon-utilisateur mr-10" }),
                    React.createElement("div", { className: 'filterSelect productFilterSelect withPointerEvents' },
                        React.createElement(Select, { name: 'saler', onChange: onChangeSaler, value: getValue('cmd.saler.id'), options: filtersParams.salers, placeholder: 'Tout', classNamePrefix: "MySel", className: "MySelect-control" }),
                        React.createElement("div", { className: 'removeCross', onClick: () => clearValue('cmd.saler.id') },
                            React.createElement("span", null, "X"))))))));
};
