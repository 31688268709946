import { useEffect, useState } from 'react';
import ApiService from '../../../utilities/apiService/ApiService';
import { GCALENDARENDPOINT } from '../../../utilities/apiService/constant';
export const useNotes = _getAll => {
    const [filters, setFilters] = useState(null);
    const [notes, setNotes] = useState([]);
    const [skip, setSkip] = useState(0);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [hasNextPage, setHasNextPage] = useState(false);
    const [selectedNote, setSelectedNote] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const formatFilters = () => {
        if (!filters)
            return [{ id: 'type', value: 'note' }];
        const eq = {
            costumer: 'subject.cmd.costumer.id',
            saler: 'subject.cmd.saler.id',
            'createdAt[after]': 'createdAt[$gte]',
            'createdAt[before]': 'createdAt[$lte]',
        };
        return filters
            .map(f => ({ id: eq[f.id] ? eq[f.id] : f.id, value: f.value }))
            .concat({ id: 'type', value: 'note' });
    };
    const getOneNote = async (id) => {
        const noteService = new ApiService(GCALENDARENDPOINT);
        const note = await noteService.get({ _id: id });
        setSelectedNote(note);
    };
    const fetchNotes = async () => {
        const noteService = new ApiService(GCALENDARENDPOINT);
        setIsLoading(true);
        const results = await noteService.search({ filtered: formatFilters(), sort: { createdAt: -1 }, skip: skip, limit: 30 });
        const totalItems = results.total;
        const data = results.data;
        const newData = skip > 0 ? [...notes, ...data] : data;
        setSkip(newData.length);
        setHasNextPage(newData.length < totalItems);
        setIsLoading(false);
        setNotes(newData);
    };
    const reloadData = () => {
        if (selectedNote) {
            getOneNote(selectedNote._id);
        }
        setSkip(0);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        if (filters !== null) {
            setSkip(0);
            setFireUseEffect(Math.random());
        }
    }, [filters]);
    useEffect(() => {
        fetchNotes();
    }, [fireUseEffect]);
    return {
        notes,
        isLoading,
        hasNextPage,
        getOneNote,
        loadNextPage,
        reloadData,
        setFilters,
        setSelectedNote,
        selectedNote,
    };
};
