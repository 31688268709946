import React, { useEffect } from 'react';
import ApiService from '../../utilities/apiService/ApiService';
import { GCALENDARENDPOINT } from '../../utilities/apiService/constant';
export const DeleteAllNote = () => {
    const data = {
        "total": 30,
        "limit": 50,
        "skip": 0,
        "data": [
            {
                "_id": "5f607f9b4caf2900127f5285",
                "recurrence": [],
                "start": "2020-09-15T10:46:59.000Z",
                "end": "2020-09-15T10:46:59.000Z",
                "creator": {
                    "_id": "5f607f9b4caf2900127f5286",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "organizer": {
                    "_id": "5f607f9b4caf2900127f5287",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "OPEN",
                "type": "relance",
                "note": "Il faut rappeler ce client avant vendredi",
                "subject": {
                    "cmd": {
                        "@id": "/api/cmds/613c296d-db34-4ecb-b8e9-a880987b53ee",
                        "@type": "Cmd",
                        "id": "613c296d-db34-4ecb-b8e9-a880987b53ee",
                        "ref": "1.363716",
                        "writeAt": "2020-09-11T00:00:00+02:00",
                        "orderAt": "2020-09-11T00:00:00+02:00",
                        "type": "cmd",
                        "isOrder": true,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-09-17T00:00:00+02:00",
                        "stock": null,
                        "isBlocked": true,
                        "blockedCode": "7",
                        "blockedDetail": "Encours",
                        "isPrepareInProgress": false,
                        "costumerRef": "33688",
                        "refBusiness": "",
                        "amountHt": "1215.25",
                        "amountBuyHt": "1003.02",
                        "amountToDelivery": "1215.25",
                        "stockAlert": "red",
                        "costumer": {
                            "@id": "/api/costumers/a8e2a9cd-9280-4360-b3a1-f8b11a38e262",
                            "@type": "Costumer",
                            "id": "a8e2a9cd-9280-4360-b3a1-f8b11a38e262",
                            "socialReason": "EURO CHAUFF",
                            "ref": "0022645",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "EURO CHAUFF"
                        },
                        "billSocialReason": "EURO CHAUFF",
                        "billAdress1": "18 BIS RUE DU GENERAL LECLERC",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "77170",
                        "billCity": "BRIE COMTE ROBERT",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "EURO CHAUFF",
                        "shipAdress1": "8 RUE LUCIEN SAMPAIX",
                        "shipAdress2": "TEL:06.62.61.94.24",
                        "shipAdress3": "",
                        "shipZipCode": "95870",
                        "shipCity": "BEZONS",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "558",
                        "createdAt": "2020-09-15T10:28:32+02:00",
                        "updatedAt": "2020-09-15T10:35:58+02:00",
                        "manager": {
                            "@id": "/api/internal_users/17",
                            "@type": "InternalUser",
                            "id": 17,
                            "code": "JS",
                            "email": "joffrey.sellier@csc-meaux.com",
                            "firstname": "Joffrey",
                            "lastname": "Sellier",
                            "name": "Joffrey Sellier"
                        },
                        "saler": {
                            "@id": "/api/internal_users/3",
                            "@type": "InternalUser",
                            "id": 3,
                            "code": "19",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Yoann",
                            "lastname": "Jorge",
                            "name": "Yoann Jorge"
                        },
                        "technician": {
                            "@id": "/api/internal_users/17",
                            "@type": "InternalUser",
                            "id": 17,
                            "code": "JS",
                            "email": "joffrey.sellier@csc-meaux.com",
                            "firstname": "Joffrey",
                            "lastname": "Sellier",
                            "name": "Joffrey Sellier"
                        },
                        "status": "cc",
                        "marginHt": "212.23",
                        "ecoTaxe": "0.00",
                        "marginRate": "17.46",
                        "marginCoef": "0.21",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": true,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Rouge",
                        "typeLabel": "Commande",
                        "statusLabel": "Commande client"
                    },
                    "saler": {
                        "@id": "/api/internal_users/3",
                        "@type": "InternalUser",
                        "id": 3,
                        "code": "19",
                        "email": "yoann.jorge@csc-meaux.com",
                        "firstname": "Yoann",
                        "lastname": "Jorge",
                        "name": "Yoann Jorge"
                    },
                    "answers": [
                        {
                            "creator": {
                                "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                                "email": "maxime@whatson-web.com",
                                "displayName": "Maxime Réthoré"
                            },
                            "comment": "test",
                            "date": "2020-09-17T09:41:39.489Z"
                        },
                        {
                            "creator": {
                                "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                                "email": "arnaud.deruel@csc-meaux.com",
                                "displayName": "Arnaud Deruel"
                            },
                            "comment": "test 2",
                            "date": "2020-09-18T10:40:30.837Z"
                        },
                        {
                            "creator": {
                                "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                                "email": "arnaud.deruel@csc-meaux.com",
                                "displayName": "Arnaud Deruel"
                            },
                            "comment": "le client de repond pas",
                            "date": "2020-09-18T10:52:32.993Z"
                        },
                        {
                            "creator": {
                                "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                                "email": "arnaud.deruel@csc-meaux.com",
                                "displayName": "Arnaud Deruel"
                            },
                            "comment": "Les réponses mettent beaucoup de temps à s’afficher",
                            "date": "2020-09-18T11:07:49.868Z"
                        },
                        {
                            "creator": {
                                "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                                "email": "arnaud.deruel@csc-meaux.com",
                                "displayName": "Arnaud Deruel"
                            },
                            "comment": "Test de reponse",
                            "date": "2020-09-22T11:47:44.487Z"
                        }
                    ]
                },
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-15T08:47:23.772Z",
                "updatedAt": "2020-09-22T11:47:44.551Z",
                "__v": 0
            },
            {
                "_id": "5f648d344caf2900127f52ce",
                "recurrence": [],
                "start": "2020-09-20T00:00:00.000Z",
                "end": "2020-09-20T00:00:00.000Z",
                "creator": {
                    "_id": "5f648d344caf2900127f52cf",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "là on est bien",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "@type": "Cmd",
                        "id": "5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "ref": "1.364357",
                        "writeAt": "2020-09-18T00:00:00+02:00",
                        "orderAt": "2020-09-18T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "C19104A",
                        "amountHt": "326.20",
                        "amountBuyHt": "250.46",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/28640fdc-621a-4b3a-8017-dc2539711a45",
                            "@type": "Costumer",
                            "id": "28640fdc-621a-4b3a-8017-dc2539711a45",
                            "socialReason": "A3BAT",
                            "ref": "0023701",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A3BAT"
                        },
                        "billSocialReason": "A3BAT",
                        "billAdress1": "5 Rue Gustave Eiffel",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "77610",
                        "billCity": "FONTENAY TRESIGNY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A3BAT",
                        "shipAdress1": "5 Rue Gustave Eiffel",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77610",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "711",
                        "createdAt": "2020-09-18T09:57:21+02:00",
                        "updatedAt": "2020-09-18T09:57:21+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/2",
                            "@type": "InternalUser",
                            "id": 2,
                            "code": "110",
                            "email": "mehdi.boukaoula@csc-meaux.com",
                            "firstname": "Mehdi",
                            "lastname": "Boukaoula",
                            "name": "Mehdi Boukaoula"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "75.74",
                        "ecoTaxe": "0.00",
                        "marginRate": "23.22",
                        "marginCoef": "0.3",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f648d344caf2900127f52d0",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T10:34:28.215Z",
                "updatedAt": "2020-09-18T13:47:42.843Z",
                "__v": 0
            },
            {
                "_id": "5f648e674caf2900127f52d1",
                "recurrence": [],
                "start": "2020-09-18T15:44:40.000Z",
                "end": "2020-09-18T15:44:40.000Z",
                "creator": {
                    "_id": "5f648e674caf2900127f52d2",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "organizer": {
                    "_id": "5f648e674caf2900127f52d3",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "OPEN",
                "type": "relance",
                "note": "relancer ce client lundi",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "@type": "Cmd",
                        "id": "5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "ref": "1.364357",
                        "writeAt": "2020-09-18T00:00:00+02:00",
                        "orderAt": "2020-09-18T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "C19104A",
                        "amountHt": "326.20",
                        "amountBuyHt": "250.46",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/28640fdc-621a-4b3a-8017-dc2539711a45",
                            "@type": "Costumer",
                            "id": "28640fdc-621a-4b3a-8017-dc2539711a45",
                            "socialReason": "A3BAT",
                            "ref": "0023701",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A3BAT"
                        },
                        "billSocialReason": "A3BAT",
                        "billAdress1": "5 Rue Gustave Eiffel",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "77610",
                        "billCity": "FONTENAY TRESIGNY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A3BAT",
                        "shipAdress1": "5 Rue Gustave Eiffel",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77610",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "711",
                        "createdAt": "2020-09-18T09:57:21+02:00",
                        "updatedAt": "2020-09-18T09:57:21+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/2",
                            "@type": "InternalUser",
                            "id": 2,
                            "code": "110",
                            "email": "mehdi.boukaoula@csc-meaux.com",
                            "firstname": "Mehdi",
                            "lastname": "Boukaoula",
                            "name": "Mehdi Boukaoula"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "75.74",
                        "ecoTaxe": "0.00",
                        "marginRate": "23.22",
                        "marginCoef": "0.3",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "answers": [
                        {
                            "creator": {
                                "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                                "email": "arnaud.deruel@csc-meaux.com",
                                "displayName": "Arnaud Deruel"
                            },
                            "comment": "le client ne repond pas",
                            "date": "2020-09-18T10:39:57.135Z"
                        },
                        {
                            "creator": {
                                "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                                "email": "maxime@whatson-web.com",
                                "displayName": "Maxime Réthoré"
                            },
                            "comment": "dddd",
                            "date": "2020-09-18T15:26:59.901Z"
                        },
                        {
                            "creator": {
                                "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                                "email": "maxime@whatson-web.com",
                                "displayName": "Maxime Réthoré"
                            },
                            "comment": "testss",
                            "date": "2020-09-18T14:16:48.259Z"
                        },
                        {
                            "creator": {
                                "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                                "email": "maxime@whatson-web.com",
                                "displayName": "Maxime Réthoré"
                            },
                            "comment": "ddddd",
                            "date": "2020-09-18T14:19:13.527Z"
                        },
                        {
                            "creator": {
                                "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                                "email": "maxime@whatson-web.com",
                                "displayName": "Maxime Réthoré"
                            },
                            "comment": "test 22",
                            "date": "2020-09-18T14:24:49.247Z"
                        },
                        {
                            "creator": {
                                "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                                "email": "maxime@whatson-web.com",
                                "displayName": "Maxime Réthoré"
                            },
                            "comment": "test 23",
                            "date": "2020-09-18T14:24:53.513Z"
                        },
                        {
                            "creator": {
                                "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                                "email": "maxime@whatson-web.com",
                                "displayName": "Maxime Réthoré"
                            },
                            "comment": "test 24",
                            "date": "2020-09-18T15:23:14.037Z"
                        }
                    ],
                    "saler": {
                        "@id": "/api/internal_users/2",
                        "@type": "InternalUser",
                        "id": "2",
                        "code": "110",
                        "email": "mehdi.boukaoula@csc-meaux.com",
                        "firstname": "Mehdi",
                        "lastname": "Boukaoula",
                        "name": "Mehdi Boukaoula"
                    }
                },
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T10:39:35.427Z",
                "updatedAt": "2020-09-18T15:27:00.062Z",
                "__v": 0
            },
            {
                "_id": "5f6490e94caf2900127f52da",
                "recurrence": [],
                "start": "2020-09-19T00:00:00.000Z",
                "end": "2020-09-19T00:00:00.000Z",
                "creator": {
                    "_id": "5f6490e94caf2900127f52db",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "Essayer de trouver une autre chaudiere qui rentre dans le placard",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/4b94f709-ebf9-4284-ba0e-6bef0ed7e2f4",
                        "@type": "Cmd",
                        "id": "4b94f709-ebf9-4284-ba0e-6bef0ed7e2f4",
                        "ref": "1.364115",
                        "writeAt": "2020-09-16T00:00:00+02:00",
                        "orderAt": "2020-09-16T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "POMPE WILO",
                        "amountHt": "2423.38",
                        "amountBuyHt": "2118.53",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/fe2b2af2-eae3-48f0-ad83-195f2995b76b",
                            "@type": "Costumer",
                            "id": "fe2b2af2-eae3-48f0-ad83-195f2995b76b",
                            "socialReason": "ACQUA FRANCE PLOMBERIE",
                            "ref": "0023400",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "ACQUA FRANCE PLOMBERIE"
                        },
                        "billSocialReason": "ACQUA FRANCE PLOMBERIE",
                        "billAdress1": "73-79 AVE DU 14 JUILLET",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "93140",
                        "billCity": "BONDY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "ACQUA FRANCE PLOMBERIE",
                        "shipAdress1": "73-79 AVE DU 14 JUILLET",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "93140",
                        "shipCity": "BONDY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "657",
                        "createdAt": "2020-09-17T04:39:14+02:00",
                        "updatedAt": "2020-09-17T12:03:26+02:00",
                        "manager": {
                            "@id": "/api/internal_users/26",
                            "@type": "InternalUser",
                            "id": 26,
                            "code": "SV",
                            "email": null,
                            "firstname": null,
                            "lastname": null,
                            "name": "SV"
                        },
                        "saler": {
                            "@id": "/api/internal_users/5",
                            "@type": "InternalUser",
                            "id": 5,
                            "code": "01",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Sylvain",
                            "lastname": "Vibert",
                            "name": "Sylvain Vibert"
                        },
                        "technician": null,
                        "status": "of",
                        "marginHt": "304.85",
                        "ecoTaxe": "0.00",
                        "marginRate": "12.58",
                        "marginCoef": "0.14",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/1ba46e7a-14fe-464c-8655-0a245d1af037",
                            "@type": "Costumer",
                            "id": "1ba46e7a-14fe-464c-8655-0a245d1af037",
                            "socialReason": null,
                            "ref": "416",
                            "lastname": "Ayhan",
                            "firstname": "Yunus",
                            "phone": "",
                            "mobile": "06.26.55.81.71",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "Yunus Ayhan"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f6490e94caf2900127f52dc",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T10:50:17.097Z",
                "updatedAt": "2020-09-21T09:37:54.874Z",
                "__v": 0
            },
            {
                "_id": "5f6492bd4caf2900127f52e4",
                "recurrence": [],
                "start": "2020-09-24T00:00:00.000Z",
                "end": "2020-09-24T00:00:00.000Z",
                "creator": {
                    "_id": "5f6492bd4caf2900127f52e5",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "Meilleure remise sur la chaudiere. Testd",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/59cd9822-eaa4-4ab1-bcf6-90ef30416eb2",
                        "@type": "Cmd",
                        "id": "59cd9822-eaa4-4ab1-bcf6-90ef30416eb2",
                        "ref": "1.364276",
                        "writeAt": "2020-09-17T00:00:00+02:00",
                        "orderAt": "2020-09-17T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "EVRY",
                        "amountHt": "906.90",
                        "amountBuyHt": "663.00",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/bc4c885e-0f62-4ecd-96a2-d00843d8dba4",
                            "@type": "Costumer",
                            "id": "bc4c885e-0f62-4ecd-96a2-d00843d8dba4",
                            "socialReason": "CHARPENTIER",
                            "ref": "0000297",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "CHARPENTIER"
                        },
                        "billSocialReason": "CHARPENTIER",
                        "billAdress1": "1 RUE DE BRETAGNE",
                        "billAdress2": "BP 6 ZI LA MOINERIE",
                        "billAdress3": "AC 386374 / 07-2019",
                        "billZipCode": "91220",
                        "billCity": "BRETIGNY SUR ORGE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "CHARPENTIER",
                        "shipAdress1": "1 RUE DE BRETAGNE",
                        "shipAdress2": "BP 6 ZI LA MOINERIE",
                        "shipAdress3": "AC 386374 / 07-2019",
                        "shipZipCode": "91220",
                        "shipCity": "BRETIGNY SUR ORGE",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "710",
                        "createdAt": "2020-09-17T21:32:38+02:00",
                        "updatedAt": "2020-09-18T09:32:48+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/7",
                            "@type": "InternalUser",
                            "id": 7,
                            "code": "14",
                            "email": "jeremy.adeline@csc-meaux.com",
                            "firstname": "Jeremy",
                            "lastname": "Adeline",
                            "name": "Jeremy Adeline"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "243.9",
                        "ecoTaxe": "0.00",
                        "marginRate": "26.89",
                        "marginCoef": "0.37",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/55c336c4-3809-442f-9ca5-e6be72d864d6",
                            "@type": "Costumer",
                            "id": "55c336c4-3809-442f-9ca5-e6be72d864d6",
                            "socialReason": null,
                            "ref": "320",
                            "lastname": "WYDASZ",
                            "firstname": "JEAN-LUC",
                            "phone": "",
                            "mobile": "06.60.21.37.73",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "JEAN-LUC WYDASZ"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f6492bd4caf2900127f52e6",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T10:58:05.123Z",
                "updatedAt": "2020-09-21T09:38:10.882Z",
                "__v": 0
            },
            {
                "_id": "5f64c53a4caf2900127f532c",
                "recurrence": [],
                "start": "2020-09-20T12:00:00.000Z",
                "end": "2020-09-20T12:10:00.000Z",
                "creator": {
                    "_id": "5f64c53a4caf2900127f532d",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "subject": {
                    "costumer": {
                        "id": "bfb8d95c-f0bc-4bd6-9145-3a2da2db7941",
                        "displayName": "ISF ENERGIES",
                        "email": null,
                        "phone": null,
                        "saler": null,
                        "city": "NC",
                        "zipCode": "NC",
                        "address": "NC"
                    },
                    "type": "costumer"
                },
                "organizer": {
                    "_id": "5f64c53a4caf2900127f532e",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "dontcare",
                "type": "appointment",
                "attendees": [
                    {
                        "_id": "5f64c53a4caf2900127f532f",
                        "id": "349bc587-63f1-4e5e-b933-1061d35dc70f",
                        "email": "i.tounkara@isf-energies.com",
                        "displayName": "Ibrahim TOUNKARA",
                        "organizer": false,
                        "self": false,
                        "optional": "true",
                        "responseStatus": "accepted"
                    }
                ],
                "location": "NC",
                "extendedProperties": [],
                "createdAt": "2020-09-18T14:33:30.410Z",
                "updatedAt": "2020-09-18T14:33:30.410Z",
                "__v": 0
            },
            {
                "_id": "5f64cac94caf2900127f5330",
                "recurrence": [],
                "start": "2020-09-19T00:00:00.000Z",
                "end": "2020-09-19T00:00:00.000Z",
                "creator": {
                    "_id": "5f64cac94caf2900127f5331",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "fixed",
                "note": "Test",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "@type": "Cmd",
                        "id": "5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "ref": "1.364357",
                        "writeAt": "2020-09-18T00:00:00+02:00",
                        "orderAt": "2020-09-18T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "C19104A",
                        "amountHt": "326.20",
                        "amountBuyHt": "250.46",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/28640fdc-621a-4b3a-8017-dc2539711a45",
                            "@type": "Costumer",
                            "id": "28640fdc-621a-4b3a-8017-dc2539711a45",
                            "socialReason": "A3BAT",
                            "ref": "0023701",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A3BAT"
                        },
                        "billSocialReason": "A3BAT",
                        "billAdress1": "5 Rue Gustave Eiffel",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "77610",
                        "billCity": "FONTENAY TRESIGNY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A3BAT",
                        "shipAdress1": "5 Rue Gustave Eiffel",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77610",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "711",
                        "createdAt": "2020-09-18T09:57:21+02:00",
                        "updatedAt": "2020-09-18T09:57:21+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/2",
                            "@type": "InternalUser",
                            "id": 2,
                            "code": "110",
                            "email": "mehdi.boukaoula@csc-meaux.com",
                            "firstname": "Mehdi",
                            "lastname": "Boukaoula",
                            "name": "Mehdi Boukaoula"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "75.74",
                        "ecoTaxe": "0.00",
                        "marginRate": "23.22",
                        "marginCoef": "0.3",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f64cac94caf2900127f5332",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T14:57:13.180Z",
                "updatedAt": "2020-09-21T09:38:06.914Z",
                "__v": 0
            },
            {
                "_id": "5f64cae14caf2900127f5333",
                "recurrence": [],
                "start": "2020-09-19T00:00:00.000Z",
                "end": "2020-09-19T00:00:00.000Z",
                "creator": {
                    "_id": "5f64cae14caf2900127f5334",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "fixed",
                "note": "Autre test",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "@type": "Cmd",
                        "id": "5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "ref": "1.364357",
                        "writeAt": "2020-09-18T00:00:00+02:00",
                        "orderAt": "2020-09-18T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "C19104A",
                        "amountHt": "326.20",
                        "amountBuyHt": "250.46",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/28640fdc-621a-4b3a-8017-dc2539711a45",
                            "@type": "Costumer",
                            "id": "28640fdc-621a-4b3a-8017-dc2539711a45",
                            "socialReason": "A3BAT",
                            "ref": "0023701",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A3BAT"
                        },
                        "billSocialReason": "A3BAT",
                        "billAdress1": "5 Rue Gustave Eiffel",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "77610",
                        "billCity": "FONTENAY TRESIGNY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A3BAT",
                        "shipAdress1": "5 Rue Gustave Eiffel",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77610",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "711",
                        "createdAt": "2020-09-18T09:57:21+02:00",
                        "updatedAt": "2020-09-18T09:57:21+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/2",
                            "@type": "InternalUser",
                            "id": 2,
                            "code": "110",
                            "email": "mehdi.boukaoula@csc-meaux.com",
                            "firstname": "Mehdi",
                            "lastname": "Boukaoula",
                            "name": "Mehdi Boukaoula"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "75.74",
                        "ecoTaxe": "0.00",
                        "marginRate": "23.22",
                        "marginCoef": "0.3",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f64cae14caf2900127f5335",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T14:57:37.671Z",
                "updatedAt": "2020-09-21T09:38:03.026Z",
                "__v": 0
            },
            {
                "_id": "5f64cb0a4caf2900127f5336",
                "recurrence": [],
                "start": "2020-09-19T00:00:00.000Z",
                "end": "2020-09-19T00:00:00.000Z",
                "creator": {
                    "_id": "5f64cb0a4caf2900127f5337",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "fixed",
                "note": "Troisieme test",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "@type": "Cmd",
                        "id": "5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "ref": "1.364357",
                        "writeAt": "2020-09-18T00:00:00+02:00",
                        "orderAt": "2020-09-18T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "C19104A",
                        "amountHt": "326.20",
                        "amountBuyHt": "250.46",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/28640fdc-621a-4b3a-8017-dc2539711a45",
                            "@type": "Costumer",
                            "id": "28640fdc-621a-4b3a-8017-dc2539711a45",
                            "socialReason": "A3BAT",
                            "ref": "0023701",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A3BAT"
                        },
                        "billSocialReason": "A3BAT",
                        "billAdress1": "5 Rue Gustave Eiffel",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "77610",
                        "billCity": "FONTENAY TRESIGNY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A3BAT",
                        "shipAdress1": "5 Rue Gustave Eiffel",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77610",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "711",
                        "createdAt": "2020-09-18T09:57:21+02:00",
                        "updatedAt": "2020-09-18T09:57:21+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/2",
                            "@type": "InternalUser",
                            "id": 2,
                            "code": "110",
                            "email": "mehdi.boukaoula@csc-meaux.com",
                            "firstname": "Mehdi",
                            "lastname": "Boukaoula",
                            "name": "Mehdi Boukaoula"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "75.74",
                        "ecoTaxe": "0.00",
                        "marginRate": "23.22",
                        "marginCoef": "0.3",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f64cb0a4caf2900127f5338",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T14:58:18.536Z",
                "updatedAt": "2020-09-21T09:38:01.166Z",
                "__v": 0
            },
            {
                "_id": "5f64cb194caf2900127f5339",
                "recurrence": [],
                "start": "2020-09-20T00:00:00.000Z",
                "end": "2020-09-20T00:00:00.000Z",
                "creator": {
                    "_id": "5f64cb194caf2900127f533a",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "fixed",
                "note": "ICI",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/4b94f709-ebf9-4284-ba0e-6bef0ed7e2f4",
                        "@type": "Cmd",
                        "id": "4b94f709-ebf9-4284-ba0e-6bef0ed7e2f4",
                        "ref": "1.364115",
                        "writeAt": "2020-09-16T00:00:00+02:00",
                        "orderAt": "2020-09-16T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "POMPE WILO",
                        "amountHt": "2423.38",
                        "amountBuyHt": "2118.53",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/fe2b2af2-eae3-48f0-ad83-195f2995b76b",
                            "@type": "Costumer",
                            "id": "fe2b2af2-eae3-48f0-ad83-195f2995b76b",
                            "socialReason": "ACQUA FRANCE PLOMBERIE",
                            "ref": "0023400",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "ACQUA FRANCE PLOMBERIE"
                        },
                        "billSocialReason": "ACQUA FRANCE PLOMBERIE",
                        "billAdress1": "73-79 AVE DU 14 JUILLET",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "93140",
                        "billCity": "BONDY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "ACQUA FRANCE PLOMBERIE",
                        "shipAdress1": "73-79 AVE DU 14 JUILLET",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "93140",
                        "shipCity": "BONDY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "657",
                        "createdAt": "2020-09-17T04:39:14+02:00",
                        "updatedAt": "2020-09-17T12:03:26+02:00",
                        "manager": {
                            "@id": "/api/internal_users/26",
                            "@type": "InternalUser",
                            "id": 26,
                            "code": "SV",
                            "email": null,
                            "firstname": null,
                            "lastname": null,
                            "name": "SV"
                        },
                        "saler": {
                            "@id": "/api/internal_users/5",
                            "@type": "InternalUser",
                            "id": 5,
                            "code": "01",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Sylvain",
                            "lastname": "Vibert",
                            "name": "Sylvain Vibert"
                        },
                        "technician": null,
                        "status": "of",
                        "marginHt": "304.85",
                        "ecoTaxe": "0.00",
                        "marginRate": "12.58",
                        "marginCoef": "0.14",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/1ba46e7a-14fe-464c-8655-0a245d1af037",
                            "@type": "Costumer",
                            "id": "1ba46e7a-14fe-464c-8655-0a245d1af037",
                            "socialReason": null,
                            "ref": "416",
                            "lastname": "Ayhan",
                            "firstname": "Yunus",
                            "phone": "",
                            "mobile": "06.26.55.81.71",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "Yunus Ayhan"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f64cb194caf2900127f533b",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T14:58:33.798Z",
                "updatedAt": "2020-09-21T09:37:53.260Z",
                "__v": 0
            },
            {
                "_id": "5f64d19e4caf2900127f5341",
                "recurrence": [],
                "start": "2020-09-30T00:00:00.000Z",
                "end": "2020-09-30T00:00:00.000Z",
                "creator": {
                    "_id": "5f64d19e4caf2900127f5342",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "Pourquoi pas",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/ac530436-24e7-4e9a-9067-4f44dcf7dbb5",
                        "@type": "Cmd",
                        "id": "ac530436-24e7-4e9a-9067-4f44dcf7dbb5",
                        "ref": "1.362972",
                        "writeAt": "2020-09-04T00:00:00+02:00",
                        "orderAt": "2020-09-04T00:00:00+02:00",
                        "type": "cmd",
                        "isOrder": true,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-09-07T00:00:00+02:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "STOCK",
                        "refBusiness": "STOCK",
                        "amountHt": "426.02",
                        "amountBuyHt": "329.00",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "@type": "Costumer",
                            "id": "7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "socialReason": "DUMONT CLIMATISATION SERVICES",
                            "ref": "0021886",
                            "lastname": null,
                            "firstname": null,
                            "phone": "01.60.74.88.80",
                            "mobile": "",
                            "shipping": false,
                            "adressRow1": "27 RUE DE LA GARE",
                            "adressRow2": null,
                            "adressRow3": "",
                            "adressRow4": "",
                            "zipCode": "77126",
                            "city": "CHATENAY SUR SEINE",
                            "name": "DUMONT CLIMATISATION SERVICES"
                        },
                        "billSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "billAdress1": "27 RUE DE LA GARE",
                        "billAdress2": "AC 09-00153 / 10-2019",
                        "billAdress3": "",
                        "billZipCode": "77126",
                        "billCity": "CHATENAY SUR SEINE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "shipAdress1": "6 RUE DE FREGY",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "482",
                        "createdAt": "2020-09-07T13:36:22+02:00",
                        "updatedAt": "2020-09-14T06:26:53+02:00",
                        "manager": {
                            "@id": "/api/internal_users/38",
                            "@type": "InternalUser",
                            "id": 38,
                            "code": "JL",
                            "email": "jerome.lemonnier@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Lemonnier",
                            "name": "Jerôme Lemonnier"
                        },
                        "saler": {
                            "@id": "/api/internal_users/9",
                            "@type": "InternalUser",
                            "id": 9,
                            "code": "03",
                            "email": "maximerethore@gmail.com",
                            "firstname": "RÉTHORETEST",
                            "lastname": "MAXIMETEST",
                            "name": "RÉTHORETEST MAXIMETEST"
                        },
                        "technician": null,
                        "status": "lvr",
                        "marginHt": "97.02",
                        "ecoTaxe": "0.00",
                        "marginRate": "22.77",
                        "marginCoef": "0.29",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Commande",
                        "statusLabel": "Livraison"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f64d19e4caf2900127f5343",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T15:26:22.964Z",
                "updatedAt": "2020-09-27T09:01:12.695Z",
                "__v": 0
            },
            {
                "_id": "5f64d2ac4caf2900127f5348",
                "recurrence": [],
                "start": "2020-09-18T17:30:44.000Z",
                "end": "2020-09-18T17:30:44.000Z",
                "creator": {
                    "_id": "5f64d2ac4caf2900127f5349",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "organizer": {
                    "_id": "5f64d2ac4caf2900127f534a",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "OPEN",
                "type": "relance",
                "note": "Une relance",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "@type": "Cmd",
                        "id": "5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "ref": "1.364357",
                        "writeAt": "2020-09-18T00:00:00+02:00",
                        "orderAt": "2020-09-18T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "C19104A",
                        "amountHt": "326.20",
                        "amountBuyHt": "250.46",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/28640fdc-621a-4b3a-8017-dc2539711a45",
                            "@type": "Costumer",
                            "id": "28640fdc-621a-4b3a-8017-dc2539711a45",
                            "socialReason": "A3BAT",
                            "ref": "0023701",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A3BAT"
                        },
                        "billSocialReason": "A3BAT",
                        "billAdress1": "5 Rue Gustave Eiffel",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "77610",
                        "billCity": "FONTENAY TRESIGNY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A3BAT",
                        "shipAdress1": "5 Rue Gustave Eiffel",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77610",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "711",
                        "createdAt": "2020-09-18T09:57:21+02:00",
                        "updatedAt": "2020-09-18T09:57:21+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/2",
                            "@type": "InternalUser",
                            "id": 2,
                            "code": "110",
                            "email": "mehdi.boukaoula@csc-meaux.com",
                            "firstname": "Mehdi",
                            "lastname": "Boukaoula",
                            "name": "Mehdi Boukaoula"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "75.74",
                        "ecoTaxe": "0.00",
                        "marginRate": "23.22",
                        "marginCoef": "0.3",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "saler": {
                        "@id": "/api/internal_users/2",
                        "@type": "InternalUser",
                        "id": "2",
                        "code": "110",
                        "email": "mehdi.boukaoula@csc-meaux.com",
                        "firstname": "Mehdi",
                        "lastname": "Boukaoula",
                        "name": "Mehdi Boukaoula"
                    },
                    "answers": [
                        {
                            "creator": {
                                "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                                "email": "maxime@whatson-web.com",
                                "displayName": "Maxime Réthoré"
                            },
                            "comment": "Test effacement",
                            "date": "2020-09-18T15:40:48.814Z"
                        }
                    ]
                },
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T15:30:52.211Z",
                "updatedAt": "2020-09-18T15:40:48.927Z",
                "__v": 0
            },
            {
                "_id": "5f64d2da4caf2900127f534b",
                "recurrence": [],
                "start": "2020-09-18T17:31:28.000Z",
                "end": "2020-09-18T17:31:28.000Z",
                "creator": {
                    "_id": "5f64d2da4caf2900127f534c",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "organizer": {
                    "_id": "5f64d2da4caf2900127f534d",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "OPEN",
                "type": "relance",
                "note": "Une relance Autre",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "@type": "Cmd",
                        "id": "5e71f5fc-211a-4519-9fe5-2ba23b72da60",
                        "ref": "1.364357",
                        "writeAt": "2020-09-18T00:00:00+02:00",
                        "orderAt": "2020-09-18T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "C19104A",
                        "amountHt": "326.20",
                        "amountBuyHt": "250.46",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/28640fdc-621a-4b3a-8017-dc2539711a45",
                            "@type": "Costumer",
                            "id": "28640fdc-621a-4b3a-8017-dc2539711a45",
                            "socialReason": "A3BAT",
                            "ref": "0023701",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A3BAT"
                        },
                        "billSocialReason": "A3BAT",
                        "billAdress1": "5 Rue Gustave Eiffel",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "77610",
                        "billCity": "FONTENAY TRESIGNY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A3BAT",
                        "shipAdress1": "5 Rue Gustave Eiffel",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77610",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "711",
                        "createdAt": "2020-09-18T09:57:21+02:00",
                        "updatedAt": "2020-09-18T09:57:21+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/2",
                            "@type": "InternalUser",
                            "id": 2,
                            "code": "110",
                            "email": "mehdi.boukaoula@csc-meaux.com",
                            "firstname": "Mehdi",
                            "lastname": "Boukaoula",
                            "name": "Mehdi Boukaoula"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "75.74",
                        "ecoTaxe": "0.00",
                        "marginRate": "23.22",
                        "marginCoef": "0.3",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "saler": {
                        "@id": "/api/internal_users/2",
                        "@type": "InternalUser",
                        "id": "2",
                        "code": "110",
                        "email": "mehdi.boukaoula@csc-meaux.com",
                        "firstname": "Mehdi",
                        "lastname": "Boukaoula",
                        "name": "Mehdi Boukaoula"
                    }
                },
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-18T15:31:38.219Z",
                "updatedAt": "2020-09-18T15:31:38.219Z",
                "__v": 0
            },
            {
                "_id": "5f685f6e4caf2900127f5352",
                "recurrence": [],
                "start": "2020-09-23T00:00:00.000Z",
                "end": "2020-09-23T00:00:00.000Z",
                "creator": {
                    "_id": "5f685f6e4caf2900127f5353",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "Test de note 1",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/dda1c8a7-33b0-469f-9b8c-e0f7ab0c6986",
                        "@type": "Cmd",
                        "id": "dda1c8a7-33b0-469f-9b8c-e0f7ab0c6986",
                        "ref": "1.348317",
                        "writeAt": "2020-02-20T00:00:00+01:00",
                        "orderAt": "2020-02-27T00:00:00+01:00",
                        "type": "cmd",
                        "isOrder": true,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-05-05T00:00:00+02:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "ST PERES",
                        "amountHt": "7130.68",
                        "amountBuyHt": "5907.50",
                        "amountToDelivery": "7130.68",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/5fd1cd11-756f-435b-89fc-d734754dd163",
                            "@type": "Costumer",
                            "id": "5fd1cd11-756f-435b-89fc-d734754dd163",
                            "socialReason": "ATB",
                            "ref": "0001868",
                            "lastname": null,
                            "firstname": null,
                            "phone": "01.64.04.73.63",
                            "mobile": "",
                            "shipping": false,
                            "adressRow1": "1 CHEMIN DES BROSSES",
                            "adressRow2": null,
                            "adressRow3": "",
                            "adressRow4": "",
                            "zipCode": "77515",
                            "city": "LA CELLE SUR MORIN",
                            "name": "ATB"
                        },
                        "billSocialReason": "ATB",
                        "billAdress1": "1 CHEMIN DES BROSSES",
                        "billAdress2": "AC SQ014896-001 / 03-2028",
                        "billAdress3": "",
                        "billZipCode": "77515",
                        "billCity": "LA CELLE SUR MORIN",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "ATB",
                        "shipAdress1": "1 CHEMIN DES BROSSES",
                        "shipAdress2": "AC SQ014896-001 / 03-2028",
                        "shipAdress3": "",
                        "shipZipCode": "77515",
                        "shipCity": "LA CELLE SUR MORIN",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "744",
                        "createdAt": "2020-06-29T15:07:55+02:00",
                        "updatedAt": "2020-09-21T09:57:28+02:00",
                        "manager": {
                            "@id": "/api/internal_users/17",
                            "@type": "InternalUser",
                            "id": 17,
                            "code": "JS",
                            "email": "joffrey.sellier@csc-meaux.com",
                            "firstname": "Joffrey",
                            "lastname": "Sellier",
                            "name": "Joffrey Sellier"
                        },
                        "saler": {
                            "@id": "/api/internal_users/5",
                            "@type": "InternalUser",
                            "id": 5,
                            "code": "01",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Sylvain",
                            "lastname": "Vibert",
                            "name": "Sylvain Vibert"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "lvr",
                        "marginHt": "1223.18",
                        "ecoTaxe": "1.67",
                        "marginRate": "17.15",
                        "marginCoef": "0.21",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": true,
                        "contact": {
                            "@id": "/api/costumers/7a0d44b5-9b34-488d-85f7-611cb7d1c96d",
                            "@type": "Costumer",
                            "id": "7a0d44b5-9b34-488d-85f7-611cb7d1c96d",
                            "socialReason": null,
                            "ref": "2063",
                            "lastname": "bouige",
                            "firstname": "olivier",
                            "phone": "",
                            "mobile": "06.11.28.88.87",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "olivier bouige"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Commande",
                        "statusLabel": "Livraison"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f685f6e4caf2900127f5354",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-21T08:08:14.419Z",
                "updatedAt": "2020-09-21T09:37:12.521Z",
                "__v": 0
            },
            {
                "_id": "5f685f7f4caf2900127f5355",
                "recurrence": [],
                "start": "2020-09-24T00:00:00.000Z",
                "end": "2020-09-24T00:00:00.000Z",
                "creator": {
                    "_id": "5f685f7f4caf2900127f5356",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "Test de note 2",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/dda1c8a7-33b0-469f-9b8c-e0f7ab0c6986",
                        "@type": "Cmd",
                        "id": "dda1c8a7-33b0-469f-9b8c-e0f7ab0c6986",
                        "ref": "1.348317",
                        "writeAt": "2020-02-20T00:00:00+01:00",
                        "orderAt": "2020-02-27T00:00:00+01:00",
                        "type": "cmd",
                        "isOrder": true,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-05-05T00:00:00+02:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "ST PERES",
                        "amountHt": "7130.68",
                        "amountBuyHt": "5907.50",
                        "amountToDelivery": "7130.68",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/5fd1cd11-756f-435b-89fc-d734754dd163",
                            "@type": "Costumer",
                            "id": "5fd1cd11-756f-435b-89fc-d734754dd163",
                            "socialReason": "ATB",
                            "ref": "0001868",
                            "lastname": null,
                            "firstname": null,
                            "phone": "01.64.04.73.63",
                            "mobile": "",
                            "shipping": false,
                            "adressRow1": "1 CHEMIN DES BROSSES",
                            "adressRow2": null,
                            "adressRow3": "",
                            "adressRow4": "",
                            "zipCode": "77515",
                            "city": "LA CELLE SUR MORIN",
                            "name": "ATB"
                        },
                        "billSocialReason": "ATB",
                        "billAdress1": "1 CHEMIN DES BROSSES",
                        "billAdress2": "AC SQ014896-001 / 03-2028",
                        "billAdress3": "",
                        "billZipCode": "77515",
                        "billCity": "LA CELLE SUR MORIN",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "ATB",
                        "shipAdress1": "1 CHEMIN DES BROSSES",
                        "shipAdress2": "AC SQ014896-001 / 03-2028",
                        "shipAdress3": "",
                        "shipZipCode": "77515",
                        "shipCity": "LA CELLE SUR MORIN",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "744",
                        "createdAt": "2020-06-29T15:07:55+02:00",
                        "updatedAt": "2020-09-21T09:57:28+02:00",
                        "manager": {
                            "@id": "/api/internal_users/17",
                            "@type": "InternalUser",
                            "id": 17,
                            "code": "JS",
                            "email": "joffrey.sellier@csc-meaux.com",
                            "firstname": "Joffrey",
                            "lastname": "Sellier",
                            "name": "Joffrey Sellier"
                        },
                        "saler": {
                            "@id": "/api/internal_users/5",
                            "@type": "InternalUser",
                            "id": 5,
                            "code": "01",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Sylvain",
                            "lastname": "Vibert",
                            "name": "Sylvain Vibert"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "lvr",
                        "marginHt": "1223.18",
                        "ecoTaxe": "1.67",
                        "marginRate": "17.15",
                        "marginCoef": "0.21",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": true,
                        "contact": {
                            "@id": "/api/costumers/7a0d44b5-9b34-488d-85f7-611cb7d1c96d",
                            "@type": "Costumer",
                            "id": "7a0d44b5-9b34-488d-85f7-611cb7d1c96d",
                            "socialReason": null,
                            "ref": "2063",
                            "lastname": "bouige",
                            "firstname": "olivier",
                            "phone": "",
                            "mobile": "06.11.28.88.87",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "olivier bouige"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Commande",
                        "statusLabel": "Livraison"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f685f7f4caf2900127f5357",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-21T08:08:31.277Z",
                "updatedAt": "2020-09-21T09:37:10.346Z",
                "__v": 0
            },
            {
                "_id": "5f685fab4caf2900127f5358",
                "recurrence": [],
                "start": "2020-09-21T10:08:48.000Z",
                "end": "2020-09-21T10:08:48.000Z",
                "creator": {
                    "_id": "5f685fab4caf2900127f5359",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "organizer": {
                    "_id": "5f685fab4caf2900127f535a",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "OPEN",
                "type": "relance",
                "note": "Merci de relancer ce client vendredi sans faute",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/dda1c8a7-33b0-469f-9b8c-e0f7ab0c6986",
                        "@type": "Cmd",
                        "id": "dda1c8a7-33b0-469f-9b8c-e0f7ab0c6986",
                        "ref": "1.348317",
                        "writeAt": "2020-02-20T00:00:00+01:00",
                        "orderAt": "2020-02-27T00:00:00+01:00",
                        "type": "cmd",
                        "isOrder": true,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-05-05T00:00:00+02:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "ST PERES",
                        "amountHt": "7130.68",
                        "amountBuyHt": "5907.50",
                        "amountToDelivery": "7130.68",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/5fd1cd11-756f-435b-89fc-d734754dd163",
                            "@type": "Costumer",
                            "id": "5fd1cd11-756f-435b-89fc-d734754dd163",
                            "socialReason": "ATB",
                            "ref": "0001868",
                            "lastname": null,
                            "firstname": null,
                            "phone": "01.64.04.73.63",
                            "mobile": "",
                            "shipping": false,
                            "adressRow1": "1 CHEMIN DES BROSSES",
                            "adressRow2": null,
                            "adressRow3": "",
                            "adressRow4": "",
                            "zipCode": "77515",
                            "city": "LA CELLE SUR MORIN",
                            "name": "ATB"
                        },
                        "billSocialReason": "ATB",
                        "billAdress1": "1 CHEMIN DES BROSSES",
                        "billAdress2": "AC SQ014896-001 / 03-2028",
                        "billAdress3": "",
                        "billZipCode": "77515",
                        "billCity": "LA CELLE SUR MORIN",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "ATB",
                        "shipAdress1": "1 CHEMIN DES BROSSES",
                        "shipAdress2": "AC SQ014896-001 / 03-2028",
                        "shipAdress3": "",
                        "shipZipCode": "77515",
                        "shipCity": "LA CELLE SUR MORIN",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "744",
                        "createdAt": "2020-06-29T15:07:55+02:00",
                        "updatedAt": "2020-09-21T09:57:28+02:00",
                        "manager": {
                            "@id": "/api/internal_users/17",
                            "@type": "InternalUser",
                            "id": 17,
                            "code": "JS",
                            "email": "joffrey.sellier@csc-meaux.com",
                            "firstname": "Joffrey",
                            "lastname": "Sellier",
                            "name": "Joffrey Sellier"
                        },
                        "saler": {
                            "@id": "/api/internal_users/5",
                            "@type": "InternalUser",
                            "id": 5,
                            "code": "01",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Sylvain",
                            "lastname": "Vibert",
                            "name": "Sylvain Vibert"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "lvr",
                        "marginHt": "1223.18",
                        "ecoTaxe": "1.67",
                        "marginRate": "17.15",
                        "marginCoef": "0.21",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": true,
                        "contact": {
                            "@id": "/api/costumers/7a0d44b5-9b34-488d-85f7-611cb7d1c96d",
                            "@type": "Costumer",
                            "id": "7a0d44b5-9b34-488d-85f7-611cb7d1c96d",
                            "socialReason": null,
                            "ref": "2063",
                            "lastname": "bouige",
                            "firstname": "olivier",
                            "phone": "",
                            "mobile": "06.11.28.88.87",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "olivier bouige"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Commande",
                        "statusLabel": "Livraison"
                    },
                    "saler": {
                        "@id": "/api/internal_users/8",
                        "@type": "InternalUser",
                        "id": "8",
                        "code": "04",
                        "email": "deruelarnaud@gmail.com",
                        "firstname": "Test1",
                        "lastname": "Test1",
                        "job": {
                            "@id": "/api/human_functions/15",
                            "@type": "HumanFunction",
                            "id": 15,
                            "code": "001",
                            "label": "TEST"
                        },
                        "name": "Test1 Test1"
                    },
                    "answers": [
                        {
                            "creator": {
                                "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                                "email": "arnaud.deruel@csc-meaux.com",
                                "displayName": "Arnaud Deruel"
                            },
                            "comment": "Pourquoi ne pas essayer de le rappeler mardi prochain",
                            "date": "2020-09-21T15:01:58.615Z"
                        }
                    ]
                },
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-21T08:09:15.118Z",
                "updatedAt": "2020-09-21T15:01:58.660Z",
                "__v": 0
            },
            {
                "_id": "5f6874144caf2900127f535f",
                "recurrence": [],
                "start": "2020-09-21T00:00:00.000Z",
                "end": "2020-09-21T00:00:00.000Z",
                "creator": {
                    "_id": "5f6874144caf2900127f5360",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "Voir avec Nico si je peux lui faire le CEBDD50L moins cher",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/8e50e1b5-570e-48de-81f2-cf81ecc71f5f",
                        "@type": "Cmd",
                        "id": "8e50e1b5-570e-48de-81f2-cf81ecc71f5f",
                        "ref": "1.362952",
                        "writeAt": "2020-09-04T00:00:00+02:00",
                        "orderAt": "2020-09-15T00:00:00+02:00",
                        "type": "cmd",
                        "isOrder": true,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-09-17T00:00:00+02:00",
                        "stock": null,
                        "isBlocked": true,
                        "blockedCode": "7",
                        "blockedDetail": "Encours",
                        "isPrepareInProgress": false,
                        "costumerRef": "08026",
                        "refBusiness": "207266 ECOLE MARIANNE",
                        "amountHt": "186.00",
                        "amountBuyHt": "137.88",
                        "amountToDelivery": "186.00",
                        "stockAlert": "green",
                        "costumer": {
                            "@id": "/api/costumers/60eb32eb-ed66-49fe-b7e0-ff494d4d5c3b",
                            "@type": "Costumer",
                            "id": "60eb32eb-ed66-49fe-b7e0-ff494d4d5c3b",
                            "socialReason": "SIAT 2",
                            "ref": "0023687",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "SIAT 2"
                        },
                        "billSocialReason": "SIAT 2",
                        "billAdress1": "20 BIS CITE DU RENDEZ VOUS",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "75012",
                        "billCity": "PARIS",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "SIAT 2",
                        "shipAdress1": "20 BIS CITE DU RENDEZ VOUS",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "75012",
                        "shipCity": "PARIS",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "746",
                        "createdAt": "2020-09-07T09:54:31+02:00",
                        "updatedAt": "2020-09-21T11:21:03+02:00",
                        "manager": {
                            "@id": "/api/internal_users/17",
                            "@type": "InternalUser",
                            "id": 17,
                            "code": "JS",
                            "email": "joffrey.sellier@csc-meaux.com",
                            "firstname": "Joffrey",
                            "lastname": "Sellier",
                            "name": "Joffrey Sellier"
                        },
                        "saler": {
                            "@id": "/api/internal_users/7",
                            "@type": "InternalUser",
                            "id": 7,
                            "code": "14",
                            "email": "jeremy.adeline@csc-meaux.com",
                            "firstname": "Jeremy",
                            "lastname": "Adeline",
                            "name": "Jeremy Adeline"
                        },
                        "technician": {
                            "@id": "/api/internal_users/22",
                            "@type": "InternalUser",
                            "id": 22,
                            "code": "GB",
                            "email": "guillaume.brard@csc-meaux.com",
                            "firstname": "Guillaume",
                            "lastname": "Brard",
                            "name": "Guillaume Brard"
                        },
                        "status": "lvr",
                        "marginHt": "48.12",
                        "ecoTaxe": "5.00",
                        "marginRate": "25.87",
                        "marginCoef": "0.35",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": true,
                        "contact": {
                            "@id": "/api/costumers/66705b19-94a4-4810-82cb-b989bb2987a1",
                            "@type": "Costumer",
                            "id": "66705b19-94a4-4810-82cb-b989bb2987a1",
                            "socialReason": null,
                            "ref": "1183",
                            "lastname": "CERQUEIRA",
                            "firstname": "Victor",
                            "phone": "",
                            "mobile": "",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "Victor CERQUEIRA"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Vert",
                        "typeLabel": "Commande",
                        "statusLabel": "Livraison"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f6874144caf2900127f5361",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-21T09:36:20.800Z",
                "updatedAt": "2020-09-21T09:36:48.945Z",
                "__v": 0
            },
            {
                "_id": "5f69e6ed4caf2900127f536d",
                "recurrence": [],
                "start": "2020-10-02T11:00:00.000Z",
                "end": "2020-10-02T12:00:00.000Z",
                "creator": {
                    "_id": "5f69e6ed4caf2900127f536e",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "subject": {
                    "costumer": {
                        "id": "7a317550-ac07-47fd-93c6-3071d6a8bee6",
                        "displayName": "DUMONT CLIMATISATION SERVICES",
                        "email": null,
                        "phone": null,
                        "saler": null,
                        "city": "NC",
                        "zipCode": "NC",
                        "address": "NC"
                    },
                    "type": "costumer"
                },
                "organizer": {
                    "_id": "5f69e6ed4caf2900127f536e",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "dontcare",
                "type": "appointment",
                "attendees": [
                    {
                        "_id": "5f69ef424caf2900127f5374",
                        "id": "a6e20ae7-5629-42f9-9e10-dd5c9b3026ad",
                        "email": "dcs@sfr.fr",
                        "displayName": "CATHY DUMONT",
                        "organizer": false,
                        "self": false,
                        "optional": "true",
                        "responseStatus": "accepted"
                    },
                    {
                        "_id": "5f69e6ed4caf2900127f5371",
                        "organizer": false,
                        "self": false,
                        "optional": "true",
                        "responseStatus": "accepted",
                        "id": "a6e20ae7-5629-42f9-9e10-dd5c9b3026ad",
                        "email": "dcs@sfr.fr",
                        "displayName": "CATHY DUMONT"
                    }
                ],
                "location": "NC",
                "extendedProperties": [],
                "createdAt": "2020-09-22T11:58:37.542Z",
                "updatedAt": "2020-09-22T12:34:10.390Z",
                "__v": 0
            },
            {
                "_id": "5f6d9c47d5ffe700117452dc",
                "recurrence": [],
                "start": "2020-10-01T00:00:00.000Z",
                "end": "2020-10-01T00:00:00.000Z",
                "creator": {
                    "_id": "5f6d9c47d5ffe700117452dd",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "confirmed",
                "note": "Chercher un autre fournisseur ASAP",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/4b94f709-ebf9-4284-ba0e-6bef0ed7e2f4",
                        "@type": "Cmd",
                        "id": "4b94f709-ebf9-4284-ba0e-6bef0ed7e2f4",
                        "ref": "1.364115",
                        "writeAt": "2020-09-16T00:00:00+02:00",
                        "orderAt": "2020-09-16T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "POMPE WILO",
                        "amountHt": "2423.38",
                        "amountBuyHt": "2118.53",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/fe2b2af2-eae3-48f0-ad83-195f2995b76b",
                            "@type": "Costumer",
                            "id": "fe2b2af2-eae3-48f0-ad83-195f2995b76b",
                            "socialReason": "ACQUA FRANCE PLOMBERIE",
                            "ref": "0023400",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "ACQUA FRANCE PLOMBERIE"
                        },
                        "billSocialReason": "ACQUA FRANCE PLOMBERIE",
                        "billAdress1": "73-79 AVE DU 14 JUILLET",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "93140",
                        "billCity": "BONDY",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "ACQUA FRANCE PLOMBERIE",
                        "shipAdress1": "73-79 AVE DU 14 JUILLET",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "93140",
                        "shipCity": "BONDY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "657",
                        "createdAt": "2020-09-17T04:39:14+02:00",
                        "updatedAt": "2020-09-17T12:03:26+02:00",
                        "manager": {
                            "@id": "/api/internal_users/26",
                            "@type": "InternalUser",
                            "id": 26,
                            "code": "SV",
                            "email": null,
                            "firstname": null,
                            "lastname": null,
                            "name": "SV"
                        },
                        "saler": {
                            "@id": "/api/internal_users/5",
                            "@type": "InternalUser",
                            "id": 5,
                            "code": "01",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Sylvain",
                            "lastname": "Vibert",
                            "name": "Sylvain Vibert"
                        },
                        "technician": null,
                        "status": "of",
                        "marginHt": "304.85",
                        "ecoTaxe": "0.00",
                        "marginRate": "12.58",
                        "marginCoef": "0.14",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/1ba46e7a-14fe-464c-8655-0a245d1af037",
                            "@type": "Costumer",
                            "id": "1ba46e7a-14fe-464c-8655-0a245d1af037",
                            "socialReason": null,
                            "ref": "416",
                            "lastname": "Ayhan",
                            "firstname": "Yunus",
                            "phone": "",
                            "mobile": "06.26.55.81.71",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "Yunus Ayhan"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f6d9c47d5ffe700117452de",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-25T07:29:11.985Z",
                "updatedAt": "2020-09-25T07:29:11.985Z",
                "__v": 0
            },
            {
                "_id": "5f6daa23d5ffe700117452df",
                "recurrence": [],
                "start": "2020-09-25T00:00:00.000Z",
                "end": "2020-09-25T00:00:00.000Z",
                "creator": {
                    "_id": "5f6daa23d5ffe700117452e0",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "fixed",
                "note": "tets",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/4acd3d1e-1ca3-499e-8651-9954ef005bd0",
                        "@type": "Cmd",
                        "id": "4acd3d1e-1ca3-499e-8651-9954ef005bd0",
                        "ref": "1.358594",
                        "writeAt": "2020-07-07T00:00:00+02:00",
                        "orderAt": "2020-07-07T00:00:00+02:00",
                        "type": "cmd",
                        "isOrder": true,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-07-08T00:00:00+02:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "AUXB07GALH",
                        "refBusiness": "",
                        "amountHt": "57.50",
                        "amountBuyHt": "44.21",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "@type": "Costumer",
                            "id": "7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "socialReason": "DUMONT CLIMATISATION SERVICES",
                            "ref": "0021886",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "DUMONT CLIMATISATION SERVICES"
                        },
                        "billSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "billAdress1": "27 RUE DE LA GARE",
                        "billAdress2": "AC 09-00153 / 10-2019",
                        "billAdress3": "",
                        "billZipCode": "77126",
                        "billCity": "CHATENAY SUR SEINE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "shipAdress1": "33 rue eiffel",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77",
                        "shipCity": "GRETZ",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "482",
                        "createdAt": "2020-07-07T16:03:59+02:00",
                        "updatedAt": "2020-09-14T06:29:09+02:00",
                        "manager": {
                            "@id": "/api/internal_users/39",
                            "@type": "InternalUser",
                            "id": 39,
                            "code": "JLP",
                            "email": null,
                            "firstname": null,
                            "lastname": null,
                            "name": "JLP"
                        },
                        "saler": {
                            "@id": "/api/internal_users/9",
                            "@type": "InternalUser",
                            "id": 9,
                            "code": "03",
                            "email": "maximerethore@gmail.com",
                            "firstname": "RÉTHORETEST",
                            "lastname": "MAXIMETEST",
                            "name": "RÉTHORETEST MAXIMETEST"
                        },
                        "technician": {
                            "@id": "/api/internal_users/39",
                            "@type": "InternalUser",
                            "id": 39,
                            "code": "JLP",
                            "email": null,
                            "firstname": null,
                            "lastname": null,
                            "name": "JLP"
                        },
                        "status": "lvr",
                        "marginHt": "13.29",
                        "ecoTaxe": "0.00",
                        "marginRate": "23.11",
                        "marginCoef": "0.3",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": true,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Commande",
                        "statusLabel": "Livraison"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f6daa23d5ffe700117452e1",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-25T08:28:19.796Z",
                "updatedAt": "2020-09-27T09:01:08.854Z",
                "__v": 0
            },
            {
                "_id": "5f6db9b5d5ffe700117452e2",
                "recurrence": [],
                "start": "2020-09-25T10:20:00.000Z",
                "end": "2020-09-25T11:20:00.000Z",
                "creator": {
                    "_id": "5f6db9b5d5ffe700117452e3",
                    "id": "a3ae917c-6bbe-495d-a46c-59096e38d017",
                    "email": "tom.hautier@gmail.com",
                    "displayName": "Bob Bobby"
                },
                "subject": {
                    "costumer": {
                        "id": "d4d02e32-6383-4e22-8cf3-fd9eef198b0b",
                        "displayName": "IDEX GENEVILLIERS",
                        "email": null,
                        "phone": null,
                        "saler": null,
                        "city": "NC",
                        "zipCode": "NC",
                        "address": "NC"
                    },
                    "type": "costumer"
                },
                "organizer": {
                    "_id": "5f6db9b5d5ffe700117452e4",
                    "id": "a3ae917c-6bbe-495d-a46c-59096e38d017",
                    "email": "tom.hautier@gmail.com",
                    "displayName": "Bob Bobby"
                },
                "status": "dontcare",
                "type": "appointment",
                "attendees": [
                    {
                        "_id": "5f6db9b5d5ffe700117452e5",
                        "id": "20de0f32-0e9f-4b73-8e24-6a8f5f6a1db1",
                        "email": "benoit.retif@idex.fr",
                        "displayName": "benoit RETIF",
                        "organizer": false,
                        "self": false,
                        "optional": "true",
                        "responseStatus": "accepted"
                    }
                ],
                "location": "NC",
                "extendedProperties": [],
                "createdAt": "2020-09-25T09:34:45.388Z",
                "updatedAt": "2020-09-25T09:34:45.388Z",
                "__v": 0
            },
            {
                "_id": "5f6dbeb9d5ffe700117452e6",
                "recurrence": [],
                "start": "2020-09-26T10:20:00.000Z",
                "end": "2020-09-26T11:20:00.000Z",
                "creator": {
                    "_id": "5f6dbeb9d5ffe700117452e7",
                    "id": "a3ae917c-6bbe-495d-a46c-59096e38d017",
                    "email": "tom.hautier@gmail.com",
                    "displayName": "Bob Bobby"
                },
                "subject": {
                    "costumer": {
                        "id": "47566f87-0b1f-45be-9b81-fc76ad00dedd",
                        "displayName": "LA PLURIELLE DU BATIMENT",
                        "email": null,
                        "phone": null,
                        "saler": null,
                        "city": "NC",
                        "zipCode": "NC",
                        "address": "NC"
                    },
                    "type": "costumer"
                },
                "organizer": {
                    "_id": "5f6dbeb9d5ffe700117452e8",
                    "id": "a3ae917c-6bbe-495d-a46c-59096e38d017",
                    "email": "tom.hautier@gmail.com",
                    "displayName": "Bob Bobby"
                },
                "status": "dontcare",
                "type": "appointment",
                "attendees": [
                    {
                        "_id": "5f6dbeb9d5ffe700117452e9",
                        "id": "5eb01f59-2ce6-4e3e-933f-8b0bfacf62f1",
                        "email": "laplurielle@gmail.com",
                        "displayName": " ANNONI",
                        "organizer": false,
                        "self": false,
                        "optional": "true",
                        "responseStatus": "accepted"
                    }
                ],
                "location": "NC",
                "extendedProperties": [],
                "createdAt": "2020-09-25T09:56:09.361Z",
                "updatedAt": "2020-09-25T09:56:09.361Z",
                "__v": 0
            },
            {
                "_id": "5f6dd0cbd5ffe700117452ea",
                "recurrence": [],
                "start": "2020-10-01T00:00:00.000Z",
                "end": "2020-10-01T00:00:00.000Z",
                "creator": {
                    "_id": "5f6dd0cbd5ffe700117452eb",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "test de note 1",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/59c15826-e9f4-4b69-9239-48b9a7431c2b",
                        "@type": "Cmd",
                        "id": "59c15826-e9f4-4b69-9239-48b9a7431c2b",
                        "ref": "1.365072",
                        "writeAt": "2020-09-25T00:00:00+02:00",
                        "orderAt": "2020-09-25T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "STADE OUEST",
                        "amountHt": "22.17",
                        "amountBuyHt": "14.35",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/080b7065-39a4-400d-b2c4-672990a2214a",
                            "@type": "Costumer",
                            "id": "080b7065-39a4-400d-b2c4-672990a2214a",
                            "socialReason": "A2C",
                            "ref": "0021950",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A2C"
                        },
                        "billSocialReason": "A2C",
                        "billAdress1": "6 RUE DES PLEUX",
                        "billAdress2": "AC 13721 / 05-2019",
                        "billAdress3": "",
                        "billZipCode": "77640",
                        "billCity": "JOUARRE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A2C",
                        "shipAdress1": "6 RUE DES PLEUX",
                        "shipAdress2": "AC 13721 / 05-2019",
                        "shipAdress3": "",
                        "shipZipCode": "77640",
                        "shipCity": "JOUARRE",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "893",
                        "createdAt": "2020-09-25T12:38:37+02:00",
                        "updatedAt": "2020-09-25T12:38:37+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/5",
                            "@type": "InternalUser",
                            "id": 5,
                            "code": "01",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Sylvain",
                            "lastname": "Vibert",
                            "name": "Sylvain Vibert"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "7.82",
                        "ecoTaxe": "0.00",
                        "marginRate": "35.27",
                        "marginCoef": "0.54",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/5cffad6b-732f-450d-86cd-11d486521a61",
                            "@type": "Costumer",
                            "id": "5cffad6b-732f-450d-86cd-11d486521a61",
                            "socialReason": null,
                            "ref": "2132",
                            "lastname": "BAYARD",
                            "firstname": "",
                            "phone": "",
                            "mobile": "06.03.03.30.87",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " BAYARD"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f6dd0cbd5ffe700117452ec",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-25T11:13:15.463Z",
                "updatedAt": "2020-09-27T09:01:01.370Z",
                "__v": 0
            },
            {
                "_id": "5f6dd0ddd5ffe700117452ed",
                "recurrence": [],
                "start": "2020-10-02T00:00:00.000Z",
                "end": "2020-10-02T00:00:00.000Z",
                "creator": {
                    "_id": "5f6dd0ddd5ffe700117452ee",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "status": "fixed",
                "note": "test de note 2",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/59c15826-e9f4-4b69-9239-48b9a7431c2b",
                        "@type": "Cmd",
                        "id": "59c15826-e9f4-4b69-9239-48b9a7431c2b",
                        "ref": "1.365072",
                        "writeAt": "2020-09-25T00:00:00+02:00",
                        "orderAt": "2020-09-25T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "STADE OUEST",
                        "amountHt": "22.17",
                        "amountBuyHt": "14.35",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/080b7065-39a4-400d-b2c4-672990a2214a",
                            "@type": "Costumer",
                            "id": "080b7065-39a4-400d-b2c4-672990a2214a",
                            "socialReason": "A2C",
                            "ref": "0021950",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "A2C"
                        },
                        "billSocialReason": "A2C",
                        "billAdress1": "6 RUE DES PLEUX",
                        "billAdress2": "AC 13721 / 05-2019",
                        "billAdress3": "",
                        "billZipCode": "77640",
                        "billCity": "JOUARRE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "A2C",
                        "shipAdress1": "6 RUE DES PLEUX",
                        "shipAdress2": "AC 13721 / 05-2019",
                        "shipAdress3": "",
                        "shipZipCode": "77640",
                        "shipCity": "JOUARRE",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "893",
                        "createdAt": "2020-09-25T12:38:37+02:00",
                        "updatedAt": "2020-09-25T12:38:37+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/5",
                            "@type": "InternalUser",
                            "id": 5,
                            "code": "01",
                            "email": "yoann.jorge@csc-meaux.com",
                            "firstname": "Sylvain",
                            "lastname": "Vibert",
                            "name": "Sylvain Vibert"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "7.82",
                        "ecoTaxe": "0.00",
                        "marginRate": "35.27",
                        "marginCoef": "0.54",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/5cffad6b-732f-450d-86cd-11d486521a61",
                            "@type": "Costumer",
                            "id": "5cffad6b-732f-450d-86cd-11d486521a61",
                            "socialReason": null,
                            "ref": "2132",
                            "lastname": "BAYARD",
                            "firstname": "",
                            "phone": "",
                            "mobile": "06.03.03.30.87",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " BAYARD"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f6dd0ddd5ffe700117452ef",
                    "id": "3b7acf59-89ef-4cde-a8f1-a7765b7ddb4e",
                    "email": "arnaud.deruel@csc-meaux.com",
                    "displayName": "Arnaud Deruel"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-25T11:13:33.069Z",
                "updatedAt": "2020-09-27T09:00:59.807Z",
                "__v": 0
            },
            {
                "_id": "5f6e041bd5ffe700117452f8",
                "recurrence": [],
                "start": "2020-09-26T00:00:00.000Z",
                "end": "2020-09-26T00:00:00.000Z",
                "creator": {
                    "_id": "5f6e041bd5ffe700117452f9",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "fixed",
                "note": "test",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/2425007c-178e-4cae-a57a-99a592ca43fd",
                        "@type": "Cmd",
                        "id": "2425007c-178e-4cae-a57a-99a592ca43fd",
                        "ref": "1.363577",
                        "writeAt": "2020-09-10T00:00:00+02:00",
                        "orderAt": "2020-09-10T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "201 LGTS RESIDENCE BUISSON BERGERE",
                        "amountHt": "24086.14",
                        "amountBuyHt": "20996.26",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/70c823a7-243c-4338-af34-3047beda1ed3",
                            "@type": "Costumer",
                            "id": "70c823a7-243c-4338-af34-3047beda1ed3",
                            "socialReason": "ATLANTIC CLIMATISATION",
                            "ref": "0023407",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "ATLANTIC CLIMATISATION"
                        },
                        "billSocialReason": "ATLANTIC CLIMATISATION",
                        "billAdress1": "13 BD MONGE",
                        "billAdress2": "",
                        "billAdress3": "",
                        "billZipCode": "69330",
                        "billCity": "MEYZIEU",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "ATLANTIC CLIMATISATION",
                        "shipAdress1": "13 BD MONGE",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "69330",
                        "shipCity": "MEYZIEU",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "529",
                        "createdAt": "2020-09-14T19:30:27+02:00",
                        "updatedAt": "2020-09-14T22:25:44+02:00",
                        "manager": {
                            "@id": "/api/internal_users/49",
                            "@type": "InternalUser",
                            "id": 49,
                            "code": "SE",
                            "email": null,
                            "firstname": null,
                            "lastname": null,
                            "name": "SE"
                        },
                        "saler": {
                            "@id": "/api/internal_users/28",
                            "@type": "InternalUser",
                            "id": 28,
                            "code": "11",
                            "email": "jeromeb@whatson.io",
                            "firstname": "Jérôme",
                            "lastname": "Brysse",
                            "name": "Jérôme Brysse"
                        },
                        "technician": null,
                        "status": "of",
                        "marginHt": "3089.88",
                        "ecoTaxe": "9.00",
                        "marginRate": "12.83",
                        "marginCoef": "0.15",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/53886a43-f77d-498a-9f91-d2184661a844",
                            "@type": "Costumer",
                            "id": "53886a43-f77d-498a-9f91-d2184661a844",
                            "socialReason": "",
                            "ref": "",
                            "lastname": "",
                            "firstname": "",
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": " "
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f6e041bd5ffe700117452fa",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-25T14:52:11.448Z",
                "updatedAt": "2020-09-27T09:00:56.716Z",
                "__v": 0
            },
            {
                "_id": "5f71a83cd5ffe700117452fd",
                "recurrence": [],
                "start": "2020-09-23T00:00:00.000Z",
                "end": "2020-09-23T00:00:00.000Z",
                "creator": {
                    "_id": "5f71a83cd5ffe700117452fe",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "fixed",
                "note": "Test",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "@type": "Cmd",
                        "id": "c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "ref": "1.363310",
                        "writeAt": "2020-09-08T00:00:00+02:00",
                        "orderAt": "2020-09-08T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "VILLA EDONIA",
                        "amountHt": "13540.44",
                        "amountBuyHt": "12098.82",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "@type": "Costumer",
                            "id": "7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "socialReason": "DUMONT CLIMATISATION SERVICES",
                            "ref": "0021886",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "DUMONT CLIMATISATION SERVICES"
                        },
                        "billSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "billAdress1": "27 RUE DE LA GARE",
                        "billAdress2": "AC 09-00153 / 10-2019",
                        "billAdress3": "",
                        "billZipCode": "77126",
                        "billCity": "CHATENAY SUR SEINE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "shipAdress1": "6 RUE DE FREGY",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "461",
                        "createdAt": "2020-09-11T23:50:51+02:00",
                        "updatedAt": "2020-09-11T23:50:51+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/9",
                            "@type": "InternalUser",
                            "id": 9,
                            "code": "03",
                            "email": "maximerethore@gmail.com",
                            "firstname": "RÉTHORETEST",
                            "lastname": "MAXIMETEST",
                            "name": "RÉTHORETEST MAXIMETEST"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "1441.62",
                        "ecoTaxe": "13.34",
                        "marginRate": "10.65",
                        "marginCoef": "0.12",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "@type": "Costumer",
                            "id": "633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "socialReason": null,
                            "ref": "1787",
                            "lastname": "DUMONT",
                            "firstname": "SEBASTIEN",
                            "phone": "01.64.42.37.50",
                            "mobile": "06.64.70.17.33",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "SEBASTIEN DUMONT"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f71a83cd5ffe700117452ff",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-28T09:09:16.061Z",
                "updatedAt": "2020-09-28T09:19:29.817Z",
                "__v": 0
            },
            {
                "_id": "5f71a848d5ffe70011745300",
                "recurrence": [],
                "start": "2020-09-27T00:00:00.000Z",
                "end": "2020-09-27T00:00:00.000Z",
                "creator": {
                    "_id": "5f71a848d5ffe70011745301",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "fixed",
                "note": "Test Note",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "@type": "Cmd",
                        "id": "c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "ref": "1.363310",
                        "writeAt": "2020-09-08T00:00:00+02:00",
                        "orderAt": "2020-09-08T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "VILLA EDONIA",
                        "amountHt": "13540.44",
                        "amountBuyHt": "12098.82",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "@type": "Costumer",
                            "id": "7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "socialReason": "DUMONT CLIMATISATION SERVICES",
                            "ref": "0021886",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "DUMONT CLIMATISATION SERVICES"
                        },
                        "billSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "billAdress1": "27 RUE DE LA GARE",
                        "billAdress2": "AC 09-00153 / 10-2019",
                        "billAdress3": "",
                        "billZipCode": "77126",
                        "billCity": "CHATENAY SUR SEINE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "shipAdress1": "6 RUE DE FREGY",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "461",
                        "createdAt": "2020-09-11T23:50:51+02:00",
                        "updatedAt": "2020-09-11T23:50:51+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/9",
                            "@type": "InternalUser",
                            "id": 9,
                            "code": "03",
                            "email": "maximerethore@gmail.com",
                            "firstname": "RÉTHORETEST",
                            "lastname": "MAXIMETEST",
                            "name": "RÉTHORETEST MAXIMETEST"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "1441.62",
                        "ecoTaxe": "13.34",
                        "marginRate": "10.65",
                        "marginCoef": "0.12",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "@type": "Costumer",
                            "id": "633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "socialReason": null,
                            "ref": "1787",
                            "lastname": "DUMONT",
                            "firstname": "SEBASTIEN",
                            "phone": "01.64.42.37.50",
                            "mobile": "06.64.70.17.33",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "SEBASTIEN DUMONT"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f71a848d5ffe70011745302",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-28T09:09:28.553Z",
                "updatedAt": "2020-09-28T11:45:14.061Z",
                "__v": 0
            },
            {
                "_id": "5f71abb8d5ffe70011745303",
                "recurrence": [],
                "start": "2020-09-29T00:00:00.000Z",
                "end": "2020-09-29T00:00:00.000Z",
                "creator": {
                    "_id": "5f71abb8d5ffe70011745304",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "confirmed",
                "note": "Nouvelle",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "@type": "Cmd",
                        "id": "c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "ref": "1.363310",
                        "writeAt": "2020-09-08T00:00:00+02:00",
                        "orderAt": "2020-09-08T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "VILLA EDONIA",
                        "amountHt": "13540.44",
                        "amountBuyHt": "12098.82",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "@type": "Costumer",
                            "id": "7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "socialReason": "DUMONT CLIMATISATION SERVICES",
                            "ref": "0021886",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "DUMONT CLIMATISATION SERVICES"
                        },
                        "billSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "billAdress1": "27 RUE DE LA GARE",
                        "billAdress2": "AC 09-00153 / 10-2019",
                        "billAdress3": "",
                        "billZipCode": "77126",
                        "billCity": "CHATENAY SUR SEINE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "shipAdress1": "6 RUE DE FREGY",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "461",
                        "createdAt": "2020-09-11T23:50:51+02:00",
                        "updatedAt": "2020-09-11T23:50:51+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/9",
                            "@type": "InternalUser",
                            "id": 9,
                            "code": "03",
                            "email": "maximerethore@gmail.com",
                            "firstname": "RÉTHORETEST",
                            "lastname": "MAXIMETEST",
                            "name": "RÉTHORETEST MAXIMETEST"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "1441.62",
                        "ecoTaxe": "13.34",
                        "marginRate": "10.65",
                        "marginCoef": "0.12",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "@type": "Costumer",
                            "id": "633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "socialReason": null,
                            "ref": "1787",
                            "lastname": "DUMONT",
                            "firstname": "SEBASTIEN",
                            "phone": "01.64.42.37.50",
                            "mobile": "06.64.70.17.33",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "SEBASTIEN DUMONT"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f71abb8d5ffe70011745305",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-28T09:24:08.217Z",
                "updatedAt": "2020-09-28T09:24:08.217Z",
                "__v": 0
            },
            {
                "_id": "5f71b44bd5ffe70011745306",
                "recurrence": [],
                "start": "2020-09-30T00:00:00.000Z",
                "end": "2020-09-30T00:00:00.000Z",
                "creator": {
                    "_id": "5f71b44bd5ffe70011745307",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "confirmed",
                "note": "Test 2",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "@type": "Cmd",
                        "id": "c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "ref": "1.363310",
                        "writeAt": "2020-09-08T00:00:00+02:00",
                        "orderAt": "2020-09-08T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "VILLA EDONIA",
                        "amountHt": "13540.44",
                        "amountBuyHt": "12098.82",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "@type": "Costumer",
                            "id": "7a317550-ac07-47fd-93c6-3071d6a8bee6",
                            "socialReason": "DUMONT CLIMATISATION SERVICES",
                            "ref": "0021886",
                            "lastname": null,
                            "firstname": null,
                            "phone": null,
                            "mobile": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "DUMONT CLIMATISATION SERVICES"
                        },
                        "billSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "billAdress1": "27 RUE DE LA GARE",
                        "billAdress2": "AC 09-00153 / 10-2019",
                        "billAdress3": "",
                        "billZipCode": "77126",
                        "billCity": "CHATENAY SUR SEINE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "shipAdress1": "6 RUE DE FREGY",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "461",
                        "createdAt": "2020-09-11T23:50:51+02:00",
                        "updatedAt": "2020-09-11T23:50:51+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/9",
                            "@type": "InternalUser",
                            "id": 9,
                            "code": "03",
                            "email": "maximerethore@gmail.com",
                            "firstname": "RÉTHORETEST",
                            "lastname": "MAXIMETEST",
                            "name": "RÉTHORETEST MAXIMETEST"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "1441.62",
                        "ecoTaxe": "13.34",
                        "marginRate": "10.65",
                        "marginCoef": "0.12",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "@type": "Costumer",
                            "id": "633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "socialReason": null,
                            "ref": "1787",
                            "lastname": "DUMONT",
                            "firstname": "SEBASTIEN",
                            "phone": "01.64.42.37.50",
                            "mobile": "06.64.70.17.33",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "SEBASTIEN DUMONT"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f71b44bd5ffe70011745308",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-28T10:00:43.087Z",
                "updatedAt": "2020-09-28T10:00:43.087Z",
                "__v": 0
            },
            {
                "_id": "5f71e6cfd5ffe70011745309",
                "recurrence": [],
                "start": "2020-09-29T00:00:00.000Z",
                "end": "2020-09-29T00:00:00.000Z",
                "creator": {
                    "_id": "5f71e6cfd5ffe7001174530a",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "status": "confirmed",
                "note": "test",
                "subject": {
                    "cmd": {
                        "@context": "/api/contexts/Cmd",
                        "@id": "/api/cmds/c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "@type": "Cmd",
                        "id": "c3cab4b7-32b0-4568-9a99-f2f252714dba",
                        "ref": "1.363310",
                        "writeAt": "2020-09-08T00:00:00+02:00",
                        "orderAt": "2020-09-08T00:00:00+02:00",
                        "type": "offer",
                        "isOrder": false,
                        "versionNumber": "",
                        "willDeliveryAt": "2020-10-31T00:00:00+01:00",
                        "stock": null,
                        "isBlocked": false,
                        "blockedCode": "",
                        "blockedDetail": "",
                        "isPrepareInProgress": false,
                        "costumerRef": "",
                        "refBusiness": "VILLA EDONIA",
                        "amountHt": "13540.44",
                        "amountBuyHt": "12098.82",
                        "amountToDelivery": "0.00",
                        "stockAlert": "grey",
                        "costumer": {
                            "@id": "/api/costumers/a8e2a9cd-9280-4360-b3a1-f8b11a38e262",
                            "@type": "Costumer",
                            "id": "a8e2a9cd-9280-4360-b3a1-f8b11a38e262",
                            "socialReason": "EURO CHAUFF",
                            "ref": "0022645",
                            "human": false,
                            "civility": null,
                            "lastname": null,
                            "firstname": null,
                            "fax": null,
                            "phone": null,
                            "mobile": null,
                            "humanFunction": null,
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "countryCode": null,
                            "createdAt": "2020-05-08T21:46:01+02:00",
                            "updatedAt": "2020-09-28T15:22:46+02:00",
                            "adressType": "bill",
                            "billing": true,
                            "solvency": null,
                            "costumerType": null,
                            "activity": null,
                            "saler": null,
                            "shippingAdressDef": null,
                            "contactDef": null,
                            "amountInsurance": null,
                            "amountAutorized": null,
                            "amountOutstandingDebt": null,
                            "amountSolde": null,
                            "amountInsuranceTotal": null,
                            "amountAutorizedTotal": null,
                            "amountNonAutorizedTotal": null,
                            "amountUnpaid": null,
                            "amountUnmaturedDebt": null,
                            "amountBillNonAccount": null,
                            "amountToBill": null,
                            "amountToShip": null,
                            "amountUntimelyDelivery": null,
                            "amountDeprivation": null,
                            "amountOrderBlocked": null,
                            "costumerFamily": null,
                            "importedBy": 934,
                            "costumer": null,
                            "email": null,
                            "name": "EURO CHAUFF"
                        },
                        "billSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "billAdress1": "27 RUE DE LA GARE",
                        "billAdress2": "AC 09-00153 / 10-2019",
                        "billAdress3": "",
                        "billZipCode": "77126",
                        "billCity": "CHATENAY SUR SEINE",
                        "billCountry": "FR",
                        "billPhone": null,
                        "billMobile": null,
                        "billEmail": null,
                        "shipSocialReason": "DUMONT CLIMATISATION SERVICES",
                        "shipAdress1": "6 RUE DE FREGY",
                        "shipAdress2": "",
                        "shipAdress3": "",
                        "shipZipCode": "77",
                        "shipCity": "FONTENAY TRESIGNY",
                        "shipAgency": null,
                        "shipPhone": null,
                        "shipMobile": null,
                        "shipEmail": null,
                        "importedBy": "461",
                        "createdAt": "2020-09-11T23:50:51+02:00",
                        "updatedAt": "2020-09-11T23:50:51+02:00",
                        "manager": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "saler": {
                            "@id": "/api/internal_users/9",
                            "@type": "InternalUser",
                            "id": "9",
                            "code": "03",
                            "email": "maximerethore@gmail.com",
                            "firstname": "RÉTHORETEST",
                            "lastname": "MAXIMETEST",
                            "name": "RÉTHORETEST MAXIMETEST"
                        },
                        "technician": {
                            "@id": "/api/internal_users/21",
                            "@type": "InternalUser",
                            "id": 21,
                            "code": "JC",
                            "email": "jerome.clement@csc-meaux.com",
                            "firstname": "Jerôme",
                            "lastname": "Clement",
                            "name": "Jerôme Clement"
                        },
                        "status": "of",
                        "marginHt": "1441.62",
                        "ecoTaxe": "13.34",
                        "marginRate": "10.65",
                        "marginCoef": "0.12",
                        "discountRate": null,
                        "prospectingStatus": null,
                        "prospectingDate": null,
                        "prospectingDetails": null,
                        "hasAnomaly": false,
                        "contact": {
                            "@id": "/api/costumers/633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "@type": "Costumer",
                            "id": "633b3089-97b5-479c-8f6e-4003769fc6cf",
                            "socialReason": null,
                            "ref": "1787",
                            "lastname": "DUMONT",
                            "firstname": "SEBASTIEN",
                            "phone": "01.64.42.37.50",
                            "mobile": "06.64.70.17.33",
                            "shipping": false,
                            "adressRow1": null,
                            "adressRow2": null,
                            "adressRow3": null,
                            "adressRow4": null,
                            "zipCode": null,
                            "city": null,
                            "name": "SEBASTIEN DUMONT"
                        },
                        "prospectingLabel": "nc",
                        "stockAlertLabel": "Grey",
                        "typeLabel": "Offre / Devis",
                        "statusLabel": "Offre/Devis"
                    },
                    "type": "affair"
                },
                "organizer": {
                    "_id": "5f71e6cfd5ffe7001174530b",
                    "id": "ee694d50-8bd0-4606-aa57-f014068a76b2",
                    "email": "maxime@whatson-web.com",
                    "displayName": "Maxime Réthoré"
                },
                "type": "note",
                "extendedProperties": [],
                "attendees": [],
                "createdAt": "2020-09-28T13:36:15.647Z",
                "updatedAt": "2020-09-28T13:36:15.647Z",
                "__v": 0
            }
        ]
    };
    const deleteAll = async () => {
        const messageService = new ApiService(GCALENDARENDPOINT);
        data.data.map(d => {
            messageService.remove(d._id);
        });
    };
    useEffect(() => {
        deleteAll();
    }, []);
    return React.createElement("div", null, "Composant qui supprime tout les messages");
};
