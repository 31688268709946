import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiService from '../../../utilities/apiService/ApiService';
import { COSTUMERSENDPOINT } from '../../../utilities/apiService/constant';
import AsyncSelect from 'react-select/async';
const SyntheseFilters = ({ defaultFilters = null, sendFilters, socialReason }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const [filters, setFilters] = useState(defaultFilters);
    const updateFilters = filter => {
        const updatedFilters = filters ? [...filters] : [];
        const idx = updatedFilters.findIndex(f => f.id === filter.id);
        if (idx === -1) {
            updatedFilters.push(filter);
            setFilters(updatedFilters);
            return;
        }
        else {
            updatedFilters[idx] = filter;
        }
        setFilters(updatedFilters);
        return;
    };
    const getValue = id => {
        if (!filters)
            return null;
        const filter = filters.find(f => f.id === id);
        return filter && filter.value ? { label: filter.label, value: filter.value } : { label: "Tout", value: "Tout" };
    };
    const asyncContactLoad = async (value) => {
        let filtered = [{ id: 'human', value: true }, { id: 'costumer.socialReason', value: socialReason }];
        if (value)
            filtered = filtered.concat([{ id: 'lastname', value: value }]);
        const contactService = new ApiService(COSTUMERSENDPOINT);
        const contacts = await contactService.search({ filtered: filtered });
        return [{ label: 'Tout', value: null }].concat(contacts['hydra:member'].map(s => ({ label: `${s.name}`, value: s.id })));
    };
    const onChangeContact = value => {
        updateFilters({ id: 'contact_id', value: value.value, label: value.label });
    };
    useEffect(() => {
        if (!filters)
            return;
        sendFilters(filters.filter(f => f.value !== null));
    }, [filters]);
    const clearValue = id => {
        if (!filters)
            return;
        setFilters([{ id: id, value: null }]);
    };
    return (React.createElement("div", { className: "filtersContainer productFilterContainer mb-15" },
        React.createElement("div", { className: 'productFilters w-20' },
            React.createElement("div", { className: "filtersContainer-row withPointerEvents" },
                React.createElement("div", { className: "filterIcon icon-contact color-blue mr-10" }),
                React.createElement("div", { className: 'filterSelect ' },
                    React.createElement(AsyncSelect, { name: 'contact', isDisabled: false, isSearchable: true, isClearable: true, backspaceRemoves: true, defaultOptions: true, defaultValue: { label: "Tout", value: null }, value: getValue('contact_id'), onChange: onChangeContact, loadOptions: selectValue => asyncContactLoad(selectValue), placeholder: 'Tout', classNamePrefix: "FMySel", className: "FMySelect-control" }),
                    React.createElement("div", { className: 'async-remove-synthese', onClick: () => clearValue('contact_id') },
                        React.createElement("span", null, "X")))))));
};
export default SyntheseFilters;
