import React, { Fragment, useState } from 'react';
import { Form } from 'react-final-form';
import FFTextArea from '../../../components/FinalForm/FFTextArea';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
export const ShareForm = ({ onSubmit, initialValues, onClose }) => {
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const btnMutator = {
        setBtn: (_args, state, utils) => {
            utils.changeValue(state, 'tos', value => {
                const updatedValue = [...value];
                const idx = updatedValue.findIndex(v => v === _args[0]);
                if (idx === -1) {
                    updatedValue.push(_args[0]);
                }
                else {
                    const ivIdx = initialValues.tos.findIndex(i => i === _args[0]);
                    if (ivIdx === -1)
                        updatedValue.splice(idx, 1);
                }
                return updatedValue;
            });
        },
    };
    return (React.createElement(Form, { onSubmit: onSubmit, mutators: { ...btnMutator }, initialValues: initialValues ? initialValues : { comment: '', tos: [] }, render: ({ handleSubmit, values, form: { mutators } }) => {
            return (React.createElement("form", { className: 'share-form', onSubmit: handleSubmit },
                React.createElement("span", { className: 'closeShareBtn', onClick: onClose }, "X"),
                React.createElement("div", { className: "whModal-btnContainer grid threeColumns w-100 flex-justifyCenter flex-alignCenter flex-selfCenter" }, humanFunctions &&
                    humanFunctions.map((hf, idx) => {
                        return (React.createElement("div", { key: `humanFunction-${idx}`, onClick: () => mutators.setBtn(hf.label), className: `whModal-btn ${values.tos.includes(hf.label)
                                ? 'whModal-btn-primary'
                                : 'whModal-btn-secondary'} text-regular` }, hf.label));
                    })),
                React.createElement(FFTextArea, { name: 'comment', className: 'text-color-gray text-left w-100 share-text-area', label: '' }),
                React.createElement("button", { type: "submit", className: 'button' }, "Partager")));
        } }));
};
export const ShareComponent = ({ onShare, dest, message, canShare, shared }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onSubmit = async (data) => {
        return onShare(data).then(() => {
            setIsOpen(false);
        });
    };
    const getRoleString = () => {
        let roleString = '';
        dest.map((d) => {
            roleString += `/ ${d ? d.label : 'RoleError'} `;
        });
        return roleString;
    };
    return (React.createElement("div", { className: 'share-header' },
        !isOpen && (React.createElement("div", { className: "header share-form" },
            React.createElement("div", { className: "button", onClick: canShare ? () => setIsOpen(true) : () => null },
                React.createElement("span", { className: `icon icon-share2 ${shared ? 'color-gray' : 'color-gray middleOpacity'}` })),
            React.createElement("div", { className: "infos" },
                shared && dest.length > 0 && message && (React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, message.createdAt)),
                dest.length > 0 && React.createElement("div", { className: 'shared-dest-list' }, getRoleString()),
                dest.length == 0 && (React.createElement("div", { className: 'shared-dest-list' }, "Cliquez sur le bouton de partage pour cr\u00E9er un message"))))),
        isOpen && (React.createElement(Fragment, null,
            React.createElement(ShareForm, { onClose: () => setIsOpen(false), onSubmit: onSubmit, initialValues: { comment: 'Merci de checker cette anomalie', tos: dest.map(d => d.label) } })))));
};
