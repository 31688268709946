export const required = value => {
    if (!value) {
        return "Obligatoire";
    }
    return undefined;
};
export const email = value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return "Adresse email non valide";
    }
    return undefined;
};
export const numeric = value => {
    if (value && !/^[0-9]*$/i.test(value)) {
        return "Ce champ ne peut contenir que des chiffres";
    }
    return undefined;
};
export const password = value => {
    if (value && !/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(value)) {
        return "Mot de passe non valide";
    }
    return undefined;
};
export default { required, email, password };
