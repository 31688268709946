import React, { useState } from 'react';
import { FicheMainContainer, FicheBody, FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import NameFormat from '../../../components/Nameformat';
import { Badge } from '../../../components/Badge/Badge';
import Icon from '../../../components/Icon/Icon';
import moment from 'moment';
import { useSelector } from 'react-redux';
import SelectionCheckBox from '../../../components/SelectionCheckBox/SelectionCheckBox';
import { isInLocalStorage, removeEntity, saveEntity } from '../../../utilities/storage';
import { setNcIfNull } from '../../../helper/setNcIfNull';
import { Redirect } from 'react-router';
export const FicheAffairePanel = ({ affair, hidden, className, selected = false, color = 'lightpink', }) => {
    const [isChecked, setIsChecked] = useState(isInLocalStorage('affaires', affair.id));
    const formatMoment = date => {
        return moment(date).format('DD/MM/YYYY');
    };
    const params = useSelector((state) => state.parameters);
    const { statusLabel, technician, saler, costumer, refBusiness, contact, ref, orderAt, writeAt, willDeliveryAt, amountHt, marginHt, } = affair;
    const badge = params && params.STATUS ? params.STATUS.find(st => st.label === statusLabel) : null;
    const onChange = e => {
        if (e.target.checked)
            saveEntity('affaires', affair.id, affair);
        if (!e.target.checked)
            removeEntity('affaires', affair.id);
        setIsChecked(e.target.checked);
    };
    return (React.createElement(FicheContainer, { color: color, className: `${selected ? `card--selected card--hover ${className}` : `card--hover ${className}`}` },
        React.createElement(SelectionCheckBox, { id: affair.id, isChecked: isChecked, onChange: onChange }),
        React.createElement(FicheBody, { columns: 'threeColumns' },
            React.createElement(Badge, { color: 'blue', text: badge && badge.value ? badge.value.toUpperCase() : '' }),
            React.createElement(Icon, { icon: 'icon-technico-commercial', text: technician ? NameFormat({ value: setNcIfNull(technician.name) }) : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-utilisateur', text: saler ? NameFormat({ value: setNcIfNull(saler.name) }) : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-entreprise', text: costumer ? setNcIfNull(costumer.socialReason) : '-', className: 'nowrap mb-5', bold: true, classNameTxt: 'bold nowrap' }),
            React.createElement(Icon, { icon: 'icon-contact', text: contact ? setNcIfNull(contact.name) : '-', bold: true, className: 'nowrap mb-5', classNameTxt: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-chantier', text: refBusiness ? setNcIfNull(refBusiness) : '-', bold: true, className: 'nowrap mb-5', classNameTxt: 'nowrap' }),
            React.createElement("div", { className: 'mb-1' }, ref),
            React.createElement(Icon, { icon: 'icon-check_2', className: 'mb-1', text: formatMoment(orderAt) }),
            React.createElement(Icon, { icon: 'icon-relances', className: 'mb-1', text: amountHt }),
            React.createElement("div", { className: 'mb-2' }, formatMoment(writeAt)),
            React.createElement(Icon, { icon: 'icon-logout', className: 'mb-1', text: formatMoment(willDeliveryAt) }),
            !hidden && React.createElement(Icon, { icon: 'icon-marge-valeur color-blue', className: 'mb-1', text: marginHt }))));
};
export const AffairsDashboard = ({ affairs, hidden, selected = null }) => {
    const params = useSelector((state) => state.parameters);
    const [redirect, setRedirect] = useState(null);
    const redirectToAffair = affair => {
        setRedirect(affair);
    };
    return (React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: 'Dernières affaires', icon: 'icon-affaire' }, affairs.map((affair, idx) => {
        return (React.createElement("div", { key: `affair-${idx}`, onClick: () => redirectToAffair(affair) },
            React.createElement(FicheAffairePanel, { affair: affair, params: params, hidden: hidden, selected: selected === idx, color: 'lightpink' }),
            redirect && (React.createElement(Redirect, { to: `/main/affaires/rightpanel/affaires/${redirect.id}/selectedAffair` }))));
    })));
};
