import React, { useState } from 'react';
import Icon from '../../../components/Icon/Icon';
import { isInLocalStorage, removeEntity, saveEntity } from '../../../utilities/storage';
const FicheProduit = ({ product, color = 'lightgray', callback }) => {
    const [isChecked, setIsChecked] = useState(isInLocalStorage('products', product.id));
    const onChange = e => {
        if (e.target.checked)
            saveEntity('products', product.id, product);
        if (!e.target.checked)
            removeEntity('products', product.id);
        setIsChecked(e.target.checked);
        if (callback)
            callback();
    };
    return (React.createElement("div", { className: `card card-color-${color}` },
        React.createElement("div", { style: { width: '100%', display: 'flex' } },
            React.createElement("div", { style: { flex: 1, display: 'flex' } },
                React.createElement(Icon, { icon: 'icon-article', className: 'flex flex-alignCenter flex-justifyStart text-fat', style: { fontSize: '12px' } }),
                React.createElement("div", { className: 'column-span-3 flex flex-alignStart flex-directionColumn' },
                    React.createElement("div", { className: 'text-big' }, product.title),
                    React.createElement("div", { className: 'text-big' }, product.ref))),
            React.createElement("div", { style: { width: '30px' } },
                React.createElement("input", { className: "inp-customCbx d-none", id: `selected-${product.id}`, type: "checkbox", name: 'affairs', onClick: onChange, defaultChecked: isChecked }),
                React.createElement("label", { className: "customCbx", htmlFor: `selected-${product.id}` },
                    React.createElement("span", null,
                        React.createElement("svg", { width: "12px", height: "10px", viewBox: "0 0 12 10" },
                            React.createElement("polyline", { points: "1.5 6 4.5 9 10.5 1" }))),
                    React.createElement("span", null))))));
};
export default FicheProduit;
