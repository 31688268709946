import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalNote from '../Notes/ModalNote';
import { setFooterAnomalie, setFooterContact, setFooterNote, setFooterRdv, setFooterRelance, setLocked, } from '../../../../redux/panelFooterModal/action';
import { setAnomaliePanelReload, setNotePanelReload, setRdvPanelReload, setRelancePanelReload, } from '../../../../redux/reloader/action';
import ModalAnomalie from '../NewAnomalies/ModalAnomalies';
import ModalRdv from '../Agenda/ModalRdv';
import ModalContact from '../../../components/RightPanelModal/ModalContact';
import ModalRelance from '../Relances/ModalRelance';
const Footer = ({ user, humanFunctions, type = 'none', selected, disableElements, reload }) => {
    const footer = useSelector((state) => state.footer);
    const dispatch = useDispatch();
    const getContactData = () => {
        if (type === 'affaires')
            return {
                address: selected.costumer.adressRow1,
                socialReason: selected.costumer.socialReason,
                phone: selected.costumer.phone,
                city: selected.costumer.city,
                zipCode: selected.costumer.zipCode,
                contact: selected.contact,
            };
        if (type === 'anomalies')
            return {
                address: selected.cmd.costumer.adressRow1,
                socialReason: selected.cmd.costumer.socialReason,
                phone: selected.cmd.costumer.phone,
                city: selected.cmd.costumer.city,
                zipCode: selected.cmd.costumer.zipCode,
                contact: selected.cmd.contact,
            };
        if (type === 'notes') {
            return {
                address: selected.subject.cmd.costumer.adressRow1,
                socialReason: selected.subject.cmd.costumer.socialReason,
                phone: selected.subject.cmd.costumer.phone,
                city: selected.subject.cmd.costumer.city,
                zipCode: selected.subject.cmd.costumer.zipCode,
                contact: selected.subject.cmd.contact,
            };
        }
        if (type === 'relances') {
            return {
                address: selected.subject.cmd.costumer.adressRow1,
                socialReason: selected.subject.cmd.costumer.socialReason,
                phone: selected.subject.cmd.costumer.phone,
                city: selected.subject.cmd.costumer.city,
                zipCode: selected.subject.cmd.costumer.zipCode,
                contact: selected.subject.cmd.contact,
            };
        }
        if (type === 'rdvs') {
            return {
                address: selected.subject.costumer.address,
                socialReason: selected.subject.costumer.displayName,
                phone: selected.subject.costumer.phone,
                city: selected.subject.costumer.city,
                zipCode: selected.subject.costumer.zipCode,
                contact: null,
            };
        }
        return { address: null, socialReason: null, phone: null, city: null, zipCode: null, contact: null };
    };
    const getCmdData = () => {
        console.log({ selected });
        if (type === 'rdvs') {
            return selected.subject.cmd;
        }
        if (type === 'notes') {
            return selected ? selected.subject.cmd : null;
        }
        if (type === 'anomalies') {
            return selected.cmd;
        }
        if (type === 'relances') {
            return selected.subject.cmd;
        }
        return selected;
    };
    const isDisabled = str => {
        return disableElements.some(el => el === str);
    };
    const onReloadNote = () => {
        console.log('onReload!!!!');
        if (reload)
            reload();
        dispatch(setNotePanelReload(true));
    };
    const onReloadRdv = () => {
        reload();
        dispatch(setRdvPanelReload(true));
    };
    const onReloadAnomalie = () => {
        if (reload)
            reload();
        dispatch(setAnomaliePanelReload(true));
    };
    const onReloadRelance = () => {
        reload();
        dispatch(setRelancePanelReload(true));
    };
    const getRdvInitialValue = () => {
        if (!selected)
            return null;
        const defaultValue = {
            attendees: [''],
            creator: { id: user.id, email: user.email, displayName: user.name },
        };
        if (type === 'affaires') {
            return {
                company: {
                    label: selected.costumer.socialReason,
                    value: selected.costumer.id,
                    all: { ...selected.costumer },
                },
                ...defaultValue,
            };
        }
        if (type === 'rdvs') {
            return {
                company: {
                    label: selected.subject.costumer.displayName,
                    value: selected.subject.costumer.id,
                    all: { ...selected.subject.costumer },
                },
                ...defaultValue,
            };
        }
        if (type === 'notes' || type === 'relances') {
            if (!selected.subject.hasOwnProperty('cmd') && !selected.subject.cmd.hasOwnProperty('costumer'))
                return null;
            return {
                company: {
                    label: selected.subject.cmd.costumer.socialReason,
                    value: selected.subject.cmd.costumer.id,
                    all: { ...selected.subject.cmd.costumer },
                },
                ...defaultValue,
            };
        }
        if (type === 'anomalies') {
            if (!selected.hasOwnProperty('cmd') && !selected.cmd.hasOwnProperty('costumer'))
                return null;
            return {
                company: {
                    label: selected.cmd.costumer.socialReason,
                    value: selected.cmd.costumer.id,
                    all: { ...selected.cmd.costumer },
                },
                ...defaultValue,
            };
        }
        return null;
    };
    console.log({ disableElements });
    return (React.createElement(Fragment, null,
        footer.isNoteModalOpen && (React.createElement(ModalNote, { onClose: () => dispatch(setFooterNote(false)), user: user, isUpdate: footer.initialValues !== null, initialValues: footer.initialValues, cmd: getCmdData(), reload: onReloadNote })),
        footer.isRelanceModalOpen && (React.createElement(ModalRelance, { onClose: () => dispatch(setFooterRelance(false)), user: user, isUpdate: footer.initialValues !== null, initialValues: footer.initialValues, affair: getCmdData(), reload: onReloadRelance })),
        footer.isRdvModalOpen && (React.createElement(ModalRdv, { onClose: () => dispatch(setFooterRdv(false)), isUpdate: footer.initialValues !== null, initialValues: footer.initialValues ? footer.initialValues : getRdvInitialValue(), user: user, reload: onReloadRdv })),
        footer.isContactModalOpen && (React.createElement(ModalContact, { onClose: () => dispatch(setFooterContact(false)), data: getContactData() })),
        footer.isAnomalieModalOpen && getCmdData() && (React.createElement(ModalAnomalie, { onClose: () => dispatch(setFooterNote(false)), user: user, humanFunctions: humanFunctions, affair: getCmdData(), reload: onReloadAnomalie })),
        React.createElement("div", { className: "actionBar", style: { width: '100%' } },
            React.createElement("div", { className: `actionBar-item  icon-relances ${isDisabled('relances') ? 'actionBar-item--disabled' : ''} ${footer.isRelanceModalOpen && 'actionBar-item--active'}`, onClick: isDisabled('relances')
                    ? () => null
                    : () => dispatch(setFooterRelance(!footer.isRelanceModalOpen)) }),
            React.createElement("div", { className: `actionBar-item  icon-anomalies-full ${isDisabled('anomalies') ? 'actionBar-item--disabled' : ''} ${footer.isAnomalieModalOpen && 'actionBar-item--active'}`, onClick: isDisabled('anomalies')
                    ? () => null
                    : () => dispatch(setFooterAnomalie(!footer.isAnomalieModalOpen)) }),
            React.createElement("div", { className: `actionBar-item  icon-note ${isDisabled('notes') ? 'actionBar-item--disabled' : ''} ${footer.isNoteModalOpen && 'actionBar-item--active'}`, onClick: isDisabled('notes') ? () => null : () => dispatch(setFooterNote(!footer.isNoteModalOpen)) }),
            React.createElement("div", { className: `actionBar-item  ${isDisabled('rdvs') ? 'actionBar-item--disabled' : ''} icon-agenda ${footer.isRdvModalOpen && 'actionBar-item--active'}`, onClick: isDisabled('rdvs') ? () => null : () => dispatch(setFooterRdv(!footer.isRdvModalOpen)) }),
            React.createElement("div", { className: `actionBar-item  ${isDisabled('suivis') ? 'actionBar-item--disabled' : ''} icon-adduser ${footer.isContactModalOpen && 'actionBar-item--active'}`, onClick: isDisabled('suivis')
                    ? () => null
                    : () => dispatch(setFooterContact(!footer.isContactModalOpen)) }),
            React.createElement("div", { className: `actionBar-item  icon-unlock ${footer.isLocked && 'actionBar-item--active'}`, onClick: () => dispatch(setLocked()) }))));
};
export default Footer;
