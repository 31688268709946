import React, { useEffect } from 'react';
import ApiService from '../../utilities/apiService/ApiService';
import { NOTIFICATIONPOINT } from '../../utilities/apiService/constant';
import { useSelector } from 'react-redux';
const NotifDebug = () => {
    const user = useSelector((state) => state.user.data);
    const getAllNotif = async () => {
        const messageService = new ApiService(NOTIFICATIONPOINT);
        const messages = await messageService.search({
            filtered: [
                { id: 'email', value: user.email },
                { id: 'sub', value: user.take.id.toString() },
            ],
        });
    };
    useEffect(() => {
        getAllNotif();
    }, []);
    return React.createElement("div", null, "Notif Debug");
};
export default NotifDebug;
