import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import Moment from 'react-moment';
import { FicheBody, FicheContainer, FicheMainContainer, FicheHeader, } from '../../../components/Fiche/FicheDefaultLayout';
import Icon from '../../../components/Icon/Icon';
import { formatMoment } from '../../../helper/momentHelper';
import ApiService from '../../../utilities/apiService/ApiService';
import { GCALENDARENDPOINT } from '../../../utilities/apiService/constant';
import { isInLocalStorage, removeEntity, saveEntity } from '../../../utilities/storage';
import SelectionCheckBox from '../../../components/SelectionCheckBox/SelectionCheckBox';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import { setFooterRelance } from '../../../../redux/panelFooterModal/action';
import { useDispatch } from 'react-redux';
import { setNcIfNull } from '../../../helper/setNcIfNull';
import Loading from '../../../shared/Loading';
export const WithSwipe = ({ relance, reload }) => {
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = async () => {
        const relanceService = new ApiService(GCALENDARENDPOINT);
        try {
            setIsLoading(true);
            await relanceService.patch({ _id: relance._id, status: relance.status === 'OPEN' ? 'CLOSE' : 'OPEN' });
            setIsLoading(false);
            reload();
        }
        catch (e) {
            setIsLoading(false);
        }
    };
    return (React.createElement("div", { className: "card backCard card-color-dark flex-justifyCenter flex-justifyCenterMobile" },
        React.createElement("div", { className: "card_body singleColumn mt-10" },
            React.createElement("div", { onClick: onSubmit, className: "button red" }, isLoading ? (React.createElement(Fragment, null,
                React.createElement(Loading, { withoutLogo: false }),
                React.createElement("span", null, relance.status === 'OPEN' ? 'Cloturer' : 'Ouvrir'))) : (React.createElement("span", null, relance.status === 'OPEN' ? 'Cloturer' : 'Ouvrir'))))));
};
export const RightPanelSelectedRelance = ({ relance, user }) => {
    const { start, note } = relance;
    const dispatch = useDispatch();
    const initialValue = {
        _id: relance._id,
        creator: relance.creator,
        organizer: relance.organizer,
        comments: relance.note,
        receiverMe: relance.subject.saler.email === user.email
            ? {
                label: user.take.name,
                id: user.take.id,
                value: user.take['@id'],
                all: relance.subject.saler,
            }
            : null,
        receiver: relance.subject.saler.email !== user.email
            ? {
                label: relance.subject.saler.name,
                id: relance.subject.saler.id,
                value: relance.subject.saler['@id'],
                all: relance.subject.saler,
            }
            : null,
        status: relance.status,
        answers: relance.subject.hasOwnProperty('answers') ? relance.subject.answers : [],
        cmd: relance.subject.cmd,
        start: relance.start,
        end: relance.end,
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'withForm' },
            React.createElement("div", { className: classnames('card-message', { author: true }) },
                React.createElement("div", { className: 'card-message-row' },
                    React.createElement("div", { className: 'card-message-header' },
                        React.createElement("div", { className: 'user-container' },
                            React.createElement(Icon, { icon: 'icon-utilisateur', text: relance.creator.displayName, className: 'bold' }),
                            relance.creator.email === user.email && relance.status === 'OPEN' && (React.createElement(Icon, { icon: `icon-edit`, className: 'size-small withPointerEvents cursorPointer', onClick: () => dispatch(setFooterRelance(true, initialValue)) }))),
                        React.createElement("div", { className: "small-date" },
                            React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, start))),
                    React.createElement("div", { className: 'card-message-body' },
                        React.createElement(FicheContainer, { color: 'white', className: "card-message-body-message" },
                            React.createElement("div", null, note))))))));
};
export const SwipeWrapper = ({ render, reload }) => {
    const [swipeIndex, setSwipeIndex] = useState(1);
    const onReload = () => {
        setSwipeIndex(0);
        reload();
    };
    return React.createElement(Fragment, null, render({ swipeIndex, onReload }));
};
export const RelanceLeftPanelList = ({ relances, hasNextPage, loadNextPage, isLoading, selected = null, onClick, reload, }) => {
    return (React.createElement(FicheMainContainer, { columns: 'singleColumn' },
        React.createElement("div", null,
            React.createElement(InfiniteScroll, { data: relances, hasNextPage: hasNextPage, loadNextPage: loadNextPage, rowHeight: 150, width: 550, isNextPageLoading: isLoading, component: ({ index }) => {
                    return (React.createElement(SwipeWrapper, { key: `${JSON.stringify(relances[index])}`, reload: reload, render: () => {
                            return (React.createElement("div", { className: classnames('card-affaire-container', {
                                    'card-affaire-container-active': selected && selected._id === relances[index]._id,
                                }), onClick: (_e) => {
                                    if (_e.target.localName !== 'svg' &&
                                        _e.target.localName !== 'input' &&
                                        _e.target.localName !== 'polyline') {
                                        onClick('relances', relances[index]._id, 'selectedRelance', relances[index]);
                                    }
                                } },
                                React.createElement(RelanceElement, { relance: relances[index], key: `relance-element-${index}`, color: selected && selected._id === relances[index]._id ? 'darkblue' : 'lightblue' })));
                        } }));
                } }))));
};
export const RelanceElement = ({ relance, color = 'lightblue', callback, checkboxClassName }) => {
    const [isChecked, setIsChecked] = useState(isInLocalStorage('relances', relance._id));
    const { start, subject } = relance;
    const { cmd, saler } = subject;
    const onChange = e => {
        if (e.target.checked)
            saveEntity('relances', relance._id, relance);
        if (!e.target.checked)
            removeEntity('relances', relance._id);
        setIsChecked(e.target.checked);
        if (callback)
            callback();
    };
    return (React.createElement("div", { className: `card card-color-${color}` },
        React.createElement(SelectionCheckBox, { id: relance._id, isChecked: isChecked, onChange: onChange, className: checkboxClassName ? checkboxClassName : 'singleColumn flex flex-justifyEnd flex-alignStart w-50' }),
        React.createElement(FicheHeader, { columns: 'singleColumn' },
            React.createElement(Icon, { icon: 'icon-entreprise', text: setNcIfNull(cmd && cmd.costumer ? cmd.costumer.socialReason : null), className: 'bold' })),
        React.createElement(FicheHeader, { columns: 'singleColumn' },
            React.createElement(Icon, { icon: 'icon-contact', text: cmd.contact && cmd.contact.name ? setNcIfNull(cmd.contact.name) : setNcIfNull(null), className: 'bold' })),
        React.createElement(FicheBody, { columns: 'dualColumns' },
            React.createElement(Icon, { icon: 'icon-affaire', text: setNcIfNull(cmd.ref), className: 'bold' }),
            React.createElement(Icon, { icon: 'icon-utilisateur', text: setNcIfNull(saler.name), className: 'bold' })),
        React.createElement(FicheBody, { columns: 'dualColumns' },
            React.createElement(Icon, { icon: 'icon-chantier', text: setNcIfNull(cmd.refBusiness), className: 'bold' }),
            React.createElement("div", null, formatMoment(start)))));
};
