import React, { Fragment, useState } from 'react';
import DatePicker from '../DatePicker';
import DateTimePicker from '../DateTimePicker';
import 'react-datepicker/dist/react-datepicker.css';
export const PasswordField = ({ disabled, placeholder, input }) => {
    const [fieldType, setFieldType] = useState('password');
    const [iconClass, setIconClass] = useState('icon-oeil');
    const showPassword = () => {
        if (fieldType === 'password') {
            setFieldType('text'), setIconClass('icon-oeil-ferme');
        }
        else {
            setFieldType('password'), setIconClass('icon-oeil');
        }
    };
    return (React.createElement(Fragment, null,
        React.createElement("input", Object.assign({ type: fieldType, disabled: disabled, placeholder: placeholder }, input)),
        React.createElement("span", { className: `${iconClass} showPassword size-big`, onClick: () => showPassword() })));
};
const FieldTxt = (props) => {
    const { input, label, iconbefore, iconafter, btnbefore, btnafter, type, placeholder, disabled, help, onChange, meta: { touched, error }, iconDate = true, maxLength, className, } = props;
    const returnComponent = () => {
        if (type === 'date')
            return DatePicker;
        if (type === 'datetime')
            return DateTimePicker;
        return 'input';
    };
    const returnType = () => {
        if (!props.type)
            return 'text';
        if (['date', 'datetime'].includes(props.type))
            return 'text';
        return props.type;
    };
    const onChangeInput = e => {
        input.onChange(e.target.value);
        if (onChange)
            onChange(e.target.value);
    };
    const onChangeNumber = e => {
        const value = e.target.value;
        const reg = new RegExp('^[0-9]+$');
        if (!reg.test(value) || !value) {
            input.onChange('');
            if (onChange)
                onChange(e.target.value);
            return;
        }
        else {
            input.onChange(value);
            if (onChange)
                onChange(e.target.value);
        }
    };
    return (React.createElement(React.Fragment, null,
        label && React.createElement("label", null, label),
        iconbefore && React.createElement("div", null, props.iconbefore),
        btnbefore && btnbefore(),
        returnComponent() === 'input' && type === 'password' && (React.createElement(PasswordField, { disabled: disabled, placeholder: placeholder, input: input })),
        returnComponent() === 'input' && type === 'time' && (React.createElement("input", Object.assign({}, input, { type: returnType(), disabled: disabled, onChange: onChangeInput, min: '00:00', max: '23:59' }))),
        returnComponent() === 'input' && type === 'text' && (React.createElement("input", Object.assign({}, input, { type: returnType(), disabled: disabled, onChange: onChangeInput, placeholder: placeholder, maxLength: maxLength, className: className }))),
        returnComponent() === 'input' && type === 'number' && (React.createElement("input", Object.assign({}, input, { type: 'text', disabled: disabled, onChange: onChangeNumber, placeholder: placeholder, maxLength: maxLength, className: className }))),
        returnComponent() === 'input' && type === 'textarea' && (React.createElement("textarea", Object.assign({ type: returnType(), disabled: disabled, className: className, placeholder: placeholder, onChange: onChange, maxLength: maxLength }, input))),
        type === 'date' && (React.createElement("div", { className: `whModal-date whModal-btn` },
            ' ',
            React.createElement(DatePicker, { input: input, onChange: onChange }))),
        type === 'datetime' && (React.createElement("div", { className: `whModal-date whModal-btn` },
            ' ',
            React.createElement(DateTimePicker, { input: input, onChange: onChange }))),
        iconafter && React.createElement("div", { className: "iconAfter" }, iconafter),
        btnafter && btnafter(),
        help && React.createElement("span", null, help),
        touched && error && React.createElement("span", { className: "form__form-group-error formError" }, error)));
};
export default FieldTxt;
