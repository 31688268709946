import React, { Fragment, useEffect } from 'react';
import NotesApiCall from '../../../components/Fiche/Note/NotesApiCall';
import { FicheNotePanel } from '../../../components/Fiche/FicheNote';
import Loading from '../../../shared/Loading';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import { setNotePanelReload } from '../../../../redux/reloader/action';
import { useDispatch } from 'react-redux';
import { setFooterNote } from '../../../../redux/panelFooterModal/action';
export const NotePanelElement = ({ n, validateNote, user, reload }) => {
    const dispatch = useDispatch();
    const initialValues = {
        _id: n._id,
        creator: { id: n.creator.id, email: n.creator.email, displayName: n.creator.name },
        organizer: { id: n.creator.id, email: n.creator.email, displayName: n.creator.name },
        comments: n.note,
        status: 'confirmed',
        client: n.subject.hasOwnProperty('cmd') && n.subject.cmd.hasOwnProperty('costumer')
            ? {
                label: n.subject.cmd.costumer.socialReason,
                id: n.subject.cmd.costumer.id,
                value: n.subject.cmd.costumer['@id'],
                all: n.subject.cmd.costumer,
            }
            : null,
        cmd: n.subject.hasOwnProperty('cmd')
            ? { label: n.subject.cmd.ref, value: n.subject.cmd['@id'], all: n.subject.cmd }
            : null,
        date: n.start,
        startDate: '00',
        endDate: '00',
    };
    const afterSubmitModal = () => {
        dispatch(setNotePanelReload(true));
    };
    return (React.createElement(Fragment, null,
        React.createElement(FicheNotePanel, { isBlue: false, validateNote: validateNote, openModal: () => dispatch(setFooterNote(true, initialValues)), classNameOverload: 'card card-color-lightgray card-panel-right-nominheight', mainNote: n })));
};
export const NotesList = ({ user, notes, validateNote, reload }) => {
    return (React.createElement(Fragment, null, notes.map((n, _idx) => (React.createElement(NotePanelElement, { user: user, n: n, validateNote: validateNote, reload: reload })))));
};
export const NotesListContainer = ({ reload, formatResult, user, filters, infiniteScrollWidth }) => {
    return (React.createElement(Fragment, null,
        React.createElement(NotesApiCall, { filters: filters, mainReload: reload, formatResult: formatResult, render: ({ notes, validateNote, isLoading, hasNextPage, loadNextPage }) => {
                return (React.createElement(Fragment, null,
                    isLoading && React.createElement(Loading, { withoutLogo: false }),
                    React.createElement(InfiniteScroll, { data: notes, hasNextPage: hasNextPage, loadNextPage: loadNextPage, rowHeight: 200, width: infiniteScrollWidth, isNextPageLoading: isLoading, component: ({ index }) => {
                            return (React.createElement(Fragment, null,
                                React.createElement(NotePanelElement, { n: notes[index], validateNote: validateNote, user: user, reload: reload })));
                        } })));
            } })));
};
export const NotePanel = ({ mainNote, user, reload, rightPanelRef }) => {
    const costumeId = mainNote.subject.hasOwnProperty('cmd') && mainNote.subject.cmd.hasOwnProperty('costumer')
        ? mainNote.subject.cmd.costumer.id
        : null;
    const filters = [
        { id: 'type', value: 'note' },
        { id: 'subject.cmd.costumer.id', value: costumeId },
    ];
    const formatResult = datas => [{ ...mainNote }].concat(datas.filter(d => d._id !== mainNote._id));
    const getWidth = () => {
        if (!rightPanelRef)
            return 0;
        if (!rightPanelRef.current)
            return 0;
        return rightPanelRef.current.clientWidth + 20;
    };
    return (React.createElement(Fragment, null,
        React.createElement(NotesListContainer, { formatResult: formatResult, filters: filters, user: user, reload: reload, infiniteScrollWidth: getWidth() })));
};
export const CustomerNoteList = ({ user, notes, isLoading, send, mainNote = null }) => {
    useEffect(() => {
        send({
            type: 'FETCH',
            filtered: [],
        }, { to: 'fetch-customer-notes' });
    }, []);
    const reload = () => {
        send('FETCH');
    };
    const validateNote = _id => {
        send({
            type: 'VALIDATE',
            _id,
        }, { to: 'validate-notes' });
    };
    return (React.createElement(Fragment, null,
        isLoading && React.createElement(Loading, { withoutLogo: false }),
        notes && notes.length > 0 && (React.createElement(NotesList, { user: user, notes: mainNote ? [mainNote, ...notes] : notes, validateNote: validateNote, reload: reload })),
        notes && notes.length === 0 && React.createElement("div", null, "Aucun r\u00E9sultat")));
};
