import React, { useState } from 'react';
import { FicheMainContainer } from './FicheDefaultLayout';
import { FicheAgendaLeftPanel } from '../../modules/Layout/Agenda/FicheAgendaLeftPanel';
import { Redirect } from 'react-router';
export const RdvList = ({ rdvs, onClick, headerTitle = "Aujourd'hui", icon }) => {
    const [redirect, setRedirect] = useState(null);
    const redirectToRdv = rdv => {
        setRedirect(rdv);
    };
    return (React.createElement("div", { className: "centerPanel-RDV" },
        React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: headerTitle, icon: `${icon}` }, rdvs.map((rdv, idx) => {
            return (React.createElement("div", { key: `rdv-${idx}`, onClick: () => redirectToRdv(rdv) },
                React.createElement(FicheAgendaLeftPanel, { rdv: rdv, color: 'lightyellow', idx: idx, onClick: onClick })));
        })),
        redirect && React.createElement(Redirect, { to: `/main/agenda/rightpanel/rdvs/${redirect._id}/selectedRdv` })));
};
