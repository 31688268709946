import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../../shared/Loading';
import Filters from '../../Filters/Filters';
import { Route } from 'react-router';
import RightPanelRoute from '../RightPanelRoute';
import Footer from '../Footer/Footer';
import { useRdvs } from './useAgenda';
import { AgendaLeftPanelList } from './FicheAgendaLeftPanel';
import { deleteRdv } from '../../../utilities/apiService/fetchLists';
import { removeEntity } from '../../../utilities/storage';
const NewAgenda = ({ match, history }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const [currentRdvs, setCurrentRdvs] = useState([]);
    const isLocked = useSelector((state) => state.footer.isLocked);
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const [shouldWaitFetched, setShouldWaitFetched] = useState(false);
    const [elementToFetch, setElementToFetch] = useState(null);
    const { rdvs: rdvs, pastRdvs, setSelectedRdv, getOneRdv, selectedRdv, reloadData, setFilters, isLoading, hasNextPage, loadNextPage, } = useRdvs();
    const onReload = () => {
        reloadData();
    };
    const sendFilters = filter => {
        setFilters(filter);
    };
    const setElement = id => {
        setShouldWaitFetched(true);
        setElementToFetch(id);
    };
    const onSelect = (type, id, defaultIcon, data) => {
        setSelectedRdv(data);
        history.push(`${match.path}/rightpanel/${type}/${id}/${defaultIcon}`);
    };
    useEffect(() => {
        if (!shouldWaitFetched)
            return;
        if (!elementToFetch)
            return;
        getOneRdv(elementToFetch);
        setShouldWaitFetched(false);
    }, [shouldWaitFetched]);
    useEffect(() => {
        setCurrentRdvs(pastRdvs.length > 0
            ? [{ rdvPresent: 'present' }, ...rdvs, { rdvTitle: 'yeah' }, ...pastRdvs]
            : [{ rdvPresent: 'present' }, ...rdvs]);
    }, [rdvs, pastRdvs]);
    const onDeleteRdv = rdvId => {
        return deleteRdv(rdvId).then(() => {
            removeEntity('rdvs', rdvId);
            onReload();
        });
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `whflex-col centerPanel` },
            React.createElement("div", { className: "toBeScrolled" },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                React.createElement(Filters, { filtersConfig: ['company', 'date', 'saler'], sendFilters: sendFilters }),
                currentRdvs && currentRdvs.length > 0 && (React.createElement(AgendaLeftPanelList, { rdvs: currentRdvs, infiniteScrollHeight: 900, isLoading: isLoading, loadNextPage: loadNextPage, hasNextPage: hasNextPage, headerTitle: '', onClick: onSelect, selected: selectedRdv, onDelete: onDeleteRdv, icon: 'icon-Agenda' })))),
        React.createElement("div", { className: 'whflex-col rightPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Route, { path: "/main/agenda/rightpanel/:type/:id/:tab", name: "rightpanel" },
                    React.createElement(RightPanelRoute, { data: selectedRdv, reload: onReload, isHidden: isLocked, type: 'rdvs', setElement: setElement, user: connectedUser }))),
            React.createElement(Footer, { user: connectedUser, type: 'rdvs', reload: onReload, humanFunctions: humanFunctions, selected: selectedRdv, disableElements: selectedRdv ? ['relances', 'anomalies'] : ['relances', 'notes', 'anomalies', 'suivis'] }))));
};
export default NewAgenda;
