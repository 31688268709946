import React, { Fragment, useState } from 'react';
import { Route, withRouter } from 'react-router';
import { useSelection } from './useSelection';
import { useSelector } from 'react-redux';
import { FicheAffairLeftPanel } from '../Affaires/AffairesLeftPanel';
import RightPanelRoute from '../RightPanelRoute';
import FicheProduit from '../Produits/FicheProduit';
import { FicheAgendaLeftPanel } from '../Agenda/FicheAgendaLeftPanel';
import { FicheAnomalie } from '../NewAnomalies/FicheAnomalie';
import { FicheNote } from '../Notes/NoteLeftPanel';
import { RelanceElement } from '../Relances/FicheRelance';
import classnames from 'classnames';
import Footer from '../Footer/Footer';
import FicheContact from '../Contacts/FicheContact';
import FicheClient from '../Clients/FicheClient';
const Selection = ({ match, history }) => {
    const { affairs, products, rdvs, relances, contacts, clients, getFromTypeAndId, anomalies, notes, deleteLocalStorage, loadData, } = useSelection();
    const [selectedElement, setSelectedElement] = useState(null);
    const [data, setData] = useState(null);
    const [type, setType] = useState(null);
    const [footerDisabledElement] = useState({
        affaires: [],
        relances: [],
        notes: ['notes'],
        products: ['rdvs', 'notes', 'relances', 'anomalies', 'suivis', 'contact'],
        rdvs: ['relances', 'anomalies'],
        anomalies: ['anomalies'],
        contacts: ['rdvs', 'notes', 'relances', 'anomalies', 'suivis', 'contact'],
        clients: ['rdvs', 'notes', 'relances', 'anomalies', 'suivis', 'contact'],
    });
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const params = useSelector((state) => state.parameters);
    const user = useSelector((state) => state.user.data);
    console.log({ user });
    const onSelect = (type, id, defaultIcon, data) => {
        setSelectedElement(id);
        setData(data);
        setType(type);
        history.push(`${match.path}/rightpanel/${type}/${id}/${defaultIcon}`);
    };
    const onDelete = () => {
        deleteLocalStorage();
        setSelectedElement(null);
        setData(null);
        setType(null);
        history.push(`${match.path}`);
    };
    const setElement = (type, id) => {
        const element = getFromTypeAndId(type, id);
        setType(type);
        setData(element);
    };
    console.log({ data, type });
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `whflex-col centerPanel ${selectedElement !== null ? 'block-shadow' : ''}` },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement("div", null,
                    Object.keys(affairs).map((id, idx) => {
                        return (React.createElement("div", { className: classnames('selection-element card-affaire-container', {
                                'card-affaire-container-active': data && data.id === id,
                            }), key: `selection-affaires-${idx}`, onClick: (_e) => {
                                if (_e.target.localName !== 'svg' &&
                                    _e.target.localName !== 'input' &&
                                    _e.target.localName !== 'polyline') {
                                    onSelect('affaires', id, 'selectedAffair', affairs[id]);
                                }
                            } },
                            React.createElement(FicheAffairLeftPanel, { affair: affairs[id], params: params, callback: loadData, hidden: user.isHidden, color: data && data.id === id ? 'darkblue' : 'lightpink' })));
                    }),
                    Object.keys(relances).map((id, idx) => {
                        return (React.createElement("div", { className: classnames('selection-element card-affaire-container', {
                                'card-affaire-container-active': data && data._id === id,
                            }), onClick: (_e) => {
                                if (_e.target.localName !== 'svg' &&
                                    _e.target.localName !== 'input' &&
                                    _e.target.localName !== 'polyline') {
                                    onSelect('relances', id, 'selectedRelance', relances[id]);
                                }
                            } },
                            React.createElement(RelanceElement, { relance: relances[id], key: `note-${idx}`, checkboxClassName: 'singleColumn flex flex-justifyEnd flex-alignStart w-100', callback: loadData, color: data && data._id === id ? 'darkblue' : 'lightblue' })));
                    }),
                    Object.keys(products).map((id, idx) => {
                        return (React.createElement("div", { className: classnames('selection-element card-affaire-container', {
                                'card-affaire-container-active': data && data.id === id,
                            }), key: `selection-products-${idx}`, onClick: (_e) => {
                                if (_e.target.localName !== 'svg' &&
                                    _e.target.localName !== 'input' &&
                                    _e.target.localName !== 'polyline') {
                                    onSelect('products', id, 'selectedProduct', products[id]);
                                }
                            } },
                            React.createElement(FicheProduit, { product: products[id], callback: loadData, color: data && data.id === id ? 'darkblue' : 'lightgray' })));
                    }),
                    Object.keys(rdvs).map((id, idx) => {
                        return (React.createElement("div", { className: classnames('selection-element card-affaire-container', {
                                'card-affaire-container-active': data && data._id === id,
                            }), key: `selection-products-${idx}` },
                            React.createElement(FicheAgendaLeftPanel, { key: `selection-products-${idx}`, rdv: rdvs[id], callback: loadData, idx: 0, color: data && data._id === id ? 'darkblue' : 'lightyellow', onClick: _e => {
                                    onSelect('rdvs', id, 'selectedRdv', rdvs[id]);
                                } })));
                    }),
                    Object.keys(anomalies).map((id, idx) => {
                        return (React.createElement("div", { className: classnames('selection-element card-affaire-container', {
                                'card-affaire-container-active': data && `${data.id}` === id,
                            }), key: `selection-anomalies-${idx}`, onClick: (_e) => {
                                if (_e.target.localName !== 'svg' &&
                                    _e.target.localName !== 'input' &&
                                    _e.target.localName !== 'polyline') {
                                    onSelect('anomalies', id, 'selectedAnomalie', anomalies[id]);
                                }
                            } },
                            React.createElement(FicheAnomalie, { anomalie: anomalies[id], callback: loadData, color: data && `${data.id}` === id ? 'darkblue' : 'lightpurple' })));
                    }),
                    Object.keys(notes).map((id, idx) => {
                        return (React.createElement("div", { className: classnames('selection-element card-affaire-container', {
                                'card-affaire-container-active': data && data._id === id,
                            }), key: `selection-notes-${idx}`, onClick: (_e) => {
                                if (_e.target.localName !== 'svg' &&
                                    _e.target.localName !== 'input' &&
                                    _e.target.localName !== 'polyline') {
                                    onSelect('notes', id, 'selectedNote', notes[id]);
                                }
                            } },
                            React.createElement(FicheNote, { mainNote: notes[id], idx: 0, callback: loadData, color: data && data._id === id ? 'darkblue' : 'lightblue', onClick: () => null })));
                    }),
                    Object.keys(contacts).map((id, idx) => {
                        return (React.createElement("div", { key: `contacts-${idx}`, style: { marginBottom: '10px' }, onClick: (_e) => {
                                if (_e.target.localName !== 'svg' &&
                                    _e.target.localName !== 'input' &&
                                    _e.target.localName !== 'polyline')
                                    onSelect('contacts', id, 'selectedContact', contacts[id]);
                            } },
                            React.createElement(FicheContact, { contact: contacts[id], color: data && type === 'contacts' && data.id === id ? 'darkblue' : 'lightgray' })));
                    }),
                    Object.keys(clients).map((id, idx) => {
                        return (React.createElement("div", { style: { marginBottom: '10px' }, key: `clients-${idx}`, onClick: (_e) => {
                                if (_e.target.localName !== 'svg' &&
                                    _e.target.localName !== 'input' &&
                                    _e.target.localName !== 'polyline')
                                    onSelect('clients', id, 'selectedClient', clients[id]);
                            } },
                            React.createElement(FicheClient, { client: clients[id], color: data && type === 'clients' && data.id === id ? 'darkblue' : 'lightgray' })));
                    })),
                React.createElement("div", { className: 'button delete mt-2', onClick: onDelete }, "X"))),
        React.createElement("div", { className: 'whflex-col rightPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Route, { path: "/main/selections/rightpanel/:type/:id/:tab", name: "rightpanel" },
                    React.createElement(RightPanelRoute, { data: data, isHidden: user.isHidden, type: type, setElement: setElement, user: user, reload: () => {
                            loadData();
                            if (type === 'anomalies') {
                                setElement(type, data.id.toString());
                            }
                        } }))),
            React.createElement(Footer, { user: user, humanFunctions: humanFunctions, selected: data, reload: () => {
                    loadData();
                    if (data && type === 'notes') {
                        setElement(type, data._id);
                    }
                    else if (type === 'anomalies') {
                        setElement(type, data.id.toString());
                    }
                }, type: type, disableElements: type ? footerDisabledElement[type] : ['relances', 'anomalies', 'notes', 'rdvs'] }))));
};
export default withRouter(Selection);
