import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Stock } from '../../modules/Layout/Produits/ProductPanel';
const ModalProduct = ({ isOpen, toggle, product }) => {
    return (React.createElement(Modal, { isOpen: isOpen, toggle: toggle },
        React.createElement(ModalBody, null,
            React.createElement("div", { className: "informationModal2", style: { height: '480px' } },
                React.createElement("div", { className: "whModal-header whModal-header--withSeparator singleColumn" },
                    React.createElement("p", { className: "text-medium text-w-regular lh-12" }, product && product.itemCode),
                    React.createElement("p", { className: "text-tiny text-w-light lh-20 mb-10" },
                        product && product.libelle,
                        " - ",
                        product && product.itemCode)),
                React.createElement("div", { className: "whModal-body" },
                    !product && React.createElement("div", null, "Produit non valide"),
                    product && React.createElement(Stock, { itemRef: product.itemCode, customer: product.cmd.costumer.ref })),
                React.createElement("div", { className: "whModal-footer" })))));
};
export default ModalProduct;
