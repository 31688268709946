import { useEffect, useState } from 'react';
import ApiService from '../../../utilities/apiService/ApiService';
import { KPIENDPOINT, OUTSTANDINGENDPOINT } from '../../../utilities/apiService/constant';
import moment from 'moment';
const useKpis = ({ saler, contact, costumer, type }) => {
    const [filters, setFilters] = useState(contact ? [{ id: 'contact_id', value: contact.id }] : []);
    const [bills, setBills] = useState(null);
    const [alreadyInitialize, setAlreadyInitialize] = useState(false);
    const [outstanding, setOutstanding] = useState({
        deliveredNotPayed: 0,
        evolCaBrut: [],
        evolCaNet: [],
        notPayed: 0,
        offerQty: 0,
        progressRate: 0,
        sellsQty: 0,
        toDelivered: 0,
        total: 0,
        totalYearBefore: 0,
    });
    const [caBrut, setCaBrut] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getFilters = () => {
        const filtered = [...filters];
        if (saler) {
            filtered.push({ id: 'saler_id', value: saler.id });
        }
        if (costumer) {
            filtered.push({ id: 'costumer_id', value: costumer.id });
        }
        return filtered;
    };
    const getBillFilters = () => {
        if (type === 'affaires' || type === 'anomalies') {
            if (costumer) {
                return [{ id: 'costumer.id', value: costumer.id }];
            }
            return [{ id: 'saler.id', value: saler.id }];
        }
        if (type === 'contacts' ||
            type === 'clients' ||
            type === 'relance' ||
            type === 'note' ||
            type === 'agenda' ||
            type === 'rdv') {
            console.log({ costumer });
            return [{ id: 'costumer.id', value: costumer.id }];
        }
        return [];
    };
    const getBills = async () => {
        const outstandingService = new ApiService(OUTSTANDINGENDPOINT);
        const outstanding = await outstandingService.search({
            filtered: [
                ...getBillFilters(),
                {
                    id: 'order[amountHtToPaid]',
                    value: 'desc',
                },
                {
                    id: 'dueDate[strictly_before]',
                    value: moment().format('YYYY-MM-DD'),
                },
                {
                    id: 'isSold',
                    value: 0,
                },
                { id: 'itemsPerPage', value: 3 },
            ],
        });
        setBills(outstanding['hydra:member']);
    };
    const getKpis = async () => {
        setIsLoading(true);
        const kpiService = new ApiService(KPIENDPOINT);
        const filters = getFilters();
        try {
            const res = await kpiService.search({ filtered: filters });
            if (!alreadyInitialize) {
                setOutstanding({
                    ...res,
                    toDelivered: res.toDelivered,
                    deliveredNotPayed: res.deliveredNotPayed,
                    notPayed: res.notPayed,
                    available: res.notPayed - res.toDelivered,
                });
            }
            setAlreadyInitialize(true);
            setCaBrut(res.evolCaBrut);
            setIsLoading(false);
        }
        catch (e) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getBills();
    }, []);
    useEffect(() => {
        getKpis();
    }, [filters]);
    const setFilters2 = (filtersData) => {
        setFilters(filtersData);
    };
    return {
        outstanding,
        isLoading,
        bills,
        caBrut,
        setFilters: setFilters2,
    };
};
export default useKpis;
