import * as storage from '../storage';
import ApiService from '../apiService/ApiService';
export const login = (data) => {
    const loginService = new ApiService(`${process.env.REACT_APP_IDENTITY_ENDPOINT}/`);
    return loginService
        .create(data)
        .then(response => {
        setJwtToken(response['token']);
    })
        .catch(exception => {
        throw exception;
    });
};
export const setJwtToken = jwtToken => {
    return storage.save('jwt_token', jwtToken);
};
export const deleteJwtToken = () => {
    return storage.destroy('jwt_token');
};
export const logout = () => {
    deleteJwtToken();
    return Promise.resolve('disconnected');
};
export const getJwtToken = () => {
    return storage.get('jwt_token');
};
