import { useEffect, useState } from "react";
import ApiService from "../../../utilities/apiService/ApiService";
import { ANOMALIECMDLINESENDPOINT } from "../../../utilities/apiService/constant";
import { useDispatch, useSelector } from "react-redux";
import { setAnomaliePanelReload } from "../../../../redux/reloader/action";
export const useCmdAnomalies = (affair) => {
    const [filters, setFilters] = useState(null);
    const [cmdAnomalies, setAffaires] = useState([]);
    const reload = useSelector((state) => state.reloader.anomaliePanelReload);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [selectedAnomalie, setSelectedAnomalie] = useState(undefined);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const getOneAnoamlies = (newData) => {
        if (selectedAnomalie) {
            const selected = newData.find((ano) => ano.id === selectedAnomalie.id);
            setSelectedAnomalie(selected);
        }
    };
    const fetchCmdAnomalies = async () => {
        const anomalyService = new ApiService(ANOMALIECMDLINESENDPOINT);
        setIsLoading(true);
        const results = await anomalyService.search({ filtered: [{ id: 'cmd.id', value: affair.id }].concat(filters), page: page });
        const totalItems = results['hydra:totalItems'];
        const data = results['hydra:member'];
        const newData = page > 1 ? [...cmdAnomalies, ...data] : data;
        getOneAnoamlies(newData);
        setHasNextPage(newData.length < totalItems);
        setIsLoading(false);
        setAffaires(newData);
    };
    const loadNextPage = () => {
        setPage(page + 1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        if (filters !== null) {
            setPage(1);
            setFireUseEffect(Math.random());
        }
    }, [filters]);
    useEffect(() => {
        if (!reload)
            return;
        setPage(1);
        setFireUseEffect(Math.random());
        dispatch(setAnomaliePanelReload(false));
    }, [reload]);
    useEffect(() => {
        fetchCmdAnomalies();
    }, [fireUseEffect]);
    return {
        cmdAnomalies,
        isLoading,
        selectedAnomalie,
        setSelectedAnomalie,
        hasNextPage,
        loadNextPage,
        setFilters,
    };
};
