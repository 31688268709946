import { useEffect, useState } from "react";
import ElasticService from "../../../utilities/apiService/ElasticService";
import { CONTACTLASTICENDPOINT, COSTUMERSENDPOINT } from "../../../utilities/apiService/constant";
import ApiService from "../../../utilities/apiService/ApiService";
export const useContact = () => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [fireUseEffect, setFireUseEffect] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState([]);
    const EsFormat = obj => {
        const { _meta, ...objWithoutMeta } = obj;
        let updatedObj = { ...objWithoutMeta };
        for (const prop in objWithoutMeta) {
            updatedObj = {
                ...updatedObj,
                [prop]: objWithoutMeta[prop].raw,
            };
        }
        return updatedObj;
    };
    const loadSingleContact = async (_id) => {
        const contactService = new ElasticService(CONTACTLASTICENDPOINT);
        const result = await contactService.search({
            query: "",
            filters: { "all": [{ "id": _id }] },
            page: { current: 1, size: 20 },
        });
        const results = result.results;
        if (results && results.length > 0) {
            const element = EsFormat(results[0]);
            const costumerService = new ApiService(COSTUMERSENDPOINT);
            if (element.costumer) {
                const costumer = await costumerService.get({ _id: element.costumer });
                setSelectedContact({ ...element, costumer: costumer });
            }
            else {
                setSelectedContact(element);
            }
        }
        return;
    };
    const loadContact = async () => {
        setIsLoading(true);
        const contactService = new ElasticService(CONTACTLASTICENDPOINT);
        const filter = filters.find(f => f.id === 'lastname');
        const result = await contactService.search({
            query: filter ? (filter.value ? filter.value : 1) : 1,
            page: { current: page, size: 20 },
        });
        const { current, total_pages } = result.meta.page;
        setHasNextPage(current < total_pages);
        const newResult = result.results.map(r => EsFormat(r));
        setIsLoading(false);
        setContacts(contacts && current > 1 ? [...contacts, ...newResult] : newResult);
    };
    const reloadData = () => {
        setPage(1);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setPage(page + 1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [filters]);
    useEffect(() => {
        if (!fireUseEffect)
            return;
        loadContact();
    }, [fireUseEffect]);
    return {
        contacts,
        isLoading,
        loadNextPage,
        loadSingleContact,
        selectedContact,
        setSelectedContact,
        hasNextPage,
        reloadData,
        setFilters,
    };
};
