import React from 'react';
import { Field } from 'react-final-form';
import { WhFieldSelect } from '../WhForm/WhFields';
import { FieldAsyncSelect } from '../WhForm/WhFields/FieldSelect';
const FFSelect = ({ validate, name, label, options, className = '' }) => {
    return (React.createElement(Field, { name: name, validate: validate }, props => {
        return React.createElement(WhFieldSelect, Object.assign({}, props, { label: label, className: className, options: options }));
    }));
};
export const FFAsyncSelect = ({ validate, formatEndpointResult, onChange = null, name, defaultFilter = [], searchFilter, label, placeholder = '', endpoint, format, className = '', classNamePrefix = '', disabled = false, }) => {
    return (React.createElement(Field, { name: name, validate: validate, disabled: disabled }, props => {
        return (React.createElement(FieldAsyncSelect, Object.assign({}, props, { onChange: onChange, label: label, formatEndpointResult: formatEndpointResult, classNamePrefix: classNamePrefix, className: className, searchFilter: searchFilter, defaultFilter: defaultFilter, placeholder: placeholder, endpoint: endpoint, format: format })));
    }));
};
export default FFSelect;
