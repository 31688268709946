import React from 'react';
const ModalContact = ({ onClose, data }) => {
    return (React.createElement("div", { style: { height: '600px' } },
        React.createElement("div", { className: "whModal darkkModal", style: { width: '300px' } },
            React.createElement("div", { className: "whModal-header singleColumn mb-15" },
                React.createElement("i", { className: "text-title text-color-green icon-adduser text-center" })),
            React.createElement("div", { className: "whModal-body" },
                React.createElement("div", { className: "whModal-bodyRow singleColumn " },
                    React.createElement("i", { className: "text-subtitle text-color-lightGray icon-entreprise text-center " }),
                    React.createElement("p", { className: "text-center text-big text-w-regular" },
                        data.socialReason ? data.socialReason : 'Non renseigné',
                        " ",
                        React.createElement("br", null),
                        React.createElement("a", { className: "text-color-white text-underline", href: "" },
                            data.address ? data.address : 'Non renseigné',
                            " ",
                            React.createElement("br", null),
                            data.zipCode && data.city ? `${data.zipCode} ${data.city}` : 'Non renseigné',
                            React.createElement("br", null)),
                        React.createElement("br", null),
                        React.createElement("a", { className: "text-color-white text-underline", href: "tel:+33145685632" }, data.phone ? data.phone : 'Non renseigné'))),
                data.contact && (React.createElement("div", { className: "whModal-bodyRow singleColumn mt-40 " },
                    React.createElement("i", { className: "text-subtitle text-color-lightGray icon-contact text-center " }),
                    React.createElement("p", { className: "text-center text-big text-w-regular" },
                        data.contact && data.contact.name ? data.contact.name : 'Non renseigné',
                        " :",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("a", { className: "text-color-white text-underline", href: `tel:+${data.contact && data.contact.phone}` }, data.contact && data.contact.phone ? data.contact.phone : 'Non renseigné')))),
                false && (React.createElement("div", { className: "whModal-bodyRow singleColumn mt-40 " },
                    React.createElement("i", { className: "text-subtitle text-color-lightGray icon-custom-apps text-center " }),
                    React.createElement("p", { className: "text-center text-big text-w-regular" },
                        "GUILLO : ",
                        React.createElement("br", null),
                        React.createElement("a", { className: "text-color-white text-underline", href: "" },
                            "325 av de la Republique",
                            React.createElement("br", null),
                            "92350 Le Plessis Robinson"))))),
            React.createElement("div", { className: "whModal-footer mt-25 flex-directionRowReverse flex-justifyStart flex-alignCenter" },
                React.createElement("div", { className: "whModal-btnContainer reverse" },
                    React.createElement("div", { onClick: onClose, className: "whModal-btn whModal-btn-secondary mr-15 text-regular" }, "Annuler"))))));
};
export default ModalContact;
