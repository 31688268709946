import { fetch } from '../fetch';
const endPoint = `${process.env.REACT_APP_IDENTITY_ENDPOINT}/api`;
const endPoints = {
    login: `${endPoint}/login_check`,
    passwordLost: `${endPoint}/lost-password`,
    passwordChange: `${endPoint}/change-password`,
    logout: `${endPoint}/logout`,
    me: `${endPoint}/me`,
};
export const login = (username, password) => fetch(endPoints.login, 'post', {
    username,
    password,
});
export const passwordLost = (username) => fetch(endPoints.passwordLost, 'post', { username });
export const passwordChange = (data) => fetch(endPoints.passwordChange, 'post', data);
export const logout = () => fetch(endPoints.logout, 'get');
export const me = () => fetch(endPoints.me, 'get');
