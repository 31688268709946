import React, { useState, Fragment, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);
export const DatePickerField = (props) => {
    const [startDate, setStartDate] = useState(props.value ? new Date(props.value) : null);
    const handleChange = date => {
        setStartDate(date);
    };
    useEffect(() => {
        if (startDate !== null && props.onChange)
            props.onChange(startDate);
    }, [startDate]);
    return (React.createElement(Fragment, null,
        React.createElement(DatePicker, { className: props.className ? props.className : "form__form-group-datepicker", selected: startDate, onChange: handleChange, dateFormat: "dd/MM/yyyy", placeholderText: props.placeholderText, locale: "fr" })));
};
const renderDatePickerField = (props) => {
    const { input, onChange } = props;
    return React.createElement(DatePickerField, Object.assign({}, input, { onChange: onChange }));
};
export default renderDatePickerField;
