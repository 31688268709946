import { createAction } from 'redux-actions';
import { uniqueArr } from '../../src/helper/uniqueArray';
import { RESET, SET_SELECTED_PRODUCT_FILTER, SET_SELECTED_PRODUCT_FILTER_OPTIONS, SET_SELECTED_PRODUCT_RELOAD_FILTER, } from './constant';
export const setSelectedProductFilterOptions = createAction(SET_SELECTED_PRODUCT_FILTER_OPTIONS, (salers, costumers) => {
    return { salers, costumers };
});
export const resetProductFilters = createAction(RESET);
export const setSelectedProductReloadFilter = createAction(SET_SELECTED_PRODUCT_RELOAD_FILTER);
export const setSelectedProductFilter = createAction(SET_SELECTED_PRODUCT_FILTER, filters => ({ filters }));
export const formatCmdLineForProductFilter = cmdLines => dispatch => {
    const costumers = cmdLines.map(cmdLine => ({
        label: cmdLine.cmd.costumer.socialReason,
        value: cmdLine.cmd.costumer.id,
    }));
    const salers = cmdLines.map(cmdLine => ({ label: cmdLine.cmd.saler.name, value: cmdLine.cmd.saler.id }));
    dispatch(setSelectedProductFilterOptions(uniqueArr(salers, 'label'), uniqueArr(costumers, 'label')));
};
