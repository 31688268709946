import React, { useEffect, useMemo, useState } from 'react';
import Moment from 'react-moment';
import Icon from '../../../components/Icon/Icon';
import * as Session from '../../../shared/session';
const FicheNotification = ({ notification, onClick, isRead = false, user }) => {
    const lastMessage = useMemo(() => {
        return notification.responses.reverse().find(item => item && item.from.email !== user.email);
    }, []);
    return (React.createElement("div", { className: `fichenotification ${isRead ? 'color-gray' : ''}`, onClick: () => onClick(notification) },
        React.createElement("div", { className: 'small-date time' },
            React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, lastMessage ? lastMessage.createdAt : notification.createdAt)),
        React.createElement("div", { className: 'subject' }, notification.subject),
        React.createElement("div", { className: 'body' }, notification.body)));
};
const Notifications = ({ readedNotifications, unreadedNotifications, onClickNotif, setOpenNotifications }) => {
    const [user, setUser] = useState();
    const getUserInfo = async () => {
        const newUser = await Session.getUser();
        setUser(newUser);
    };
    useEffect(() => {
        getUserInfo();
    }, []);
    return (React.createElement("div", { className: `whflex-col notificationPanel`, style: { backgroundColor: 'black' } },
        React.createElement("div", { className: 'notification-header' },
            React.createElement("div", { className: 'notification-icon-container' },
                React.createElement(Icon, { icon: `text-title icon-notifications ${unreadedNotifications.length > 0 ? 'color-darkred' : 'color-gray'}`, text: `${unreadedNotifications.length}` })),
            React.createElement("div", null, "Notification"),
            React.createElement("div", { className: 'cross', onClick: () => setOpenNotifications(false) }, "X")),
        React.createElement("div", null,
            user &&
                unreadedNotifications.map((notif, idx) => {
                    return (React.createElement(FicheNotification, { key: `unreadednotif-${idx}`, notification: notif, onClick: onClickNotif, isRead: false, user: user }));
                }),
            user &&
                readedNotifications.map((notif, idx) => {
                    return (React.createElement(FicheNotification, { key: `readednotif-${idx}`, notification: notif, onClick: onClickNotif, isRead: true, user: user }));
                }))));
};
export default Notifications;
