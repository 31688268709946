import React, { Fragment } from 'react';
import moment from 'moment';
import { FicheBody, FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import { formatMoment } from '../../../helper/momentHelper';
import Icon from '../../../components/Icon/Icon';
import { setNcIfNull } from '../../../helper/setNcIfNull';
import { setFooterRdv } from '../../../../redux/panelFooterModal/action';
import { useDispatch } from 'react-redux';
export const FicheAgendaRightPanel = ({ rdv, onEdit }) => {
    const { end, start, attendees, subject, organizer } = rdv;
    const meetingHour = `${moment.utc(start).format('HH:mm')} > ${moment.utc(end).format('HH:mm')}`;
    return (React.createElement(FicheContainer, { color: 'lightgray', className: 'card-panel-right', onClick: null },
        React.createElement(FicheContainer, { color: 'white', onClick: null },
            React.createElement(FicheBody, { columns: 'singleColumn', className: 'card-rdv mb-8' },
                React.createElement("div", { className: 'card-rdv-header' },
                    React.createElement("div", null,
                        formatMoment(start),
                        React.createElement("span", { className: "sep" }),
                        meetingHour),
                    React.createElement(Icon, { icon: 'icon-utilisateur', text: setNcIfNull(organizer.displayName), bold: true, className: 'author' }),
                    React.createElement(Icon, { icon: 'icon-edit', className: 'withPointerEvents cursorPointer edit', onClick: onEdit }))),
            React.createElement(FicheBody, { columns: 'singleColumn' },
                React.createElement(Icon, { icon: 'icon-entreprise', text: setNcIfNull(subject.costumer.name), bold: true, className: 'mb-8' }),
                React.createElement("div", { className: 'attendees-list' }, attendees && attendees.length > 0 &&
                    attendees.map((a) => {
                        return (React.createElement(Icon, { icon: ' icon-utilisateur', text: a &&
                                a.hasOwnProperty('displayName')
                                ? a.displayName
                                : 'NC', bold: true, className: 'mb-8' }));
                    })),
                React.createElement("div", null, `${setNcIfNull(subject.costumer.adressRow1)} ${setNcIfNull(subject.costumer.zipCode)} ${setNcIfNull(subject.costumer.city)}`)))));
};
export const FicheAgendaPanelWithModal = ({ rdv }) => {
    const dispatch = useDispatch();
    const attendeesDefaultValue = {
        organizer: false,
        self: false,
        optional: true,
        responseStatus: 'accepted',
    };
    const attendees = rdv.attendees
        .filter(f => f !== null)
        .map(a => ({
        label: a.displayName,
        value: a.id,
        all: { ...attendeesDefaultValue, ...a },
    }));
    const initialValues = {
        ...rdv,
        company: { label: rdv.subject.costumer.displayName, value: rdv.subject.costumer.id, all: rdv.subject.costumer },
        attendees: attendees.length === 0 ? [''] : attendees,
        date: rdv.start,
        startDate: moment.utc(rdv.start).format('HH:mm'),
        endDate: moment.utc(rdv.end).format('HH:mm'),
    };
    const onEdit = () => {
        dispatch(setFooterRdv(true, initialValues));
    };
    return (React.createElement(Fragment, null,
        React.createElement(FicheAgendaRightPanel, { rdv: rdv, onEdit: onEdit })));
};
