import React, { useState, Fragment } from 'react';
import { Form } from 'react-final-form';
import FFTextArea from '../../../components/FinalForm/FFTextArea';
import { required } from '../../../components/WhForm/WhFields/Validator';
import { SALERSENDPOINT } from '../../../utilities/apiService/constant';
import { FFAsyncSelect } from '../../../components/FinalForm/FFSelect';
import { formatDate, submitRelance, updateRelance } from '../../../components/RightPanelModal/ModalService';
import { Field } from 'react-final-form';
import Loading from '../../../shared/Loading';
const ModalRelance = ({ onClose, user, initialValues, affair, reload, isUpdate }) => {
    const [userSaler] = useState({
        label: user.take.name,
        id: user.take.id,
        value: user.take['@id'],
        all: { ...user.take, id: `${user.take.id}` },
    });
    const [isLoading, setIsLoading] = useState(false);
    const onUpdate = data => {
        const { _id, creator, organizer, receiverMe, receiver, comments, status, cmd, answers } = data;
        const { start, end } = formatDate(data);
        const postData = {
            _id,
            start,
            end,
            creator,
            organizer: organizer,
            status: status,
            type: 'relance',
            note: comments,
            subject: {
                cmd: cmd,
                answers: answers,
                saler: receiverMe
                    ? { ...receiverMe.all, id: `${receiverMe.all.id}` }
                    : { ...receiver.all, id: `${receiver.all.id}` },
            },
        };
        setIsLoading(true);
        return updateRelance(postData).then(() => {
            setIsLoading(false);
            if (reload)
                reload();
            onClose();
        });
    };
    const onSubmit = (data) => {
        setIsLoading(true);
        return submitRelance(data, affair).then(() => {
            setIsLoading(false);
            if (reload)
                reload();
            onClose();
        });
    };
    const formatSaler = obj => {
        return ({
            label: obj.name ? obj.name : 'NC',
            id: obj.id,
            value: obj['@id'],
            all: { ...obj, id: `${obj.id}` },
        });
    };
    const receiverMutator = {
        setMe: (_args, state, utils) => {
            utils.changeValue(state, 'receiver', () => null);
            utils.changeValue(state, 'receiverMe', () => userSaler);
        },
        setReceiver: (_args, state, utils) => {
            utils.changeValue(state, 'receiverMe', () => null);
            utils.changeValue(state, 'receiver', () => _args[0]);
        },
    };
    const getMeOrClient = () => {
        return { receiverMe: userSaler, receiver: null };
    };
    return (React.createElement(Form, { onSubmit: isUpdate ? onUpdate : onSubmit, validate: values => {
            const errors = {};
            if (!values.receiverMe && !values.receiver) {
                errors.receiver = 'Obligatoire';
                errors.receiverMe = 'Obligatoire';
            }
            return errors;
        }, mutators: { ...receiverMutator }, initialValues: initialValues
            ? initialValues
            : {
                creator: { id: user.id, email: user.email, displayName: user.name },
                organizer: { id: user.id, email: user.email, displayName: user.name },
                comments: '',
                client: null,
                ...getMeOrClient(),
                status: 'OPEN',
                cmd: null,
                date: new Date(),
                startDate: new Date(),
                endDate: new Date(),
            }, render: ({ handleSubmit, values, form: { mutators } }) => {
            const { receiverMe } = values;
            return (React.createElement("form", { onSubmit: handleSubmit },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                React.createElement("div", { className: "whModal darkkModal", style: { width: '536px' } },
                    React.createElement("div", { className: "whModal-header singleColumn mb-15" },
                        React.createElement("i", { className: "text-title text-color-green icon-relances text-center" })),
                    React.createElement("div", { className: "whModal-body flex-alignCenter" },
                        React.createElement(FFTextArea, { validate: required, name: 'comments', className: 'text-color-gray text-left w-100', label: 'Commentaire' }),
                        React.createElement("div", { className: "whModal-btnContainer grid dualColumns mt-25" },
                            React.createElement(Field, { name: 'receiverMe' }, ({ meta }) => {
                                return (React.createElement(Fragment, null,
                                    React.createElement("div", { onClick: () => mutators.setMe(userSaler), className: `whModal-btn  whModal-btn--small me ${receiverMe ? 'whModal-btn-primary' : 'whModal-btn-secondary'} text-regular` }, "Moi"),
                                    meta.error && meta.touched && React.createElement("span", null, meta.error)));
                            }),
                            React.createElement(FFAsyncSelect, { format: formatSaler, onChange: value => mutators.setReceiver(value), className: 'whModal-select whModal-btn', classNamePrefix: 'modal_select', defaultFilter: [{ id: 'human', value: true }], searchFilter: { id: 'lastname', value: null }, name: 'receiver', endpoint: SALERSENDPOINT, formatEndpointResult: res => {
                                    return res.filter(r => r.id !== userSaler.id);
                                }, placeholder: 'Collaborateurs', label: '' }))),
                    React.createElement("div", { className: "whModal-footer mt-25 flex-directionRowReverse flex-justifyStart flex-alignCenter" },
                        React.createElement("div", { className: "whModal-footer mt-25 flex-directionRowReverse flex-justifyStart flex-alignCenter" },
                            React.createElement("div", { className: "whModal-btnContainer reverse" },
                                React.createElement("button", { type: 'submit', className: "whModal-btn whModal-btn-primary text-regular" }, "Enregistrer"),
                                React.createElement("div", { onClick: onClose, className: "whModal-btn whModal-btn-secondary mr-15 text-regular" }, "Annuler")))))));
        } }));
};
export default ModalRelance;
