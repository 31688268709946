import React, { Fragment } from 'react';
import Loading from '../../../../shared/Loading';
import { NotePanelElement } from '../../Notes/NotePanel';
import { useCustomerNotes } from './useCustomerNotes';
import InfiniteScroll from '../../../../components/InfiniteScroll/InfiniteScroll';
const CustomerNotes = ({ data, user, mainNote, rightPanelRef, isCustomerNote = true }) => {
    const getId = () => {
        if (!data)
            return null;
        if (data.hasOwnProperty('@type') && data['@type'] === 'Cmd') {
            return data.id;
        }
        if (data.hasOwnProperty('subject')) {
            return data.subject.hasOwnProperty('costumer') ? data.subject.costumer.id : data.subject.cmd.id;
        }
        if (data.hasOwnProperty('type') && data.type === 'cmd') {
            return data.id;
        }
        if (data.hasOwnProperty('@type') && data['@type'] === 'AnomalyCmdLine')
            return data.cmd.id;
        return null;
    };
    const { customerNotes: notes, isLoading, validateNote, hasNextPage, loadNextPage, reloadData } = useCustomerNotes(getId(), isCustomerNote);
    const getWidth = () => {
        if (!rightPanelRef)
            return 0;
        if (!rightPanelRef.current)
            return 0;
        return rightPanelRef.current.clientWidth + 20;
    };
    console.log({ notes });
    return (React.createElement(Fragment, null,
        isLoading && React.createElement(Loading, { withoutLogo: false }),
        !isLoading && notes.length === 0 && React.createElement("div", { style: { padding: '10px' } }, "Pas de notes"),
        React.createElement(InfiniteScroll, { data: mainNote ? [mainNote, ...notes] : notes, hasNextPage: hasNextPage, loadNextPage: loadNextPage, rowHeight: 200, height: '100%', width: getWidth(), isNextPageLoading: isLoading, component: ({ index }) => {
                return (React.createElement(Fragment, null,
                    React.createElement(NotePanelElement, { n: notes[index], validateNote: validateNote, user: user, reload: reloadData })));
            } }),
        notes && notes.length === 0 && React.createElement("div", null, "Aucun r\u00E9sultat")));
};
export default CustomerNotes;
