import React, { useState, Fragment, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import Icon from '../../components/Icon/Icon';
import AffairPanel from './Affaires/AffairePanel';
import AnomalieCmd, { SingleAnomalie } from './NewAnomalies/AnomalieCmd';
import ContactPanel from './Contacts/ContactPanel';
import ClientPanel from './Clients/ClientPanel';
import ProductPanel, { Classification, Stock } from './Produits/ProductPanel';
import { FicheAgendaPanelWithModal } from './Agenda/FicheAgendaRightPanel';
import CustomerAffairs from './RightPanel/CustomerAffairs/CustomerAffairs';
import CustomerNotes from './RightPanel/CustomerNotes/CustomerNotes';
import { FicheSyntheseTab } from './Syntheses/FicheSyntheses';
import { NotePanelElement } from './Notes/NotePanel';
import ProductCmdLine from './Produits/ProductCmdLine';
import { RelanceCmd } from './Relances/CmdRelance';
import RightPanelRelances from './Relances/RightPanelRelances';
import { SelectedProductFilters } from './Produits/SelectedProductFilters';
import ApiService from '../../utilities/apiService/ApiService';
import { GCALENDARENDPOINT } from '../../utilities/apiService/constant';
import { useDispatch, useSelector } from 'react-redux';
import { resetProductFilters } from '../../../redux/productFilters/action';
const Tabs = ({ type, selected, onSelect }) => {
    const [selectedTab, setSelectedTab] = useState(selected);
    useEffect(() => {
        setSelectedTab(selected);
    }, [selected]);
    const config = {
        anomalies: [
            { name: 'selectedAnomalie', icon: 'icon-anomalies' },
            { name: 'noteCmd', icon: 'icon-note' },
            { icon: 'icon-affaire', name: 'affairAnomalie' },
            { name: 'syntheses', icon: 'icon-business' },
        ],
        relances: [
            { name: 'selectedRelance', icon: 'icon-relances' },
            { icon: 'icon-affaire', name: 'cmdRelance' },
            { name: 'noteCmd', icon: 'icon-note' },
            { name: 'anomalieCmd', icon: 'icon-anomalies' },
            { name: 'syntheses', icon: 'icon-business' },
        ],
        affaires: [
            { name: 'selectedAffair', icon: ' icon-affaire' },
            { name: 'noteCmd', icon: 'icon-note' },
            { name: 'relanceCmd', icon: 'icon-relances' },
            { name: 'anomalies', icon: 'icon-anomalies' },
            { name: 'syntheses', icon: 'icon-business' },
        ],
        notes: [
            { name: 'selectedNote', icon: 'icon-note' },
            { icon: 'icon-affaire', name: 'cmdNote' },
            { name: 'anomalieCmd', icon: 'icon-anomalies' },
            { name: 'syntheses', icon: 'icon-business' },
        ],
        rdvs: [
            { name: 'selectedRdv', icon: 'icon-Agenda' },
            { icon: 'icon-liste-affaires', name: 'customerAffairs' },
            { icon: 'icon-note', name: 'customerNotes' },
            { name: 'syntheses', icon: 'icon-business' },
        ],
        products: [
            { name: 'selectedProduct', icon: 'icon-article' },
            { name: 'productAffairsDetails', icon: 'icon-liste-affaires' },
        ],
        clients: [
            { name: 'selectedClient', icon: 'icon-entreprise' },
            { name: 'syntheses', icon: 'icon-business' },
        ],
        contacts: [
            { name: 'selectedContact', icon: 'icon-contact' },
            { name: 'syntheses', icon: 'icon-business' },
        ],
    };
    return (React.createElement("ul", { className: 'folder-list' }, config[type].map((t, idx) => {
        return (React.createElement("li", { key: `tab_${idx}`, className: `${selectedTab === t.name ? 'folder-list--selected' : ''}`, onClick: () => {
                setSelectedTab(`${t.name}`);
                onSelect(t.name);
            } },
            React.createElement(Icon, { icon: t.icon })));
    })));
};
const RightPanelRoute = ({ data, history, match, type, setElement, user, isHidden, reload }) => {
    const rightPanelRef = useRef(null);
    const dispatch = useDispatch();
    const footer = useSelector((state) => state.footer);
    const linkToTab = path => {
        if (path === 'productAffairsDetails' || path === 'selectedProduct') {
            dispatch(resetProductFilters());
        }
        const urlTemplate = match.path.split('/');
        const tabIdx = urlTemplate.findIndex(el => el === ':tab');
        const urls = match.url.split('/');
        let newUrl = '';
        urls.map((e, idx) => {
            if (idx === tabIdx)
                newUrl += `/${path}`;
            else
                newUrl += idx === 0 ? `${e}` : `/${e}`;
        });
        history.push(`${newUrl}`);
    };
    const getSyntheseData = () => {
        if (match.params.type === 'notes') {
            return {
                saler: data.subject.cmd.saler,
                salerName: data.subject.cmd.saler.name,
                contact: data.subject.cmd.contact,
                costumer: data.subject.cmd.costumer,
                type: 'note',
                socialReason: data.subject.cmd.costumer.socialReason,
                cmd: data.subject.cmd,
            };
        }
        if (match.params.type === 'relances') {
            return {
                saler: data.subject.cmd.saler,
                salerName: data.subject.cmd.saler.name,
                contact: data.subject.cmd.contact,
                costumer: data.subject.cmd.costumer,
                type: 'note',
                socialReason: data.subject.cmd.costumer.socialReason,
                cmd: data.subject.cmd,
            };
        }
        if (match.params.type === 'rdvs') {
            return {
                saler: data.subject.costumer.saler,
                salerName: data.subject.costumer.saler && data.subject.costumer.saler.name
                    ? data.subject.costumer.saler.name
                    : null,
                contact: null,
                costumer: data.subject.costumer,
                type: 'rdv',
                socialReason: data.subject.costumer.socialReason,
                cmd: null,
            };
        }
        if (match.params.type === 'affaires')
            return {
                saler: data.saler,
                salerName: data.saler && data.saler ? data.saler.name : null,
                contact: data.contact,
                costumer: data.costumer,
                type: match.params.type,
                cmd: data,
                socialReason: data.costumer.socialReason,
            };
        if (match.params.type === 'anomalies') {
            return {
                saler: data.cmd ? data.cmd.saler : null,
                salerName: data.cmd && data.cmd.saler ? data.cmd.saler.name : null,
                contact: data.cmd ? data.cmd.contact : null,
                costumer: data.cmd ? data.cmd.costumer : null,
                type: match.params.type,
                cmd: data.cmd,
                socialReason: data.cmd ? data.cmd.costumer.socialReason : null,
            };
        }
        if (match.params.type === 'contacts')
            return {
                saler: data.costumer ? data.costumer.saler : null,
                salerName: `${data.last_name} ${data.first_name}`,
                contact: data,
                defaultsyntheseFilter: data.costumer
                    ? { id: data.costumer.id, name: `${data.last_name} ${data.first_name}` }
                    : null,
                costumer: data.costumer,
                type: match.params.type,
                cmd: null,
                socialReason: data.social_reason,
            };
        if (match.params.type === 'clients')
            return {
                saler: null,
                salerName: `${data.last_name} ${data.first_name}`,
                contact: null,
                costumer: data,
                type: match.params.type,
                cmd: null,
                socialReason: data.social_reason,
            };
        return data;
    };
    useEffect(() => {
        const urlTemplate = match.path.split('/');
        const selectionIdx = urlTemplate.findIndex(el => el === 'selections');
        if (selectionIdx > -1) {
            if (setElement)
                setElement(match.params.type, match.params.id);
        }
        else {
            if (setElement)
                setElement(match.params.id);
        }
    }, [match.url]);
    if (!data)
        return (React.createElement(Fragment, null,
            React.createElement("div", null, "Pas de donn\u00E9es")));
    const validateNote = _id => {
        const Service = new ApiService(GCALENDARENDPOINT);
        return Service.patch({ _id, status: 'fixed' })
            .then(() => {
            reload();
        })
            .catch(() => {
            console.log('::ValidationEchec');
        });
    };
    console.log({ tab: match.params.tab });
    return (React.createElement("div", { ref: rightPanelRef, style: { height: '100%' } },
        match.params.tab === 'productAffairsDetails' && React.createElement(SelectedProductFilters, { key: match.url }),
        match.params.tab === 'selectedProduct' && React.createElement(SelectedProductFilters, { key: match.url }),
        React.createElement(Tabs, { type: type, selected: match.params.tab, onSelect: linkToTab }),
        data && match.params.tab === 'selectedRelance' && (React.createElement(Fragment, null,
            React.createElement(RightPanelRelances, { relance: data, reload: reload, user: user }))),
        data && match.params.tab === 'selectedRdv' && (React.createElement(Fragment, null,
            React.createElement(FicheAgendaPanelWithModal, { rdv: data, user: user, reload: reload }))),
        match.params.tab === 'selectedAffair' && (React.createElement(Fragment, null, data && React.createElement(AffairPanel, { affair: data, isHidden: footer.isLocked }))),
        match.params.tab === 'selectedNote' && (React.createElement(Fragment, null, data && React.createElement(NotePanelElement, { n: data, validateNote: validateNote, user: user, reload: reload }))),
        data && match.params.tab === 'cmdNote' && (React.createElement(Fragment, null,
            React.createElement(AffairPanel, { affair: data.subject.cmd, isHidden: footer.isLocked }))),
        data && match.params.tab === 'cmdRelance' && (React.createElement(Fragment, null,
            React.createElement(AffairPanel, { affair: data.subject.cmd, isHidden: footer.isLocked }))),
        match.params.tab === 'syntheses' && React.createElement(FicheSyntheseTab, { data: getSyntheseData() }),
        data && match.params.tab === 'customerAffairs' && (React.createElement(Fragment, null, React.createElement(CustomerAffairs, { data: data, withFilter: true, rightPanelRef: rightPanelRef, isHidden: footer.isLocked }))),
        data && match.params.tab === 'affairAnomalie' && (React.createElement(Fragment, null, data && React.createElement(AffairPanel, { affair: data.cmd, isHidden: footer.isLocked }))),
        data && match.params.tab === 'customerNotes' && (React.createElement(Fragment, null, React.createElement(CustomerNotes, { data: data, user: user, mainNote: null, rightPanelRef: rightPanelRef, isCustomerNote: true }))),
        data && match.params.tab === 'noteCmd' && (React.createElement(Fragment, null, React.createElement(CustomerNotes, { data: data, user: user, mainNote: null, rightPanelRef: rightPanelRef, isCustomerNote: false }))),
        data && match.params.tab === 'selectedRdv' && (React.createElement(Fragment, null,
            React.createElement(FicheAgendaPanelWithModal, { rdv: data, user: user, reload: () => null }))),
        match.params.tab === 'anomalieCmd' && (React.createElement(Fragment, null, data && React.createElement(AnomalieCmd, { affair: data.subject.cmd, user: user }))),
        match.params.tab === 'anomalies' && (React.createElement(Fragment, null, data && React.createElement(AnomalieCmd, { affair: data, user: user }))),
        data && match.params.tab === 'selectedContact' && (React.createElement(Fragment, null,
            React.createElement(ContactPanel, { contact: data }))),
        data && match.params.tab === 'selectedClient' && (React.createElement(Fragment, null,
            React.createElement(ClientPanel, { client: data }))),
        data && match.params.tab === 'selectedProduct' && (React.createElement(Fragment, null,
            React.createElement(ProductPanel, { product: data }),
            React.createElement(Stock, { itemRef: data.ref }),
            React.createElement(Classification, null))),
        data && match.params.tab === 'selectedAnomalie' && (React.createElement(Fragment, null, data && React.createElement(SingleAnomalie, { anomalie: data, user: user, reload: reload }))),
        data && match.params.tab === 'productAffairsDetails' && (React.createElement(Fragment, null,
            React.createElement(ProductCmdLine, { product: data, hidden: footer.isLocked }))),
        data && match.params.tab === 'relanceCmd' && React.createElement(RelanceCmd, { user: user, cmd: data })));
};
export default withRouter(RightPanelRoute);
