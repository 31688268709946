import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiService from '../../../utilities/apiService/ApiService';
import { CMDENDPOINT, KPIENDPOINT, OUTSTANDINGENDPOINT, ANOMALIESENDPOINT, GCALENDARENDPOINT, } from '../../../utilities/apiService/constant';
import moment from 'moment';
export const useDashboard = () => {
    const [affairs, setAffairs] = useState([]);
    const [anomalies, setAnomalies] = useState([]);
    const [rdvs, setRdvs] = useState([]);
    const [notes, setNotes] = useState([]);
    const [outstanding, setOutstanding] = useState(null);
    const [biggestOutstanding, setBiggestOutstanding] = useState([]);
    const user = useSelector((state) => state.user.data);
    useEffect(() => {
        (async function () {
            const affairService = new ApiService(CMDENDPOINT);
            const affairs = await affairService.search({
                filtered: [
                    { id: 'saler.id', value: user.id },
                    { id: 'itemsPerPage', value: 4 },
                    { id: 'page', value: 1 },
                    { id: 'type', value: 'cmd' },
                    { id: 'order[orderAt]', value: 'desc' },
                ],
            });
            setAffairs(affairs['hydra:member']);
        })();
    }, []);
    useEffect(() => {
        (async function () {
            const anomalyService = new ApiService(ANOMALIESENDPOINT);
            const anomalies = await anomalyService.search({
                filtered: [
                    {
                        id: 'order.updatedAt',
                        value: 'desc',
                    },
                    { id: 'hasAnomaly', value: true },
                    { id: 'itemsPerPage', value: 4 },
                ],
            });
            setAnomalies(anomalies['hydra:member']);
        })();
    }, []);
    useEffect(() => {
        (async function () {
            const noteService = new ApiService(GCALENDARENDPOINT);
            const notes = await noteService.search({
                filtered: [
                    { id: 'type', value: 'note' },
                    { id: '$limit', value: 4 },
                ],
            });
            setNotes(notes.data);
        })();
    }, []);
    useEffect(() => {
        (async function () {
            const rdvService = new ApiService(GCALENDARENDPOINT);
            const todayDate = new Date();
            todayDate.setHours(0, 0, 0, 0);
            const rdvs = await rdvService.search({
                filtered: [
                    { id: 'type', value: 'appointment' },
                    { id: '$limit', value: 4 },
                    { id: 'start[$gte]', value: moment(todayDate).format('YYYY-MM-DD') },
                ],
            });
            setRdvs(rdvs.data);
        })();
    }, []);
    const getKpis = async () => {
        const kpiService = new ApiService(KPIENDPOINT);
        try {
            const res = await kpiService.search({
                filtered: [{ id: 'saler_id', value: user.take.id }],
            });
            setOutstanding({
                ...res,
                toDelivered: res.toDelivered,
                deliveredNotPayed: res.deliveredNotPayed,
                notPayed: res.notPayed,
                available: res.notPayed - res.toDelivered,
            });
        }
        catch (e) {
            console.log('Error while fetching kpis::', e);
        }
    };
    useEffect(() => {
        getKpis();
    }, []);
    useEffect(() => {
        (async function () {
            const outstandingService = new ApiService(OUTSTANDINGENDPOINT);
            const outstanding = await outstandingService.search({
                filtered: [
                    { id: 'saler.id', value: user.take.id },
                    {
                        id: 'order[amountHtToPaid]',
                        value: 'desc',
                    },
                    {
                        id: 'newDueDate[strictly_before]',
                        value: new Date(),
                    },
                    { id: 'itemsPerPage', value: 3 },
                ],
            });
            setBiggestOutstanding(outstanding['hydra:member']);
        })();
    }, []);
    return {
        affairs,
        anomalies,
        outstanding,
        biggestOutstanding,
        rdvs,
        notes,
    };
};
