import React from 'react';
import { useTable } from 'react-table';
const Table = ({ columns, data, className = "", rowClassName, onClickRow = null }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, } = useTable({
        columns,
        data,
    });
    return (React.createElement("table", Object.assign({ className: className }, getTableProps()),
        React.createElement("thead", null, headerGroups.map(headerGroup => (React.createElement("tr", Object.assign({}, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(column => (React.createElement("th", Object.assign({}, column.getHeaderProps()), column.render('Header')))))))),
        React.createElement("tbody", Object.assign({}, getTableBodyProps()), rows.map((row, idx) => {
            prepareRow(row);
            return (React.createElement("tr", Object.assign({}, row.getRowProps(), { className: rowClassName, onClick: onClickRow ? () => onClickRow(idx) : () => null }), row.cells.map(cell => {
                return React.createElement("td", Object.assign({}, cell.getCellProps()), cell.render('Cell'));
            })));
        }))));
};
export default Table;
