import React, { useState, useEffect, useRef } from 'react';
import echarts from 'echarts';
const EchartCaNet = ({ data, bfa }) => {
    const [chart, setChart] = useState(null);
    useEffect(() => {
        setChart(echarts.init(document.getElementById(`graphCaNetCostumer`)));
    }, []);
    const chartsContainer = useRef(null);
    const getBfa = () => {
        return bfa;
        const size = data.length;
        const last = data[size - 1];
        const value = last.value;
        if (bfa > value)
            return value;
        return bfa;
    };
    const formatBFa = () => {
        if (bfa >= 1000)
            return `${bfa / 1000}M €`;
        return `${bfa} €`;
    };
    useEffect(() => {
        if (!chart)
            return;
        const dataX = [];
        const dataY = [];
        data.map(el => {
            dataX.push(el.date);
            dataY.push(el.value);
            return null;
        }, []);
        const option = {
            grid: {
                right: '10px',
                left: '40px',
                top: '10px',
                bottom: '20px',
            },
            tooltip: {
                trigger: 'axis',
                formatter: '<div class="toolTip"><div>{c0}€</div></</div>',
                padding: 0,
                zIndex: 100,
                position: function (pt) {
                    return [pt[0], '0%'];
                },
            },
            xAxis: {
                type: 'category',
                data: dataX,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#c8c7cc',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCC',
                    },
                },
                axisLabel: {
                    fontSize: 10,
                    color: '#7c8187',
                },
            },
            yAxis: {
                type: 'value',
                show: false,
                max: bfa + 10000,
            },
            lineStyle: { color: '#' },
            series: [
                {
                    data: dataY,
                    type: 'line',
                    clip: true,
                    showSymbol: false,
                    smooth: true,
                    lineStyle: { color: '#505862' },
                    markLine: {
                        data: [
                            {
                                yAxis: getBfa(),
                                xAxis: 50,
                                symbol: 'none',
                                label: {
                                    position: 'middle',
                                    formatter: formatBFa(),
                                },
                                lineStyle: {
                                    normal: {
                                        color: '#4690ef',
                                    },
                                },
                            },
                        ],
                    },
                },
            ],
        };
        chart.setOption(option, true);
    }, [chart]);
    const getWidth = () => {
        return chartsContainer.clientWidth;
    };
    return (React.createElement("div", null,
        React.createElement("div", { id: `graphCaNetCostumer`, style: { width: getWidth(), height: '200px' } })));
};
export default EchartCaNet;
