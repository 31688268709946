import React from 'react';
const SearchElement = ({ element, onSelect }) => {
    return (React.createElement("li", { className: "searchModal-entry", onClick: () => onSelect(element) },
        React.createElement("i", { className: "icon-article" }),
        React.createElement("p", { className: "text-big text-w-light" },
            React.createElement("span", { className: "searchModal-focus" }),
            element.libelle)));
};
const SearchCurrent = ({ results, onSelect }) => {
    return (React.createElement("ul", { className: "searchModal-entries" }, results.map((res, idx) => (React.createElement(SearchElement, { key: `search_current_${idx}`, element: res, onSelect: onSelect })))));
};
export default SearchCurrent;
