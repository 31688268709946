import { useEffect, useState } from 'react';
import ApiService from '../../../utilities/apiService/ApiService';
import { GCALENDARENDPOINT } from '../../../utilities/apiService/constant';
import moment from 'moment';
export const useRdvs = () => {
    const [filters, setFilters] = useState(null);
    const [mode, setMode] = useState('present');
    const [rdvs, setRdvs] = useState([]);
    const [pastRdvs, setPastRdvs] = useState([]);
    const [skip, setSkip] = useState(0);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [hasNextPage, setHasNextPage] = useState(false);
    const [selectedRdv, setSelectedRdv] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const getDate = () => {
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        const tomorrow = new Date(todayDate);
        const after = new Date(todayDate);
        tomorrow.setDate(tomorrow.getDate() + 1);
        after.setDate(after.getDate() + 2);
        const res = {
            present: { id: 'start[$gte]', value: moment(todayDate).format('YYYY-MM-DD') },
            past: { id: 'start[$lt]', value: moment(todayDate).format('YYYY-MM-DD') },
        };
        return res[mode];
    };
    const formatFilters = () => {
        if (!filters)
            return [{ id: 'type', value: 'appointment' }].concat(getDate());
        const eq = {
            costumer: 'subject.costumer.id',
            saler: 'subject.cmd.saler.id',
            'createdAt[after]': 'createdAt[$gte]',
            'createdAt[before]': 'createdAt[$lte]',
        };
        return filters
            .map(f => ({ id: eq[f.id] ? eq[f.id] : f.id, value: f.value }))
            .concat({ id: 'type', value: 'appointment' })
            .concat(getDate());
    };
    const getOneRdv = async (id) => {
        const agendaService = new ApiService(GCALENDARENDPOINT);
        const agenda = await agendaService.get({ _id: id });
        setSelectedRdv(agenda);
    };
    const fetchRdvs = async () => {
        const agendaService = new ApiService(GCALENDARENDPOINT);
        setIsLoading(true);
        const results = await agendaService.search({
            filtered: formatFilters(),
            sort: { start: mode === 'past' ? -1 : 1 },
            skip: skip,
            limit: 30,
        });
        const totalItems = results.total;
        const data = results.data;
        let newData = [];
        if (mode === 'present') {
            newData = skip > 0 ? [...rdvs, ...data] : data;
            setSkip(newData.length);
            setHasNextPage(newData.length < totalItems);
            if (newData.length >= totalItems) {
                setMode('past');
                setSkip(0);
                if (newData.length === 0) {
                    setRdvs([]);
                    setFireUseEffect(Math.random());
                }
                setHasNextPage(true);
            }
            setRdvs(newData);
        }
        else {
            newData = skip > 0 ? [...pastRdvs, ...data] : data;
            setSkip(newData.length);
            setHasNextPage(newData.length < totalItems);
            setPastRdvs(newData);
        }
        setIsLoading(false);
    };
    const reloadData = () => {
        if (selectedRdv) {
            getOneRdv(selectedRdv._id);
        }
        setMode('present');
        setRdvs([]);
        setPastRdvs([]);
        setSkip(0);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setMode('present');
        setRdvs([]);
        setPastRdvs([]);
        setSkip(0);
        setFireUseEffect(Math.random());
    }, [filters]);
    useEffect(() => {
        fetchRdvs();
    }, [fireUseEffect]);
    return {
        rdvs,
        pastRdvs,
        isLoading,
        hasNextPage,
        getOneRdv,
        loadNextPage,
        reloadData,
        setFilters,
        setSelectedRdv,
        selectedRdv,
    };
};
