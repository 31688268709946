import { useState, useEffect } from 'react';
export const useSelection = () => {
    const [affairs, setAffairs] = useState({});
    const [relances, setRelances] = useState({});
    const [clients, setClients] = useState({});
    const [contacts, setContacts] = useState({});
    const [rdvs, setRdvs] = useState({});
    const [products, setProducts] = useState({});
    const [anomalies, setAnomalies] = useState({});
    const [notes, setNotes] = useState({});
    const [reload, setReload] = useState(false);
    const loadData = () => {
        console.log('FLAG LOAD DATA');
        const dataString = localStorage.getItem('data');
        if (!dataString)
            return;
        const data = JSON.parse(dataString);
        if (data.data && data.data.hasOwnProperty('affaires')) {
            setAffairs(data.data.affaires);
        }
        if (data.data && data.data.hasOwnProperty('products')) {
            setProducts(data.data.products);
        }
        if (data.data && data.data.hasOwnProperty('rdvs')) {
            setRdvs(data.data.rdvs);
        }
        if (data.data && data.data.hasOwnProperty('anomalies')) {
            setAnomalies(data.data.anomalies);
        }
        if (data.data && data.data.hasOwnProperty('notes')) {
            setNotes(data.data.notes);
        }
        if (data.data && data.data.hasOwnProperty('relances')) {
            setRelances(data.data.relances);
        }
        if (data.data && data.data.hasOwnProperty('contacts')) {
            setContacts(data.data.contacts);
        }
        if (data.data && data.data.hasOwnProperty('clients')) {
            setClients(data.data.clients);
        }
    };
    const getFromTypeAndId = (type, id) => {
        const dataString = localStorage.getItem('data');
        if (!dataString)
            return;
        const data = JSON.parse(dataString);
        if (data.data && data.data.hasOwnProperty(type)) {
            const all = data.data[type];
            const keys = Object.keys(all);
            for (let i = 0; i < keys.length; i++) {
                const el = keys[i];
                if (el === id)
                    return all[el];
            }
        }
        return null;
    };
    const deleteLocalStorage = () => {
        localStorage.removeItem('data');
        setAffairs({});
        setAnomalies({});
        setContacts({});
        setClients({});
        setRelances({});
        setProducts({});
        setNotes({});
        setRdvs({});
    };
    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        if (!reload)
            return;
        loadData();
        setReload(false);
    }, [reload]);
    return {
        affairs,
        anomalies,
        products,
        setReload,
        clients,
        contacts,
        relances,
        notes,
        loadData,
        rdvs,
        deleteLocalStorage,
        getFromTypeAndId,
    };
};
