import { useEffect, useState } from 'react';
import ApiService from '../../../utilities/apiService/ApiService';
import { GCALENDARENDPOINT } from '../../../utilities/apiService/constant';
export const useRelances = () => {
    const [filters, setFilters] = useState([
        { id: 'type', value: 'relance' },
        { id: 'status', value: 'OPEN' },
    ]);
    const [relances, setRelances] = useState([]);
    const [skip, setSkip] = useState(0);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [hasNextPage, setHasNextPage] = useState(false);
    const [selectedRelance, setSelectedRelance] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const formatFilters = () => {
        if (!filters)
            return [{ id: 'type', value: 'relance' }];
        const eq = {
            costumer: 'subject.cmd.costumer.id',
            saler: 'subject.cmd.saler.id',
            'createdAt[after]': 'start[$gte]',
            'createdAt[before]': 'start[$lte]',
        };
        return filters
            .map(f => ({ id: eq[f.id] ? eq[f.id] : f.id, value: f.value }))
            .concat({ id: 'type', value: 'relance' });
    };
    const getOneRelance = async (id) => {
        const relanceService = new ApiService(GCALENDARENDPOINT);
        const relance = await relanceService.get({ _id: id });
        setSelectedRelance(relance);
    };
    const fetchRelances = async () => {
        const relanceService = new ApiService(GCALENDARENDPOINT);
        setIsLoading(true);
        const results = await relanceService.search({ filtered: formatFilters(), sort: { createdAt: -1 }, skip: skip, limit: 30 });
        const totalItems = results.total;
        const data = results.data;
        const newData = skip > 0 ? [...relances, ...data] : data;
        setSkip(newData.length);
        setHasNextPage(newData.length < totalItems);
        setIsLoading(false);
        setRelances(newData);
    };
    const reloadData = () => {
        if (selectedRelance) {
            getOneRelance(selectedRelance._id);
        }
        setSkip(0);
        setFireUseEffect(Math.random());
    };
    const loadNextPage = () => {
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        if (filters !== null) {
            setSkip(0);
            setFireUseEffect(Math.random());
        }
    }, [filters]);
    useEffect(() => {
        fetchRelances();
    }, [fireUseEffect]);
    return {
        relances,
        isLoading,
        hasNextPage,
        getOneRelance,
        loadNextPage,
        reloadData,
        setFilters,
        setSelectedRelance,
        selectedRelance,
    };
};
