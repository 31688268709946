import { createAction } from 'redux-actions';
import { SET_FOOTER_NOTE, SET_LOCKED, SET_FOOTER_ANOMALIE } from './constant.js';
import { SET_FOOTER_RDV, SET_FOOTER_CONTACT, SET_FOOTER_RELANCE } from './constant';
export const setFooterNote = createAction(SET_FOOTER_NOTE, (noteModalOpen, initialValues) => {
    return { noteModalOpen, initialValues };
});
export const setFooterContact = createAction(SET_FOOTER_CONTACT, (contactModalOpen, initialValues) => {
    return { contactModalOpen, initialValues };
});
export const setFooterRelance = createAction(SET_FOOTER_RELANCE, (relanceModalOpen, initialValues) => {
    return { relanceModalOpen, initialValues };
});
export const setFooterAnomalie = createAction(SET_FOOTER_ANOMALIE, (anomalieModalOpen, initialValues) => {
    return { anomalieModalOpen, initialValues };
});
export const setFooterRdv = createAction(SET_FOOTER_RDV, (rdvModalOpen, initialValues) => {
    return { rdvModalOpen, initialValues };
});
export const setLocked = createAction(SET_LOCKED);
