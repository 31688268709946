import React, { Fragment } from 'react';
import { RdvList } from '../../../components/Fiche/FicheRdv';
import { AffairsDashboard } from '../Affaires/FicheAffairs';
import { AnomalieDashboard } from '../../../components/Fiche/FicheAnomalie';
import Outstanding from './Outstanding';
import { useDashboard } from './useDashboard';
import { NotesDashboard } from '../../../components/Fiche/FicheNote';
import { useSelector } from 'react-redux';
import Footer from "../Footer/Footer";
const Dashboard = () => {
    const { affairs, anomalies, outstanding, biggestOutstanding, rdvs, notes } = useDashboard();
    const connectedUser = useSelector((state) => state.user.data);
    const footer = useSelector((state) => state.footer);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'whflex-col centerPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(RdvList, { rdvs: rdvs, headerTitle: "Prochain RDV", onClick: () => { }, icon: `icon-Agenda` }),
                React.createElement(AffairsDashboard, { affairs: affairs, hidden: footer.isLocked }))),
        React.createElement("div", { className: 'whflex-col rightPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(NotesDashboard, { notes: notes, headerTitle: 'Dernières notes', onClick: () => { } }),
                React.createElement(AnomalieDashboard, { anomalies: anomalies, onClick: () => { } }),
                outstanding && React.createElement(Outstanding, { biggestOutstanding: biggestOutstanding, outstanding: outstanding })),
            React.createElement(Footer, { user: connectedUser, reload: () => null, type: 'affaires', humanFunctions: null, selected: null, disableElements: ['rdvs', 'notes', 'relances', 'anomalies', 'suivis'] }))));
};
export default Dashboard;
