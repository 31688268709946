import React from 'react';
import { InfiniteLoader, List } from 'react-virtualized';
import { FicheMainContainer } from '../Fiche/FicheDefaultLayout';
const InfiniteScroll = ({ data, loadNextPage, hasNextPage, isNextPageLoading, component, width = 900, height = 1000, rowHeight = 140, }) => {
    const isRowLoaded = ({ index }) => !!data[index];
    const loadMoreRows = () => {
        if (isNextPageLoading)
            return;
        return loadNextPage();
    };
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
        let content;
        if (data && data.length === 0)
            return React.createElement("div", null);
        if (data && data.length <= index && !isRowLoaded({ index })) {
            content = React.createElement("div", null);
        }
        else
            content = component({ index });
        if (data[index].hasOwnProperty('rdvTitle'))
            return (React.createElement("div", { key: key, style: style },
                React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: 'Mes rendez-vous passés', icon: `icon-Agenda` },
                    React.createElement("div", null))));
        if (data[index].hasOwnProperty('rdvPresent'))
            return (React.createElement("div", { key: key, style: style },
                React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: 'Mes rendez-vous à venir', icon: `icon-Agenda` },
                    React.createElement("div", null))));
        return (React.createElement("div", { key: key, style: style }, content));
    };
    return (React.createElement(InfiniteLoader, { isRowLoaded: isRowLoaded, loadMoreRows: loadMoreRows, rowCount: hasNextPage ? data.length + 1 : data.length }, ({ onRowsRendered, registerChild }) => {
        return (React.createElement(List, { width: width, className: 'infinite-loader-list', height: height, onRowsRendered: onRowsRendered, ref: registerChild, rowHeight: rowHeight, rowCount: data.length, rowRenderer: rowRenderer }));
    }));
};
export default InfiniteScroll;
