import React, { Fragment, useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FFAsyncSelect } from '../../../components/FinalForm/FFSelect';
import ClientFormArray from '../../../components/Form/ClientArrays';
import FFText from '../../../components/FinalForm/FFText';
import { COSTUMERSENDPOINT } from '../../../utilities/apiService/constant';
import { required } from '../../../components/WhForm/WhFields/Validator';
import { submitRdv, updateRdv } from '../../../components/RightPanelModal/ModalService';
import Loading from '../../../shared/Loading';
import { setNcIfNull } from '../../../helper/setNcIfNull';
const ModalRdv = ({ onClose, user, reload, initialValues, isUpdate = false }) => {
    const [isLoading, setIsLoading] = useState(false);
    const onUpdate = data => {
        return updateRdv(data)
            .then(() => {
            setIsLoading(false);
            reload && reload();
            onClose();
        })
            .catch(() => setIsLoading(false));
    };
    const onSubmitRdv = data => {
        setIsLoading(true);
        return submitRdv(data)
            .then(() => {
            setIsLoading(false);
            reload && reload();
            onClose();
        })
            .catch(() => setIsLoading(false));
    };
    const onSubmit = data => {
        return isUpdate ? onUpdate(data) : onSubmitRdv(data);
    };
    const formatCompanies = obj => {
        return {
            label: `${obj.socialReason}`,
            value: obj.id,
            all: {
                id: obj.id,
                displayName: obj.socialReason,
                email: obj.email,
                phone: obj.phone,
                saler: obj.saler ? { id: obj.saler.id, name: obj.saler.name } : null,
                city: `${setNcIfNull(obj.city)}`,
                zipCode: `${setNcIfNull(obj.cityzipCode)}`,
                address: `${setNcIfNull(obj.adressRow1)}`,
            },
        };
    };
    const dateMutators = {
        deleteContact: (_args, state, utils) => {
            utils.changeValue(state, 'company', () => _args[0]);
            utils.changeValue(state, 'attendees', () => ['']);
        },
        setDate: (_args, state, utils) => {
            utils.changeValue(state, 'date', () => _args[0]);
        },
    };
    return (React.createElement(Form, { onSubmit: onSubmit, initialValues: initialValues ? initialValues : { attendees: [""], creator: { id: user.id, email: user.email, displayName: user.name } }, mutators: { ...dateMutators, ...arrayMutators }, render: ({ handleSubmit, values, form: { mutators } }) => {
            return (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: "whModal darkkModal modalRdv" },
                    React.createElement("div", { className: "whModal-header singleColumn mb-15" },
                        React.createElement("i", { className: "text-title text-color-green icon-agenda text-center" })),
                    isLoading && React.createElement(Loading, { withoutLogo: false }),
                    React.createElement(Fragment, null,
                        React.createElement("div", { className: "whModal-body modalDateBody" },
                            React.createElement("div", { className: "whModal-bodyRow fourColumns " },
                                React.createElement("i", { className: "text-subtitle text-color-lightGray icon-entreprise text-center " }),
                                React.createElement(FFAsyncSelect, { format: formatCompanies, onChange: value => mutators.deleteContact(value), validate: required, defaultFilter: [{ id: 'human', value: false }], searchFilter: { id: 'socialReason', value: null }, name: 'company', endpoint: COSTUMERSENDPOINT, placeholder: 'Client', label: '' })),
                            values.hasOwnProperty('company') && (React.createElement(ClientFormArray, { company: values.company.label })),
                            React.createElement("div", { className: "whModal-bodyRow fourColumns  modalDate mt-10" },
                                React.createElement("i", { className: "text-subtitle text-color-lightGray icon-agenda text-center " }),
                                React.createElement(FFText, { name: 'date', type: 'date', label: '', validate: required, onChange: mutators.setDate }),
                                values.date && (React.createElement(Fragment, null,
                                    React.createElement("div", { className: 'wrapperWhModalDate' },
                                        React.createElement(FFText, { name: 'startDate', label: '', validate: required, type: 'time', className: `hourInput` })),
                                    React.createElement("div", { className: 'wrapperWhModalDate' },
                                        React.createElement(FFText, { name: 'endDate', label: '', validate: required, type: 'time', className: `hourInput` })))))),
                        React.createElement("div", { className: "whModal-footer mt-25 flex-directionRowReverse flex-justifyStart flex-alignCenter" },
                            React.createElement("div", { className: "whModal-btnContainer reverse" },
                                React.createElement("button", { type: 'submit', className: "whModal-btn whModal-btn-primary text-regular" }, "Enregistrer"),
                                React.createElement("div", { onClick: onClose, className: "whModal-btn whModal-btn-secondary mr-15 text-regular" }, "Annuler")))))));
        } }));
};
export default ModalRdv;
