import React, { Fragment, useEffect } from 'react';
import ProductPanel from './ProductPanel';
import Loading from '../../../shared/Loading';
import Icon from '../../../components/Icon/Icon';
import NameFormat from '../../../components/Nameformat';
import { FicheBody, FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import { formatMoment } from '../../../helper/momentHelper';
import CmdLinePrice from './CmdLinePrice';
import { useFetchCmdLine } from '../Affaires/useFetchCmdLine';
import { useSelector } from 'react-redux';
const PCmdLine = ({ cmdLine, hidden }) => {
    const { cmd } = cmdLine;
    const { manager, contact, saler, costumer, writeAt, ref, refBusiness, orderAt, amountHt, updatedAt, marginHt, } = cmd;
    return (React.createElement(FicheBody, { columns: 'threeColumns', className: 'borderDotted w-50' },
        React.createElement(Icon, { icon: 'icon-affaire' }),
        React.createElement(Icon, { icon: 'icon-technico-commercial', text: manager ? NameFormat({ value: manager.name }) : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
        React.createElement(Icon, { icon: 'icon-utilisateur', text: saler ? saler.name : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
        React.createElement(Icon, { icon: 'icon-entreprise', text: costumer ? costumer.socialReason : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
        React.createElement(Icon, { icon: 'icon-contact', text: contact ? contact.name : '-', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
        React.createElement(Icon, { icon: 'icon-ref-commande', text: refBusiness, bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
        React.createElement("div", null, ref),
        React.createElement(Icon, { icon: 'icon-check_2', text: formatMoment(orderAt) }),
        React.createElement(Icon, { icon: 'icon-montant', text: amountHt }),
        React.createElement("div", null, formatMoment(writeAt)),
        React.createElement(Icon, { icon: 'icon-logout', text: formatMoment(updatedAt) }),
        !hidden && React.createElement(Icon, { icon: 'icon-group-131 color-blue', text: marginHt ? marginHt : '-' })));
};
const ProductCmdLine = ({ product, hidden }) => {
    const filters = useSelector((state) => state.selectedProductFilter.filters);
    const { cmdLinesCopy, isLoading, setApiFilters, setDefaultFilters } = useFetchCmdLine();
    useEffect(() => {
        setDefaultFilters([{ id: 'itemRef', value: product.ref }]);
    }, [product]);
    useEffect(() => {
        setApiFilters(filters);
    }, [filters]);
    return (React.createElement(Fragment, null,
        React.createElement(ProductPanel, { product: product }),
        isLoading && React.createElement(Loading, { withoutLogo: true }),
        cmdLinesCopy.length === 0 && React.createElement("div", null, "Aucune ligne de produits trouv\u00E9e"),
        cmdLinesCopy.map(cmdLine => {
            return (React.createElement(FicheContainer, { key: `cmdLine-${cmdLine.id}`, className: 'halfRounded-bottom mb-10 cmdLinePriceContainer', color: 'lightgray', onClick: null },
                React.createElement(PCmdLine, { cmdLine: cmdLine, hidden: hidden }),
                React.createElement(CmdLinePrice, { cmdLine: cmdLine, hidden: hidden })));
        })));
};
export default ProductCmdLine;
