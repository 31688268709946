import React, { Fragment, useEffect, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import FFSelect from '../FinalForm/FFSelect';
import { COSTUMERSENDPOINT } from '../../utilities/apiService/constant';
import ApiService from '../../utilities/apiService/ApiService';
const ClientList = ({ fields, options }) => {
    return (React.createElement(Fragment, null, fields.map((_field, idx) => (React.createElement("div", { key: `clientlist-${idx}`, className: 'whModal-bodyRow fourColumns mt-10' },
        React.createElement("i", { className: "text-subtitle text-color-lightGray icon-contact text-center " }),
        React.createElement(FFSelect, { name: _field, options: options, label: '' }),
        idx === 0 && React.createElement("div", { className: "whModal-btn--add whModal-btn", onClick: () => fields.push('') }),
        idx > 0 && React.createElement("div", { className: "whModal-btn--sub whModal-btn", onClick: () => fields.remove(idx) }))))));
};
const ClientFormArray = ({ company }) => {
    const [options, setOptions] = useState([]);
    const formatAttendees = obj => {
        return {
            label: `${obj.firstname.toLowerCase()} ${obj.lastname.toLowerCase()}`,
            value: obj.id,
            all: {
                id: obj.id,
                email: obj.email,
                displayName: `${obj.firstname} ${obj.lastname}`,
                organizer: false,
                self: false,
                optional: true,
                responseStatus: 'accepted',
            },
        };
    };
    const getOptions = async () => {
        const apiService = new ApiService(COSTUMERSENDPOINT);
        const res = await apiService.search({
            filtered: [
                { id: 'costumer.socialReason', value: company },
                { id: 'human', value: true },
            ],
        });
        const newOptions = res['hydra:member'].map(c => formatAttendees(c));
        setOptions(newOptions);
    };
    useEffect(() => {
        getOptions();
    }, [company]);
    return (React.createElement(Fragment, null,
        React.createElement(FieldArray, { name: 'attendees' }, ({ fields }) => {
            return (React.createElement(Fragment, null,
                React.createElement(ClientList, { fields: fields, options: options })));
        })));
};
export default ClientFormArray;
