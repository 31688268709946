import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { FicheBody, FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import moment from 'moment';
import Icon from '../../../components/Icon/Icon';
import Loading from '../../../shared/Loading';
import useStock from './useStock';
import NumberFormat from 'react-number-format';
const StockElement = ({ stock, date, status }) => {
    const statusObj = {
        NS: 'Non stocké',
        ABT: 'Abandonnée',
        Stock: 'Stocké',
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { style: { flex: 1 } },
            React.createElement("div", { className: "patch-block", style: { marginRight: '5px' } },
                React.createElement("div", null,
                    React.createElement("div", null, stock.place),
                    React.createElement("div", null,
                        React.createElement("strong", null, date ? moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY') : React.createElement("span", null, "-")))),
                React.createElement("div", null,
                    React.createElement("div", null, "BF"),
                    React.createElement("div", null,
                        stock.qty !== '' && parseInt(stock.qty) > 0 && React.createElement("strong", null, stock.qty),
                        (stock.qty === '0.00' || stock.qty === '') && React.createElement("strong", null, '-'))),
                React.createElement("div", null,
                    React.createElement("div", null, "TE"),
                    React.createElement("div", null, stock.qty !== '' && parseInt(stock.qty) > 0 ? (React.createElement("strong", null, stock.qty)) : (React.createElement("strong", null, '-')))))),
        React.createElement("div", { style: { flex: 1 } },
            React.createElement("div", { className: "patch-block", style: { marginLeft: '5px' } },
                React.createElement("strong", null, status && status !== '' ? statusObj[status] : 'Non communiqué')))));
};
const StockHeader = ({ stocks }) => {
    if (!stocks.deposits)
        return (React.createElement("div", { className: "patch-block", style: { marginLeft: '5px', backgroundColor: 'white' } }, "Produit non trouv\u00E9"));
    return (React.createElement("div", { className: "patch-block", style: { marginLeft: '5px', backgroundColor: 'white' } }, stocks.deposits.map((s, idx) => {
        return (React.createElement("div", { key: `stock-header-${idx}` },
            React.createElement("div", { className: 'stock_element' },
                React.createElement("div", null, s.name),
                React.createElement("div", { className: 'stockData' },
                    s.qty && Number(s.qty) > 0 && React.createElement("strong", null, s.qty),
                    !s.qty && s.reaproDate && (React.createElement("strong", { className: `color-orange` }, moment(s.reaproDate).format('DD/MM/YYYY'))),
                    !s.qty && !s.reaproDate && s.reaproTheoricDate && (React.createElement("strong", { className: `color-red` }, moment(s.reaproTheoricDate).format('DD/MM/YYYY'))),
                    !s.qty && !s.reaproDate && !s.reaproTheoricDate && React.createElement("strong", null, "-"),
                    React.createElement("div", { className: 'separator' }),
                    React.createElement("div", null,
                        React.createElement("strong", null, s.reaproFamiliyCode))))));
    })));
};
export const StockTarifs = ({ stocks }) => {
    let marginCoef = 0;
    if (!stocks.promoPrice || Number(stocks.promoPrice) > Number(stocks.costumerPriceHt)) {
        marginCoef = (Number(stocks.costumerPriceHt) - Number(stocks.netBuyPrice)) / Number(stocks.netBuyPrice);
    }
    else {
        marginCoef = (Number(stocks.promoPrice) - Number(stocks.netBuyPrice)) / Number(stocks.netBuyPrice);
    }
    marginCoef = Math.round(marginCoef * 100) / 100 + 1;
    let marginRate = 0;
    if (!stocks.promoPrice || Number(stocks.promoPrice) > Number(stocks.costumerPriceHt)) {
        marginRate = ((Number(stocks.costumerPriceHt) - Number(stocks.netBuyPrice)) / Number(stocks.netBuyPrice)) * 100;
    }
    else {
        marginRate = ((Number(stocks.promoPrice) - Number(stocks.netBuyPrice)) / Number(stocks.netBuyPrice)) * 100;
    }
    marginRate = Math.round(marginRate * 100) / 100;
    return (React.createElement("div", null,
        React.createElement("h2", null,
            React.createElement("span", null, "Tarification")),
        React.createElement("div", { className: "patch-block" },
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-PA-UNITAIRE-HT', text: '', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
                React.createElement("div", null,
                    React.createElement("strong", null,
                        React.createElement(NumberFormat, { value: stocks.netBuyPrice, displayType: 'text', decimalScale: 2 })))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-PV-UNITAIRE-HT', text: '', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
                React.createElement("div", null,
                    React.createElement("strong", null,
                        React.createElement(NumberFormat, { value: stocks.publicPriceHt, displayType: 'text', decimalScale: 2 })))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-remise', text: '', bold: true, className: 'nowrap', classNameTxt: 'nowrap', style: { color: '#82da79' } }),
                React.createElement("div", null,
                    React.createElement("strong", null,
                        stocks.customerId && (React.createElement("span", { className: stocks.promoRate && Number(stocks.promoRate) > Number(stocks.remCostumerRate)
                                ? 'color-orange'
                                : '' },
                            React.createElement(NumberFormat, { value: Number(stocks.promoRate) > Number(stocks.remCostumerRate)
                                    ? stocks.promoRate
                                    : stocks.remCostumerRate, displayType: 'text', suffix: "%", decimalScale: 2 }))),
                        !stocks.customerId && `-`))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-PV-APRES-REMISE-HT', text: '', bold: true, className: 'nowrap', classNameTxt: 'nowrap' }),
                React.createElement("div", null,
                    React.createElement("strong", null,
                        stocks.customerId && (React.createElement("span", { className: stocks.promoPrice && Number(stocks.promoPrice) < Number(stocks.costumerPriceHt)
                                ? 'color-orange'
                                : '' },
                            React.createElement(NumberFormat, { value: !stocks.promoPrice ||
                                    Number(stocks.promoPrice) > Number(stocks.costumerPriceHt)
                                    ? stocks.costumerPriceHt
                                    : stocks.promoPrice, displayType: 'text', suffix: "\u20AC", decimalScale: 2 }))),
                        !stocks.customerId && `-`))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-coef', text: '', bold: true, className: 'nowrap', classNameTxt: 'nowrap', style: { color: '#4690ef' } }),
                React.createElement("div", null,
                    React.createElement("strong", null,
                        stocks.customerId && (React.createElement(NumberFormat, { value: marginCoef, displayType: 'text', decimalScale: 2 })),
                        !stocks.customerId && `-`))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-marge-pourcentage', text: '', bold: true, className: 'nowrap', classNameTxt: 'nowrap', style: { color: '#4690ef' } }),
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("strong", null,
                            stocks.customerId && (React.createElement(NumberFormat, { value: marginRate, suffix: "%", displayType: 'text', decimalScale: 2 })),
                            !stocks.customerId && `-`)))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-marge-valeur', text: '', bold: true, className: 'nowrap', classNameTxt: 'nowrap', style: { color: '#4690ef' } }),
                React.createElement("div", null,
                    React.createElement("strong", null,
                        stocks.customerId && (React.createElement(NumberFormat, { value: !stocks.promoPrice || Number(stocks.promoPrice) > Number(stocks.costumerPriceHt)
                                ? stocks.costumerPriceHt - stocks.netBuyPrice
                                : stocks.promoPrice - stocks.netBuyPrice, suffix: "\u20AC", displayType: 'text', decimalScale: 2 })),
                        !stocks.customerId && `-`))))));
};
export const Stock = ({ itemRef, customer = null }) => {
    const filters = useSelector((state) => state.selectedProductFilter.filters);
    if (customer)
        filters.push({ id: 'cmd.costumer.id', value: customer });
    const { stocks, isStockLoaded } = useStock(itemRef, filters);
    return (React.createElement(Fragment, null,
        !stocks && React.createElement("div", null, "Aucun stock trouv\u00E9"),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", { style: { flex: 1 } },
                React.createElement("h2", null,
                    React.createElement("span", null, "Stocks")))),
        !isStockLoaded && React.createElement(Loading, { withoutLogo: false }),
        isStockLoaded && !stocks && React.createElement("div", null, "Pas de stocks"),
        stocks && React.createElement(StockHeader, { stocks: stocks }),
        stocks && React.createElement(StockTarifs, { stocks: stocks })));
};
export const Classification = () => {
    return (React.createElement("div", null,
        React.createElement("h2", null,
            React.createElement("span", null, "Classification")),
        React.createElement("h3", null, "Famille statistiques"),
        React.createElement("table", { className: "patch-table" },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Tarifaire"),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null, "Comptable"),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null, "R\u00E9appro"),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null, "Pr\u00E9paration"),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null, "Logistique"),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null, "Marque"),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null,
                        React.createElement("strong", null, "Stat. (Type article)")),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null,
                        React.createElement("strong", null, "Stat. (Famille article)")),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null,
                        React.createElement("strong", null, "Stat. (Sous-famille article)")),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null, "Stat. (Sous-famille 2 article)"),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null, "Stat. (Sous-famille 3 article)"),
                    React.createElement("td", null, "-")),
                React.createElement("tr", null,
                    React.createElement("th", null, "Saison"),
                    React.createElement("td", null, "-"))))));
};
const ProductPanel = ({ product }) => {
    return (React.createElement(Fragment, null,
        React.createElement(FicheContainer, { className: 'halfRounded-bottom', color: 'lightgray', onClick: null },
            React.createElement(FicheBody, { columns: 'singleColumn', className: 'mt-10 patch-header-product' },
                React.createElement("div", null,
                    React.createElement("strong", null, `${product.ref}`)),
                React.createElement("div", null,
                    React.createElement("strong", null, `${product.title}`)),
                React.createElement("div", null, `${product.comment ? product.comment : ''}`))),
        React.createElement("hr", null)));
};
export default ProductPanel;
