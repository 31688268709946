import { createAction } from 'redux-actions';
import { SET_ANOMALIE_PANEL_RELOAD } from './constant.js';
import { SET_ANOMALIE_CLOTURE_RELOAD, SET_NOTEPANEL__RELOAD, SET_RDVPANEL__RELOAD, SET_RELANCEPANEL__RELOAD, SET_UPDATE_MESSAGE_PANEL_RELOAD, } from './constant';
export const setAnomaliePanelReload = createAction(SET_ANOMALIE_PANEL_RELOAD, reload => {
    return { reload };
});
export const setUpdateMessagePanelReload = createAction(SET_UPDATE_MESSAGE_PANEL_RELOAD, reload => {
    return { reload };
});
export const setAnomalieCloturePanelReload = createAction(SET_ANOMALIE_CLOTURE_RELOAD, reload => {
    return { reload };
});
export const setRelancePanelReload = createAction(SET_RELANCEPANEL__RELOAD, reload => {
    return { reload };
});
export const setNotePanelReload = createAction(SET_NOTEPANEL__RELOAD, reload => {
    return { reload };
});
export const setRdvPanelReload = createAction(SET_RDVPANEL__RELOAD, reload => {
    return { reload };
});
