import { useEffect, useState } from 'react';
import ApiService from '../../../utilities/apiService/ApiService';
import { CMDLINESENDPOINT } from '../../../utilities/apiService/constant';
export const useFetchCmdLine = () => {
    const [defaultFilters, setDefaultFilters] = useState([]);
    const [apiFilters, setApiFilters] = useState([]);
    const [cmdLines, setCmdLines] = useState([]);
    const [cmdLinesCopy, setCmdLinesCopy] = useState([]);
    const [fireUseEffect, setFireUseEffect] = useState(Math.random());
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const setFilters = filter => {
        const eq = {
            Tout: () => cmdLines,
            Dispo: () => cmdLines.filter(r => r.stockAlert === 'green'),
            Indispo: () => cmdLines.filter(r => r.stockAlert === 'orange'),
            CF: () => cmdLines.filter(r => r.stockAlert === 'blue'),
            PRP: () => cmdLines.filter(r => r.IsInPrep),
            Livre: () => cmdLines.filter(r => r.deliveryQty === r.cmdQty),
            Relicat: () => cmdLines.filter(r => r.deliveryQty < r.cmdQty),
        };
        setCmdLinesCopy(eq[filter]);
    };
    const fetchCmdLines = async () => {
        const fetchCmdLine = new ApiService(CMDLINESENDPOINT);
        setIsLoading(true);
        const results = await fetchCmdLine.search({ filtered: [...defaultFilters].concat(apiFilters), pagination: 'false' });
        const totalItems = results['hydra:totalItems'];
        const data = results['hydra:member'];
        const newData = page > 1 ? [...cmdLines, ...data] : data;
        setHasNextPage(newData.length < totalItems);
        setIsLoading(false);
        setCmdLines(newData);
        setCmdLinesCopy(newData);
    };
    const loadNextPage = () => {
        setPage(page + 1);
        setFireUseEffect(Math.random());
    };
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [apiFilters]);
    useEffect(() => {
        setPage(1);
        setFireUseEffect(Math.random());
    }, [defaultFilters]);
    useEffect(() => {
        if (defaultFilters.length === 0)
            return;
        fetchCmdLines();
    }, [fireUseEffect]);
    return {
        cmdLines,
        setFilters,
        setDefaultFilters,
        setApiFilters,
        cmdLinesCopy,
        isLoading,
        hasNextPage,
        loadNextPage,
    };
};
