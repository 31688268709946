import React, { useState } from 'react';
import classnames from 'classnames';
import SearchSelection from './Selection';
import SearchCurrent from './CurrentSearch';
const InputSearch = ({ inputs, onUpdate, inputValue, onRemove, onClose }) => {
    return (React.createElement("div", { className: "searchModal_searchContainer" },
        inputs.map((i, idx) => {
            return (React.createElement("span", { onClick: () => onRemove(idx), className: "searchModal_searchBadge" }, i));
        }),
        React.createElement("input", { type: "text", className: "searchModal_searchInput", onChange: onUpdate, value: inputValue, placeholder: "Rechercher ..." }),
        React.createElement("div", { onClick: onClose, className: 'cursorPointer text-fat' }, "\u00D7")));
};
const SearchIcon = ({ types, type, name, onSelect, icon }) => {
    return (React.createElement("div", { onClick: () => onSelect(type), className: `searchModal-tab ${types.includes(type) && 'searchModal-tab-selected'} button` },
        React.createElement("i", { className: classnames(`icon-${icon}`, 'text-button') }),
        React.createElement("p", { className: "text-small text-w-light" }, name)));
};
const Search = ({ current, send }) => {
    const [inputValue, setInputValue] = useState('');
    const onChangeValue = e => {
        const value = e.target.value;
        setInputValue(value);
        const isEmpty = e.target.value.replace(/\s/g, '') == '';
        if (value.charAt(value.length - 1) === ' ' && value.length > 3 && !isEmpty) {
            send('ADD_INPUT', { data: value.substring(0, value.length - 1) });
            setInputValue('');
            send('SELECT');
        }
    };
    const removeInput = idx => {
        send('REMOVE_INPUT', { idx: idx });
        send('SELECT');
    };
    const setType = type => {
        send('SET_TYPE', { data: type });
        send('SELECT');
    };
    const onClose = () => send('CLOSE');
    const addItem = selected => {
        send('ADD_SELECTION', { data: selected });
    };
    const removeItem = idx => send('REMOVE_ITEM', { data: idx });
    const removeAll = () => send('REMOVE_ALL');
    return (React.createElement("div", { style: { height: '600px' } },
        React.createElement("div", { className: "searchModal" },
            React.createElement(InputSearch, { onUpdate: onChangeValue, inputs: current.context.inputs, onClose: onClose, onRemove: removeInput, inputValue: inputValue }),
            React.createElement("div", { className: "searchModal-content noPadding dualColumns" },
                React.createElement("div", { className: "searchModal-tabFiltersContainer" },
                    React.createElement("div", { className: "searchModal-tabs" },
                        React.createElement(SearchIcon, { name: 'Clients', type: 'clients', onSelect: setType, types: current.context.types, icon: 'entreprise' }),
                        React.createElement(SearchIcon, { name: "Chargés d'affaires", type: 'c_affaires', onSelect: setType, types: current.context.types, icon: 'utilisateur' }),
                        React.createElement(SearchIcon, { name: 'Chantiers', type: 'chantiers', onSelect: setType, types: current.context.types, icon: 'ref-commande' }),
                        React.createElement(SearchIcon, { name: 'Affaires', type: 'affaires', onSelect: setType, types: current.context.types, icon: 'affaire' }),
                        React.createElement(SearchIcon, { name: 'Articles', type: 'item', onSelect: setType, types: current.context.types, icon: 'article' }),
                        React.createElement(SearchIcon, { name: 'Notes', type: 'notes', onSelect: setType, types: current.context.types, icon: 'note' })),
                    current.matches('open.success') && (React.createElement(SearchCurrent, { results: current.context.results, onSelect: addItem }))),
                current.matches('open.success') && (React.createElement(SearchSelection, { selections: current.context.selected, onRemoveAll: removeAll, onRemove: removeItem, onSeeingAll: () => send('OPEN_VIEW') }))))));
};
export default Search;
