import React, { Fragment, useEffect } from 'react';
import CmdLines from './CmdLines';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FicheBody, FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import { Badge } from '../../../components/Badge/Badge';
import Icon from '../../../components/Icon/Icon';
import NameFormat from '../../../components/Nameformat';
import { useFetchCmdLine } from './useFetchCmdLine';
const FicheAffaireFolderLayout = ({ affair }) => {
    const params = useSelector((state) => state.parameters);
    const formatMoment = date => {
        return moment(date).format('DD/MM/YYYY');
    };
    const { statusLabel, manager, saler, costumer, refBusiness, contact, ref, orderAt, writeAt, willDeliveryAt, } = affair;
    const badge = params && params.STATUS ? params.STATUS.find(st => st.label === statusLabel) : null;
    return (React.createElement(FicheContainer, { className: 'halfRounded-bottom', color: 'lightgray', onClick: null },
        React.createElement(FicheBody, { columns: 'singleColumn' },
            React.createElement(Badge, { color: 'blue', text: badge && badge.value.toUpperCase() })),
        React.createElement(FicheBody, { columns: 'fourColumns', className: 'mt-10' },
            React.createElement(Icon, { icon: 'icon-entreprise', text: costumer ? costumer.socialReason : '-', bold: true, className: 'light text-color-primary text-big' }),
            React.createElement(Icon, { icon: 'icon-contact', text: contact ? contact.name : '-', bold: true, className: 'text-color-primary text-big' }),
            React.createElement(Icon, { icon: 'icon-check_2 text-small', text: formatMoment(orderAt) }),
            React.createElement(Icon, { icon: 'icon-utilisateur', bold: true, text: saler ? NameFormat({ value: saler.name }) : '-', className: 'text-color-primary text-big' })),
        React.createElement(FicheBody, { columns: 'fourColumns', className: 'mt-10' },
            React.createElement(Icon, { icon: 'icon-affaire', text: ref, className: 'text-bold text-color-primary text-big' }),
            React.createElement(Icon, { icon: 'icon-ref-commande', text: refBusiness, className: 'text-bold text-color-primary text-big' }),
            React.createElement(Icon, { icon: 'icon-logout', text: formatMoment(willDeliveryAt) }),
            React.createElement(Icon, { icon: 'icon-technico-commercial', text: manager ? NameFormat({ value: manager.name }) : '-', className: 'text-bold text-color-primary text-big' })),
        React.createElement(FicheBody, { columns: 'singleColumn', className: 'mt-10' },
            React.createElement("div", null, formatMoment(writeAt)))));
};
const AffairPanel = ({ affair, isHidden }) => {
    const params = useSelector((state) => state.parameters);
    const { cmdLinesCopy, isLoading, setFilters, setDefaultFilters } = useFetchCmdLine();
    useEffect(() => {
        setDefaultFilters([{ id: 'cmd.id', value: affair.id }]);
    }, [affair]);
    return (React.createElement(Fragment, null,
        React.createElement(FicheAffaireFolderLayout, { affair: affair, params: params, selected: false }),
        React.createElement(CmdLines, { affair: affair, hidden: isHidden, cmdLines: cmdLinesCopy, setFilters: setFilters, isLoading: isLoading })));
};
export default AffairPanel;
