import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAffaires } from './useAffaires';
import Loading from '../../../shared/Loading';
import Filters from '../../Filters/Filters';
import { Route } from 'react-router';
import RightPanelRoute from '../RightPanelRoute';
import { AffairListLeftPanel } from './AffairesLeftPanel';
import Footer from "../Footer/Footer";
const Affaires = ({ match, history }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const [defaultFilter] = useState([{ id: 'saler', value: connectedUser.take.id, label: connectedUser.take.name }]);
    const [shouldWaitFetched, setShouldWaitFetched] = useState(false);
    const [elementToFetch, setElementToFetch] = useState(null);
    const { affaires, setSelectedAffaire, isLoading, getOneAffair, setFilters, selectedAffaire, hasNextPage, loadNextPage, reloadData } = useAffaires();
    const footer = useSelector((state) => state.footer);
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const onSelect = (type, id, defaultIcon, data) => {
        setSelectedAffaire(data);
        history.push(`${match.path}/rightpanel/${type}/${id}/${defaultIcon}`);
    };
    const getFicheAffairsSwipe = () => {
        return (React.createElement(Fragment, null,
            isLoading && React.createElement(Loading, { withoutLogo: true }),
            React.createElement(AffairListLeftPanel, { affairs: affaires, reload: reloadData, loadNextPage: loadNextPage, hasNextPage: hasNextPage, isNextPageLoading: isLoading, selected: selectedAffaire, onClick: affair => {
                    onSelect('affaires', affair.id, 'selectedAffair', affair);
                }, hidden: footer.isLocked })));
    };
    const sendFilters = filter => {
        setFilters(filter);
    };
    useEffect(() => {
        if (!shouldWaitFetched)
            return;
        if (!elementToFetch)
            return;
        getOneAffair(elementToFetch);
        setShouldWaitFetched(false);
    }, [shouldWaitFetched]);
    const setElement = id => {
        setShouldWaitFetched(true);
        setElementToFetch(id);
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `whflex-col centerPanel` },
            React.createElement("div", { className: "toBeScrolled" },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                React.createElement(Filters, { filtersConfig: ['multipleRef', 'company', 'badges', 'date', 'saler'], defaultFilters: defaultFilter, sendFilters: sendFilters }),
                getFicheAffairsSwipe())),
        React.createElement("div", { className: 'whflex-col rightPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Route, { path: "/main/affaires/rightpanel/:type/:id/:tab", name: "rightpanel" },
                    React.createElement(RightPanelRoute, { data: selectedAffaire, type: 'affaires', setElement: setElement, user: connectedUser }))),
            React.createElement(Footer, { user: connectedUser, reload: reloadData, type: 'affaires', humanFunctions: humanFunctions, selected: selectedAffaire, disableElements: selectedAffaire ? [] : ['relances', 'anomalies', 'suivis'] }))));
};
export default Affaires;
