import React, { Fragment, useState } from 'react';
import RightPanelFilters from '../../../components/Filters/RightPanelFilters';
import moment from 'moment';
import Icon from '../../../components/Icon/Icon';
import Table from '../../../components/Table/Table';
import ModalProduct from '../../../components/RightPanelModal/ModalProduct';
import NumberFormat from 'react-number-format';
import Loading from '../../../shared/Loading';
const HeaderCol = ({ text, value, icon }) => {
    return (React.createElement("div", { className: 'flex-directionColumn flex-alignCenter' },
        React.createElement(Icon, { icon: icon, text: text, className: 'text-medium text-w-light' }),
        React.createElement("span", { className: 'text-big text-w-bold' }, value)));
};
const HeaderColLight = ({ text, value, icon }) => {
    return (React.createElement("div", { className: 'flex-directionColumn flex-alignCenter' },
        React.createElement(Icon, { icon: icon, text: text, className: 'text-medium text-w-light' }),
        React.createElement("span", { className: 'text-subtitle text-w-light' }, value)));
};
const getDiscountRate = cmdLines => {
    let totalDiscount = 0;
    let totalRows = 0;
    cmdLines.map(el => {
        if (el.discountRate || el.publicPrice) {
            totalRows++;
            totalDiscount += el.discountRate
                ? Number(el.discountRate)
                : ((el.publicPrice - el.unitPriceHt) * 100) / el.publicPrice;
        }
    });
    return Math.round(totalDiscount / totalRows);
};
const Header = ({ affair, hidden = false, cmdLines }) => {
    const { amountHt, amountBuyHt, ecoTaxe, marginCoef, marginHt, marginRate } = affair;
    return (React.createElement("div", { className: 'grid sevenColumns mt-20' },
        !hidden && React.createElement(HeaderColLight, { text: 'T.Ht (€)', value: amountHt, icon: `` }),
        !hidden && React.createElement(HeaderColLight, { text: 'T.Acht.Ht (€)', value: amountBuyHt, icon: `` }),
        React.createElement(HeaderCol, { text: 'Eco.T', value: ecoTaxe, icon: `` }),
        React.createElement(HeaderCol, { text: 'Rem%', value: getDiscountRate(cmdLines), icon: `icon-remise color-green` }),
        !hidden && React.createElement(HeaderCol, { text: 'Coef', value: marginCoef, icon: `icon-coef color-blue text-small` }),
        !hidden && (React.createElement(HeaderCol, { text: 'Mrg', value: marginRate, icon: `icon-marge-pourcentage color-blue text-small` })),
        !hidden && React.createElement(HeaderCol, { text: 'Mrg', value: marginHt, icon: `icon-marge-valeur color-blue text-small` })));
};
const CmdLines = ({ affair, hidden, cmdLines, setFilters, isLoading }) => {
    const filters = ['Tout', 'Dispo', 'Indispo', 'CF', 'PRP', 'Livre', 'Relicat'];
    const getDiscountRate = row => {
        return row.original.discountRate
            ? row.original.discountRate
            : ((row.original.publicPrice - row.original.unitPriceHt) * 100) / row.original.publicPrice;
    };
    const hiddenColumns = React.useMemo(() => [
        {
            Header: '',
            accessor: 'pack',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement("span", { className: "text-w-bold text-medium" }, row.original.itemCode),
                React.createElement("span", { className: "text-w-light text-small text-ellipsis" }, row.original.libelle))),
        },
        {
            Header: '',
            accessor: 'cmdQty',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-quantites text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' }, row.original.cmdQty))),
        },
        {
            Header: '',
            accessor: 'publicPrice',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-PV-UNITAIRE-HT text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: row.original.publicPrice, displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'discountRate',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-remise text-tiny color-green' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: getDiscountRate(row), displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'unitPriceHt',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-PV-APRES-REMISE-HT color-lightGray text-small' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: row.original.publicPrice - (row.original.publicPrice * getDiscountRate(row)) / 100, displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'total',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-PV-TOTAL-APRES-REMISE-HT color-lightGray text-small' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: row.original.unitPriceHt * row.original.cmdQty, displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'willBeDeliberedAt',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-logout color-lightGray text-small' }),
                React.createElement("span", { className: 'text-big' }, row.original.canBeDeliveredAt
                    ? moment(row.original.canBeDeliveredAt).format('DD/MM/YYYY')
                    : row.original.willBeDeliberedAt &&
                        moment(row.original.willBeDeliberedAt).format('DD/MM/YYYY')))),
        },
    ], []);
    const columns = React.useMemo(() => [
        {
            Header: '',
            accessor: 'pack',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement("span", { className: "text-w-bold text-medium" }, row.original.itemCode),
                React.createElement("span", { className: "text-w-light text-small text-ellipsis" }, row.original.libelle))),
        },
        {
            Header: '',
            accessor: 'cmdQty',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-quantites text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' }, row.original.cmdQty))),
        },
        {
            Header: '',
            accessor: 'priceAverage',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-PA-UNITAIRE-HT text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: row.original.priceAvarage, displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'publicPrice',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-PV-UNITAIRE-HT text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: row.original.publicPrice, displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'discountRate',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-remise text-tiny color-green' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: getDiscountRate(row), displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'unitPriceHt',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-PV-APRES-REMISE-HT color-lightGray text-small' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: row.original.publicPrice - (row.original.publicPrice * getDiscountRate(row)) / 100, displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'total',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-PV-TOTAL-APRES-REMISE-HT color-lightGray text-small' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: row.original.unitPriceHt * row.original.cmdQty, displayType: 'text', decimalScale: 2 })))),
        },
        {
            Header: '',
            accessor: 'willBeDeliberedAt',
            Cell: ({ row }) => (React.createElement(Fragment, null,
                React.createElement(Icon, { icon: 'icon-logout color-lightGray text-small' }),
                React.createElement("span", { className: 'text-big' }, row.original.canBeDeliveredAt
                    ? moment(row.original.canBeDeliveredAt).format('DD/MM/YYYY')
                    : row.original.willBeDeliberedAt &&
                        moment(row.original.willBeDeliberedAt).format('DD/MM/YYYY')))),
        },
        {
            Header: '',
            accessor: 'marginRate',
            Cell: ({ row }) => {
                return (React.createElement(Fragment, null,
                    React.createElement(Icon, { icon: 'icon-group-131 color-blue text-small' }),
                    React.createElement("span", { className: 'text-big' }, row.original.unitPriceHt && row.original.priceAvarage && (React.createElement(NumberFormat, { value: ((row.original.unitPriceHt -
                            (row.original.unitPriceHt * row.original.discountRate) / 100 -
                            row.original.priceAvarage) *
                            100) /
                            (row.original.unitPriceHt -
                                (row.original.unitPriceHt * row.original.discountRate) / 100), displayType: 'text', decimalScale: 2 })))));
            },
        },
    ], []);
    const [selected, setSelected] = useState(null);
    return (React.createElement(Fragment, null,
        React.createElement(Header, { affair: affair, hidden: hidden, cmdLines: cmdLines }),
        React.createElement("span", { className: 'separatorV mt-10' }),
        React.createElement(RightPanelFilters, { affair: affair, filters: filters, setFilter: setFilters, className: 'mt-15' }),
        isLoading && React.createElement(Loading, { withoutLogo: false }),
        cmdLines && cmdLines.length === 0 && React.createElement("div", null, "Pas de r\u00E9sultat"),
        React.createElement(Table, { columns: hidden ? hiddenColumns : columns, data: cmdLines && cmdLines.length > 0 ? cmdLines : [], className: 'affairesTable mt-25', rowClassName: 'cursorPointer', onClickRow: idx => {
                setSelected(cmdLines[idx]);
            } }),
        React.createElement(ModalProduct, { isOpen: selected !== null, product: selected, toggle: () => setSelected(null) })));
};
export default CmdLines;
