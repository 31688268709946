import ApiService from './ApiService';
import { CMDLINESENDPOINT, CMDENDPOINT, GCALENDARENDPOINT } from './constant';
import moment from 'moment';
export const fetchAffairs = (filtered = []) => {
    const affairService = new ApiService(CMDENDPOINT);
    return affairService.search({ filtered: filtered });
};
export const fetchCmdLine = (filtered = []) => {
    const fetchCmdLine = new ApiService(CMDLINESENDPOINT);
    return fetchCmdLine.search({ filtered: filtered, pageSize: 500 });
};
export const fetchAnomalies = (filtered = [{ id: 'hasAnomaly', value: true }]) => {
    const affairService = new ApiService(CMDENDPOINT);
    return affairService.search({ filtered: filtered });
};
export const deleteRdv = id => {
    const rdvService = new ApiService(GCALENDARENDPOINT);
    return rdvService.remove(id);
};
export const fetchAllRdvs = (filtered = [{ id: 'type', value: 'appointment' }]) => {
    const promises = [];
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const tomorrow = new Date(todayDate);
    const after = new Date(todayDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    after.setDate(after.getDate() + 2);
    const rdvService = new ApiService(GCALENDARENDPOINT);
    promises.push(rdvService.search({
        filtered: filtered.concat({ id: 'start[$gte]', value: moment(todayDate).format('YYYY-MM-DD') }),
        sort: { start: -1 },
    }));
    promises.push(rdvService.search({
        filtered: filtered.concat({ id: 'start[$lt]', value: moment(todayDate).format('YYYY-MM-DD') }),
        sort: { start: -1 },
    }));
    return Promise.all(promises).then((res) => {
        return {
            todayRdvs: res[0].data,
            tomorrowRdvs: [],
            otherRdvs: res[1].data,
        };
    });
};
export const fetchRdvs = (filtered = [{ id: 'type', value: 'appointment' }]) => {
    const rdvService = new ApiService(GCALENDARENDPOINT);
    return rdvService
        .search({ filtered: filtered, sort: { createdAt: -1 } })
        .then(r => r.data.filter(d => d.hasOwnProperty('attendees') && d.hasOwnProperty('subject')));
};
export const fetchNotes = (filtered = [{ id: 'type', value: 'note' }]) => {
    const calandarService = new ApiService(GCALENDARENDPOINT);
    return calandarService.search({ filtered: filtered, sort: { createdAt: -1 } }).then(r => {
        return r.data;
    });
};
export const fetchRelances = (filtered = [{ id: 'type', value: 'relance' }]) => {
    const calandarService = new ApiService(GCALENDARENDPOINT);
    return calandarService.search({ filtered: filtered, sort: { start: -1 } }).then(r => {
        return r.data;
    });
};
const elasticCall = (inputs, type) => {
    const formatedInputs = inputs.map((input, idx) => (idx === 0 ? `libelle=${input}` : `&libelle=${input}`)).join('');
    const query = `elastic/${type}?${formatedInputs}`;
    const elasticSearchService = new ApiService(`${process.env.REACT_API_ELASTIC}/${query}`);
    return elasticSearchService.search();
};
const resolveData = (inputs, types) => {
    return Promise.all(types.map(type => elasticCall(inputs, type)));
};
export const fetchSearchResult = (inputs, types) => {
    return resolveData(inputs, types).then(values => {
        const data = values.map(val => val.data).reduce((acc, arr) => [...acc, ...arr]);
        return data;
    });
};
