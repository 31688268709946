import React from 'react';
const SelectionCheckBox = ({ id, isChecked, onChange, className }) => {
    return (React.createElement("div", { className: `${className ? className : 'singleColumn flex flex-justifyEnd flex-alignStart w-100'}` },
        React.createElement("input", { className: "inp-customCbx d-none", id: `selected-${id}`, type: "checkbox", name: 'affairs', onClick: onChange, defaultChecked: isChecked }),
        React.createElement("label", { className: "customCbx", htmlFor: `selected-${id}` },
            React.createElement("span", null,
                React.createElement("svg", { width: "12px", height: "10px", viewBox: "0 0 12 10" },
                    React.createElement("polyline", { points: "1.5 6 4.5 9 10.5 1" }))),
            React.createElement("span", null))));
};
export default SelectionCheckBox;
