import React, { useState } from 'react';
import Moment from 'react-moment';
import EchartCaNet from '../../../components/Fiche/echartCaNet';
import EchartCaBrut from '../../../components/Fiche/echartCaBrut';
import Euroformat from '../../../components/Euroformat';
import { FicheContainer } from '../../../components/Fiche/FicheDefaultLayout';
import Icon from '../../../components/Icon/Icon';
import useKpis from './useSynthese';
import SyntheseFilters from './SyntheseFilter';
import Loading from '../../../shared/Loading';
import { setNcIfNull } from '../../../helper/setNcIfNull';
const Bar = ({ text, value, percent, color }) => {
    return (React.createElement("div", { className: 'ml-2 mr-2', style: { width: `${percent > 8 ? percent : '8'}%` } },
        React.createElement("p", { className: 'flex flex-justifyCenter' }, text),
        React.createElement("div", { className: `bar flex flex-justifyCenter flex-alignCenter color-white backgroundColor-${color}`, style: { width: `100%` } },
            React.createElement("p", null, `${value / 1000} K€`))));
};
export const FicheSyntheseTab = ({ data }) => {
    const { outstanding, isLoading, setFilters, caBrut, bills } = useKpis(data);
    const [defaultFilter] = useState([
        {
            id: 'contact_id',
            value: data.hasOwnProperty('defaultsyntheseFilter') && data.defaultsyntheseFilter
                ? data.defaultsyntheseFilter.id
                : data.contact && data.contact.id
                    ? data.contact.id
                    : null,
            label: data.hasOwnProperty('defaultsyntheseFilter') && data.defaultsyntheseFilter
                ? data.defaultsyntheseFilter.name
                : data.contact
                    ? data.contact.name
                    : null,
        },
    ]);
    const getPercent = () => {
        const { toDelivered, deliveredNotPayed, notPayed, dispo } = outstanding;
        let total = 0;
        total = toDelivered + deliveredNotPayed + notPayed;
        return {
            notPayed: (Math.abs(notPayed) * 40) / total + 10,
            toDelivered: (Math.abs(toDelivered) * 40) / total + 10,
            deliveredNotPayed: (Math.abs(notPayed) * 40) / total + 10,
            dispo: (Math.abs(dispo) * 100) / total,
        };
    };
    return (React.createElement(FicheContainer, { color: 'lightgray', className: 'card-panel-right card-syntheses', onClick: null },
        isLoading && React.createElement(Loading, { withoutLogo: true }),
        React.createElement(FicheContainer, { color: 'white', onClick: null, className: 'card-syntheses-header whfleximp whfleximp-row whfleximp-row-spaceBetween' },
            React.createElement(Icon, { icon: 'icon-entreprise', text: setNcIfNull(data.socialReason), bold: true }),
            React.createElement(Icon, { icon: 'icon-utilisateur', text: setNcIfNull(data.salerName), bold: true })),
        React.createElement("div", { className: "stats-row" },
            React.createElement("div", { className: "card stats-card" },
                React.createElement("div", { className: "stats-card-header" }, "CA. NET / BFA"),
                React.createElement("div", { className: "stats-card-body" }, outstanding.evolCaNet.length > 0 && (React.createElement(EchartCaNet, { data: outstanding.evolCaNet, bfa: outstanding.bfa })))),
            React.createElement("div", { className: "card stats-card" },
                React.createElement("div", { className: "stats-card-header" }, `ENCOURS AUTORISE ${outstanding.autorized ? outstanding.autorized : 'NC'} €`),
                React.createElement("div", { className: "stats-card-body outstanding" },
                    React.createElement("div", { className: 'flex w-100 bar' },
                        React.createElement(Bar, { text: 'Impayés', value: outstanding.notPayed, percent: getPercent()['notPayed'], color: 'red' }),
                        React.createElement(Bar, { text: 'CC Livrés', value: outstanding.deliveredNotPayed, percent: getPercent()['deliveredNotPayed'], color: 'yellow' }),
                        React.createElement(Bar, { text: 'CC Non livrés', value: outstanding.toDelivered, percent: getPercent()['toDelivered'], color: 'blue' }),
                        React.createElement(Bar, { text: 'Dispo', value: outstanding.dispo, percent: getPercent()['dispo'], color: 'green' })),
                    React.createElement("div", { className: 'mt-3 w-100' },
                        React.createElement("table", { className: "table table-striped", style: { fontSize: '12px' } },
                            React.createElement("tbody", null,
                                bills && bills.length === 0 && React.createElement("div", null, "Pas de r\u00E9sultats"),
                                bills &&
                                    bills.map((o, index) => {
                                        const { ref, dueDate, saler: { name }, costumer: { socialReason }, refChantier, amountHtToPaid, } = o;
                                        return (React.createElement("tr", { key: index },
                                            React.createElement("td", { className: 'color-red bold700' }, ref),
                                            React.createElement("td", null,
                                                React.createElement(Moment, { format: "DD/MM/YYYY" }, dueDate)),
                                            React.createElement("td", null, name),
                                            React.createElement("td", null, socialReason),
                                            React.createElement("td", null, refChantier),
                                            React.createElement("td", { className: 'color-red bold700 align-right' },
                                                React.createElement(Euroformat, { value: amountHtToPaid }))));
                                    }))))))),
        React.createElement("div", { className: "card stats-card" },
            React.createElement("div", { className: "stats-card-header" }, "CHIFFRE D\u2019AFFAIRE BRUT"),
            data.type !== 'clients' && (React.createElement(SyntheseFilters, { sendFilters: setFilters, defaultFilters: defaultFilter, socialReason: data.socialReason })),
            React.createElement("div", { className: "stats-card-body" }, caBrut.length > 0 && React.createElement(EchartCaBrut, { key: JSON.stringify(caBrut), data: caBrut })))));
};
