import React, { useState } from 'react';
import Moment from 'react-moment';
import classnames from 'classnames';
import { FicheBody, FicheContainer, FicheMainContainer } from './FicheDefaultLayout';
import Icon from '../Icon/Icon';
import NameFormat from '../Nameformat';
import SelectionCheckBox from '../SelectionCheckBox/SelectionCheckBox';
import { isInLocalStorage, removeEntity, saveEntity } from '../../utilities/storage';
import { Redirect } from 'react-router';
const FicheAnomalie = ({ answer, main_user, anomalies }) => {
    const { user, date, text } = answer;
    const HASARD = Math.floor(Math.random() * Math.floor(4));
    return (React.createElement("div", { className: classnames('card-message', {
            author: HASARD === 0,
        }) },
        React.createElement("div", { className: 'card-message-row' },
            React.createElement("div", { className: 'card-message-header' },
                React.createElement(Icon, { text: user, icon: 'icon-utilisateur', bold: true }),
                React.createElement("div", { className: "small-date" },
                    React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, date))),
            React.createElement("div", { className: 'card-message-body' },
                React.createElement(FicheContainer, { color: 'white', className: "card-message-body-message" },
                    React.createElement("div", { className: "card-message-body-header" },
                        React.createElement(Icon, { text: main_user, bold: true, icon: 'icon-utilisateur' }),
                        React.createElement("div", { className: "small-date" },
                            React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, date))),
                    React.createElement("div", { className: 'color-red mb-9' }, anomalies),
                    React.createElement("div", null, text)),
                HASARD !== 0 && (React.createElement("button", { className: "actions" },
                    React.createElement("i", { className: `icon-chevron-gauche` })))))));
};
export const AnomalieElement = ({ anomalie, idx, selected, onClick }) => {
    const onClickFiche = _e => {
        if (_e.target.localName !== 'svg' && _e.target.localName !== 'input') {
            onClick(idx);
        }
        else {
            _e.preventDefaul();
        }
    };
    const [isChecked, setIsChecked] = useState(isInLocalStorage('anomalies', anomalie.id));
    const onChange = e => {
        if (e.target.checked)
            saveEntity('anomalies', anomalie.id, anomalie);
        if (!e.target.checked)
            removeEntity('anomalies', anomalie.id);
        setIsChecked(e.target.checked);
    };
    const { saler, costumer, costumerRef, contact, writeAt, ref } = anomalie;
    return (React.createElement("div", { className: `card card-color-lightpurple ${selected ? 'card--selected card--hover' : ' card--hover'}`, onClick: e => onClickFiche(e) },
        React.createElement(SelectionCheckBox, { id: anomalie.id, isChecked: isChecked, onChange: onChange }),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-entreprise', bold: true, text: costumer ? costumer.socialReason : '', className: 'nowrap' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-contact', bold: true, text: contact ? contact.name : 'Contact', className: 'nowrap' })),
        React.createElement(FicheBody, { columns: 'dualColumns', className: 'mb-9' },
            React.createElement(Icon, { icon: 'icon-affaire', text: ref, className: 'nowrap' }),
            React.createElement(Icon, { icon: 'icon-utilisateur', bold: true, text: saler ? NameFormat({ value: saler.name }) : 'saler', className: 'nowrap align-right' })),
        React.createElement(FicheBody, { columns: 'dualColumns' },
            React.createElement(Icon, { icon: 'icon-ref-commande', bold: true, text: costumerRef, className: 'nowrap' }),
            React.createElement("div", { className: 'align-right small-date' },
                React.createElement(Moment, { format: "DD/MM/YYYY HH:mm" }, writeAt)))));
};
export const AnomalieDashboard = ({ anomalies, onClick, selected = null }) => {
    const [redirect, setRedirect] = useState(null);
    const redirectToAnomalie = anomalie => {
        setRedirect(anomalie);
    };
    return (React.createElement(FicheMainContainer, { columns: 'dualColumns', headerTitle: 'Dernières anomalies', icon: 'icon-anomalies' },
        anomalies.map((anomalie, idx) => {
            return (React.createElement("div", { key: `note-${idx}`, onClick: () => redirectToAnomalie(anomalie) },
                React.createElement(AnomalieElement, { anomalie: anomalie, selected: selected === idx, idx: idx, onClick: onClick })));
        }),
        redirect && React.createElement(Redirect, { to: `/main/anomalies/rightpanel/anomalies/${redirect.id}/selectedAnomalie` })));
};
export const AnomalieList = ({ anomalies, onClick, selected = -1 }) => {
    return (React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: 'Anomalies', icon: 'icon-anomalies' }, anomalies.map((anomalie, idx) => {
        return (React.createElement(AnomalieElement, { anomalie: anomalie, key: `note-${idx}`, selected: selected === idx, idx: idx, onClick: onClick }));
    })));
};
export default FicheAnomalie;
