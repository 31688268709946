import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Loading from '../../../shared/Loading';
import { required } from '../../../components/WhForm/WhFields/Validator';
import FFTextArea from '../../../components/FinalForm/FFTextArea';
import { updateRelance } from '../../../components/RightPanelModal/ModalService';
const AnswerForm = ({ reload, relance, user, isUpdate = false, initialValue, answerIdx = 0 }) => {
    const [isLoading, setIsLoading] = useState(false);
    const onUpdate = data => {
        setIsLoading(true);
        const { comment } = data;
        const currentAnswers = relance.subject.answers;
        currentAnswers[answerIdx] = { ...currentAnswers[answerIdx], comment, date: new Date() };
        const updatedData = {
            ...relance,
            subject: {
                ...relance.subject,
                answers: [...currentAnswers],
            },
        };
        return updateRelance(updatedData)
            .then(() => {
            if (reload)
                reload();
            setIsLoading(false);
        })
            .catch(e => {
            console.error(e);
        });
    };
    const onSubmit = data => {
        setIsLoading(true);
        const { comment } = data;
        const currentAnswers = relance.subject.hasOwnProperty('answers') ? relance.subject.answers : [];
        const updatedData = {
            ...relance,
            subject: {
                ...relance.subject,
                answers: [
                    ...currentAnswers,
                    {
                        creator: { id: user.id, email: user.email, displayName: user.name },
                        comment: comment,
                        date: new Date(),
                    },
                ],
            },
        };
        return updateRelance(updatedData)
            .then(() => {
            if (reload)
                reload();
            setIsLoading(false);
        })
            .catch(e => {
            console.error(e);
        });
    };
    return (React.createElement(Form, { onSubmit: isUpdate ? onUpdate : onSubmit, initialValues: initialValue ? initialValue : {}, render: ({ handleSubmit, form }) => {
            return (React.createElement("form", { onSubmit: event => handleSubmit(event).then(() => {
                    form.reset();
                }), className: 'send-message-form' },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                React.createElement("div", { className: 'text-form-container w-100' },
                    React.createElement(FFTextArea, { validate: required, name: 'comment', className: 'w-100 text-color-gray text-left relance-message-border message-area', label: '' }),
                    React.createElement("button", { className: 'button message-btn', type: 'submit' }, isLoading ? React.createElement(Loading, { withoutLogo: true }) : React.createElement("span", null, "Envoyer")))));
        } }));
};
export default AnswerForm;
