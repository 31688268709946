import moment from 'moment';
import { FicheBody, FicheHeader, FicheMainContainer } from '../../../components/Fiche/FicheDefaultLayout';
import { formatMoment } from '../../../helper/momentHelper';
import Icon from '../../../components/Icon/Icon';
import NameFormat from '../../../components/Nameformat';
import React, { useState } from 'react';
import { isInLocalStorage, removeEntity, saveEntity } from '../../../utilities/storage';
import SelectionCheckBox from '../../../components/SelectionCheckBox/SelectionCheckBox';
import classnames from 'classnames';
import { setNcIfNull } from '../../../helper/setNcIfNull';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
export const FicheAgendaLeftPanel = ({ rdv, idx, color = 'lightyellow', onClick, callback, onDelete }) => {
    const [isChecked, setIsChecked] = useState(isInLocalStorage('rdvs', rdv._id));
    const onChange = e => {
        if (e.target.checked)
            saveEntity('rdvs', rdv._id, rdv);
        if (!e.target.checked)
            removeEntity('rdvs', rdv._id);
        setIsChecked(e.target.checked);
        if (callback)
            callback();
    };
    const onClickFiche = (_e, _idx) => {
        if (_e.target.localName !== 'svg' && _e.target.localName !== 'input' && _e.target.localName !== 'polyline') {
            onClick('rdvs', rdv._id, 'selectedRdv', rdv);
        }
    };
    const getTextAttendees = attendees => {
        if (!attendees || (attendees && attendees.length === 0))
            return 'Aucun participant';
        if (attendees.length === 1 && attendees[0]) {
            return attendees[0] && attendees[0].hasOwnProperty('displayName') ? attendees[0].displayName : 'NC';
        }
        const size = attendees.length;
        if (size - 1 === 0)
            return attendees[0] && attendees[0].hasOwnProperty('displayName') ? attendees[0].displayName : 'NC';
        return attendees[0] && attendees[0].hasOwnProperty('displayName')
            ? `${attendees[0].displayName} + ${size - 1} autre(s)`
            : `NC + ${size - 1} autre(s)`;
    };
    const { end, start, attendees, subject, organizer } = rdv;
    const meetingHour = `${moment.utc(start).format('HH:mm')} > ${moment.utc(end).format('HH:mm')}`;
    return (React.createElement("div", { className: `card card-color-${color}`, onClick: e => onClickFiche(e, idx) },
        React.createElement(SelectionCheckBox, { id: rdv._id, isChecked: isChecked, onChange: onChange }),
        React.createElement(FicheHeader, { columns: 'rdvHeaderColumns' },
            React.createElement("div", null, formatMoment(start)),
            React.createElement("div", null, meetingHour),
            React.createElement(Icon, { icon: 'icon-utilisateur', text: NameFormat({ value: organizer.displayName }), bold: true })),
        React.createElement(FicheBody, { columns: 'singleColumn' },
            React.createElement(Icon, { icon: 'icon-entreprise', text: setNcIfNull(subject.costumer.name), className: 'mt-7', bold: true }),
            React.createElement("div", { className: 'mt-7 nowrap' }, `${setNcIfNull(subject.costumer.adressRow1)} ${setNcIfNull(subject.costumer.zipCode)} ${setNcIfNull(subject.costumer.city)}`),
            React.createElement(Icon, { icon: 'icon-contact', text: getTextAttendees(attendees), className: 'mt-7', bold: true })),
        onDelete && (React.createElement("div", { onClick: () => onDelete(rdv._id) },
            React.createElement("span", null, "X")))));
};
export const AgendaLeftPanelList = ({ rdvs, onClick, infiniteScrollHeight, hasNextPage, loadNextPage, isLoading, selected, headerTitle = '', icon, onDelete, }) => {
    return (React.createElement("div", { className: "centerPanel-RDV" },
        React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: headerTitle },
            React.createElement("div", null,
                rdvs.length === 0 && React.createElement("div", null, "Aucun rendez vous"),
                React.createElement(InfiniteScroll, { data: rdvs, hasNextPage: hasNextPage, loadNextPage: loadNextPage, rowHeight: 180, height: infiniteScrollHeight, width: 440, isNextPageLoading: isLoading, component: ({ index }) => {
                        return (React.createElement("div", { key: `agent-list-${index}`, className: classnames('card-affaire-container', {
                                'card-affaire-container-active': selected && selected._id === rdvs[index]._id,
                            }) },
                            React.createElement(FicheAgendaLeftPanel, { rdv: rdvs[index], onDelete: onDelete, key: `rdv-${index}`, color: selected && selected._id === rdvs[index]._id ? 'darkblue' : 'lightyellow', idx: index, onClick: onClick })));
                    } })))));
};
