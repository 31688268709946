import React, { Fragment } from 'react';
import { useCmdAffair } from './useCustomerAffairs';
import { FicheAffairePanel } from '../../Affaires/FicheAffairs';
import { useSelector } from 'react-redux';
import Loading from '../../../../shared/Loading';
import Filters from '../../../Filters/Filters';
import { useAffaires } from '../../Affaires/useAffaires';
import InfiniteScroll from '../../../../components/InfiniteScroll/InfiniteScroll';
export const SingleAffair = ({ data, hidden }) => {
    const { affair, isLoading } = useCmdAffair(data.id);
    const params = useSelector((state) => state.parameters);
    return (React.createElement(Fragment, null,
        isLoading && React.createElement(Loading, { withoutLogo: false }),
        affair && (React.createElement(FicheAffairePanel, { affair: affair, params: params, hidden: hidden, selected: false, color: 'white' }))));
};
const CustomerAffairs = ({ data, withFilter = false, rightPanelRef, isHidden }) => {
    const { affaires: customerAffairs, setFilters, hasNextPage, isLoading, loadNextPage, reloadData } = useAffaires([
        { id: 'costumer.id', value: data.subject.costumer.id },
    ]);
    const params = useSelector((state) => state.parameters);
    const getWidth = () => {
        if (!rightPanelRef)
            return 0;
        if (!rightPanelRef.current)
            return 0;
        return rightPanelRef.current.clientWidth - 20;
    };
    return (React.createElement(Fragment, null, customerAffairs && (React.createElement("div", { className: "card card-color-lightgray card-panel-right" },
        isLoading && React.createElement(Loading, { withoutLogo: false }),
        withFilter && (React.createElement(Filters, { filtersConfig: ['badges', 'date', 'salerCostumerId'], sendFilters: filters => setFilters(filters), defaultAsyncValue: { costumerId: data.subject.costumer.id } })),
        React.createElement(InfiniteScroll, { data: customerAffairs, hasNextPage: hasNextPage, loadNextPage: loadNextPage, rowHeight: 160, width: getWidth(), isNextPageLoading: isLoading, component: ({ index }) => {
                return (React.createElement(FicheAffairePanel, { affair: customerAffairs[index], params: params, key: `affair-${index}`, hidden: isHidden, className: 'mb-3', selected: false, color: 'white' }));
            } })))));
};
export default CustomerAffairs;
