import React from 'react';
import { FicheBody } from '../../../components/Fiche/FicheDefaultLayout';
import Icon from '../../../components/Icon/Icon';
import NumberFormat from 'react-number-format';
const CmdLinePrice = ({ cmdLine, hidden }) => {
    const getValueOrNull = value => {
        if (value)
            return value;
        return '-';
    };
    return (React.createElement("div", { className: 'w-50 pl-2' },
        React.createElement(FicheBody, { columns: 'fiveColumns' },
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-quantites text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' }, getValueOrNull(cmdLine.cmdQty))),
            !hidden && (React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-PA-UNITAIRE-HT text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: getValueOrNull(cmdLine.priceAvarage), displayType: 'text', decimalScale: 2 })))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-PV-UNITAIRE-HT text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: getValueOrNull(cmdLine.publicPrice), displayType: 'text', decimalScale: 2 }))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-remise color-green text-small' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: getValueOrNull(cmdLine.unitPriceHt), displayType: 'text', decimalScale: 2 }))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-PV-UNITAIRE-HT text-tiny color-lightGray' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: getValueOrNull(cmdLine.discountRate), displayType: 'text', decimalScale: 2 })))),
        React.createElement(FicheBody, { columns: 'fiveColumns' },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-PV-TOTAL-APRES-REMISE-HT text-small' }),
                React.createElement("span", { className: 'text-big' },
                    React.createElement(NumberFormat, { value: getValueOrNull(cmdLine.unitPriceHt * cmdLine.cmdQty), displayType: 'text', decimalScale: 2 }))),
            React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-remise rotate color-blue text-small' }),
                React.createElement("span", { className: 'text-big' }, cmdLine && (React.createElement(NumberFormat, { value: getValueOrNull(cmdLine.unitPriceHt - cmdLine.priceAvarage), displayType: 'text', decimalScale: 2 })))),
            !hidden && (React.createElement("div", null,
                React.createElement(Icon, { icon: 'icon-group-131 color-blue text-small' }),
                React.createElement("span", { className: 'text-big' }, cmdLine.unitPriceHt && cmdLine.priceAvarage && (React.createElement(NumberFormat, { value: ((cmdLine.unitPriceHt - cmdLine.priceAvarage) * 100) / cmdLine.priceAvarage, displayType: 'text', decimalScale: 2 }))))))));
};
export default CmdLinePrice;
