import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './user/userReducer';
import paramReducer from './params/paramReducer';
import humanFunctionsReducer from './humanFunctions/paramReducer';
import socketReducer from './sockets/socketReducer';
import footerReducer from './panelFooterModal/footerReducer';
import reloaderReducer from './reloader/reloaderReducer';
import selectedProductFilterReducer from './productFilters/productFilterReducer';
export default history => combineReducers({
    router: connectRouter(history),
    user: userReducer,
    sockets: socketReducer,
    footer: footerReducer,
    reloader: reloaderReducer,
    parameters: paramReducer,
    humanFunctions: humanFunctionsReducer,
    selectedProductFilter: selectedProductFilterReducer,
});
