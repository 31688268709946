import React, { Fragment, useEffect, useState } from 'react';
import { useNotes } from './useNotes';
import Loading from '../../../shared/Loading';
import Filters from '../../Filters/Filters';
import { NotesLeftPanelList } from './NoteLeftPanel';
import RightPanelRoute from '../RightPanelRoute';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import Footer from "../Footer/Footer";
const NewNotes = ({ match, history }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const isLocked = useSelector((state) => state.footer.isLocked);
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const [shouldWaitFetched, setShouldWaitFetched] = useState(false);
    const [elementToFetch, setElementToFetch] = useState(null);
    const { notes, setSelectedNote, getOneNote, selectedNote, reloadData, setFilters, isLoading, hasNextPage, loadNextPage, } = useNotes(match.isExact);
    const sendFilters = filter => {
        setFilters(filter);
    };
    const setElement = id => {
        setShouldWaitFetched(true);
        setElementToFetch(id);
    };
    const onSelect = (type, id, defaultIcon, data) => {
        setSelectedNote(data);
        history.push(`${match.path}/rightpanel/${type}/${id}/${defaultIcon}`);
    };
    useEffect(() => {
        if (!shouldWaitFetched)
            return;
        if (!elementToFetch)
            return;
        getOneNote(elementToFetch);
        setShouldWaitFetched(false);
    }, [shouldWaitFetched]);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `whflex-col centerPanel` },
            React.createElement("div", { className: "toBeScrolled" },
                isLoading && React.createElement(Loading, { withoutLogo: false }),
                React.createElement(Filters, { filtersConfig: ['company', 'date', 'saler'], sendFilters: sendFilters }),
                React.createElement(NotesLeftPanelList, { notes: notes, onClick: onSelect, selected: selectedNote, hasNextPage: hasNextPage, isNextPageLoading: isLoading, loadNextPage: loadNextPage }))),
        React.createElement("div", { className: 'whflex-col rightPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Route, { path: "/main/notes/rightpanel/:type/:id/:tab", name: "rightpanel" },
                    React.createElement(RightPanelRoute, { data: selectedNote, reload: reloadData, isHidden: isLocked, type: 'notes', setElement: setElement, user: connectedUser }))),
            React.createElement(Footer, { user: connectedUser, type: 'notes', reload: reloadData, humanFunctions: humanFunctions, selected: selectedNote, disableElements: selectedNote
                    ? []
                    : ['relances', 'anomalies', 'suivis'] }))));
};
export default NewNotes;
