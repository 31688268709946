import React, { Fragment, useEffect, useState } from 'react';
import { useClients } from './useClients';
import { FicheMainContainer } from '../../../components/Fiche/FicheDefaultLayout';
import FicheClient from './FicheClient';
import RightPanelRoute from '../RightPanelRoute';
import { Route } from 'react-router';
import Filters from '../../Filters/Filters';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
const Clients = ({ match, history }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const humanFunctions = useSelector((state) => state.humanFunctions);
    const [shouldWaitFetched, setShouldWaitFetched] = useState(false);
    const [elementToFetch, setElementToFetch] = useState(null);
    const { clients, setFilters, setSelectedClient, selectedClient, loadSingleClient, hasNextPage, loadNextPage, isLoading, } = useClients();
    useEffect(() => {
        if (!shouldWaitFetched)
            return;
        if (!elementToFetch)
            return;
        loadSingleClient(elementToFetch);
        setShouldWaitFetched(false);
    }, [shouldWaitFetched]);
    const setElement = id => {
        setShouldWaitFetched(true);
        setElementToFetch(id);
    };
    const onSelect = async (type, id, defaultIcon, data) => {
        setSelectedClient(data);
        history.push(`${match.path}/rightpanel/${type}/${id}/${defaultIcon}`);
    };
    const sendFilters = filter => {
        setFilters(filter);
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: `whflex-col centerPanel ${selectedClient !== null ? 'block-shadow' : ''}` },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Filters, { filtersConfig: ['clientSocialReason'], sendFilters: sendFilters }),
                React.createElement(FicheMainContainer, { columns: 'singleColumn', headerTitle: 'Clients', icon: 'icon-entreprise' },
                    React.createElement("div", null,
                        React.createElement(InfiniteScroll, { data: clients, hasNextPage: hasNextPage, loadNextPage: loadNextPage, width: 450, rowHeight: 60, isNextPageLoading: isLoading, component: ({ index }) => {
                                return (React.createElement("div", { style: { marginBottom: '10px' }, key: `clients-${index}`, onClick: (_e) => {
                                        if (_e.target.localName !== 'svg' &&
                                            _e.target.localName !== 'input' &&
                                            _e.target.localName !== 'polyline')
                                            onSelect('clients', clients[index].id, 'selectedClient', clients[index]);
                                    } },
                                    React.createElement(FicheClient, { client: clients[index], color: selectedClient && selectedClient.id === clients[index].id ? 'darkblue' : 'lightgray' })));
                            } }))))),
        React.createElement("div", { className: 'whflex-col rightPanel' },
            React.createElement("div", { className: "toBeScrolled" },
                React.createElement(Route, { path: "/main/clients/rightpanel/:type/:id/:tab", name: "rightpanel" },
                    React.createElement(RightPanelRoute, { data: selectedClient, type: 'clients', setElement: setElement }))),
            React.createElement(Footer, { user: connectedUser, reload: null, type: 'clients', humanFunctions: humanFunctions, selected: selectedClient, disableElements: ['rdvs', 'notes', 'relances', 'anomalies', 'suivis', 'contact'] }))));
};
export default Clients;
