const QueryBuilder = state => {
    if (!state)
        return {};
    const { pageSize, page, sorted, filtered, pagination, sort, skip, limit } = state;
    const params = {};
    if (pagination)
        params.pagination = pagination;
    if (pageSize)
        params.itemsPerPage = pageSize;
    if (page)
        params.page = page;
    params.order = {};
    if (params.limit) {
        params.$limit = limit;
    }
    if (sort) {
        params.$sort = sort;
    }
    if (skip) {
        params.$skip = skip;
    }
    if (sorted) {
        sorted.map(el => {
            params.order[el.id] = el.desc ? 'desc' : 'asc';
            return null;
        });
    }
    if (filtered) {
        filtered.map(el => {
            switch (el.id) {
                case 'statusName':
                    params['status'] = el.value;
                    break;
                case 'property.statusName':
                    params['property.status'] = el.value;
                    break;
                case 'organisation.id':
                    params['organisation.id'] = el.value;
                    break;
                case 'session.organisation.id':
                    params['session.organisation.id'] = el.value;
                    break;
                case 'roles':
                    params['roles'] = el.value;
                    break;
                case 'id[]':
                    params['id[]'] = el.value;
                default:
                    params[el.id] = el.value;
                    break;
            }
            return null;
        });
    }
    return params;
};
export default QueryBuilder;
