import React from 'react';
import Euroformat from '../../../components/Euroformat';
import Moment from 'react-moment';
import { setNcIfNull } from '../../../helper/setNcIfNull';
const Bar = ({ text, value, percent, color }) => {
    return (React.createElement("div", { className: 'ml-2 mr-2', style: { width: `${percent}%` } },
        React.createElement("p", { className: 'flex flex-justifyCenter' }, text),
        React.createElement("div", { className: `bar flex flex-justifyCenter flex-alignCenter color-white backgroundColor-${color}`, style: { width: `100%` } }, value ? React.createElement("p", null, `${Math.abs(value) / 1000} K€`) : React.createElement("p", null, `NC`))));
};
const Outstanding = ({ biggestOutstanding, outstanding }) => {
    const getPercent = () => {
        const { toDelivered, deliveredNotPayed, notPayed, dispo } = outstanding;
        let total = 0;
        total = toDelivered + deliveredNotPayed + notPayed;
        return {
            notPayed: (Math.abs(notPayed) * 40) / total + 10,
            toDelivered: (Math.abs(toDelivered) * 40) / total + 10,
            deliveredNotPayed: (Math.abs(notPayed - toDelivered) * 40) / total + 10,
            dispo: (Math.abs(dispo) * 100) / total + 10,
        };
    };
    return (React.createElement("div", { className: 'outstanding ml-2 mr-2 cards-container outstanding' },
        React.createElement("div", { className: "cards-container_header" },
            React.createElement("p", { className: "cards-container_headerTitle uppercase" }, `ENCOURS ${setNcIfNull(outstanding.autorized)} €`)),
        React.createElement("div", { className: 'flex w-100 bar' },
            React.createElement(Bar, { text: 'Impayés', value: outstanding.notPayed, percent: getPercent()['notPayed'], color: 'red' }),
            React.createElement(Bar, { text: 'CC Livrés', value: outstanding.toDelivered, percent: getPercent()['toDelivered'], color: 'yellow' }),
            React.createElement(Bar, { text: 'CC Non livrés', value: outstanding.notPayed - outstanding.toDelivered, percent: getPercent()['deliveredNotPayed'], color: 'blue' }),
            React.createElement(Bar, { text: 'Dispo', value: outstanding.dispo, percent: getPercent()['dispo'], color: 'green' })),
        React.createElement("div", { className: 'mt-3 w-100' },
            React.createElement("table", { className: "table table-striped", style: { fontSize: '12px' } },
                React.createElement("tbody", null, biggestOutstanding.map((o, index) => {
                    const { ref, date, costumer: { socialReason }, saler, amountHtToPaid, } = o;
                    return (React.createElement("tr", { key: index },
                        React.createElement("td", { className: 'color-red bold700' }, ref),
                        React.createElement("td", null,
                            React.createElement(Moment, { format: "DD/MM/YYYY" }, date)),
                        React.createElement("td", null, socialReason),
                        React.createElement("td", null, saler ? saler.name : 'saler'),
                        React.createElement("td", { className: 'color-red bold700 align-right' },
                            React.createElement(Euroformat, { value: amountHtToPaid }))));
                }))))));
};
export default Outstanding;
