import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { createHashHistory } from "history";
import configureStore from "../redux/store";
import "bootstrap/dist/css/bootstrap.css";
import "../scss/style.scss";
import App from "./App";
const history = createHashHistory();
const { store } = configureStore(history, {});
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(ConnectedRouter, { history: history },
        React.createElement(App, null))), document.getElementById("root"));
