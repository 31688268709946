import { assign, Machine } from 'xstate';
import { fetchSearchResult } from '../../../utilities/apiService/fetchLists';
export const createSearchMachine = () => {
    const machine = Machine({
        id: 'searchMachine',
        initial: 'idle',
        context: { inputs: [], types: [], results: [], selected: [] },
        states: {
            idle: {
                on: {
                    OPEN: 'open',
                },
            },
            view: { on: { CLOSE: 'idle', SEARCH_AGAIN: 'open.selection' } },
            open: {
                initial: 'noselect',
                on: {
                    CLOSE: 'idle',
                    OPEN_VIEW: 'view',
                    SET_TYPE: { target: 'open', actions: ['setType'] },
                    REMOVE_INPUT: { target: 'open', actions: ['removeInput'] },
                    ADD_INPUT: { target: 'open', actions: ['addInput'] },
                },
                states: {
                    noselect: { on: { SELECT: { target: 'selection', cond: 'searchValid' } } },
                    selection: {
                        invoke: {
                            src: (context) => fetchSearchResult(context.inputs, context.types),
                            onDone: {
                                target: 'success',
                                actions: assign({
                                    results: (_context, event) => event.data,
                                }),
                            },
                            onError: {
                                target: 'failure',
                            },
                        },
                    },
                    success: {
                        on: {
                            REMOVE_ALL: {
                                actions: assign({
                                    selected: () => [],
                                }),
                            },
                            REMOVE_ITEM: {
                                actions: assign({
                                    selected: (context, event) => {
                                        const updatedSelected = [...context.selected];
                                        const idx = event.data;
                                        updatedSelected.splice(idx, 1);
                                        return updatedSelected;
                                    },
                                }),
                            },
                            ADD_SELECTION: {
                                actions: assign({
                                    selected: (context, event) => {
                                        const updatedSelected = [...context.selected];
                                        const { data } = event;
                                        const idx = updatedSelected.findIndex(t => t._id === data._id);
                                        if (idx === -1)
                                            updatedSelected.push(data);
                                        return updatedSelected;
                                    },
                                }),
                            },
                        },
                    },
                    failure: {},
                },
            },
        },
    }, {
        guards: {
            searchValid: (context) => {
                return context.inputs.length > 0 && context.types.length > 0;
            },
        },
        actions: {
            addInput: assign({
                inputs: (context, event) => {
                    const updatedInputs = [...context.inputs];
                    updatedInputs.push(event.data);
                    return updatedInputs;
                },
            }),
            removeInput: assign({
                inputs: (context, event) => {
                    const updatedInputs = [...context.inputs];
                    updatedInputs.splice(event.idx, 1);
                    return updatedInputs;
                },
            }),
            setType: assign({
                types: (context, event) => {
                    const idx = context.types.findIndex(t => t === event.data);
                    const updatedTypes = context.types;
                    if (idx === -1)
                        updatedTypes.push(event.data);
                    else
                        updatedTypes.splice(idx, 1);
                    return updatedTypes;
                },
            }),
        },
    });
    return machine;
};
