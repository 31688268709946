import React from 'react';
import { Field, Form } from 'react-final-form';
import { WhFieldTxt } from '../../../components/Form/index';
import { required } from '../../../components/Form/Validator';
import { composeValidators } from '../../../components/WhForm/WhFields/Validator';
export const LoginRow = () => {
    return (React.createElement(Field, { name: "username", validate: composeValidators(required) }, props => (React.createElement("div", { className: 'inputContainer' },
        React.createElement(WhFieldTxt, Object.assign({ name: props.input.name }, props, { required: true, label: `Utilisateur`, type: "text", containerSize: "whflex-column size-tiniest colorGray" })),
        props.meta && !props.meta.error && React.createElement("span", { className: 'inputOK' })))));
};
export const PasswordRow = dataProps => {
    return (React.createElement(Field, { name: "password", validate: required }, props => (React.createElement("div", { className: 'inputContainer mt-20' },
        React.createElement(WhFieldTxt, Object.assign({ name: props.input.name }, props, dataProps, { required: true, label: `Mot de passe`, containerSize: "whflex-column size-tiniest colorGray" }))))));
};
const LoginForm = ({ onSubmit }) => {
    return (React.createElement(Form, { onSubmit: onSubmit, render: ({ handleSubmit, submitting, submitError }) => (React.createElement("form", { className: 'form', onSubmit: handleSubmit },
            React.createElement(LoginRow, null),
            React.createElement(PasswordRow, { type: 'password', placeholder: 'Mot de passe' }),
            submitError && React.createElement("div", { className: 'error' }, submitError),
            React.createElement("button", { className: 'loginForm-button mt-40', type: 'submit', disabled: submitting }, "Connexion"),
            React.createElement("a", { className: 'text-subtitle text-gray mt-20 text-w-regular', href: "#" }, "Mot de passe oubli\u00E9 ? Cliquez ici"))) }));
};
export default LoginForm;
