import React, { Fragment, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import ApiService from '../../utilities/apiService/ApiService';
import { CMDENDPOINT, COSTUMERSENDPOINT, SALERSENDPOINT } from '../../utilities/apiService/constant';
import { DatePickerField } from '../../components/WhForm/DatePicker';
import moment from 'moment';
import { Input } from 'reactstrap';
import { useSelector } from 'react-redux';
const Filters = ({ filtersConfig, sendFilters, defaultStatus, type = null, defaultFilters = null, defaultAsyncValue }) => {
    const connectedUser = useSelector((state) => state.user.data);
    const [status, setStatus] = useState(defaultStatus ? defaultStatus : null);
    const [anoType, setAnoType] = useState(null);
    const [filters, setFilters] = useState(defaultFilters);
    const updateFilters = filter => {
        const updatedFilters = filters ? [...filters] : [];
        const idx = updatedFilters.findIndex(f => f.id === filter.id);
        if (idx === -1) {
            updatedFilters.push(filter);
            setFilters(updatedFilters);
            return;
        }
        else {
            updatedFilters[idx] = filter;
        }
        setFilters(updatedFilters);
        return;
    };
    const asyncMultipleRefLoad = async (value) => {
        let filtered = [{ id: 'saler.id', value: connectedUser.take.id }];
        if (value)
            filtered = [{ id: 'multipleRef', value: value }];
        const affairService = new ApiService(CMDENDPOINT);
        const affairs = await affairService.search({ filtered: filtered, pageSize: 50 });
        return [{ label: 'Tout', value: null }].concat(affairs['hydra:member'].map(c => ({ label: c.ref, value: c.ref })));
    };
    const asyncAffairLoad = async (value) => {
        let filtered = [{ id: 'saler.id', value: connectedUser.take.id }];
        if (value)
            filtered = [{ id: 'ref', value: value }];
        const affairService = new ApiService(CMDENDPOINT);
        const affairs = await affairService.search({ filtered: filtered, pageSize: 50 });
        return [{ label: 'Tout', value: null }].concat(affairs['hydra:member'].map(c => ({ label: c.ref, value: c.ref })));
    };
    const asyncLoad = async (value) => {
        let filtered = [{ id: 'human', value: false }];
        if (value)
            filtered = [
                { id: 'human', value: false },
                { id: 'socialReason', value: value },
            ];
        const companyService = new ApiService(COSTUMERSENDPOINT);
        const companies = await companyService.search({ filtered: filtered, pageSize: 50 });
        return [{ label: 'Tout', value: null }].concat(companies['hydra:member'].map(c => ({ label: c.socialReason, value: c.id })));
    };
    const asyncContactListLoad = async (value) => {
        let filtered = [];
        if (value)
            filtered = [{ id: 'lastName', value: value }];
        const apiService = new ApiService(COSTUMERSENDPOINT);
        const res = await apiService.search({
            filtered: [
                { id: 'costumer.socialReason', value: defaultAsyncValue ? defaultAsyncValue['agendaSaler'] : null },
                { id: 'human', value: true },
                ...filtered
            ],
        });
        return [{ label: 'Tout', value: null }].concat(res['hydra:member'].map(s => ({ label: `${s.firstname.toLowerCase()} ${s.lastname.toLowerCase()}`, value: s.id })));
    };
    const asyncSalerLoad = async (value) => {
        let filtered = [];
        if (value)
            filtered = [{ id: 'lastname', value: value }];
        const salerService = new ApiService(SALERSENDPOINT);
        const salers = await salerService.search({ filtered: filtered, pageSize: 50 });
        return [{ label: 'Tout', value: null }].concat(salers['hydra:member'].map(s => ({ label: `${s.name}`, value: s.id })));
    };
    const asyncSalerCostumerIdLoad = async (value) => {
        const salerService = new ApiService(COSTUMERSENDPOINT);
        const salers = await salerService.getCostumer(defaultAsyncValue.costumerId);
        return [{ label: 'Tout', value: null }].concat(salers.map(s => ({ label: s.firstname && s.lastname ? `${s.firstname} ${s.lastname}` : 'NC', value: s.id })));
    };
    const sendAnomalyCmdFilter = () => {
        const newFilters = anoType
            ? anoType.map(t => {
                if (t === 'ALL')
                    return { id: 'all', value: null };
                if (t === 'SHARED')
                    return { id: 'shared', value: true };
                if (t === 'NOTSHARED')
                    return { id: 'shared', value: false };
                if (t === 'CLOTURE')
                    return { id: 'status', value: 100 };
                return { id: 'all', value: null };
            })
            : [];
        setFilters(newFilters);
    };
    const updateAnomalyCmdFilter = filterType => {
        const currentAnoType = anoType ? anoType : [];
        if (filterType === 'ALL') {
            if (currentAnoType.includes('ALL')) {
                setAnoType(currentAnoType.filter(a => a !== 'ALL'));
            }
            else {
                const newAnoType = [...currentAnoType];
                newAnoType.push('ALL');
                setAnoType(['ALL']);
            }
        }
        if (filterType === 'SHARED') {
            if (currentAnoType.includes('SHARED')) {
                setAnoType(currentAnoType.filter(a => a !== 'SHARED'));
            }
            else {
                const newAnoType = [...currentAnoType];
                newAnoType.push('SHARED');
                setAnoType(newAnoType.filter(a => a !== 'NOTSHARED' && a !== 'ALL'));
            }
        }
        if (filterType === 'NOTSHARED') {
            if (currentAnoType.includes('NOTSHARED')) {
                setAnoType(currentAnoType.filter(a => a !== 'NOTSHARED'));
            }
            else {
                const newAnoType = [...currentAnoType];
                newAnoType.push('NOTSHARED');
                setAnoType(newAnoType.filter(a => a !== 'SHARED' && a !== 'ALL'));
            }
        }
        if (filterType === 'CLOTURE') {
            if (currentAnoType.includes('CLOTURE')) {
                setAnoType(currentAnoType.filter(a => a !== 'CLOTURE'));
                sendAnomalyCmdFilter();
            }
            else {
                const newAnoType = [...currentAnoType];
                newAnoType.push('CLOTURE');
                setAnoType(newAnoType.filter(a => a !== 'ALL'));
            }
        }
    };
    const onChangeLibelle = e => {
        updateFilters({ id: 'libelle', value: e.target.value });
    };
    const onChangeContactLastName = e => {
        updateFilters({ id: 'lastname', value: e.target.value });
    };
    const onChangeClientSocialReason = e => {
        updateFilters({ id: 'socialReason', value: e.target.value });
    };
    const onChangeSelect = value => {
        updateFilters({ id: type === 'anomaly' ? 'cmd.costumer' : 'costumer', value: value.value, label: value.label });
    };
    const onChangeMultipleRefSelect = value => updateFilters({ id: 'multipleRef', value: value.value, label: value.label });
    const onChangeAffairSelect = value => updateFilters({ id: 'ref', value: value.value, label: value.label });
    const onChangeSalerSelect = value => updateFilters({ id: 'saler', value: value.value, label: value.label });
    const onChangeAgendaContactSaler = value => updateFilters({ id: 'agendaSaler', value: value.value, label: value.label });
    const onUpdateDate = dateObj => updateFilters(dateObj);
    const getValue = id => {
        if (!filters)
            return null;
        const filter = filters.find(f => f.id === id);
        return filter && filter.value ? { label: filter.label, value: filter.value } : null;
    };
    const clearValue = id => {
        if (!filters)
            return;
        updateFilters({ id: id, value: null });
    };
    useEffect(() => {
        updateFilters({ id: 'status', value: status });
    }, [status]);
    useEffect(() => {
        if (!filters)
            return;
        sendFilters(filters.filter(f => f.value !== null));
    }, [filters]);
    useEffect(() => {
        if (!anoType)
            return;
        sendAnomalyCmdFilter();
    }, [anoType]);
    return (React.createElement("div", { className: "filtersContainer mb-15" },
        filtersConfig.includes('libelle') && (React.createElement("div", { className: "filtersContainer-row ml-10" },
            React.createElement("div", { className: "filterIcon icon-article mr-10" }),
            React.createElement(Input, { type: 'text', onChange: onChangeLibelle }))),
        filtersConfig.includes('multipleRef') && (React.createElement("div", { className: "filtersContainer-row withPointerEvents" },
            React.createElement("div", { className: "filterIcon icon-affaire mr-10" }),
            React.createElement("div", { className: 'filterSelect ' },
                React.createElement(AsyncSelect, { name: 'affair', isDisabled: false, closeOnSelect: false, isClearable: true, isSearchable: true, value: getValue('multipleRef'), defaultOptions: true, onChange: onChangeMultipleRefSelect, loadOptions: selectValue => asyncMultipleRefLoad(selectValue), placeholder: 'Tout', backspaceRemoves: true, classNamePrefix: "FMySel", className: "FMySelect-control" }),
                React.createElement("div", { className: 'async-remove', onClick: () => clearValue('multipleRef') },
                    React.createElement("span", null, "X"))))),
        filtersConfig.includes('ref') && (React.createElement("div", { className: "filtersContainer-row withPointerEvents" },
            React.createElement("div", { className: "filterIcon icon-affaire mr-10" }),
            React.createElement("div", { className: 'filterSelect ' },
                React.createElement(AsyncSelect, { name: 'affair', isDisabled: false, closeOnSelect: false, isClearable: true, isSearchable: true, value: getValue('ref'), defaultOptions: true, onChange: onChangeAffairSelect, loadOptions: selectValue => asyncAffairLoad(selectValue), placeholder: 'Tout', backspaceRemoves: true, classNamePrefix: "FMySel", className: "FMySelect-control" }),
                React.createElement("div", { className: 'async-remove', onClick: () => clearValue('ref') },
                    React.createElement("span", null, "X"))))),
        filtersConfig.includes('contactLastName') && (React.createElement("div", { className: "filtersContainer-row ml-10" },
            React.createElement("div", { className: "filterIcon icon-contact mr-10" }),
            React.createElement(Input, { type: 'text', onChange: onChangeContactLastName }))),
        filtersConfig.includes('clientSocialReason') && (React.createElement("div", { className: "filtersContainer-row ml-10" },
            React.createElement("div", { className: "filterIcon icon-entreprise mr-10" }),
            React.createElement(Input, { type: 'text', onChange: onChangeClientSocialReason }))),
        filtersConfig.includes('company') && (React.createElement("div", { className: "filtersContainer-row withPointerEvents" },
            React.createElement("div", { className: "filterIcon icon-entreprise mr-10" }),
            React.createElement("div", { className: 'filterSelect withPointerEvents' },
                React.createElement(AsyncSelect, { name: 'company', isDisabled: false, isSearchable: true, closeOnSelect: false, value: getValue(type === 'anomaly' ? 'cmd.costumer' : 'costumer'), defaultOptions: true, onChange: onChangeSelect, loadOptions: selectValue => asyncLoad(selectValue), placeholder: 'Tout', classNamePrefix: "FMySel", className: "FMySelect-control" })),
            React.createElement("div", { className: 'async-remove', onClick: () => clearValue(type === 'anomaly' ? 'cmd.costumer' : 'costumer') },
                React.createElement("span", null, "X")))),
        filtersConfig.includes('anomaly_status') && (React.createElement("div", { className: "filtersContainer-row mt-5" },
            React.createElement("div", { className: "filterIcon icon-filter mr-10" }),
            React.createElement("div", { className: "filtersContainer-row mt-5" },
                React.createElement("span", { onClick: () => updateAnomalyCmdFilter('ALL'), className: `badge badge-filterBadge ${anoType && anoType.includes('ALL') ? 'active' : ''}` }, "Tous"),
                React.createElement("span", { onClick: () => updateAnomalyCmdFilter('SHARED'), className: `badge badge-filterBadge ${anoType && anoType.includes('SHARED') ? 'active' : ''}` }, "Partag\u00E9es"),
                React.createElement("span", { onClick: () => updateAnomalyCmdFilter('NOTSHARED'), className: `badge badge-filterBadge ${anoType && anoType.includes('NOTSHARED') ? 'active' : ''}` }, "Non partag\u00E9es"),
                React.createElement("span", { onClick: () => updateAnomalyCmdFilter('CLOTURE'), className: `badge badge-filterBadge ${anoType && anoType.includes('CLOTURE') ? 'active' : ''}` }, "Clotur\u00E9e")))),
        filtersConfig.includes('badges_relance') && (React.createElement("div", { className: "filtersContainer-row mt-5" },
            React.createElement("div", { className: "filterIcon icon-filter mr-10" }),
            React.createElement("div", { className: "filtersContainer-row mt-5" },
                React.createElement("span", { onClick: () => setStatus('OPEN'), className: `badge badge-filterBadge ${status === 'OPEN' ? 'active' : ''}` }, "OUVERTS"),
                React.createElement("span", { onClick: () => setStatus('CLOSE'), className: `badge badge-filterBadge ${status === 'CLOSE' ? 'active' : ''}` }, "CLOTURES")))),
        filtersConfig.includes('badges') && (React.createElement("div", { className: "filtersContainer-row mt-5" },
            React.createElement("div", { className: "filterIcon icon-filter mr-10" }),
            React.createElement("div", { className: "filtersContainer-row mt-5" },
                React.createElement("span", { onClick: () => setStatus(null), className: `badge badge-filterBadge ${status === null ? 'active' : ''}` }, "TOUT"),
                React.createElement("span", { onClick: () => setStatus('of'), className: `badge badge-filterBadge ${status === 'of' ? 'active' : ''}` }, "OF"),
                React.createElement("span", { onClick: () => setStatus('cc'), className: `badge badge-filterBadge ${status === 'cc' ? 'active' : ''}` }, "CC"),
                React.createElement("span", { onClick: () => setStatus('cf'), className: `badge badge-filterBadge ${status === 'cf' ? 'active' : ''}` }, "CF"),
                React.createElement("span", { onClick: () => setStatus('prp'), className: `badge badge-filterBadge ${status === 'prp' ? 'active' : ''}` }, "PRP"),
                React.createElement("span", { onClick: () => setStatus('lvr'), className: `badge badge-filterBadge ${status === 'lvr' ? 'active' : ''}` }, "LVR"),
                React.createElement("span", { onClick: () => setStatus('er'), className: `badge badge-filterBadge ${status === 'er' ? 'active' : ''}` }, "\u20AC!")))),
        React.createElement("div", { className: "filterContainer-datePicker filtersContainer-row mt-5" },
            filtersConfig.includes('date') && (React.createElement(Fragment, null,
                React.createElement("div", { className: "filterIcon icon-date mr-10" }),
                React.createElement("div", { className: 'filter-date-picker-wrapper badge badge-filterBadge mr-5 ' },
                    React.createElement(DatePickerField, { className: 'badge-filterBadge', onChange: value => {
                            onUpdateDate({ id: 'createdAt[after]', value: moment(value).format('YYYY-MM-DD') });
                        }, placeholderText: moment(new Date()).format('DD/MM/YYYY') })),
                React.createElement("div", { className: 'filter-date-picker-wrapper badge mr-5 badge badge-filterBadge mr-5 ' },
                    React.createElement(DatePickerField, { className: 'badge-filterBadge', onChange: value => {
                            onUpdateDate({
                                id: 'createdAt[before]',
                                value: moment(value).format('YYYY-MM-DD'),
                            });
                        }, placeholderText: moment(new Date()).format('DD/MM/YYYY') })))),
            filtersConfig.includes('agendaSaler') && (React.createElement("div", { className: "filtersContainer-row ml-10" },
                React.createElement("div", { className: "filterIcon icon-contact mr-10" }),
                React.createElement("div", { className: 'filterSelect ' },
                    React.createElement(AsyncSelect, { name: 'agendaSaler', isDisabled: false, closeOnSelect: false, isSearchable: true, defaultOptions: true, value: getValue('agendaSaler'), onChange: onChangeAgendaContactSaler, loadOptions: selectValue => asyncContactListLoad(selectValue), placeholder: 'Tout', classNamePrefix: "FMySel", className: "FMySelect-control" }),
                    React.createElement("div", { className: 'async-remove', onClick: () => clearValue('agendaSaler') },
                        React.createElement("span", null, "X"))))),
            filtersConfig.includes('saler') && (React.createElement("div", { className: "filtersContainer-row ml-10" },
                React.createElement("div", { className: "filterIcon icon-utilisateur mr-10" }),
                React.createElement("div", { className: 'filterSelect ' },
                    React.createElement(AsyncSelect, { name: 'company', isDisabled: false, closeOnSelect: false, isSearchable: true, defaultOptions: true, value: getValue('saler'), onChange: onChangeSalerSelect, loadOptions: selectValue => asyncSalerLoad(selectValue), placeholder: 'Tout', classNamePrefix: "FMySel", className: "FMySelect-control" }),
                    React.createElement("div", { className: 'async-remove', onClick: () => clearValue('saler') },
                        React.createElement("span", null, "X"))))),
            filtersConfig.includes('salerCostumerId') && (React.createElement("div", { className: "filtersContainer-row ml-10" },
                React.createElement("div", { className: "filterIcon icon-utilisateur mr-10" }),
                React.createElement("div", { className: 'filterSelect ' },
                    React.createElement(AsyncSelect, { name: 'company', isDisabled: false, closeOnSelect: false, isSearchable: true, defaultOptions: true, value: getValue('saler'), onChange: onChangeSalerSelect, loadOptions: selectValue => asyncSalerCostumerIdLoad(selectValue), placeholder: 'Tout', classNamePrefix: "FMySel", className: "FMySelect-control" }),
                    React.createElement("div", { className: 'async-remove', onClick: () => clearValue('saler') },
                        React.createElement("span", null, "X"))))))));
};
export default Filters;
